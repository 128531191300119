import { yupResolver } from '@hookform/resolvers/yup';
import { ButtonsContainer } from 'components/edit-forms/styles';
import EnterCustomButton from 'components/enter-custom-button';
import { format, parse } from 'date-fns';
import { EditButton } from 'features/profile/styles';
import Stroke from 'images/x.svg';
import { isDate, toLower } from 'lodash';
import { Client } from 'model/client';
import { Partner } from 'model/dashboard';
import { EditStep, legalPersonPath, partnerPath } from 'model/edit-paths';
import SystemStepCategory from 'model/enums/system-step-category';
import { useEditForm } from 'provider/edit-form';
import { useSelectLists } from 'provider/select-list';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { unMaskedCurrency } from 'shared/util/register-utils';
import StringUtils, { validateCNPJ, validateCpf } from 'shared/util/string-utils';
import * as yup from 'yup';
import { editLegalPersonFormFields, editPartnerFormFields } from './edit-form-fields';
import { EditLegalFormSteps } from './edit-form-steps';
import { Container, FormContainer, Header, HeaderItem } from './styles';

interface IDynamicEditFormProps {
  onlyView?: boolean;
  mobile?: boolean;
  editingPartner?: boolean;
  partner?: Partner;
  clientData?: Client | null;
  onClickBack?: () => void;
  onEditClient?: (client: Client) => void;
  onEditPartner?: (partner: Partner) => void;
  isOnModal?: boolean;
}

const DynamicDesktopLegalPersonEditForm: React.FC<IDynamicEditFormProps> = props => {
  const {
    getClientData,
    editSteps,
    setEditSteps,
    actualStep,
    setInitialPartnerData,
    onClickNext,
    isLoading,
    initialPartnerData,
    setCurrentPartner,
    initialClientData,
    currentPartner,
    setCurrentEditStep,
    currentEditStep,
    setClientFiles,
    setInitialClientData,
    files,
  } = useEditForm();
  const { mainActivities, occupations } = useSelectLists();
  const { t } = useTranslation();
  const history = useHistory();
  const [editingPartner, setEditingPartner] = useState<boolean>(!!props.editingPartner);

  useEffect(() => {
    getClientData();
    const editPath = editingPartner ? partnerPath : legalPersonPath;
    setEditSteps(editPath);
    setCurrentEditStep(editPath[0]);
  }, []);

  useEffect(() => {
    if (!!props.partner) {
      setInitialPartnerData(props.partner);
      props.clientData && setInitialClientData(props.clientData);
      const partners = initialClientData?.legalPerson?.partners;
      const index = partners?.findIndex(partner => partner.cpf == props.partner?.cpf);
      if (index !== undefined) {
        setCurrentPartner(index);
      }
    }
  }, [initialClientData]);

  useEffect(() => {
    if (props.clientData) {
      setInitialClientData(props.clientData);
    }
  }, [props.clientData]);

  useEffect(() => {
    setEditingPartner(initialPartnerData !== null);
  }, [initialPartnerData]);

  const resetPartnerEdit = () => {
    setEditingPartner(false);
    setInitialPartnerData(null);
    if (!props.partner) {
      setEditSteps(legalPersonPath);
      const myCompanyIndex = legalPersonPath.findIndex(item => item.step === SystemStepCategory.MY_COMPANY);
      const stepIndex = myCompanyIndex === -1 ? 0 : myCompanyIndex;
      setCurrentEditStep(legalPersonPath[stepIndex]);
      setClientFiles();
    } else {
      onClickBack();
    }
  };

  const partnerSchema = yup.object({
    name: yup.string().required(t('global.errorMessage.required')),
    birth: yup
      .date()
      .transform((value, originalValue) => {
        const parsedDate = isDate(originalValue) ? originalValue : parse(originalValue, 'dd/MM/yyyy', new Date());
        return parsedDate;
      })
      .required(t('global.errorMessage.required'))
      .typeError(t('global.errorMessage.date')),
    phone: yup.string().min(10, t('global.errorMessage.phone')).required(t('global.errorMessage.required')),
    cpf: yup
      .string()
      .min(11, t('global.errorMessage.invalidCpf'))
      .test('test-cpf', t('global.errorMessage.invalidCpf'), cpf => validateCpf(cpf!))
      .required(t('global.errorMessage.required')),
    rg: yup.string().min(5, t('global.errorMessage.invalidRG')).required(t('global.errorMessage.required')),
    email: yup.string().email(t('global.errorMessage.email')).required(t('global.errorMessage.required')),
    occupation: yup
      .string()
      .required(t('global.errorMessage.required'))
      .test('valid-reference', t('global.errorMessage.invalidReference'), value => {
        if (value) {
          return occupations.map(item => item.name && item.name.toLowerCase()).includes(value.toLowerCase());
        } else {
          return false;
        }
      }),
    income: yup
      .string()
      .required(t('global.errorMessage.required'))
      .test('test-income', t('global.errorMessage.income'), income => {
        return Boolean(income?.match(/\d/g));
      }),
    zipcode: yup.string().min(9, t('global.errorMessage.zipcode')).required(t('global.errorMessage.required')),
    street: yup.string().required(t('global.errorMessage.required')),
    number: yup.string().required(t('global.errorMessage.required')),
    complement: yup.string(),
    district: yup.string().required(t('global.errorMessage.required')),
    state: yup.string().required(t('global.errorMessage.required')),
    city: yup.string().required(t('global.errorMessage.required')),
  });

  const legalPersonSchema = yup.object({
    responsibleName: yup.string().required(t('global.errorMessage.required')),
    formationDate: yup
      .date()
      .transform((value, originalValue) => {
        const parsedDate = isDate(originalValue) ? originalValue : parse(originalValue, 'dd/MM/yyyy', new Date());
        return parsedDate;
      })
      .required(t('global.errorMessage.required'))
      .typeError(t('global.errorMessage.date')),
    cnpj: yup
      .string()
      .min(15, t('global.errorMessage.invalidCnpj'))
      .test('test-cnpj', t('global.errorMessage.invalidCnpj'), cnpj => validateCNPJ(cnpj!))
      .required(t('global.errorMessage.required')),
    corporateName: yup.string().required(t('global.errorMessage.required')),
    mainActivity: yup
      .string()
      .required(t('global.errorMessage.required'))
      .test(
        'test-mainActivity',
        t('global.errorMessage.invalidMainActivity'),
        activityName => mainActivities.find(activity => activity.name === activityName) !== undefined
      ),
    email: yup.string().email(t('global.errorMessage.email')).required(t('global.errorMessage.required')),
    zipcode: yup.string().min(9, t('global.errorMessage.zipcode')).required(t('global.errorMessage.required')),
    street: yup.string().required(t('global.errorMessage.required')),
    number: yup.string().required(t('global.errorMessage.required')),
    complement: yup.string(),
    district: yup.string().required(t('global.errorMessage.required')),
    state: yup.string().required(t('global.errorMessage.required')),
    city: yup.string().required(t('global.errorMessage.required')),
    baseDate: yup
      .date()
      .transform((value, originalValue) => {
        const parsedDate = isDate(originalValue) ? originalValue : StringUtils.parseMonthYearDate(originalValue);
        return parsedDate;
      })
      .required(t('global.errorMessage.required'))
      .typeError(t('global.errorMessage.date')),
    netWorth: yup
      .string()
      .required(t('global.errorMessage.required'))
      .test('test-income', t('global.errorMessage.income'), netWorth => {
        return Boolean(netWorth?.match(/\d/g));
      }),
    revenue: yup
      .string()
      .required(t('global.errorMessage.required'))
      .test('test-income', t('global.errorMessage.income'), revenue => {
        return Boolean(revenue?.match(/\d/g));
      }),
  });

  const legalPersonMethods = useForm({ resolver: yupResolver(legalPersonSchema) });
  const partnerMethods = useForm({ resolver: yupResolver(partnerSchema) });

  const getCurrentMethods = () => {
    return editingPartner ? partnerMethods : legalPersonMethods;
  };

  const onSubmit = res => {
    const baseDate = format(StringUtils.parseMonthYearDate(res.baseDate), 'yyyy-MM-dd');
    if (editingPartner) {
      if (!!initialPartnerData && currentPartner !== null) {
        const partners = initialClientData?.legalPerson?.partners ?? [];
        const newPartner = partnerFromForm(res);
        partners[currentPartner] = newPartner;
        setInitialPartnerData(newPartner);
        const newClient = {
          ...initialClientData,
          legalPerson: {
            ...initialClientData?.legalPerson,
            baseDate,
            partners,
          },
        } as Client;
        setInitialClientData(newClient);
        if (props.onEditClient) {
          props.onEditClient(newClient);
        }
        if (props.onEditPartner) {
          props.onEditPartner(newPartner);
        } else {
          onClickNext({
            ...newClient.address,
            ...newClient.legalPerson,
            ...newClient,
          });
        }
      }
      resetPartnerEdit();
    } else {
      const newRes = {
        ...res,
        baseDate,
      };
      onClickNext(newRes as Client);
      onClickBack();
    }
  };

  const partnerFromForm = res => {
    const newIncome = unMaskedCurrency(res.income) / 100;
    const partnerBirth = format(parse(res.birth, 'dd/MM/yyyy', new Date()), 'yyyy-MM-dd');
    const occupation = occupations.find(occupation => occupation.name === res.occupation);

    const newPartner = {
      address: {
        ...initialPartnerData?.address,
        zipcode: res.zipcode,
        street: res.street,
        number: res.number,
        complement: res.complement,
        district: res.district,
        reference: res.reference,
      },
      income: newIncome,
      birth: partnerBirth,
      occupation,
      cpf: res.cpf,
      rg: res.rg,
      email: res.email,
      name: res.name,
      attachments: files,
      phone: res.phone,
    } as Partner;

    return newPartner;
  };

  const isCurrentStepFieldsValid = async () => {
    if (editingPartner) {
      return await getCurrentMethods().trigger(editPartnerFormFields[editSteps[actualStep].step]);
    }
    return await getCurrentMethods().trigger(editLegalPersonFormFields[editSteps[actualStep].step]);
  };

  const onChangeStep = async (editStep: EditStep) => {
    const canChangeStep = !!props.onlyView || (await isCurrentStepFieldsValid());
    if (canChangeStep) {
      setCurrentEditStep(editStep);
    }
  };

  const onClickBack = () => {
    if (!!props.onClickBack) {
      props.onClickBack();
    } else {
      history.goBack();
    }
  };

  return (
    <FormProvider {...getCurrentMethods()}>
      <form
        onSubmit={async res => {
          res.preventDefault();
          const currentStepFieldsValid = await isCurrentStepFieldsValid();
          if (currentStepFieldsValid) {
            onSubmit(getCurrentMethods().watch());
          }
        }}
        style={{ width: '100%' }}
      >
        <Container hasBorder={!props.isOnModal}>
          <Header>
            {!!editSteps &&
              editSteps.map((editStep, key) => {
                return (
                  <HeaderItem
                    key={key}
                    active={currentEditStep?.step === editStep.step}
                    onClick={async () => {
                      await onChangeStep(editStep);
                    }}
                  >
                    {t(`stepper.title.${toLower(editStep?.step)}`)}
                  </HeaderItem>
                );
              })}
          </Header>
          {!!editSteps && (
            <FormContainer>
              <EditLegalFormSteps steps={editSteps} currentStep={currentEditStep} onlyView={props.onlyView ?? false} />
            </FormContainer>
          )}
        </Container>
        {!props.onlyView && (
          <ButtonsContainer>
            <EditButton type="button" onClick={onClickBack}>
              <img src={Stroke} />
              <span>{t('global.button.cancelEdit')}</span>
            </EditButton>
            <EnterCustomButton type="submit" isLoading={isLoading} text={t('global.button.saveData')} />
          </ButtonsContainer>
        )}
      </form>
    </FormProvider>
  );
};

export default DynamicDesktopLegalPersonEditForm;
