export default {
  primaryColor: '#3457F9',
  primaryColorDarker: '#00AAA9',
  primaryColorLight: '#3457F9',
  customColorText: '#4C4D4D',
  titleText: '#333333',
  paragraphText: '#666666',
  lpColors: {
    headerLp: '#FFFFFF',
    headerTextColor: '#333',
    openingScreen: '#F6F6F6',
    infinityDataScreen: '#00AAA9',
    infinityDataText: '#FFFFFF',
    infinityDataCircle: '#03B7B6',
    infinityDataIcon: '#FFFFFF',
    whatIsScreen: '#FFFFFF',
    whatIsScreenTextColor: '#FFFFFF',
    whatIsIcon: '#00AAA9',
    stepColor: '##00AAA9',
    stepByStepScreen: '#FFFFFF',
    faqScreen: '#F3F8FF',
    footerLp: '#1A2E4E',
    simulatorButton: '#3457F9',
    color1: '#3457F9',
    color2: '#3457F9',
    color3: '#00AAA9',
    color4: '#00AAA9',
    color5: '#2B4E85',
    color6: '#F3F8FF',
  },
};
