import styled from 'styled-components/macro';

export const PageContainer = styled.div`
  height: 100vh;
  overflow: auto;
  background-color: ${({ theme }) => theme.color.grayBackground};
`;

export const Container = styled.div`
  background-color: ${({ theme }) => theme.color.white};
  padding: 60px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  max-width: 630px;
  margin: 0 auto;

  border-radius: 8px;

  align-self: center;

  margin-top: 200px;

  > button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 240px;
  }
`;

export const Title = styled.span`
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 150%;
  color: ${({ theme }) => theme.color.gray21};

  text-align: center;

  margin: 24px 0px 40px 0px;
`;
