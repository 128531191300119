import styled from 'styled-components/macro';

export const HeaderContainer = styled.div`
  display: flex;
  align-items: baseline;
`;

export const Title = styled.div`
  width: 100%;
  margin-top: 38px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  font-style: normal;
  font-size: 32px;
  font-weight: 700;
  line-height: 48px;
  color: ${({ theme }) => theme.color.gray21};
`;

export const FilterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-top: 24px;
`;

export const FilterTagContainer = styled.div`
  display: flex;
  align-items: start;
  flex-direction: row;
  margin-top: 12px;
`;

export const FilterTagInnerContainer = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  row-gap: 12px;
  column-gap: 8px;
`;

export const Button = styled.button`
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  color: ${({ theme }) => theme.color.rejected};
  background: none;
  border: none;
`;

export const FilterContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 8px;
  row-gap: 12px;
`;
