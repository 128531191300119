import Header from 'components/general-components/header';
import { Biometrics } from 'model/auth-biometric';
import { BiometryStatus } from 'model/enums/biometric-status';
import { HeaderVariants } from 'model/enums/header-variants';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiChevronLeft } from 'react-icons/fi';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import financingService from 'services/financing-service';
import StringUtils from 'shared/util/string-utils';
import { useTheme } from 'styled-components';
import { Status } from '../dashboard/components/status';
import { Content, Label, PageContainer, QRCodeContainer, Text, TextContainer, TextField, Title, TitleHeader } from './styles';
import QRCode from 'qrcode.react';
import EnterCustomButton from 'components/enter-custom-button';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducer';
import { Authority } from 'model/enums/authority';

export const BiometryScreen = () => {
  const { color } = useTheme();
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation<{ prevPath: string }>();
  const { id } = useParams<{ id: string }>();
  const [biometry, setBiometry] = useState<Biometrics | null>(null);
  const [loading, setLoading] = useState(false);
  const authorities = useSelector((state: IRootState) => state.authentication.account?.authorities);

  const allowedFunctions = [Authority.ROLE_ADMIN, Authority.ROLE_INTERNAL_ADMIN];
  const isAdmin = authorities?.includes(Authority.ROLE_ADMIN);
  const isAdminOrInternalAdmin = allowedFunctions.some(role => authorities?.includes(role));

  const biometryHasError = biometry?.status !== BiometryStatus.ERROR;
  const biometryIsWaiting = biometry?.status === BiometryStatus.WAITING;

  const biometryHasErrorAndIsAdminOrInternal = biometryHasError && isAdminOrInternalAdmin;
  const biometryIsWaitingAndIsAdmin = biometryIsWaiting && isAdmin;

  useEffect(() => {
    getBiometry();
  }, []);

  const getBiometry = async () => {
    if (id) {
      const res = await financingService.getAuthenticateAndBiometry(Number(id));
      setBiometry(res.biometrics);
    }
  };

  const manuallyApproveBiometrics = async () => {
    setLoading(true);
    try {
      await financingService.manuallyApproveBiometrics(Number(id));
      await getBiometry();
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  return (
    <PageContainer>
      <Header variant={HeaderVariants.SECONDARY} />

      <TitleHeader>
        <Title>
          <button
            onClick={() => {
              if (location.state?.prevPath) {
                history.push(location.state.prevPath);
              } else {
                history.goBack();
              }
            }}
          >
            <FiChevronLeft color={color.primaryColor} />
          </button>
          <span className="title">{t('admin.biometryScreen.pageTitle')}</span>
        </Title>
      </TitleHeader>

      <Content>
        <TextContainer>
          <TextField gridName="bio">
            <Label>{t('admin.biometryScreen.labels.url')}</Label>
            <Text>{biometry?.url ?? ''}</Text>
          </TextField>
          <TextField gridName="date">
            <Label>{t('admin.biometryScreen.labels.date')}</Label>
            <Text>{biometry?.createdAt ? StringUtils.dateFormatMask(biometry?.createdAt.split('T')[0]) : ''}</Text>
          </TextField>
          <TextField gridName="score">
            <Label>{t('admin.biometryScreen.labels.score')}</Label>
            <Text>{biometry?.score ?? ''}</Text>
          </TextField>
          <TextField gridName="status">
            <Label>{t('admin.biometryScreen.labels.status')}</Label>
            <Status text={t(`enum.authBiometryShortStatus.${biometry?.status}`)} color={biometry?.status ?? ''} />
          </TextField>
          {biometry?.status === BiometryStatus.ERROR && biometry?.message && (
            <TextField gridName="error">
              <Label>{t('admin.biometryScreen.labels.errorMessage')}</Label>
              <Text>{biometry.message}</Text>
            </TextField>
          )}
          {(biometryHasErrorAndIsAdminOrInternal || biometryIsWaitingAndIsAdmin) && (
            <EnterCustomButton
              maxWidth={260}
              fullSize
              isLoading={loading}
              text={t('admin.biometryScreen.approveBiometry')}
              onClick={manuallyApproveBiometrics}
            />
          )}
        </TextContainer>

        {biometry?.url && (
          <QRCodeContainer>
            <QRCode renderAs="svg" style={{ width: '100%', height: '100%' }} value={biometry?.url} />
          </QRCodeContainer>
        )}
      </Content>
    </PageContainer>
  );
};
