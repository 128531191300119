import styled from 'styled-components/macro';

export const FormContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const MobileFormContainer = styled.div`
  display: flex;
`;

export const FormInputsContainer = styled.div`
  max-width: 1060px;
  min-width: 800px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  @media (max-width: 850px) {
    min-width: unset;
  }
`;

export const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  bottom: 0;
  margin-top: 25px;
`;
interface IButtonProps {
  primary?: boolean;
}
export const Button = styled.button<IButtonProps>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  gap: 8px;
  width: 160px;
  height: 48px;
  background: ${props => (props.primary ? ({ theme }) => theme.color.primaryColor : 'transparent')};
  border: 1px solid ${props => (props.primary ? ({ theme }) => theme.color.primaryColor : '#CCCCCC')};
  border-radius: 24px;

  span {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    display: flex;
    align-items: center;
    text-align: center;
    color: ${props => (props.primary ? '#fff' : '#CCCCCC')};
  }
`;
export const ImageContainer = styled.div`
  margin-left: 30px;
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  row-gap: 18px;
  padding: 18px 12px;
  background: #ffffff;
  border: 1px solid #eeeeee;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  gap: 18px;
  > div,
  label {
    flex: 1;
    max-width: 280px;
  }
  @media (min-width: 1300px) {
    > div,
    label {
      max-width: 280px;
    }
  }
`;

export const InfoPassword = styled.label`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  color: #7f7f7f;
  margin-bottom: 20px;
`;

export const RequirementTitle = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #7f7f7f;
`;
interface RequirementItemProps {
  isValid?: boolean;
}
export const RequirementItem = styled.li<RequirementItemProps>`
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: ${props => (props.isValid ? '#0DB052' : '#7f7f7f')};
`;

interface RequirementsContainerProps {
  desktop?: boolean;
}
export const RequirementsContainer = styled.div<RequirementsContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
  width: ${props => props.desktop && '100%'};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 24px 24px;
  gap: 5px;
  width: 100%;
  max-height: 170px;
  background: #ffffff;
  border: 1px solid #eeeeee;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  margin-bottom: 8px;

  > .row {
    width: 100%;
    display: flex;
    flex-direction: row;
  }
  .title {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    color: #000000;
    margin-right: 10px;
  }

  .description {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #4c4d4d;
  }
`;
