import BlueCustomButtonNoBackground from 'components/blue-custom-button-no-background';
import CardForDashboard from 'components/card-for-dashboard';
import CustomButton from 'components/custom-button';
import CustomModalTwoButtons from 'components/custom-modal-two-buttons';
import Header from 'components/general-components/header';
import LinkWithButtonAppearance from 'components/link-with-button-appearance';
import LoadingDashboard from 'components/loading-dashboard';
import StepView from 'components/step-view';
import BankAccountForm from 'features/simulator-result/dashboard/bank-account-form';
import BankAccountSelection from 'features/simulator-result/dashboard/bank-account-selection';
import { ReactComponent as BankDataSvg } from 'images/bank-data.svg';
import { ReactComponent as ChevronRightSvg } from 'images/blue-chevron-right.svg';
import { ReactComponent as ContractSignSvg } from 'images/contract-sign.svg';
import { ReactComponent as InfoSvg } from 'images/gray-info.svg';
import { ReactComponent as PaymentCompletedSvg } from 'images/payment-completed.svg';
import { ReactComponent as PaymentSvg } from 'images/payment.svg';
import { ReactComponent as ViewContratSvg } from 'images/view-contract.svg';
import { BankAccount } from 'model/bank-account';
import { ContractStatus } from 'model/enums/contract-status';
import { ICardData, IFinancingStageComponent } from 'model/enums/dashboard';
import FinancingStatusType from 'model/enums/financing-status-type';
import FinancingPaymentType from 'model/enums/financingPaymentType';
import { HeaderVariants } from 'model/enums/header-variants';
import StepMoment from 'model/enums/step-moment';
import StepType from 'model/enums/step-type';
import SystemStepCategory from 'model/enums/system-step-category';
import { BankAccountInFinancing, ContractType, PaymentType } from 'model/financing';
import OrganizationsSystemSteps from 'model/organization-system-steps';
import QRCode from 'qrcode.react';
import { useEffect, useState } from 'react';

import { isMobile } from 'react-device-detect';
import { WithTranslation, withTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import bankService from 'services/bank-service';
import financingService from 'services/financing-service';
import organizationsSystemStepsService from 'services/organization-system-steps-service';
import { ordererInstallments, stepsOrdererByStepMomentAndOrderStep } from 'shared/util/utils';
import procedureAnalysis from './procedure-analysis';
import StepsController from './steps-controller';
import { PaintSvg, StyledColumn, StyledContainer, StyledInnerContainer, StyledNameContainer, StyledStage } from './styles';
import ProcedurePaymentStatusType from 'model/procedure-payment-status';
import { ProcedurePaymentStatus } from 'model/enums/procedure-payment-status';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducer';
import contractQueueService from 'services/contract-queue-service';

export interface ProcedureStatus {
  procedureStatus: FinancingStatusType;
  procedureId: number;
  choosedValue: string;
  medicalProcedure: string;
  consultantComment?: string;
}

type TypeProcedureStage = {
  [key in SystemStepCategory]?: IFinancingStageComponent;
};

const ProcedureDashboard = ({ t }: WithTranslation) => {
  const history = useHistory();
  const location = useLocation<ProcedureStatus>();
  const { procedureStatus, procedureId, choosedValue, medicalProcedure, consultantComment } = location.state;

  const account = useSelector((state: IRootState) => state.authentication.account);

  const [isLoadingSystemSteps, setIsLoadingSystemSteps] = useState<boolean>(true);
  const [isInternalDataLoading, setIsInternalDataLoading] = useState<boolean>(true);
  const [isLockChangeAllStepsBeforeValidation, setIsLockChangeAllStepsBeforeValidation] = useState<boolean>(false);
  const [isLoadingSendSettingsButton, setIsLoadingSendSettingsButton] = useState<boolean>(false);
  const [isShowListDataModal, setIsShowListDataModal] = useState<boolean>(false);

  const [steps, setSteps] = useState<OrganizationsSystemSteps[]>([]);
  const [activeStepIndex, setActiveStepIndex] = useState<number>();
  const [lastActiveStepIndex, setLastActiveStepIndex] = useState<number>(0);
  const [lastActiveStep, setLastActiveStep] = useState<OrganizationsSystemSteps>();
  const [procedureStageComponentData, setProcedureStageComponentData] = useState<IFinancingStageComponent>();

  const [bankAccountInProcedure, setBankAccountInProcedure] = useState<BankAccountInFinancing>();
  const [selectedBankAccount, setSelectedBankAccount] = useState<BankAccount>();
  const [allBankAccounts, setAllBankAccounts] = useState<BankAccount[]>([]);
  const [isShowModalSelectBankAccount, setIsShowModalSelectBankAccount] = useState<boolean>(false);
  const [isLoadingBankAccount, setIsLoadingBankAccount] = useState<boolean>(false);
  const [isShowModalCreateBankAccount, setIsShowModalCreateBankAccount] = useState<boolean>(false);

  const [procedureContracts, setProcedureContracts] = useState<ContractType>();
  const [procerurePayments, setProcerurePayments] = useState<PaymentType>();
  const [procedurePaymentStatus, setProcedurePaymentStatus] = useState<ProcedurePaymentStatusType>();

  const [cardData, setCardData] = useState<ICardData[]>([]);

  let keepCheckingContractSignature: NodeJS.Timer | null = null;

  useEffect(() => {
    getSteps();
  }, []);

  useEffect(() => {
    steps.map((step, index) => {
      if (index > 0) {
        const previousStepCardData = StepsController({
          step: steps[index - 1].systemStep.step,
          procedureStatus,
          bankAccountInProcedure,
          procedureContracts,
          procerurePayments,
          procedurePaymentStatus,
        });

        if (previousStepCardData != null) {
          const data = StepsController({
            step: step.systemStep.step,
            procedureStatus,
            bankAccountInProcedure,
            procedureContracts,
            procerurePayments,
            procedurePaymentStatus,
            previousStep: previousStepCardData,
          });

          if (data != null) {
            setCardData(old => [...old, data]);

            if (data.isActive) {
              setLastActiveStep(step);
              setLastActiveStepIndex(index);

              if (step.stepMoment === StepMoment.AFTER_VALIDATION) {
                setIsLockChangeAllStepsBeforeValidation(true);
              }
            }
          }
        }
      } else {
        const data = StepsController({
          step: step.systemStep.step,
          procedureStatus,
          bankAccountInProcedure,
          procedureContracts,
          procerurePayments,
          procedurePaymentStatus,
        });

        if (data != null) {
          setCardData([data]);

          if (data.isActive) {
            setLastActiveStep(step);
            setLastActiveStepIndex(index);
          }
        }
      }
    });
  }, [steps, bankAccountInProcedure, procedureContracts, procerurePayments]);

  useEffect(() => {
    cardData.map(card => {
      if (card.isActive && !card.isDataFilled && card.stepCategory != null) {
        handleRequestsStep(card.stepCategory);
      }
    });

    setTimeout(() => setIsInternalDataLoading(false), 4000);
  }, [cardData]);

  useEffect(() => {
    if (lastActiveStep != null) {
      setProcedureStageComponentData(procedureStageComponent(lastActiveStep.systemStep.step, lastActiveStep.mandatory));
    } else {
      setProcedureStageComponentData(procedureStageComponent(SystemStepCategory.PROCEDURE_ANALYSIS));
    }
  }, [lastActiveStep, isInternalDataLoading, procerurePayments, procedureContracts]);

  useEffect(() => {
    if (
      procedureContracts?.contracts != null &&
      !procedureContracts.contracts.map(contract => contract.status).includes(ContractStatus.FINISHED) &&
      keepCheckingContractSignature == null
    ) {
      keepCheckingContractSignature = setInterval(() => getProcedureContract(), 30000);
    }

    return () => {
      if (keepCheckingContractSignature != null) {
        clearInterval(keepCheckingContractSignature);
      }
    };
  }, [procedureContracts?.id]);

  const getSteps = () => {
    organizationsSystemStepsService
      .getSteps(StepType.PROCEDURE)
      .then(response => setSteps([...response].sort(stepsOrdererByStepMomentAndOrderStep)))
      .finally(() => setIsLoadingSystemSteps(false));
  };

  const getProcedureBankAccount = () => {
    financingService.getFinancingBankAccount(procedureId).then(response => setBankAccountInProcedure(response.data));
  };

  const getProcedureContract = async () => {
    const contractsFromProcedure = await financingService.getFinancingContract(procedureId);
    const contractQueueForProcedureExists = (await contractQueueService.getContractQueueExists(procedureId)).contractQueueExists;
    const procedureHasContracts = contractsFromProcedure.data.contracts.length > 0;

    if (procedureHasContracts) {
      if (
        contractsFromProcedure.data.contracts.map(contract => contract.status).includes(ContractStatus.FINISHED) &&
        keepCheckingContractSignature != null
      ) {
        clearInterval(keepCheckingContractSignature);
      }

      setProcedureContracts(contractsFromProcedure.data);
    } else if (!procedureHasContracts && !contractQueueForProcedureExists) {
      createContractQueue();
    }
  };

  const getProcedurePayments = () => {
    financingService.getFinancingPayments(procedureId).then(response => {
      response.data.installments = [...response.data.installments].sort(ordererInstallments);
      setProcerurePayments(response.data);
    });
  };

  const getProcedurePaymentStatus = () => {
    financingService.getProcedurePaymentStatus(procedureId).then(response => setProcedurePaymentStatus(response.data));
  };

  const handleSendingBankAccountForProcedure = () => {
    if (selectedBankAccount?.id != null) {
      setIsLoadingBankAccount(true);

      financingService.addBankAccountInFinancing(procedureId, selectedBankAccount.id).then(() => {
        setIsShowModalSelectBankAccount(false);
        getProcedureBankAccount();
        setIsLoadingBankAccount(false);
      });
    }
  };

  const getRegisteredBanks = () => {
    bankService.getBankAccount().then(response => setAllBankAccounts(response.data));
  };

  const createContractQueue = () => {
    contractQueueService.createContractQueue(procedureId).then(() => getProcedureContract());
  };

  const handleRequestsStep = (step: SystemStepCategory) => {
    switch (step) {
      case SystemStepCategory.BANK_ACCOUNT_DATA_MULTTIPLO:
        if (bankAccountInProcedure === undefined) {
          getProcedureBankAccount();
        }

        break;
      case SystemStepCategory.CONTRACT_INTERNAL:
        if (procedureContracts?.contracts == null) {
          getProcedureContract();
        }
        break;
      case SystemStepCategory.PAYMENT_MULTTIPLO_CLINIC:
        if (procerurePayments == null) {
          getProcedurePayments();
          getProcedurePaymentStatus();
        }
        break;
      case SystemStepCategory.PAYMENT_MULTTIPLO_REFUND:
        if (procerurePayments == null) {
          getProcedurePayments();
          getProcedurePaymentStatus();
        }
        break;
      default:
        break;
    }
  };

  const procedureStageComponent = (step: SystemStepCategory, isMandatory?: boolean) => {
    const procedureStage: TypeProcedureStage = {
      BANK_ACCOUNT_DATA_MULTTIPLO: {
        title: t('dashboard.bankData'),
        subtitle:
          bankAccountInProcedure?.financingPaymentType === FinancingPaymentType.CLIENT && bankAccountInProcedure.bankAccount == null
            ? t('proceduresDashboard.productConfirmedNowAddBankDetails')
            : t('proceduresDashboard.procedureConfirmedNowWaitAccountCreation'),
        text: t('proceduresDashboard.amountCarryProcedureAccount'),
        children: (
          <PaintSvg className="bankDataSvg">
            <BankDataSvg />
          </PaintSvg>
        ),
        footer:
          bankAccountInProcedure?.financingPaymentType === FinancingPaymentType.CLIENT && bankAccountInProcedure.bankAccount == null ? (
            <CustomButton
              style={{ margin: '36px auto 0 auto', justifyContent: 'center' }}
              height={48}
              width="255px"
              onClick={() => setIsShowModalSelectBankAccount(true)}
            >
              {t('global.button.addBankData')}
            </CustomButton>
          ) : (
            <></>
          ),
      },
      CONTRACT_INTERNAL: {
        title: t('dashboard.contractSigning'),
        subtitle:
          procedureContracts?.contracts == null ||
          procedureContracts?.contracts.length < 1 ||
          procedureContracts.contracts.map(contract => contract.status).includes(ContractStatus.NEW)
            ? t('dashboard.waitSignContract')
            : t('dashboard.almostThere'),
        text:
          procedureContracts?.contracts == null ||
          procedureContracts?.contracts.length < 1 ||
          procedureContracts.contracts.map(contract => contract.status).includes(ContractStatus.NEW)
            ? t('proceduresDashboard.youWillReceiveEmailWithInstructions')
            : procedureContracts?.contracts[0].contractSign.contractSignatories[0].signed
            ? t('proceduresDashboard.pleaseWaitReviewContract')
            : isMobile
            ? t('proceduresDashboard.clickButtonToContinueContractSigningProcess')
            : t('proceduresDashboard.scanQRCodeBelowCell'),
        children:
          procedureContracts?.contracts[0].contractSign.contractSignatories[0].signatureLink == null ||
          procedureContracts.contracts.map(contract => contract.status).includes(ContractStatus.NEW) ? (
            <PaintSvg className="contractSignSvg">
              <ContractSignSvg />
            </PaintSvg>
          ) : isMobile ? (
            <LinkWithButtonAppearance
              url={procedureContracts?.contracts[0].contractSign.contractSignatories[0].signatureLink}
              width="214px"
              style={{ margin: '24px auto 24px auto' }}
            >
              {t('global.button.signContract')}
            </LinkWithButtonAppearance>
          ) : (
            <QRCode renderAs="svg" value={procedureContracts?.contracts[0].contractSign.contractSignatories[0].signatureLink} />
          ),
        footer:
          procedureContracts?.contracts[0].id != null ? (
            <CustomButton
              style={{ margin: '25px auto 0 auto', justifyContent: 'center' }}
              height={48}
              width="197px"
              onClick={() => history.push(`/meus-contratos/${procedureContracts?.contracts[0].id}`)}
            >
              {t('global.button.viewContract')}
            </CustomButton>
          ) : (
            <></>
          ),
      },
      PAYMENT_MULTTIPLO_CLINIC: {
        title: t('dashboard.payment'),
        subtitle:
          procerurePayments?.installments == null ||
          procedurePaymentStatus?.procedurePayment?.procedurePaymentStatus !== ProcedurePaymentStatus.PAID
            ? t('proceduresDashboard.waitPaymentProcedure')
            : t('proceduresDashboard.congratulationsPaymentProcedureConfirmed'),
        text:
          procerurePayments?.installments == null ||
          procedurePaymentStatus?.procedurePayment?.procedurePaymentStatus !== ProcedurePaymentStatus.PAID
            ? t('proceduresDashboard.soonWillPaidProcedure')
            : t('proceduresDashboard.paymentWasMadeClinic'),
        children:
          procerurePayments?.installments == null ||
          procedurePaymentStatus?.procedurePayment?.procedurePaymentStatus !== ProcedurePaymentStatus.PAID ? (
            <PaintSvg className="viewContratSvg">
              <ViewContratSvg />
            </PaintSvg>
          ) : procedurePaymentStatus?.procedurePayment?.procedurePaymentStatus === ProcedurePaymentStatus.PAID ? (
            <PaintSvg className="paymentCompletedSvg">
              <PaymentCompletedSvg />
            </PaintSvg>
          ) : (
            <PaintSvg className="paymentSvg">
              <PaymentSvg />
            </PaintSvg>
          ),
        footer:
          procerurePayments?.installments[0].bankSlip != null ? (
            <CustomButton
              style={{ marginTop: '36px', justifyContent: 'center' }}
              height={48}
              width="181px"
              onClick={() => history.push(`/meus-pagamentos/${procerurePayments?.id}`)}
            >
              {t('global.button.downloadVoucher')}
            </CustomButton>
          ) : (
            <></>
          ),
      },
      PAYMENT_MULTTIPLO_REFUND: {
        title: t('proceduresDashboard.refundPayment'),
        subtitle:
          procerurePayments?.installments == null ||
          procedurePaymentStatus?.procedureRefund?.procedureRefundStatus !== ProcedurePaymentStatus.PAID
            ? t('proceduresDashboard.waitPaymentProcedure')
            : t('proceduresDashboard.congratulationsPaymentProcedureConfirmed'),
        text:
          procerurePayments?.installments == null ||
          procedurePaymentStatus?.procedureRefund?.procedureRefundStatus !== ProcedurePaymentStatus.PAID
            ? t('proceduresDashboard.soonWillPaidProcedure')
            : t('proceduresDashboard.paymentWasMadeClinic'),
        children:
          procerurePayments?.installments == null ||
          procedurePaymentStatus?.procedureRefund?.procedureRefundStatus !== ProcedurePaymentStatus.PAID ? (
            <PaintSvg className="viewContratSvg">
              <ViewContratSvg />
            </PaintSvg>
          ) : procerurePayments?.payment?.status === FinancingStatusType.FINISHED ? (
            <PaintSvg className="paymentCompletedSvg">
              <PaymentCompletedSvg />
            </PaintSvg>
          ) : (
            <PaintSvg className="paymentSvg">
              <PaymentSvg />
            </PaintSvg>
          ),
        footer:
          procerurePayments?.installments[0].bankSlip != null ? (
            <CustomButton
              style={{ marginTop: '36px', justifyContent: 'center' }}
              height={48}
              width="181px"
              onClick={() => history.push(`/meus-pagamentos/${procerurePayments?.id}`)}
            >
              {t('global.button.downloadVoucher')}
            </CustomButton>
          ) : (
            <></>
          ),
      },
      PROCEDURE_ANALYSIS: procedureAnalysis({
        procedureId,
        procedureStatus,
        choosedValue,
        medicalProcedure,
        consultantComment,
        isLoadingSendSettingsButton,
        setIsLoadingSendSettingsButton,
        backProductsList,
      }),
      LOADING: {
        title: '',
        children: <LoadingDashboard showSummaryLoading />,
      },
    };

    if (isInternalDataLoading) {
      return procedureStage[SystemStepCategory.LOADING];
    }

    if (step != null) {
      return procedureStage[step];
    }

    return procedureAnalysis({
      procedureId,
      procedureStatus,
      choosedValue,
      medicalProcedure,
      consultantComment,
      isLoadingSendSettingsButton,
      setIsLoadingSendSettingsButton,
      backProductsList,
    });
  };

  const backProductsList = () => {
    history.goBack();
  };

  return (
    <StyledContainer>
      <Header variant={HeaderVariants.SECONDARY} />
      {isLoadingSystemSteps ? (
        <LoadingDashboard />
      ) : (
        <StyledInnerContainer>
          <StyledColumn>
            <StyledNameContainer>
              <BlueCustomButtonNoBackground onClick={() => history.push('/procedimentos')}>
                <ChevronRightSvg style={{ transform: 'rotate(180deg)' }} />
                {t('global.button.backStep')}
              </BlueCustomButtonNoBackground>
            </StyledNameContainer>

            <StyledStage>
              {t('proceduresDashboard.stepProcedure')}
              <InfoSvg />
            </StyledStage>
            <StepView
              title={procedureStageComponentData?.title ?? ''}
              subtitle={procedureStageComponentData?.subtitle}
              text={procedureStageComponentData?.text}
              lastStep={lastActiveStepIndex}
              activeStep={activeStepIndex}
              steps={steps.map(step => step.systemStep.step)}
              footer={procedureStageComponentData?.footer}
            >
              {procedureStageComponentData?.children ?? <></>}
            </StepView>
          </StyledColumn>
          <StyledColumn>
            {cardData.map((card, index) => (
              <CardForDashboard
                loading={isInternalDataLoading}
                icon={card?.icon}
                title={card?.title}
                content={card?.content}
                isActive={card?.isActive}
                key={`card-${index}`}
                onHandleClick={() => {
                  if (card.stepCategory != null && !isLockChangeAllStepsBeforeValidation) {
                    setProcedureStageComponentData(procedureStageComponent(card.stepCategory, card.mandatory));
                    setActiveStepIndex(index);
                  }
                }}
                disableClick={isLockChangeAllStepsBeforeValidation && card.stepCategory !== lastActiveStep?.systemStep.step}
                arrowButton={card?.isShowArrow}
                flexCard
                redirectPathClickArrow={card.redirectPathClickArrow ?? ''}
              />
            ))}
          </StyledColumn>
        </StyledInnerContainer>
      )}

      <CustomModalTwoButtons
        isShowModal={isShowModalSelectBankAccount}
        onCloseModal={() => setIsShowModalSelectBankAccount(false)}
        title={t('dashboard.bankData')}
        children={
          <BankAccountSelection
            isShowListDataModal={isShowListDataModal}
            selectedBankAccount={selectedBankAccount}
            allBankAccounts={allBankAccounts}
            setIsShowListDataModal={setIsShowListDataModal}
            setSelectedBankAccount={setSelectedBankAccount}
            setIsShowModalCreateBankAccount={setIsShowModalCreateBankAccount}
            getRegisteredBanks={getRegisteredBanks}
            getFinancingBankAccount={getProcedureBankAccount}
          />
        }
        firstButtonText={t('global.button.cancel')}
        secondButtonText={t('global.button.add')}
        handleClickFirstButton={() => setIsShowModalSelectBankAccount(false)}
        handleClickSecondButton={() => handleSendingBankAccountForProcedure()}
        loading={isLoadingBankAccount}
      />

      <CustomModalTwoButtons
        large
        formId="bank-account-creation-form"
        isShowModal={isShowModalCreateBankAccount}
        onCloseModal={() => setIsShowModalCreateBankAccount(false)}
        title={selectedBankAccount?.id != null ? t('dashboard.editBankDetails') : t('dashboard.addBankDetails')}
        children={
          <BankAccountForm
            selectedBankAccount={selectedBankAccount}
            setSelectedBankAccount={setSelectedBankAccount}
            getRegisteredBanks={getRegisteredBanks}
            setIsShowModalCreateBankAccount={setIsShowModalCreateBankAccount}
            setIsLoadingBankAccount={setIsLoadingBankAccount}
            clientData={account?.client?.physicalPerson}
          />
        }
        firstButtonText={t('global.button.cancel')}
        secondButtonText={selectedBankAccount?.id != null ? t('global.button.save') : t('global.button.add')}
        handleClickFirstButton={() => setIsShowModalCreateBankAccount(false)}
        loading={isLoadingBankAccount}
      />
    </StyledContainer>
  );
};

export default withTranslation()(ProcedureDashboard);
