import styled, { css } from 'styled-components';

interface Props {
  selected?: boolean;
  menuOpen?: boolean;
}

interface PropsMenuOpen {
  menuOpen?: boolean;
}

export const Container = styled.div<PropsMenuOpen>`
  display: flex;
  flex-direction: column;
  padding-top: 35px;
  background-color: ${({ theme }) => theme.color.primaryColor};
  width: ${({ menuOpen }) => (menuOpen ? '280px' : '80px')};
  transition: all 0.5s ease;
  padding-left: ${({ menuOpen }) => (menuOpen ? '56px' : '16px')};
  padding-right: ${({ menuOpen }) => (menuOpen ? '36px' : '16px')};
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 33px;
  min-width: max-content;
  gap: 12px;
  flex: 1;
`;

export const ContainerUser = styled.div`
  margin-bottom: 25px;
`;

export const TabButton = styled.button<Props>`
  border: none;
  background-color: transparent;
  font-size: 14px;
  line-height: 150%;
  font-weight: 500;
  color: ${({ theme }) => theme.color.gray18};
  color: var(--primary-light, #eff6ff);
  padding: 4px 14px;
  text-align: left;
  min-height: 40px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 12px;
  width: ${({ menuOpen }) => (menuOpen ? '175px' : '48px')};
  overflow: hidden;
  transition: all 0.5s ease;

  ${({ selected }) =>
    selected &&
    css`
      color: #fff;
      font-weight: 700;
      background-image: linear-gradient(rgb(0 0 0/40%) 0 0);
    `};
`;

export const ContainerArrow = styled.div`
  width: 210px;
  display: flex;
  justify-content: space-between;
`;

export const StyledArrow = styled.div<PropsMenuOpen>`
  width: ${({ menuOpen }) => (menuOpen ? '40px' : '48px')};
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.5s ease;
  transform: ${({ menuOpen }) => (menuOpen ? 'rotate(-180deg)' : 'rotate(0deg)')};
`;
