import styled from 'styled-components';
import Select from 'react-select';

interface StyledSelectProps {
  hasError?: boolean;
}

export const StyledSelect = styled(Select)`
  .Select__container {
  }

  .Select__control {
    border-radius: 6px;
    height: 44px;
    width: 100%;
    border: ${({ hasError }: StyledSelectProps) => (hasError ? '2px solid #ff0000' : '1px solid #a1a1a1')};
    cursor: pointer;
  }

  .Select__control:hover {
    background-color: #f6f6f6;
    border-color: ${({ hasError }: StyledSelectProps) => (hasError ? '#ff0000' : '#a1a1a1')};
  }

  .Select__menu {
  }

  .Select__menu-list {
  }

  .Select__menu-portal {
  }

  .Select__value-container {
    font-size: 16px;
    color: #333333;
  }

  .Select__option {
    border-bottom: 0.5px solid #cccccc;
    padding: 15px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    background-color: #fafafa;
    color: #656666;

    :last-child {
      border-bottom: none;
    }
  }

  .Select__option--is-focused {
    background-color: #f6f6f6;
  }

  .Select__option--is-selected {
    background-color: #f6f6f6;
    color: #656666;
  }

  .Select__option:hover {
    background-color: #f6f6f6;
  }

  .Select__control--is-focused {
    box-shadow: 0 0 0 1px black;
    outline: none;
  }

  .Select__indicator-separator {
    display: none;
  }

  .Select__dropdown-indicator {
    color: #3c3d3e;
  }

  .Select__single-value {
    color: #333333;
    font-weight: 400;
    font-size: 16px;
  }

  .Select__menu {
    color: #3c3d3e;
  }
`;
