import registerApi from 'api/register-api';
import axios from 'axios';
import { ORGANIZATION_NAME } from 'config/constants';
import { AddressViaCep } from 'model/address';
import { Attachment } from 'model/attachment';
import { Client } from 'model/client';
import { Partner } from 'model/dashboard';
import { Authority } from 'model/enums/authority';
import { LocalStorageKeys } from 'model/enums/local-storage-keys';
import { OrganizationName } from 'model/enums/organization-name';
import { RoutePath } from 'model/enums/route-path';
import StepMoment from 'model/enums/step-moment';
import StepType from 'model/enums/step-type';
import SystemStepCategory from 'model/enums/system-step-category';
import TypeSubStepCategory from 'model/enums/type-substep-category';
import OrganizationsSystemSteps, { SubStep } from 'model/organization-system-steps';
import { References } from 'model/physical-person';
import { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { IRootState } from 'reducer';
import clientService from 'services/client-service';
import organizationSystemStepsService from 'services/organization-system-steps-service';
import registerService from 'services/register-service';
import AuthUtils from 'shared/util/auth-utils';
import { unMaskedFieldsValues } from 'shared/util/register-utils';
import { UF } from 'shared/util/states';

interface RegisterData {
  steps: OrganizationsSystemSteps[];
  substeps: SubStep[];
  setSubsteps: (substeps: SubStep[]) => void;
  actualStep: number;
  actualSubstep: TypeSubStepCategory;
  setActualSubstep: (substep: TypeSubStepCategory) => void;
  isOpenEndModal: boolean;
  initialClientData: Client | null;
  initialPartnerData: Partner | null;
  addingPartner: boolean;
  setAddingPartner: (set: boolean) => void;
  registerStatus: string;
  onClickNext: (newData: Client, isGuarantorRegister?: boolean) => void;
  onClickBack: () => void;
  isFirstForm: () => boolean;
  isLastForm: () => boolean;
  getClientData: () => void;
  getCepData: (cep: string) => void;
  validateMajority: (date: Date) => boolean;
  handleSetEndModal: () => void;
  resetStates: () => void;
  setInitialClientData: (Register: Client) => void;
  setInitialPartnerData: (register: Partner | null) => void;
  setFiles: (file: Attachment[]) => void;
  files: Attachment[];
  isLoading: boolean;
  isCepLoading: boolean;
  showMobileStepsModal: boolean;
  setShowMobileStepsModal: (value: boolean) => void;
  references: References[];
  setReferences: (value: References[]) => void;
}

interface RegisterProps {
  children: ReactNode;
}

const RegisterFormContext = createContext<RegisterData>({} as RegisterData);

export const RegisterFormProvider = ({ children }: RegisterProps) => {
  const history = useHistory();
  const [registrationSteps, setRegistrationSteps] = useState<OrganizationsSystemSteps[]>([]);
  const [steps, setSteps] = useState<OrganizationsSystemSteps[]>([]);
  const [substeps, setSubsteps] = useState<SubStep[]>([]);
  const [files, setFiles] = useState<Attachment[]>([]);
  const [references, setReferences] = useState<References[]>([]);
  const [actualStep, setActualStep] = useState(0);
  const [actualSubstep, setActualSubstep] = useState<TypeSubStepCategory>(TypeSubStepCategory.NONE);
  const [initialClientData, setInitialClientData] = useState<Client | null>(null);
  const [initialPartnerData, setInitialPartnerData] = useState<Partner | null>(null);
  const [addingPartner, setAddingPartner] = useState(false);
  const [isOpenEndModal, setIsOpenEndModal] = useState(false);
  const [registerStatus, setRegisterStatus] = useState('');
  const [stateId, setStateId] = useState<number>(0);
  const [cityId, setCityId] = useState<number>(0);
  const [cepDetails, setCepDetails] = useState<AddressViaCep>();
  const [isLoading, setIsLoading] = useState(false);
  const [isCepLoading, setCepIsLoading] = useState(false);
  const [showMobileStepsModal, setShowMobileStepsModal] = useState(false);

  const useQuery = () => {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
  };
  const authorities = useSelector((state: IRootState) => state.authentication.account?.authorities);
  const account = useSelector((state: IRootState) => state.authentication.account);

  const allowedFunctions = [
    Authority.ROLE_ADMIN,
    Authority.ROLE_INTERNAL_ADMIN,
    Authority.ROLE_MANAGER,
    Authority.ROLE_FINANCIAL,
    Authority.ROLE_ANALYST,
  ];
  const customPermissionForMulttiploOrganization =
    ORGANIZATION_NAME === OrganizationName.MULTTIPLO && allowedFunctions.some(role => authorities?.includes(role));

  useEffect(() => {
    const registrationKey = query.get(LocalStorageKeys.REGISTRATION_KEY);

    if (registrationKey == null && !customPermissionForMulttiploOrganization) {
      history.push(RoutePath.HOME);
    }
  });

  useEffect(() => {
    const fetchRegistrationSteps = async () => {
      const organizationSystemSteps = await organizationSystemStepsService.getSteps();
      const registrationSteps = organizationSystemSteps
        .filter(step => step.stepType === StepType.REGISTRATION)
        .filter(step => step.stepMoment === StepMoment.BEFORE_VALIDATION);
      if (AuthUtils.isAuthenticated() && AuthUtils.isAdmin()) {
        const newSteps = registrationSteps.filter(step => step.systemStep?.step !== SystemStepCategory.PASSWORD);
        setRegistrationSteps(newSteps);
      } else {
        setRegistrationSteps(registrationSteps);
      }
      setSteps(organizationSystemSteps);
    };

    fetchRegistrationSteps();
  }, []);

  useEffect(() => {
    if (registerStatus !== '') {
      setIsOpenEndModal(true);
    }
  }, [registerStatus]);

  useEffect(() => {
    if (cepDetails) {
      if (addingPartner) {
        handleSetNewPartnerAddress(cepDetails);
      } else {
        handleSetNewAddress(cepDetails);
      }
      getCityId(cepDetails.localidade);
    }
  }, [stateId, cepDetails, cityId]);

  const query = useQuery();

  const onClickNext = newData => {
    setIsLoading(true);

    if (actualStep < registrationSteps.length - 1) {
      setActualStep(actualStep + 1);
      setInitialClientData({ ...initialClientData, ...newData });
      setIsLoading(false);
    }

    if (actualStep === registrationSteps.length - 1) {
      setInitialClientData({ ...initialClientData, ...newData });

      const registerData: Client = unMaskedFieldsValues({ ...initialClientData, ...newData });
      if (registerData.physicalPerson && registerData.physicalPerson.income) {
        registerData.physicalPerson.income = Number(registerData.physicalPerson.income.toFixed(2));
      }

      register(registerData);
    }
  };

  const onClickBack = () => {
    if (actualStep > 0) {
      setActualStep(actualStep - 1);
    }
  };

  const isFirstForm = () => actualStep === 0;

  const isLastForm = () => actualStep === registrationSteps.length - 1;

  const register = async (registerData: Client) => {
    const isLegalPerson = !!registerData.legalPerson?.cnpj;

    if (customPermissionForMulttiploOrganization && account?.internal?.organizationSubsidiary) {
      registerData.organizationSubsidiary = account.internal.organizationSubsidiary;
    }

    try {
      if (AuthUtils.isAuthenticated() && AuthUtils.isAdmin()) {
        const registerRoute = isLegalPerson ? registerApi.registerLegalPersonAdmin : registerApi.registerPhysicalPersonAdmin;
        const res = await registerRoute(registerData);
        setRegisterStatus(res.status.toString());
        if (steps.some(it => it.systemStep.step === SystemStepCategory.SELFIE)) {
          history.push(`/selfie/${res.data.id}/admin/qrcode/${res.data.selfieKey}`);
        } else {
          history.push(`/admin/cadastros/${res.data.id}`);
        }
      } else {
        const registerRoute = isLegalPerson ? registerApi.registerLegalPerson : registerApi.registerPhysicalPerson;
        const res = await registerRoute(registerData);

        setRegisterStatus(res.status.toString());
        history.push('/cadastro/enviado');
      }
      setIsLoading(false);
    } catch (error: any) {
      setRegisterStatus(error.response.status.toString());
      setIsLoading(false);
    }
  };

  const getClientData = () => {
    const registrationKey = query.get(LocalStorageKeys.REGISTRATION_KEY);

    if (registrationKey != null) {
      clientService
        .getClientRegistration(registrationKey)
        .then(registration => {
          const res = registration;
          const client = {
            ...res,
            address: {
              ...res.address,
              state: res.address?.city?.state,
            },
          } as Client;
          setInitialClientData(client);
        })
        .catch(() => {
          history.push('/');
        });
    }
  };

  const getStateId = async (name?: string) => {
    const res = await registerApi.getStates(7, 0, name, '');
    setStateId(res.data.content?.[0]?.id ?? 0);
  };

  const getCityId = async (name?: string) => {
    const res = await registerApi.getCities(stateId, 1, 0, name);
    setCityId(res.data.content?.[0]?.id ?? 0);
  };

  const handleSetNewAddress = (res: AddressViaCep) => {
    const newAddress = {
      ...initialClientData?.address,
      zipcode: res.cep,
      street: res.logradouro !== '' ? res.logradouro : initialClientData?.address?.street,
      district: res.bairro !== '' ? res.bairro : initialClientData?.address?.district,
      city: {
        ...initialClientData?.address?.city,
        id: cityId,
        name: res.localidade,
        state: {
          ...initialClientData?.address?.city?.state,
          id: stateId,
          name: UF.find(state => state.acronym === res.uf)?.name,
        },
      },
    };

    setInitialClientData({
      ...initialClientData,
      address: newAddress,
    });
  };

  const handleSetNewPartnerAddress = (res: AddressViaCep) => {
    const newAddress = {
      ...initialPartnerData?.address,
      zipcode: res.cep,
      street: res.logradouro !== '' ? res.logradouro : initialPartnerData?.address?.street,
      district: res.bairro !== '' ? res.bairro : initialPartnerData?.address?.district,
      city: {
        id: cityId,
        name: res.localidade,
      },
      state: {
        id: stateId,
        name: UF.find(state => state.acronym === res.uf)?.name,
      },
    };

    const data = initialPartnerData ?? ({} as Partner);
    setInitialPartnerData({
      ...data,
      address: newAddress,
    });
  };

  const getCepData = (cep: string) => {
    setCepIsLoading(true);
    cep = cep.replace(/\D/g, '');

    axios.get<AddressViaCep>(`https://viacep.com.br/ws/${cep}/json/`).then(res => {
      if (res.data.cep) {
        setCepDetails(res.data);
        getStateId(UF.find(state => state.acronym === res.data.uf)?.name);
        setCepIsLoading(false);
      }
    });
  };

  const validateMajority = (date: Date): boolean => {
    const today = new Date();

    const majorityYear = today.getFullYear() - 18;

    if (date.getFullYear() <= majorityYear) {
      return true;
    }

    return false;
  };

  const handleSetEndModal = () => {
    setIsOpenEndModal(!isOpenEndModal);
  };

  const resetStates = () => {
    setActualStep(0);
    setSubsteps([]);
    setActualSubstep(TypeSubStepCategory.NONE);
    setInitialClientData(null);
    setInitialPartnerData(null);
    setRegisterStatus('');
  };

  return (
    <RegisterFormContext.Provider
      value={{
        steps: registrationSteps,
        substeps,
        setSubsteps,
        actualStep,
        actualSubstep,
        setActualSubstep,
        getCepData,
        onClickNext,
        onClickBack,
        isFirstForm,
        isLastForm,
        getClientData,
        validateMajority,
        initialClientData,
        initialPartnerData,
        isOpenEndModal,
        handleSetEndModal,
        resetStates,
        registerStatus,
        files,
        setFiles,
        setInitialClientData,
        setInitialPartnerData,
        addingPartner,
        setAddingPartner,
        isLoading,
        isCepLoading,
        showMobileStepsModal,
        setShowMobileStepsModal,
        references,
        setReferences,
      }}
    >
      {children}
    </RegisterFormContext.Provider>
  );
};

export const useRegisterForm = () => useContext(RegisterFormContext);
