import styled from 'styled-components/macro';

interface ContainerProps {
  visible?: boolean;
}
export const Container = styled.div<ContainerProps>`
  width: 100%;
  display: ${props => !props.visible && 'none'};
`;
export const Title = styled.span`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  display: flex;
  align-items: center;
  color: #1b1a1b;
  margin-bottom: 10px;
`;
export const IdentityContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 30px;
  max-height: 520px;
  gap: 20px;
`;
export const ProofsContainer = styled.div`
  margin-top: 10px;
  margin-bottom: 30px;
`;
export const InfoContainer = styled.div`
  display: flex;
  ul {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;
export const DocumentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px 10px 0px 10px;
`;
