import UploadImg from 'images/upload.svg';
import { UploadedFileType } from 'model/uploaded-file';
import { useEditForm } from 'provider/edit-form';
import React, { useEffect, useState } from 'react';
import Dropzone from 'react-dropzone';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Container, ContainerTitleAndData, Label } from './styles';
import { ErrorMessage } from 'features/admin/clientData/components/analisysModal/styles';

interface Props {
  title?: string;
  titleDocument: string;
  editDocument?: boolean;
  nameInput?: string;
  name?: string;
  type?: string;
  docUrl?: string;
  requiredError?: boolean;
}
export default function ContainerEditDocumentMobile({
  title,
  titleDocument,
  editDocument,
  nameInput,
  name,
  type,
  docUrl,
  requiredError,
}: Props) {
  const { t } = useTranslation();
  const [filename, setFilename] = useState<string>();
  const [newFileName, setNewFileName] = useState<string>('');
  const [url, setUrl] = useState<string>();
  const [subtitle, setSubTitle] = useState(title);
  const { setFiles, files } = useEditForm();
  const [invalidDocumentFormat, setInvalidDocumentFormat] = useState<string>();

  const {
    register,
    formState: { errors },
  } = useFormContext();

  const handleRegister = register(name ?? '');

  const SliceFilename = (file: string) => {
    const splitFile = file.split('.');
    const len = splitFile.length;
    const extension = splitFile[len - 1];
    return `${splitFile[0].split('').slice(0, 6).join('')}...${extension}`;
  };

  const onHandleChangeField = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target?.files?.[0]?.name) {
      return;
    }
    if (e.target.files) {
      let newFile = e.target.files[0].name;
      if (newFile.split('.')[0].length > 45) {
        newFile = SliceFilename(newFile);
      }
      setFilename(e.target.files[0].name);
      setSubTitle(newFile);
    }
  };

  const handleAddFile = (event: any) => {
    const uploadedFile = event[0];
    const uploadedFileTypes = [
      UploadedFileType.PNG,
      UploadedFileType.JPG,
      UploadedFileType.JPEG,
      UploadedFileType.DNG,
      UploadedFileType.HEIF,
      UploadedFileType.HEIC,
      UploadedFileType.HEICs,
      UploadedFileType.PDF,
    ];

    if (uploadedFile == null) {
      return;
    }

    if (!uploadedFileTypes.includes(uploadedFile.type)) {
      setInvalidDocumentFormat(uploadedFile.type);
      return;
    } else {
      setInvalidDocumentFormat(undefined);
    }

    const reader = new FileReader();
    onHandleChangeField(event);
    reader.onload = (ev: any) => {
      const existFilenameEquals = files.find(file => file.fileName === uploadedFile.name);
      const renameFile = `${Math.floor(Math.random() * 101)}${uploadedFile.name}`;
      setNewFileName(renameFile);

      setFiles([
        ...files,
        {
          fileName: renameFile,
          contentType: uploadedFile.type,
          file: ev.target.result.split(',')[1],
          type: type ?? '',
        },
      ]);
    };
    reader.readAsDataURL(uploadedFile);
  };

  useEffect(() => {
    setSubTitle(title);
    setFilename(title);
  }, [title]);

  useEffect(() => {
    if (newFileName !== '') {
      setFilename(newFileName);
    }
  }, [newFileName]);

  useEffect(() => {
    if (docUrl) {
      setUrl(docUrl);
    }
  }, [docUrl]);

  return (
    <Dropzone onDrop={acceptedFiles => handleAddFile(acceptedFiles)}>
      {({ getRootProps, getInputProps }) => (
        <>
          <Container>
            <div {...getRootProps()}>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '10px' }}>
                <img src={UploadImg} width="20" />
              </div>
              <Label>{t('global.button.mobileAttach')}</Label>
              <input
                type="file"
                id={nameInput}
                style={{ display: 'none' }}
                {...register(name ?? '')}
                accept="image/x-png, image/jpg, image/jpeg, image/svg, .pdf"
                onChange={e => {
                  handleAddFile(e);
                  handleRegister.onChange(e);
                }}
                {...getInputProps()}
              />
            </div>
            <span className="subtitle">{t('documents.container.maxSize')}</span>
            <span className="subtitle">{t('documents.container.formats')}</span>
          </Container>
          {invalidDocumentFormat && (
            <ErrorMessage>{`${invalidDocumentFormat}: ${t('global.errorMessage.invalidDocumentFormat')}`}</ErrorMessage>
          )}
        </>
      )}
    </Dropzone>
  );
}
