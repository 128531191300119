import styled from 'styled-components/macro';

interface IStyledProps {
  isReverseDirection: boolean;
}

export const StyledContainer = styled.div`
  height: 100vh;
  font-size: 16px;
  background-color: ${({ theme }) => theme.color.grayBackground};
  overflow-y: auto;

  @media (max-width: 575.98px) {
    font-size: 12px;
  }
`;

export const StyledInnerContainer = styled.div`
  display: flex;
  max-width: 962px;
  margin: 48px auto 0 auto;
  column-gap: 35px;

  @media (max-width: 991.98px) {
    flex-direction: column;
    align-items: center;
    row-gap: 24px;
    padding: 0 16px;
  }
`;

export const StyledColumn = styled.div`
  width: 100%;

  @media (min-width: 992px) {
    width: 50%;
  }
`;

export const StyledRightContainer = styled.div``;

export const StyledNameContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledStage = styled.div`
  display: flex;
  align-items: center;

  color: ${({ theme }) => theme.color.gray19};

  margin-top: 32px;

  svg {
    margin-left: 10px;
  }

  svg {
    @media (max-width: 575.98px) {
      width: 15px;
      height: 15px;
    }
  }
`;

export const StyledGuarantorsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  svg path {
    fill: ${({ theme }) => theme.color.primaryColor};
  }
`;

export const StyledList = styled.ul`
  width: 90%;
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    padding: 8px 0;
    border-bottom: ${({ theme }) => `1px solid ${theme.color.gray20}`};
  }

  li:nth-child(1) {
    padding-top: 0;
  }

  li:nth-last-child(1) {
    padding-bottom: 0;
    border: none;
  }
`;

export const StyledChevronRightContainer = styled.div`
  display: flex;
  justify-content: space-between;

  svg path {
    fill: ${({ theme }) => theme.color.primaryColor};
  }
`;

export const StyledButtonsContainer = styled.div<IStyledProps>`
  display: flex;
  column-gap: 16px;
  justify-content: left;
  flex-direction: ${({ isReverseDirection }) => (isReverseDirection ? 'row-reverse' : 'row')};
  margin-top: 24px;

  @media (max-width: 575.98px) {
    justify-content: center;
  }
`;

export const StyledButtonAddGuarantorContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledModalContainer = styled.div`
  width: 306px;
  margin: 0 auto;
`;

export const StyledModalInnerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;

  color: ${({ theme }) => theme.color.gray18};
  border-bottom: 1px solid #999999;
  font-size: 14px;
  font-weight: 400;
  padding-bottom: 8px;
  margin-bottom: 15px;
  cursor: pointer;
`;

export const StyledModalData = styled.ul`
  width: 306px;
  max-height: 138px;

  position: absolute;
  top: 35px;

  border: 1px solid #eeeeee;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  background: #ffffff;
  list-style: none;
  padding: 16px;
  font-size: 16px;
  color: ${({ theme }) => `1px solid ${theme.color.gray19}`};
  overflow-y: auto;
  z-index: 1;

  li {
    display: flex;
    justify-content: space-between;

    border-bottom: ${({ theme }) => `1px solid ${theme.color.gray20}`};
    padding: 8px 0;
    text-align: left;
  }

  li:last-child {
    border: none;
  }
`;

export const StyledLastSelected = styled.div`
  width: 306px;
  height: 56px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  border: 1px solid #eeeeee;
  border-radius: 8px;
  font-weight: 400;
  font-size: 16px;
  color: ${({ theme }) => theme.color.gray19};
  padding: 16px;
  margin-top: 16px;
`;

export const PaintSvg = styled.span`
  &.financingAnalysisSvg {
    svg path:nth-child(12),
    svg path:nth-child(14),
    svg path:nth-child(24),
    svg path:nth-child(30) {
      fill: ${({ theme }) => theme.color.primaryColor};
    }
  }

  &.dataAdjustmentsSvg {
    svg path:nth-child(6),
    svg path:nth-child(17),
    svg path:nth-child(22),
    svg path:nth-child(32) {
      fill: ${({ theme }) => theme.color.primaryColor};
    }
  }

  &.fundingRejectedSvg {
    svg path:nth-child(5),
    svg path:nth-child(12),
    svg path:nth-child(29) {
      fill: ${({ theme }) => theme.color.primaryColor};
    }
  }

  &.biometricsSvg {
    svg path:nth-child(38),
    svg path:nth-child(40),
    svg path:nth-child(41),
    svg path:nth-child(48),
    svg path:nth-child(49) {
      fill: ${({ theme }) => theme.color.primaryColor};
    }
  }

  &.authenticationNotApprovedSvg {
    svg path:nth-child(5),
    svg path:nth-child(12),
    svg path:nth-child(29) {
      fill: ${({ theme }) => theme.color.primaryColor};
    }
  }

  &.authenticationSvg {
    svg path:nth-child(38),
    svg path:nth-child(40),
    svg path:nth-child(41),
    svg path:nth-child(48),
    svg path:nth-child(49) {
      fill: ${({ theme }) => theme.color.primaryColor};
    }
  }

  &.viewContratSvg {
    svg path:nth-child(12),
    svg path:nth-child(14),
    svg path:nth-child(24),
    svg path:nth-child(30) {
      fill: ${({ theme }) => theme.color.primaryColor};
    }
  }

  &.contractSignSvg {
    svg path:nth-child(39),
    svg path:nth-child(43),
    svg path:nth-child(62) {
      fill: ${({ theme }) => theme.color.primaryColor};
    }
  }

  &.bankDataSvg {
    svg path:nth-child(1),
    svg path:nth-child(3),
    svg path:nth-child(5),
    svg path:nth-child(12),
    svg path:nth-child(16),
    svg path:nth-child(17),
    svg path:nth-child(18),
    svg path:nth-child(30) {
      fill: ${({ theme }) => theme.color.primaryColor};
    }
  }

  &.paymentCompletedSvg {
    svg path:nth-child(2),
    svg path:nth-child(3),
    svg path:nth-child(5),
    svg path:nth-child(5),
    svg path:nth-child(12),
    svg path:nth-child(16),
    svg path:nth-child(17),
    svg path:nth-child(18),
    svg path:nth-child(30) {
      fill: ${({ theme }) => theme.color.primaryColor};
    }
  }

  &.paymentSvg {
    svg path:nth-child(44),
    svg path:nth-child(46),
    svg path:nth-child(55),
    svg path:nth-child(60),
    svg path:nth-child(67),
    svg path:nth-child(71),
    svg path:nth-child(73),
    svg path:nth-child(75),
    svg path:nth-child(77),
    svg path:nth-child(79),
    svg path:nth-child(81),
    svg path:nth-child(83),
    svg path:nth-child(85),
    svg path:nth-child(87),
    svg path:nth-child(89),
    svg path:nth-child(91),
    svg path:nth-child(93),
    svg path:nth-child(95),
    svg path:nth-child(97),
    svg path:nth-child(99) {
      fill: ${({ theme }) => theme.color.primaryColor};
    }
  }
`;

export const StyledText = styled.div`
  font-weight: 500;
  font-size: 16px;
  color: ${({ theme }) => theme.color.gray18};
  text-align: left;
  margin-top: 15px;
`;
