import styled from 'styled-components/macro';

export const StlyedProcessingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 575.98px) {
    .MuiCircularProgress-svg {
      width: 40px;
      height: 40px;
    }
  }
`;

export const ProcessingLodingText = styled.div`
  width: 480px;
  font-weight: 700;
  font-size: 16px;
  text-align: center;
  color: ${({ theme }) => theme.color.black0};
  margin: 16px 0px 0px;

  @media (max-width: 671px) {
    width: 272px;
  }
`;
