import { useSendDataToSimulator } from 'components/simulator-data/hooks/use-send-data-to-simulator';
import SimulatorContext, { SimulatorLimit } from 'context/simulator';
import { useContext } from 'react';
import landingPageService from 'services/landing-page-service';
import { useRedirect } from 'hooks/use-redirect/use-redirect';
import { ProcessingSimulatorData, ProcessingSimulatorHandler } from '..';
import crefizService from 'services/crefiz-service';

export const useCrefizSendFinancingDataHandler = (): ProcessingSimulatorHandler => {
  const { sendDataToSimulator } = useSendDataToSimulator();
  const { setSimulatorLimits } = useContext(SimulatorContext);

  const execute = async (data: ProcessingSimulatorData): Promise<ProcessingSimulatorData> => {
    const { simulatorData } = data;
    const { isNewCredit, isAdmin } = data.parameters;
    if (simulatorData) {
      if (simulatorData.id) {
        await crefizService.createAnalysis(simulatorData.id);
      }

      const organizationData = await landingPageService.getSimulatorParameters();
      await sendDataToSimulator(simulatorData, isNewCredit, isAdmin);

      const simulatorLimits: SimulatorLimit = {
        newMaxValue: organizationData.maxValue,
        newMinInstallment: organizationData.minInstallment,
      };

      data.simulatorLimits = simulatorLimits;
      setSimulatorLimits(simulatorLimits);

      data.redirect = {
        confirmSimulation: true,
      };
      return Promise.resolve(data);
    } else {
      return Promise.reject('No simulator data');
    }
  };

  return {
    execute,
  };
};
