import styled from 'styled-components/macro';

export const StyledContainer = styled.div`
  height: 100vh;
  background-color: ${({ theme }) => theme.color.grayBackground};
  overflow-y: auto;
`;

export const StyledInnerContainer = styled.div`
  max-width: 599px;
  margin: 24px auto 0 auto;
  padding: 0 16px;

  @media (max-width: 767.98px) {
    max-width: 359px;
  }
`;

export const StyledTitle = styled.div`
  color: #000000;
  font-weight: 700;
  font-size: 20px;
`;

export const StyledSvgContainer = styled.div`
  max-width: 213px;
  margin: 0 auto;
`;

export const StyledText = styled.div`
  color: ${({ theme }) => theme.color.gray22};
  font-weight: 400;
  font-size: 16px;

  margin: 12px 0 40px 0;
`;

export const StyledButton = styled.a`
  width: 288px;
  height: 45px;

  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 9px;

  background-color: ${({ theme }) => theme.color.grayBackground};
  font-size: 14px;
  font-weight: 700;
  border: ${({ theme }) => `1.5px solid ${theme.color.primaryColor}`};
  color: ${({ theme }) => theme.color.primaryColor};
  border-radius: 24px;
  text-decoration: none;
  outline: none;

  :hover {
    color: ${({ theme }) => theme.color.primaryColor};
  }
`;

export const StyledButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  row-gap: 24px;
  flex-direction: column;
  margin-top: 48px;
`;
