import CustomButton from 'components/custom-button';
import { ScreenDimensions } from 'model/enums/screen-dimensions-types';
import styled from 'styled-components/macro';

export const SimulatorContainer = styled.div`
  width: 100%;
  min-height: calc(100vh - 68px);
  height: auto;
  background: ${({ theme }) => theme.color.grayBackground}; //dynamic

  padding: 0px 0px 32px 0px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const SimulatorInsuranceContainer = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 1120px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const SimulatorButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledTitle = styled.div`
  width: 480px;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;

  color: ${({ theme }) => theme.color.black0};

  margin: 32px 0px 0px;
  text-align: center;

  @media (max-width: ${ScreenDimensions.MID_TABLET}) {
    width: 272px;
    margin: 24px 0px 0px;

    text-align: center;
  }
`;

export const StyledText = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.color.gray18};
  margin: 16px 0px 0px;
  text-align: center;
  @media (max-width: 671px) {
    width: 272px;
    text-align: center;
  }
`;

export const StyledWarning = styled.div`
  width: 480px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin: 16px 0px 0px;
  text-align: center;
  @media (max-width: 671px) {
    width: 272px;
  }
  a {
    color: ${({ theme }) => theme.color.primaryColor};
    text-align: center;
    font-family: Satoshi;
    font-style: normal;
    line-height: 150%;
    text-decoration: none;
    flex-wrap: wrap;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const ProcessingText = styled.div`
  width: 480px;

  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: ${({ theme }) => theme.color.black0};
  margin: 16px 0px 0px;

  @media (max-width: 671px) {
    width: 272px;
  }
`;

export const SimulatorBox = styled.div``;

export const StyledButton = styled.button`
  width: 480px;
  height: 48px;

  background: ${({ theme, disabled }) => (disabled ? theme.color.gray18 : theme.color.primaryColor)}; //dynamic

  border: none;
  border-radius: 24px;

  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  color: #ffffff;

  margin: 12px 0px 0px;
  padding: 0px;

  @media (max-width: 671px) {
    width: 272px;
  }
`;

export const MonthLimitExceded = styled.div`
  font-weight: 400;
  font-size: 14px;
  color: ${({ theme }) => theme.color.rejected};
`;

export const AttentionContainer = styled.div`
  width: 100%;
  max-width: 480px;
  height: 100px;
  background: #fff4f2;
  border-radius: 8px;
  display: flex;
  align-items: center;
  margin: 16px 0px 0px;
  column-gap: 24px;
  padding: 12px 24px;

  @media (max-width: ${ScreenDimensions.MID_TABLET}) {
    max-width: 272px;

    text-align: center;
  }

  span {
    text-align: left;
    font-size: 16px;
    font-weight: 700;
    line-height: 150%;
    display: flex;
    align-items: center;
    color: #333333;

    @media (max-width: ${ScreenDimensions.MID_TABLET}) {
      font-size: 14px;
    }
  }
`;
