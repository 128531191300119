import { useLayoutEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

interface IResponsive {
  maxWidth: number;
}

const useResponsiveMobile = () => {
  const [isClient, setIsClient] = useState<boolean>(false);

  const isTablet = useMediaQuery({
    maxWidth: 1080,
  });

  const isMidTablet = useMediaQuery({
    maxWidth: 768,
  });
  const isMobile = useMediaQuery({
    maxWidth: 480,
  });

  useLayoutEffect(() => {
    if (typeof window !== 'undefined') setIsClient(true);
  }, []);

  return {
    isTablet,
    isMobile,
    isMidTablet,
    isClient,
  };
};

export default useResponsiveMobile;
