import styled from 'styled-components/macro';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 16px 24px 24px;
  gap: 24px;
  width: 962px;
  background: #ffffff;
  border: 1px solid #eeeeee;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
`;
export const DocumentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
export const Title = styled.span`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  display: flex;
  align-items: center;
  color: #1b1a1b;
  margin-bottom: 10px;
`;
export const Subtitle = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  display: flex;
  align-items: center;
  color: #1b1a1b;
  margin-bottom: 20px;
  max-width: 650px;
`;
export const IdentityContainer = styled.div`
  margin-top: 10px;
  max-height: 520px;
`;
export const ProofsContainer = styled.div`
  margin-top: 10px;
  margin-bottom: 30px;
`;
export const InfoContainer = styled.div`
  display: flex;
  ul {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 340px;

    li {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 32px;
    }
  }
`;
