import { Authority } from 'model/enums/authority';
import { ComponentType, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Redirect, Route, withRouter } from 'react-router-dom';
import { IRootState } from 'reducer';
import AccessDenied from 'shared/error/access-denied';
import AuthUtils from 'shared/util/auth-utils';

interface IPrivateRouteProps extends RouteComponentProps {
  path: string;
  exact?: boolean;
  accessLevel?: Authority[];
  component: ComponentType<RouteComponentProps<any>> | ComponentType<any>;
}

interface IPrivateRouteState {
  isPreLoading: boolean;
}

export const adminAccessLevels: Authority[] = [Authority.ROLE_ADMIN, Authority.ROLE_INTERNAL_ADMIN];
export const internalAccessLevels: Authority[] = [
  Authority.ROLE_MANAGER,
  Authority.ROLE_ANALYST,
  Authority.ROLE_FINANCIAL,
  Authority.ROLE_ATTENDANT,
  Authority.ROLE_INTERNAL_ADMIN,
  Authority.ROLE_INTERNAL,
];
export const clientAccessLevel: Authority[] = [Authority.ROLE_CLIENT];

const PrivateRoute = ({ accessLevel, component: Component, ...rest }: IPrivateRouteProps) => {
  const account = useSelector((state: IRootState) => state.authentication.account);

  const checkIfAuthoritiesIncludes = useCallback(() => {
    return accessLevel
      ? account?.authorities?.some(auth =>
          accessLevel.some(access => {
            return access === auth;
          })
        )
      : true;
  }, [account]);

  return (
    <Route
      {...rest}
      render={props => {
        return AuthUtils.isAuthenticated() ? (
          checkIfAuthoritiesIncludes() ? (
            <Component {...props} />
          ) : (
            <AccessDenied />
          )
        ) : (
          <Redirect to={{ pathname: '/', state: { from: props.location.pathname } }} />
        );
      }}
    />
  );
};

export default withRouter(PrivateRoute);
