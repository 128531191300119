import clientApi from 'api/client-api';
import { AxiosError, AxiosResponse } from 'axios';
import { Attachment } from 'model/attachment';
import BiometricsStatusAndClientAuthentication from 'model/biometrics-status-and-client-authentication';
import { Client } from 'model/client';
import { CreateQueueDocFraud } from 'model/create-queue-doc-fraud';
import { CreditCard, FinancingCreditCard } from 'model/credit-card';
import HttpStatus from 'model/enums/http-status';
import { OrganizationSubsidiaries } from 'model/landing-page';
import { SelfieVM } from 'model/selfie-info';
import { ApprovalData, CanRetryMessage, Signatures } from 'model/signatures';
import { VerifyIdentity } from 'model/verify-identity';

export const ClientService = () => {
  const getClientData = async (): Promise<Client> => {
    try {
      const result: AxiosResponse<Client> = await clientApi.clientData();
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };
  const getAllClientData = async (id: number): Promise<Client> => {
    try {
      const result: AxiosResponse<Client> = await clientApi.getAllClientData(id);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };

  const getBiometricsStatusAndClientAuthentication = async (clientId: number): Promise<BiometricsStatusAndClientAuthentication> => {
    try {
      const result: AxiosResponse<BiometricsStatusAndClientAuthentication> = await clientApi.getBiometricsStatusAndClientAuthentication(
        clientId
      );
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const sendSelfie = async (attachment: Attachment, selfieKey: string): Promise<Client> => {
    try {
      const result: AxiosResponse<Client> = await clientApi.sendSelfie(attachment, selfieKey);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };

  const sendSelfieAdmin = async (attachment: Attachment, selfieKey: string): Promise<Client> => {
    try {
      const result: AxiosResponse<Client> = await clientApi.sendSelfieAdmin(attachment, selfieKey);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };

  const selfieAnalysisAdmin = async (id: number, payload: CreateQueueDocFraud): Promise<Client> => {
    try {
      const result: AxiosResponse<Client> = await clientApi.selfieAnalysisAdmin(id, payload);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };

  const finishUpdate = async (): Promise<Client> => {
    try {
      const result: AxiosResponse<Client> = await clientApi.finishUpdate();
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };

  const cancelCreditCard = async (id: number): Promise<Client> => {
    try {
      const result: AxiosResponse<Client> = await clientApi.cancelCreditCard(id);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };

  const generateSelfieKey = async (): Promise<SelfieVM> => {
    try {
      const result: AxiosResponse<SelfieVM> = await clientApi.generateSelfieKey();
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };

  const generateSelfieKeyAdmin = async (id: number): Promise<SelfieVM> => {
    try {
      const result: AxiosResponse<SelfieVM> = await clientApi.generateSelfieKeyAdmin(id);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };

  const getSelfieStatus = async (): Promise<SelfieVM> => {
    try {
      const result: AxiosResponse<SelfieVM> = await clientApi.getSelfieStatus();
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };

  const getSelfieStatusAdmin = async (id: number): Promise<SelfieVM> => {
    try {
      const result: AxiosResponse<SelfieVM> = await clientApi.getSelfieStatusAdmin(id);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };

  const getBasicInfo = async (cpf: string): Promise<Client> => {
    try {
      const result: AxiosResponse<Client> = await clientApi.getBasicInfo(cpf);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      return Promise.reject(error.response as AxiosResponse);
    }
  };

  const getCreditCardFromClient = async (clientId: number): Promise<CreditCard[]> => {
    try {
      const result: AxiosResponse<CreditCard[]> = await clientApi.getCreditCardFromClient(clientId);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };

  const getCreditCardFromFinancing = async (financingId: number): Promise<FinancingCreditCard> => {
    try {
      const result: AxiosResponse<FinancingCreditCard> = await clientApi.getCreditCardFromFinancing(financingId);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };

  const reactivateAccountAdmin = async (clientId: number): Promise<BiometricsStatusAndClientAuthentication> => {
    try {
      const result: AxiosResponse<BiometricsStatusAndClientAuthentication> = await clientApi.reactivateAccountAdmin(clientId);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };

  const getIfClientNeedsToDoBiometricsOrAuthentication = async (clientId: number): Promise<VerifyIdentity> => {
    try {
      const res: AxiosResponse<VerifyIdentity> = await clientApi.getIfClientNeedsToDoBiometricsOrAuthentication(clientId);
      if (res.status === HttpStatus.OK && res.data != null) {
        return Promise.resolve(res.data);
      }

      return Promise.reject({ status: res.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const changeClientSubsidiary = async (clientId: number, subsidiaryId: number): Promise<OrganizationSubsidiaries> => {
    try {
      const res: AxiosResponse<OrganizationSubsidiaries> = await clientApi.changeClientSubsidiary(clientId, subsidiaryId);
      if (res.status === HttpStatus.OK && res.data != null) {
        return Promise.resolve(res.data);
      }

      return Promise.reject({ status: res.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const getSignatureData = async (id: number): Promise<Signatures> => {
    try {
      const result: AxiosResponse<Signatures> = await clientApi.getSignatureData(id);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };

  const enableRetry = async (id: number, message: CanRetryMessage): Promise<Signatures> => {
    try {
      const result: AxiosResponse<Signatures> = await clientApi.enableRetry(id, message);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response?.data.code);
    }
  };

  const cancelContract = async (id: number): Promise<Signatures> => {
    try {
      const result: AxiosResponse<Signatures> = await clientApi.cancelContract(id);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };

  const resendEmail = async (id: number): Promise<Signatures> => {
    try {
      const result: AxiosResponse<Signatures> = await clientApi.resendEmail(id);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };

  const manualApproval = async (approvalData: ApprovalData): Promise<Signatures> => {
    try {
      const result: AxiosResponse<Signatures> = await clientApi.manualApproval(approvalData);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };

  const getClientRegistration = async (registrationKey: string): Promise<Client> => {
    try {
      const result: AxiosResponse<Client> = await clientApi.getClientRegistration(registrationKey);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  return {
    getClientData,
    getAllClientData,
    sendSelfie,
    sendSelfieAdmin,
    selfieAnalysisAdmin,
    finishUpdate,
    generateSelfieKey,
    generateSelfieKeyAdmin,
    getSelfieStatus,
    getBasicInfo,
    getCreditCardFromClient,
    getCreditCardFromFinancing,
    getBiometricsStatusAndClientAuthentication,
    getIfClientNeedsToDoBiometricsOrAuthentication,
    reactivateAccountAdmin,
    getSelfieStatusAdmin,
    changeClientSubsidiary,
    cancelCreditCard,
    getSignatureData,
    enableRetry,
    cancelContract,
    resendEmail,
    manualApproval,
    getClientRegistration,
  };
};

export default ClientService();
