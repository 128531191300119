import styled from 'styled-components/macro';

export const Container = styled.div`
  width: 100%;
`;
export const Title = styled.span`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  display: flex;
  align-items: center;
  color: #1b1a1b;
  margin-bottom: 10px;
`;
export const Subtitle = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  display: flex;
  align-items: center;
  color: #1b1a1b;
  margin-bottom: 20px;
  max-width: 650px;
`;
export const IdentityContainer = styled.div`
  margin-top: 10px;
  margin-bottom: 30px;
  max-height: 520px;
`;
export const ProofsContainer = styled.div`
  margin-top: 10px;
  margin-bottom: 30px;
`;
export const InfoContainer = styled.div`
  display: flex;
  ul {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;
