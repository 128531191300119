import { ButtonsContainer } from 'components/register-forms-mobile/styles';
import StyledMobileRegisterHeader from 'components/styled-mobile-register-header';
import { StyledMobileScreenContainer } from 'features/register/style';
import Attention from 'images/attention.svg';
import Sent from 'images/sent.svg';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { AttentionContainer, Button, ContainerDesktop, SubTitleDesktop, TitleDesktop } from '../styles';

interface MobileProps {}

const Mobile: React.FC<MobileProps> = props => {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <StyledMobileScreenContainer>
      <StyledMobileRegisterHeader />
      <ContainerDesktop mobile>
        <img src={Sent} width="60" />
        <TitleDesktop mobile>{t('registerSent.title')}</TitleDesktop>
        <AttentionContainer mobile>
          <img src={Attention} />
          <span>{t('registerSent.attentionMessage')}</span>
        </AttentionContainer>
        <SubTitleDesktop mobile>{t('registerSent.subTitle')}</SubTitleDesktop>
        <ButtonsContainer>
          <Button onClick={() => history.push('/login')}>
            <span>{t('global.button.login')}</span>
          </Button>
        </ButtonsContainer>
      </ContainerDesktop>
    </StyledMobileScreenContainer>
  );
};

export default Mobile;
