import { Client } from 'model/client';
import { AnalisysOptions } from 'model/enums/client-data';
import { RegistrationStatus } from 'model/enums/registration-status';
import 'react-circular-progressbar/dist/styles.css';
import { useTranslation } from 'react-i18next';
import {
  BaseModalContainer,
  ContentContainer,
  ContentContainerExtends,
  DarkModalContainer,
  InputContainer,
  Label,
  ModalTitle,
  StyledGrClose,
  Wrapper,
} from './styles';

interface AnalisysModalProps {
  clientData: Client;
  setShowModal: (action: boolean) => void;
}

export const AnalisysInfoModal = ({ clientData, setShowModal }: AnalisysModalProps) => {
  const { t } = useTranslation();

  return (
    <DarkModalContainer>
      <BaseModalContainer>
        <Wrapper>
          <StyledGrClose onClick={() => setShowModal(false)} />
          <ModalTitle>{t('clientData.seeAnalysis')}</ModalTitle>
          <InputContainer>
            <Label>{t('admin.customerAnalysis.labels.action')}</Label>
            <ContentContainer>
              {clientData.registrationStatus === RegistrationStatus.ADJUST
                ? AnalisysOptions.SEND_ADJUSTS
                : clientData.registrationStatus === RegistrationStatus.APPROVED
                ? AnalisysOptions.APPROVE
                : clientData.registrationStatus === RegistrationStatus.REJECTED
                ? AnalisysOptions.NEGATE
                : ''}
            </ContentContainer>
          </InputContainer>
          <InputContainer>
            <Label>{t('admin.customerAnalysis.labels.comment')}</Label>
            <ContentContainerExtends>{clientData?.internalComment}</ContentContainerExtends>
          </InputContainer>
        </Wrapper>
      </BaseModalContainer>
    </DarkModalContainer>
  );
};
