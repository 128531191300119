import { HeaderVariants } from 'model/enums/header-variants';
import * as S from './styles';
import Header from 'components/general-components/header';
import { WithTranslation, withTranslation } from 'react-i18next';
import PreviousPage from 'components/previous-page';
import CustomStepper from './components/custom-stepper';
import CustomLoadingStep from './components/custom-loading-step';
import CustomButton from 'components/custom-button';
import CustomModalTwoButtons from 'components/custom-modal-two-buttons';
import useNewBalanceScreen from './hooks/use-new-balance-screen';
import ContentCancelPaymentModal from './components/content-cancel-payment-modal';

const NewBalanceScreen = ({ t }: WithTranslation) => {
  const {
    color,
    state,
    isShowPaymentCancellationModal,
    currentStep,
    pixKey,
    goToPreviousScreen,
    handleCancelPayment,
    openCancelPaymentModal,
    closeCancelPaymentModal,
  } = useNewBalanceScreen();

  return (
    <div>
      <Header variant={HeaderVariants.SECONDARY} />

      <S.InnerContainer>
        <S.TitleContainer>
          <PreviousPage title={t('admin.newBalance.title')} handleClick={() => goToPreviousScreen()} />
          <S.CCBNumber>{state?.CCBNumber}</S.CCBNumber>

          {currentStep === 1 && (
            <CustomButton
              isInvertColor
              onClick={() => openCancelPaymentModal()}
              style={{ marginLeft: 'auto', color: `${color.rejected}`, borderColor: `${color.rejected}` }}
            >
              {t('global.button.cancelPayment')}
            </CustomButton>
          )}
        </S.TitleContainer>

        <CustomStepper currentStep={currentStep} />

        <CustomLoadingStep
          title={t('admin.newBalance.loading.generatingPixCode')}
          message={t('admin.newBalance.loading.yourPixCodeIsBeingGenerated')}
        />
      </S.InnerContainer>

      <CustomModalTwoButtons
        hideButtons
        isShowModal={isShowPaymentCancellationModal}
        onCloseModal={() => closeCancelPaymentModal()}
        children={
          <ContentCancelPaymentModal handleCancelPayment={() => handleCancelPayment()} handleCloseModal={() => closeCancelPaymentModal()} />
        }
      />
    </div>
  );
};

export default withTranslation()(NewBalanceScreen);
