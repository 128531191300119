import { StyledButton } from 'components/custom-button/styles';
import Header from 'components/general-components/header';
import InputText from 'components/styled-input';
import { useAuthorities } from 'hooks/use-authorities';
import { truncate } from 'lodash';
import debounce from 'lodash/debounce';
import { Authority } from 'model/enums/authority';
import { HeaderVariants } from 'model/enums/header-variants';
import { Insurance, InsuranceStatus } from 'model/insurance';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FiChevronLeft } from 'react-icons/fi';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import insuranceService from 'services/insurance-service';
import StringUtils from 'shared/util/string-utils';
import { useTheme } from 'styled-components';
import * as S from './styles';
import CustomButton from 'components/custom-button';
import CustomLoadingButton from 'components/custom-loading-button';
import { useEffect, useState } from 'react';

const InsuranceScreen = () => {
  const methods = useForm({});
  const { color } = useTheme();
  const history = useHistory();
  const { hasAuthorities } = useAuthorities();
  const { t } = useTranslation();
  const params = useParams<{ financingId: string }>();

  const [financingInsurance, setFinancingInsurance] = useState<Insurance>();

  const financingSimulation = financingInsurance?.insuranceSimulations?.[0];
  const insuranceAttachment = financingInsurance?.attachments?.[0];

  useEffect(() => {
    if (params.financingId) {
      getFinancingInsurance(Number(params.financingId));
    }
  }, [params.financingId]);

  const getFinancingInsurance = async (financingId: number) => {
    await insuranceService.getInsuranceByFinancing(financingId).then(insurance => {
      setFinancingInsurance(insurance);
    });
  };

  const cancelInsurance = async () => {
    await insuranceService.cancelInsurance(Number(params.financingId));
    await getFinancingInsurance(Number(params.financingId));
  };

  const debounceCancelInsurance = debounce(cancelInsurance, 500);

  const redirectToContract = () => {
    history.push(`/admin/contratos/analise/${financingInsurance?.contract?.id}`);
  };

  const renderViewContractButton = financingInsurance?.contract && financingInsurance.internalStatus === InsuranceStatus.TICKET_CANCELLED;
  const renderGeneratingContractMessage =
    financingInsurance?.internalStatus === InsuranceStatus.TICKET_CANCELLED && !financingInsurance.contract;

  return (
    <S.Container>
      <Header variant={HeaderVariants.SECONDARY} />
      <S.TitleHeader>
        <S.Title>
          <div className="firstTitle">
            <button
              onClick={() => {
                history.goBack();
              }}
            >
              <FiChevronLeft color={color.primaryColor} />
            </button>
            <span className="title">{t('insuranceScreen.title')}</span>
          </div>
        </S.Title>
        <S.ButtonContainer>
          {hasAuthorities([Authority.ROLE_ADMIN, Authority.ROLE_INTERNAL_ADMIN]) &&
            financingInsurance?.internalStatus === InsuranceStatus.TICKET_CREATED && (
              <div style={{ width: '100%', display: 'flex', maxWidth: '250px' }}>
                <StyledButton justifyContent="center" maxWidth="300px" width="90%" onClick={() => debounceCancelInsurance()}>
                  {t('insuranceScreen.cancel')}
                </StyledButton>
              </div>
            )}
          {renderViewContractButton && (
            <div style={{ width: '100%', display: 'flex', maxWidth: '250px' }}>
              <StyledButton justifyContent="center" maxWidth="300px" width="90%" onClick={() => redirectToContract()}>
                {t('insuranceScreen.viewContract')}
              </StyledButton>
            </div>
          )}
          {renderGeneratingContractMessage && (
            <div style={{ width: '100%', display: 'flex', maxWidth: '300px' }}>
              <CustomLoadingButton isLoading justifyContent="center" maxWidth="300px" width="90%" onClick={() => redirectToContract()}>
                {t('insuranceScreen.generatingContract')}
              </CustomLoadingButton>
            </div>
          )}
        </S.ButtonContainer>
      </S.TitleHeader>

      <S.TabContainer>
        <FormProvider {...methods}>
          <S.InputBox>
            <InputText
              name="grossPremium"
              label={t('insuranceScreen.grossPremium')}
              disabled
              defaultValue={StringUtils.currencyMask(financingSimulation?.grossPremium?.toFixed(2) ?? '')}
              width={100}
            />
            <InputText
              name="financialAmount"
              label={t('insuranceScreen.financialAmount')}
              disabled
              defaultValue={StringUtils.currencyMask(financingSimulation?.financialAmount?.toFixed(2) ?? '')}
              width={100}
            />
            <InputText
              name="insuranceStatus"
              label={t('insuranceScreen.status')}
              disabled
              defaultValue={financingSimulation?.status}
              width={100}
            />
          </S.InputBox>
          <S.InputBox>
            <InputText
              name="ticketNo"
              label={t('insuranceScreen.ticketNo')}
              disabled
              defaultValue={financingInsurance?.ticketNo}
              width={400}
              flexWidth
            />
          </S.InputBox>
          {insuranceAttachment && (
            <S.AttachmentContainer>
              <S.AttachmentTitle>{t('insuranceScreen.ticket')}</S.AttachmentTitle>
              <S.AttachmentBox>
                <a download={insuranceAttachment.fileName} href={StringUtils.getFileOrUrl(insuranceAttachment)} target="_blank">
                  {insuranceAttachment.fileName && truncate(insuranceAttachment.fileName, { length: 25 })}
                </a>
              </S.AttachmentBox>
            </S.AttachmentContainer>
          )}
        </FormProvider>
      </S.TabContainer>
    </S.Container>
  );
};

export default InsuranceScreen;
