import { LocalStorageKeys } from 'model/enums/local-storage-keys';
import { useAdmin } from 'provider/admin-general';
import { createContext } from 'react';
import useLocalStorage from 'react-use-localstorage';

export interface DateRange {
  gte?: string;
  lte?: string;
}
export enum OrderBy {
  ASC = 'asc',
  DESC = 'desc',
}

export interface Filters {
  statusFilters: string[];
  dateRange: DateRange;
  orderBy: OrderBy;
}

interface AdminContext {
  filters: Filters;
  resetFilters: () => void;
  updateStatusFilters: (newStatusFilters: string[]) => void;
  updateDateRange: (newDateRange: Partial<DateRange>) => void;
}

const DEFAULT_VALUE: AdminContext = {
  filters: {
    statusFilters: [],
    dateRange: {
      lte: '',
      gte: '',
    },
    orderBy: OrderBy.ASC,
  },
  resetFilters: () => {},
  updateStatusFilters: (newStatusFilters: string[]) => {},
  updateDateRange: (newDateRange: Partial<DateRange>) => {},
};

const AdminFiltersContext = createContext<AdminContext>(DEFAULT_VALUE);

const AdminFiltersProvider: React.FC = ({ children }) => {
  const [filtersString, setFiltersString] = useLocalStorage(LocalStorageKeys.ADMIN_FILTERS, JSON.stringify(DEFAULT_VALUE.filters));
  const { resetStatusFilters, resetCreatedDates } = useAdmin();

  const filters: Filters = JSON.parse(filtersString);

  const updateStatusFilters = (newStatusFilters: string[]) => {
    const newFilters = { ...filters, statusFilters: newStatusFilters };
    setFiltersString(JSON.stringify(newFilters));
  };
  const updateDateRange = (newDateRange: Partial<DateRange>) => {
    const updatedDateRange = { ...filters.dateRange, ...newDateRange };
    const newFilters = { ...filters, dateRange: updatedDateRange };
    setFiltersString(JSON.stringify(newFilters));
  };

  const resetFilters = () => {
    setFiltersString(JSON.stringify(DEFAULT_VALUE.filters));
  };

  return (
    <AdminFiltersContext.Provider value={{ filters, resetFilters, updateStatusFilters, updateDateRange }}>
      {children}
    </AdminFiltersContext.Provider>
  );
};

export { AdminFiltersProvider };
export default AdminFiltersContext;
