import CustomButton from 'components/custom-button';
import Header from 'components/general-components/header';
import { ORGANIZATION_NAME } from 'config/constants';
import { TabPayments } from 'features/admin/customerAnalysis/components/tabPayments';
import { Status } from 'features/admin/dashboard/components/status';
import { DownloadSvg } from 'images/download';
import { Authority } from 'model/enums/authority';
import { HeaderVariants } from 'model/enums/header-variants';
import { InstallmentStatus } from 'model/enums/installment-status';
import { OrganizationName } from 'model/enums/organization-name';
import { Installment } from 'model/installments';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiChevronLeft } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { IRootState } from 'reducer';
import StringUtils from 'shared/util/string-utils';
import { hasAuthorities } from 'shared/util/utils';
import { useTheme } from 'styled-components';
import { StatusContainer, StyledButtonContainer, StyledLoadingContainer, Tab, TabBox, TabContent } from '../styles';
import { Container, ContainerHeader, InstallmentContainer, InstallmentDetails, InstallmentTitle } from './styles';
import ReactLoading from 'react-loading';

interface MobileProps {
  tabSelected: number;
  setTabSelected: (value: number) => void;
  installments: Installment[];
  idLastConfirmedInstallment: number | null;
  downloadBankSlip: (attachUrl?: string, installment?: Installment) => void;
  handleConfirmPayment: (instalmentId?: number) => void;
  getMeat: () => Promise<void>;
}

export const Mobile = ({
  tabSelected,
  setTabSelected,
  installments,
  idLastConfirmedInstallment,
  downloadBankSlip,
  handleConfirmPayment,
  getMeat,
}: MobileProps) => {
  const history = useHistory();
  const { color } = useTheme();
  const { t } = useTranslation();
  const account = useSelector((state: IRootState) => state.authentication.account);

  const [isLoadingMeat, setIsLoadingMeat] = useState<boolean>(false);

  const getTabContent = () => {
    const notPaidInstallments = installments.filter(installment => installment.status !== InstallmentStatus.PAID);
    const paidInstallments = installments.filter(installment => installment.status === InstallmentStatus.PAID);
    switch (tabSelected) {
      case 1:
        return notPaidInstallments.map((installment, key) => (
          <InstallmentContainer key={key} isPaymentLate={installment.status === InstallmentStatus.OVERDUE}>
            <div className="details-container">
              <InstallmentTitle>
                <span className="title">{`Parcela ${installment.installmentNumber} de ${installments.length}`}</span>
                <Status text={t(`enum.filterAllStatus.${installment?.status}`)} color={installment?.status ?? ''} />
              </InstallmentTitle>
              <InstallmentDetails>
                <span className="details">
                  {`${StringUtils.currencyMask(installment.installmentValue?.toFixed(2) ?? '0')} | Venc. ${StringUtils.dateFormatMask(
                    installment.dueDate
                  )}`}
                </span>
              </InstallmentDetails>
            </div>
            {hasAuthorities(account?.authorities, [Authority.ROLE_ADMIN, Authority.ROLE_INTERNAL_ADMIN]) && (
              <StatusContainer>
                <button onClick={() => handleConfirmPayment(installment.id)} disabled={idLastConfirmedInstallment === installment.id}>
                  {idLastConfirmedInstallment === installment.id ? (
                    <StyledLoadingContainer>
                      <ReactLoading type="spinningBubbles" color={color.primaryColor} />
                    </StyledLoadingContainer>
                  ) : (
                    <span>{t('admin.paymentScreen.confirmPayment')}</span>
                  )}
                </button>
              </StatusContainer>
            )}
            <div
              onClick={() => {
                if (installment.attachment?.presignedUrl != null) {
                  downloadBankSlip(installment.attachment.presignedUrl ?? '', installment);
                }
              }}
            >
              <DownloadSvg />
            </div>
          </InstallmentContainer>
        ));
      case 2:
        return paidInstallments.map((installment, key) => (
          <InstallmentContainer key={key} isPaymentLate={installment.status === InstallmentStatus.OVERDUE}>
            <div className="details-container">
              <InstallmentTitle>
                <span className="title">{`Parcela ${installment.installmentNumber} de ${installments.length}`}</span>
                <Status text={t(`enum.filterAllStatus.${installment?.status}`)} color={installment?.status ?? ''} />
              </InstallmentTitle>
              <InstallmentDetails>
                <span className="details">
                  {`${StringUtils.currencyMask(installment.installmentValue?.toFixed(2) ?? '0')} | Venc. ${StringUtils.dateFormatMask(
                    installment.dueDate
                  )}`}
                </span>
              </InstallmentDetails>
            </div>
            <DownloadSvg />
          </InstallmentContainer>
        ));
      case 3:
        return <TabPayments isMobile showStats={false} />;
      default:
        return <></>;
    }
  };

  return (
    <>
      <Header variant={HeaderVariants.SECONDARY} />
      <Container>
        <ContainerHeader>
          <button
            onClick={() => {
              history.goBack();
            }}
          >
            <FiChevronLeft color={color.primaryColor} />
          </button>
          <span>{t('admin.paymentScreen.title')}</span>

          {ORGANIZATION_NAME === OrganizationName.CREFIZ && (
            <StyledButtonContainer>
              <CustomButton
                onClick={() =>
                  getMeat()
                    .then(() => setIsLoadingMeat(true))
                    .finally(() => setIsLoadingMeat(false))
                }
                loading={isLoadingMeat}
              >
                {t('global.button.downloadInstallmentVoucher')}
              </CustomButton>
            </StyledButtonContainer>
          )}
        </ContainerHeader>
        <TabBox isMobile>
          <Tab $isSelected={tabSelected === 1} onClick={() => setTabSelected(1)}>
            <span>{t('admin.paymentScreen.tabs.opened')}</span>
          </Tab>
          <Tab $isSelected={tabSelected === 2} onClick={() => setTabSelected(2)}>
            <span>{t('admin.paymentScreen.tabs.paid')}</span>
          </Tab>
          <Tab $isSelected={tabSelected === 3} onClick={() => setTabSelected(3)}>
            <span>{t('admin.paymentScreen.tabs.panorama')}</span>
          </Tab>
        </TabBox>
        <TabContent>{getTabContent()}</TabContent>
      </Container>
    </>
  );
};
