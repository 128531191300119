import { Button, ButtonsContainer, FormInputsContainer, Row } from 'components/edit-forms/styles';
import EnterCustomButton from 'components/enter-custom-button';
import { Column } from 'components/register-forms-mobile/styles';
import InputText from 'components/styled-input';
import { Mask } from 'model/enums/mask-types';
import { useEditForm } from 'provider/edit-form';
import { useTranslation } from 'react-i18next';
import StringUtils from 'shared/util/string-utils';

export const ContainerInputsPersonalDataMulttiplo = props => {
  const { t } = useTranslation();
  const { initialClientData, isFirstForm, isLastForm, onClickBack, isLoading } = useEditForm();

  const renderMobile = () => {
    return (
      <Column>
        <InputText
          isRequired
          disabled={props.onlyView}
          name="name"
          label={t('register.inputs.name')}
          placeholder={t('register.placeholder.name')}
          defaultValue={initialClientData?.physicalPerson?.name}
        />
        <InputText
          isRequired
          disabled={props.onlyView}
          name="birth"
          label={t('register.inputs.birthDate')}
          placeholder={t('register.placeholder.birthDate')}
          maxLength={10}
          mask={Mask.BIRTHDAY}
          defaultValue={
            initialClientData?.physicalPerson?.birth ? StringUtils.dateFormatMask(initialClientData?.physicalPerson?.birth.toString()) : ''
          }
        />
        <InputText
          isRequired
          name="phone"
          disabled={props.onlyView}
          label={t('register.inputs.phone')}
          placeholder={t('register.placeholder.phone')}
          mask={Mask.PHONE}
          maxLength={15}
          defaultValue={initialClientData?.phone}
        />
        <InputText
          isRequired
          name="cpf"
          disabled
          label={t('register.inputs.cpf')}
          placeholder={t('register.placeholder.cpf')}
          mask={Mask.CPF}
          maxLength={14}
          defaultValue={initialClientData?.physicalPerson?.cpf}
        />
        <InputText
          isRequired
          name="rg"
          disabled={props.onlyView}
          label={t('register.inputs.rg')}
          placeholder={t('register.placeholder.rg')}
          defaultValue={initialClientData?.physicalPerson?.rg}
          maxLength={20}
        />
        <InputText
          isRequired
          disabled={props.onlyView}
          name="email"
          label={t('register.inputs.email')}
          placeholder={t('register.placeholder.email')}
          defaultValue={initialClientData?.email}
        />
      </Column>
    );
  };
  return (
    <FormInputsContainer visible={props.visible}>
      {props.mobile ? (
        renderMobile()
      ) : (
        <>
          <Row>
            <InputText
              isRequired
              disabled={props.onlyView}
              name="name"
              label={t('register.inputs.name')}
              placeholder={t('register.placeholder.name')}
              defaultValue={initialClientData?.physicalPerson?.name}
            />
            <InputText
              isRequired
              disabled={props.onlyView}
              name="birth"
              label={t('register.inputs.birthDate')}
              placeholder={t('register.placeholder.birthDate')}
              maxLength={10}
              mask={Mask.BIRTHDAY}
              defaultValue={
                initialClientData?.physicalPerson?.birth
                  ? StringUtils.dateFormatMask(initialClientData?.physicalPerson?.birth.toString())
                  : ''
              }
            />
            <InputText
              isRequired
              name="phone"
              disabled={props.onlyView}
              label={t('register.inputs.phone')}
              placeholder={t('register.placeholder.phone')}
              mask={Mask.PHONE}
              maxLength={15}
              defaultValue={initialClientData?.phone}
            />
          </Row>
          <Row>
            <InputText
              isRequired
              name="cpf"
              disabled
              label={t('register.inputs.cpf')}
              placeholder={t('register.placeholder.cpf')}
              mask={Mask.CPF}
              maxLength={14}
              defaultValue={initialClientData?.physicalPerson?.cpf}
            />
            <InputText
              isRequired
              name="rg"
              disabled={props.onlyView}
              label={t('register.inputs.rg')}
              placeholder={t('register.placeholder.rg')}
              defaultValue={initialClientData?.physicalPerson?.rg}
              maxLength={20}
            />
            <InputText
              isRequired
              disabled={props.onlyView}
              name="email"
              label={t('register.inputs.email')}
              placeholder={t('register.placeholder.email')}
              defaultValue={initialClientData?.email}
            />
          </Row>
        </>
      )}
    </FormInputsContainer>
  );
};
