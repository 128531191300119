import styled from 'styled-components/macro';
interface IStyledProps {
  isWhite?: boolean;
}

export const StyledButton = styled.button<IStyledProps>`
  font-weight: 700;
  color: ${({ isWhite, theme }) => (isWhite ? '#fff' : `#0f62fe`)};
  font-size: 14px;
  border: none;
  background: none;
  padding: 0;
  margin: 16px 0 34px 0;

  svg {
    vertical-align: text-top;
    margin-left: 3px;
  }

  g {
    path {
      fill: ${({ theme, isWhite }) => (isWhite ? 'white' : '#0f62fe')};
    }
  }

  @media (min-width: 768px) {
    margin: 16px 0 10px 0px;
  }
`;
