import { yupResolver } from '@hookform/resolvers/yup';
import Translate from 'i18n/translate';
import _ from 'lodash';
import ButtonVariants from 'model/enums/buttonVariants';
import { FormProperties } from 'model/enums/form-properties';
import { SimulatorFormData } from 'model/landing-page';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import Input from '../input';
import { SliderButton, StyledForm } from '../style';
import { StyledPopup, StyledPopupDescription, StyledPopupTitle } from './styled';

interface AddressModalProps {
  open: boolean;
  formData: SimulatorFormData;
  setFormData: (data: SimulatorFormData) => void;
  setIsLoading: (value: number) => void;
  sendApi: (data: SimulatorFormData) => void;
  setHasMissingFields: (value: boolean) => void;
}

const AddressModal: React.FC<AddressModalProps> = ({ open, setIsLoading, formData, setFormData, sendApi, setHasMissingFields }) => {
  const schema = yup.object().shape({
    [FormProperties.STREET]: yup.string().required('Campo obrigatório'),
    [FormProperties.DISTRICT]: yup.string().required('Campo obrigatório'),
  });
  const methods = useForm({ resolver: yupResolver(schema), mode: 'onSubmit' });

  const handleForm = async () => {
    const isValid = await methods.trigger();
    if (!isValid) {
      return;
    }

    setIsLoading(1);
    sendApi(formData);

    setTimeout(() => {
      setIsLoading(0);
    }, 2500);
  };

  const handleSubmit = _.debounce(handleForm, 500);
  const formId = 'cep-form';
  const { t } = useTranslation();
  return (
    <StyledPopup open={open} modal nested onClose={() => setHasMissingFields(false)}>
      <StyledPopupTitle>{t('landingPage.openingScreen.simulatorForm.addressModal.title')}</StyledPopupTitle>
      <StyledPopupDescription>{t('landingPage.openingScreen.simulatorForm.addressModal.text1')}</StyledPopupDescription>
      <FormProvider {...methods}>
        <StyledForm
          onSubmit={e => {
            e.preventDefault();
            handleSubmit();
          }}
          id={formId}
        >
          <Input
            name={FormProperties.STREET}
            required={true}
            htmlFor={FormProperties.STREET}
            label={<Translate contentKey={'landingPage.openingScreen.simulatorForm.inputLabels.street'} />}
            styled={{ borderRadius: true, borderRight: true }}
            formData={formData}
            setFormData={setFormData}
          />
          <Input
            name={FormProperties.DISTRICT}
            required={true}
            htmlFor={FormProperties.DISTRICT}
            label={<Translate contentKey={'landingPage.openingScreen.simulatorForm.inputLabels.district'} />}
            styled={{ borderRadius: true, borderRight: true }}
            formData={formData}
            setFormData={setFormData}
          />
        </StyledForm>
        <SliderButton type="submit" customStyle={{ variant: ButtonVariants.DEFAULT }} form={formId}>
          <Translate contentKey={'global.button.simulate'} />
        </SliderButton>
      </FormProvider>
    </StyledPopup>
  );
};

export default AddressModal;
