import InputErrorMessage from 'components/InputErrorMessage';
import ConfirmationModal from 'components/confirmation-modal';
import CustomButton from 'components/custom-button';
import CustomModalTwoButtons from 'components/custom-modal-two-buttons';
import Slider from 'components/slider';
import { ORGANIZATION_TYPE } from 'config/constants';
import SimulatorContext from 'context/simulator';
import { GeneralImages } from 'images/general-images';
import { CreateFinancingResponse } from 'model/create-financing-response';
import { OrganizationType } from 'model/enums/organization-type';
import TypesPlusMinus from 'model/enums/types-plus-minus';
import { Financing } from 'model/financing';
import { Simulator, SimulatorParameters } from 'model/landing-page';
import { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import ReactLoading from 'react-loading';
import financingService from 'services/financing-service';
import landingPageService from 'services/landing-page-service';
import { unMaskedCurrency } from 'shared/util/register-utils';
import StringUtils from 'shared/util/string-utils';
import { useTheme } from 'styled-components';
import {
  DesiredValue,
  DesiredValueButtons,
  DesiredValueContainer,
  Installments,
  PaymentInformationBox,
  PaymentInformationContainer,
  PaymentInformationHeader,
  PaymentInformationText,
  PaymentInformationTitle,
  ResultInformationContainer,
  SectionTitle,
  SimulatorContainer,
  SliderValue,
  StyledButtonContainer,
  StyledContainerButtons,
  StyledIcon,
  StyledModalContainer,
  StyledRate,
  StyledRateBox,
  StyledTitle,
} from './styled';

interface SimulatorProps extends WithTranslation {
  financingId: number;
  isConfirmationButtonIsLoading?: boolean;
  setIsFinishedLoadingSimulatorData?: Dispatch<SetStateAction<boolean>>;
  setIsFundingAlreadyConfirmed?: Dispatch<SetStateAction<boolean>>;
  isAdminSimulator?: boolean;
  handleCloseModal?: () => void;
}

interface PaymentInformation {
  icon: string;
  title: string;
  value: string;
}

const SimulatorDashboard: React.FC<SimulatorProps> = ({
  financingId,
  isConfirmationButtonIsLoading,
  setIsFinishedLoadingSimulatorData,
  setIsFundingAlreadyConfirmed,
  isAdminSimulator,
  handleCloseModal,
  t,
}) => {
  const theme = useTheme();
  const [financingData, setFinancingData] = useState<Financing>();
  const { simulatorScreenData, setSimulatorScreenData } = useContext(SimulatorContext);
  const [desiredValue, setDesiredValue] = useState<string>('');
  const [installments, setInstallments] = useState<number>(0);
  const [maxInstallment, setMaxInstallment] = useState<number>();
  const [minInstallment, setMinInstallment] = useState<number>();
  const [currentTax, setCurrentTax] = useState<number>(0);
  const [simulatorOtherData, setSimulatorOtherData] = useState<SimulatorParameters>();
  const [simulatorFinancingResult, setSimulatorFinancingResult] = useState<Financing | null>(null);
  const [confirmError, setConfirmError] = useState<string>();
  const [isInstallmentAmountGreaterIncome, setIsInstallmentAmountGreaterIncome] = useState<boolean>(false);
  const [isShowSimulatorConfirmationModal, setIsShowSimulatorConfirmationModal] = useState<boolean>(false);
  const [isShowConfirmationModal, setIsShowConfirmationModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const isPhysicalPerson = ORGANIZATION_TYPE === OrganizationType.PF;

  const handleChange = (event: React.SyntheticEvent | Event, newValue: number | number[]) => {
    setIsInstallmentAmountGreaterIncome(false);

    if (typeof newValue === 'number') {
      setInstallments(newValue);
    }
  };

  useEffect(() => {
    if (financingData != null && financingData.choosedValue != null) {
      const defaultSimulatorDataFormatted: Simulator = {
        simulate: {
          firstInstallmentValue: Number(financingData.installmentValue?.toFixed(2).toString()),
          firstDueDate: financingData.firstDueDate,
        },
      };

      defaultSimulatorDataFormatted.simulate.requestedValue =
        financingData.approvedValue != null ? financingData.approvedValue : financingData.choosedValue;

      setSimulatorScreenData(defaultSimulatorDataFormatted as CreateFinancingResponse);
      setInstallments(financingData?.installmentsTotal ?? 0);
      setCurrentTax(financingData.approvedTax ?? financingData.financingTax ?? 0);
    }
  }, [financingData]);

  useEffect(() => {
    checkIfFundingAlreadyConfirmed();

    financingService.getFinancingById(financingId).then(response => {
      setFinancingData(response);
    });

    landingPageService.getSimulatorParameters().then(data => {
      if (financingData?.approvedValue != null) {
        data.maxValue = financingData.approvedValue;
      }
      setSimulatorOtherData(data);

      const getMaxValue = data.simulatorTaxes.sort((a, b) => a.maxInstallment - b.maxInstallment)[data.simulatorTaxes.length - 1]
        .maxInstallment;
      setMaxInstallment(getMaxValue);
      const getMinValue = data.simulatorTaxes.sort((a, b) => a.minInstallment - b.minInstallment)[0].minInstallment;
      setMinInstallment(getMinValue);
    });

    if (setIsFinishedLoadingSimulatorData != null) {
      setIsFinishedLoadingSimulatorData(true);
    }
  }, []);

  useEffect(() => {
    if (simulatorScreenData?.simulate) {
      setDesiredValue(simulatorScreenData?.simulate.requestedValue?.toFixed(2).toString());
    }
  }, [simulatorScreenData]);

  useEffect(() => {
    if (simulatorOtherData?.simulatorTaxes && installments !== financingData?.installmentsTotal) {
      const findTax = simulatorOtherData?.simulatorTaxes.find(item => {
        if (installments >= item.minInstallment && installments <= item.maxInstallment) {
          return item.tax;
        }
      });

      if (findTax?.tax != null) {
        setCurrentTax(findTax.tax);
      }
    }
  }, [installments, simulatorOtherData]);

  const handleNewSimulation = () => {
    if (financingData?.id != null) {
      setIsLoading(true);

      const formattedFinancingData: Financing = {
        id: financingData.id,
        installmentsTotal: installments,
        firstDueDateInDays: financingData.firstDueDateInDays,
        firstDueDateInDaysId:
          simulatorOtherData?.firstDueDateInDays &&
          simulatorOtherData?.firstDueDateInDays?.find(item => item.days === financingData.firstDueDateInDays)?.id,
      };

      if (unMaskedCurrency(desiredValue) / 100 === financingData.approvedValue) {
        formattedFinancingData.approvedValue = financingData.approvedValue;
      } else {
        formattedFinancingData.choosedValue = unMaskedCurrency(desiredValue) / 100;
      }

      isAdminSimulator
        ? financingService
            .createFinancingByAdmin(formattedFinancingData)
            .then(response => {
              if (!isPhysicalPerson) {
                setSimulatorFinancingResult(response);
                setIsLoading(false);
              } else if (simulatorOtherData?.percentageOverClientIncome != null && financingData?.client?.physicalPerson?.income != null) {
                const maximumAmountInstallment =
                  (financingData?.client?.physicalPerson?.income * simulatorOtherData.percentageOverClientIncome) / 100;

                if (response?.installmentValue != null && response.installmentValue <= maximumAmountInstallment) {
                  setSimulatorFinancingResult(response);
                } else {
                  setIsInstallmentAmountGreaterIncome(true);
                }
              }

              setIsLoading(false);
            })
            .catch(response => {
              setConfirmError(t(`global.errorMessage.${response.data.code}`));
              setIsLoading(false);
            })
        : financingService
            .createFinancing(formattedFinancingData)
            .then(response => {
              if (!isPhysicalPerson) {
                setSimulatorFinancingResult(response);
                setIsLoading(false);
              } else if (simulatorOtherData?.percentageOverClientIncome != null && financingData?.client?.physicalPerson?.income != null) {
                const maximumAmountInstallment =
                  (financingData?.client?.physicalPerson?.income * simulatorOtherData.percentageOverClientIncome) / 100;

                if (response?.installmentValue != null && response.installmentValue <= maximumAmountInstallment) {
                  setSimulatorFinancingResult(response);
                } else {
                  setIsInstallmentAmountGreaterIncome(true);
                }

                setIsLoading(false);
              }
            })
            .catch(response => {
              setConfirmError(response.data.detail);
              setIsLoading(false);
            });
    }
  };

  const handleSimulatorConfirm = () => {
    financingService.confirmFinancing(financingId).then(() => {
      setIsShowSimulatorConfirmationModal(false);
      setIsShowConfirmationModal(true);
    });
  };

  const checkIfFundingAlreadyConfirmed = () => {
    financingService.getIfFundingAlreadyConfirmed(financingId).then(response => {
      setIsShowConfirmationModal(false);

      if (setIsFundingAlreadyConfirmed != null) {
        setIsFundingAlreadyConfirmed(response.data.isConfirmed);
      }
    });
  };

  const paymentInformation: PaymentInformation[] = [
    {
      icon: GeneralImages.alertCircle,
      title: t('simulatorData.receiptPeriod'),
      value: t('simulatorData.businessDay', { count: simulatorOtherData?.receiptDeadLine }),
    },
    {
      icon: GeneralImages.calendar,
      title: t('simulatorData.firstSalary'),
      value:
        (simulatorScreenData?.simulate?.firstDueDate && StringUtils.apiDateFormatMask(simulatorScreenData?.simulate?.firstDueDate)) ?? '',
    },
  ];

  const actionNumber: number = 100000;

  const desiredValueChanged = e => {
    if (simulatorOtherData) {
      setDesiredValue(StringUtils.currencyMask(e.target.value));
    }
  };

  const buttonAction = action => {
    if (simulatorOtherData) {
      if (action === TypesPlusMinus.PLUS) {
        if (Number(unMaskedCurrency(desiredValue)) + actionNumber <= unMaskedCurrency(simulatorOtherData?.maxValue.toFixed(2))) {
          setDesiredValue((Number(unMaskedCurrency(desiredValue)) + actionNumber).toString());
        }
      } else {
        if (Number(unMaskedCurrency(desiredValue)) - actionNumber >= unMaskedCurrency(simulatorOtherData?.minValue.toFixed(2))) {
          setDesiredValue((Number(unMaskedCurrency(desiredValue)) - actionNumber).toString());
        }
      }
    }
  };

  const handleBlur = e => {
    setDesiredValue(StringUtils.verifyMinAndMaxValue(e.target.value, simulatorOtherData?.minValue, simulatorOtherData?.maxValue));
  };

  const simulatorConfirmationComponent = () => (
    <StyledModalContainer>
      <SectionTitle>{t('simulatorData.requestedCreditAmount')}</SectionTitle>
      {simulatorFinancingResult?.choosedValue != null && (
        <>
          <DesiredValue
            style={{ height: '65px' }}
            value={StringUtils.currencyMask(
              simulatorFinancingResult.approvedValue != null
                ? simulatorFinancingResult.approvedValue.toFixed(2)
                : simulatorFinancingResult.choosedValue.toFixed(2) ?? String(0)
            )}
          />
          <PaymentInformationContainer style={{ justifyContent: 'center', margin: 0, height: '100px' }}>
            <PaymentInformationBox style={{ height: '124px', width: '200px', borderRadius: '10px 0 0 10px', backgroundColor: 'white' }}>
              <PaymentInformationHeader>
                <PaymentInformationTitle>{t('simulatorData.fees')}</PaymentInformationTitle>
              </PaymentInformationHeader>
              <PaymentInformationText>
                {t('simulatorData.feesValue', {
                  value: StringUtils.changeDotToComma(String(simulatorFinancingResult.monthlyTotalEffectiveCost ?? 0)),
                })}
              </PaymentInformationText>
              <PaymentInformationHeader>
                <PaymentInformationTitle>{t('simulatorData.installents')}</PaymentInformationTitle>
              </PaymentInformationHeader>
              <PaymentInformationText>{`${simulatorFinancingResult.installmentsTotal}x ${StringUtils.currencyMask(
                simulatorFinancingResult.installmentValue?.toFixed(2)
              )}`}</PaymentInformationText>
            </PaymentInformationBox>
            <PaymentInformationBox style={{ height: '124px', width: '200px', borderRadius: '0 10px 10px 0', backgroundColor: 'white' }}>
              <PaymentInformationHeader>
                <PaymentInformationTitle>{t('simulatorData.cet')}</PaymentInformationTitle>
              </PaymentInformationHeader>
              <PaymentInformationText>
                {t('simulatorData.cetValue', {
                  value: StringUtils.changeDotToComma(String(simulatorFinancingResult.annualTotalEffectiveCost ?? 0)),
                })}
              </PaymentInformationText>
              <PaymentInformationHeader>
                <PaymentInformationTitle>{t('simulatorData.dueDate')}</PaymentInformationTitle>
              </PaymentInformationHeader>
              <PaymentInformationText>{StringUtils.dateFormatMask(simulatorFinancingResult?.firstDueDate ?? '')}</PaymentInformationText>
            </PaymentInformationBox>
          </PaymentInformationContainer>
        </>
      )}
    </StyledModalContainer>
  );

  return (
    <>
      {simulatorFinancingResult == null ? (
        <SimulatorContainer>
          <StyledRateBox>
            <StyledIcon customStyle={{ backgroundImage: GeneralImages.alertCircle }} />
            <StyledRate>{t('simulatorData.tax', { value: StringUtils.changeDotToComma(String(currentTax ?? 0)) })}</StyledRate>
          </StyledRateBox>
          <SectionTitle>Valor desejado</SectionTitle>
          <DesiredValueContainer isDisableClick={!simulatorOtherData?.requestedAmountMutable}>
            <DesiredValueButtons
              customStyle={{
                backgroundImage: simulatorOtherData?.requestedAmountMutable ? GeneralImages.minus : '',
              }}
              onClick={() => buttonAction(TypesPlusMinus.MINUS)}
            />

            <DesiredValue value={StringUtils.currencyMask(desiredValue)} onChange={desiredValueChanged} onBlur={handleBlur} />
            <DesiredValueButtons
              customStyle={{
                backgroundImage: simulatorOtherData?.requestedAmountMutable ? GeneralImages.plus : '',
              }}
              onClick={() => buttonAction(TypesPlusMinus.PLUS)}
            />
          </DesiredValueContainer>
          <SectionTitle>{t('simulatorData.numberInstallments')}</SectionTitle>
          <Installments>
            <SliderValue>{installments}</SliderValue>
            {simulatorOtherData?.installmentNumberMutable && (
              <Slider min={minInstallment} max={maxInstallment} value={installments} handleChange={handleChange} />
            )}
            {isInstallmentAmountGreaterIncome && (
              <span style={{ marginTop: '15px' }}>
                <InputErrorMessage errorMessage={t('global.errorMessage.valueInstallmentsGreaterIncome')} isFocused />
              </span>
            )}
            {confirmError && (
              <span style={{ marginTop: '5px' }}>
                <InputErrorMessage errorMessage={confirmError} isFocused />
              </span>
            )}
          </Installments>
          <PaymentInformationContainer>
            {paymentInformation.map((payment, idx) => (
              <PaymentInformationBox key={idx}>
                <PaymentInformationHeader>
                  <StyledIcon customStyle={{ backgroundImage: payment.icon }} />
                  <PaymentInformationTitle>{payment.title}</PaymentInformationTitle>
                </PaymentInformationHeader>
                <PaymentInformationText>{payment.value}</PaymentInformationText>
              </PaymentInformationBox>
            ))}
          </PaymentInformationContainer>
          <StyledButtonContainer>
            <CustomButton
              height={48}
              width="160px"
              onClick={() => handleNewSimulation()}
              style={{ justifyContent: 'center' }}
              loading={isLoading}
            >
              {isAdminSimulator ? t('global.button.confirm') : t('global.button.simulate')}
            </CustomButton>
          </StyledButtonContainer>
        </SimulatorContainer>
      ) : (
        <>
          <SimulatorContainer>
            <StyledTitle>{t('simulatorData.simulationResults')}</StyledTitle>
            <PaymentInformationBox style={{ width: '100%', height: '90px', paddingTop: '10px', marginTop: '12px' }}>
              <SectionTitle>{t('simulatorData.choosedValue')}</SectionTitle>
              {simulatorFinancingResult.choosedValue != null && (
                <DesiredValue
                  value={StringUtils.currencyMask(
                    simulatorFinancingResult.approvedValue != null
                      ? simulatorFinancingResult.approvedValue.toFixed(2)
                      : simulatorFinancingResult.choosedValue.toFixed(2) ?? String(0)
                  )}
                  style={{ backgroundColor: '#fafafa' }}
                />
              )}
            </PaymentInformationBox>
            <ResultInformationContainer style={{ justifyContent: 'center' }}>
              <PaymentInformationBox style={{ height: '124px', width: '200px', borderRadius: '10px 0 0 10px' }}>
                <PaymentInformationHeader>
                  <PaymentInformationTitle>{t('simulatorData.fees')}</PaymentInformationTitle>
                </PaymentInformationHeader>
                <PaymentInformationText>
                  {t('simulatorData.feesValue', {
                    value: StringUtils.changeDotToComma(String(simulatorFinancingResult.monthlyTax ?? 0)),
                  })}
                </PaymentInformationText>
                <PaymentInformationHeader>
                  <PaymentInformationTitle>{t('simulatorData.installents')}</PaymentInformationTitle>
                </PaymentInformationHeader>
                <PaymentInformationText>{`${simulatorFinancingResult.installmentsTotal}x ${StringUtils.currencyMask(
                  simulatorFinancingResult.installmentValue?.toFixed(2)
                )}`}</PaymentInformationText>
              </PaymentInformationBox>
              <PaymentInformationBox style={{ height: '124px', width: '200px', borderRadius: '0 10px 10px 0' }}>
                <PaymentInformationHeader>
                  <PaymentInformationTitle>{t('simulatorData.cet')}</PaymentInformationTitle>
                </PaymentInformationHeader>
                <PaymentInformationText>
                  {t('simulatorData.cetValue', {
                    value: StringUtils.changeDotToComma(String(simulatorFinancingResult.monthlyTotalEffectiveCost ?? 0)),
                  })}
                </PaymentInformationText>
                <PaymentInformationHeader>
                  <PaymentInformationTitle>{t('simulatorData.dueDate')}</PaymentInformationTitle>
                </PaymentInformationHeader>
                <PaymentInformationText>{StringUtils.dateFormatMask(simulatorFinancingResult?.firstDueDate ?? '')}</PaymentInformationText>
              </PaymentInformationBox>
            </ResultInformationContainer>

            <StyledContainerButtons>
              <CustomButton
                style={{ justifyContent: 'center', marginTop: '22px' }}
                isInvertColor
                height={48}
                width="120px"
                onClick={() => setSimulatorFinancingResult(null)}
              >
                {t('global.button.backStep')}
              </CustomButton>
              <CustomButton
                style={{ justifyContent: 'center', marginTop: '22px' }}
                height={48}
                width="237px"
                onClick={() => (handleCloseModal != null ? handleCloseModal() : setIsShowSimulatorConfirmationModal(true))}
              >
                {isConfirmationButtonIsLoading ? (
                  <ReactLoading type="spinningBubbles" color={theme.color.primaryColor} />
                ) : (
                  t('global.button.confirmFunding')
                )}
              </CustomButton>
            </StyledContainerButtons>
          </SimulatorContainer>
        </>
      )}

      <CustomModalTwoButtons
        large
        isShowModal={isShowSimulatorConfirmationModal}
        onCloseModal={() => setIsShowSimulatorConfirmationModal(false)}
        title={t('simulatorData.doWantConfirmFunding')}
        children={simulatorConfirmationComponent()}
        firstButtonText={t('global.button.backStep')}
        secondButtonText={t('global.button.confirm')}
        handleClickFirstButton={() => setIsShowSimulatorConfirmationModal(false)}
        handleClickSecondButton={() => handleSimulatorConfirm()}
        loading={isConfirmationButtonIsLoading}
      />

      <ConfirmationModal
        isShowModal={isShowConfirmationModal}
        text={t('dashboard.creditApproved')}
        handleClick={() => checkIfFundingAlreadyConfirmed()}
        handleClose={() => checkIfFundingAlreadyConfirmed()}
      />
    </>
  );
};

export default withTranslation()(SimulatorDashboard);
