import CustomModalTwoButtons from 'components/custom-modal-two-buttons';
import DynamicDesktopLegalPersonEditForm from 'components/dynamic-legal-person-desktop-edit-form';
import { Button } from 'components/edit-forms/styles';
import EnterCustomButton from 'components/enter-custom-button';
import { GeneralImages } from 'images/general-images';
import plus from 'images/plus-add.svg';
import { Partner } from 'model/dashboard';
import TypeSubStepCategory from 'model/enums/type-substep-category';
import { partnerPath } from 'model/substep-paths';
import { EditFormProvider } from 'provider/edit-form';
import { useRegisterForm } from 'provider/register-form';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EditFormPartnerAddress } from './partner-forms/partner-address';
import { EditFormPartnerData } from './partner-forms/partner-data';
import { FormPartnerDocuments } from './partner-forms/partner-documents';
import { EditFormPartnerIncome } from './partner-forms/partner-income';
import { AddPartner, ButtonContainer, Container, EditButton, PartnerItem, PlusIcon } from './styles';
import { ContentContainer } from 'components/styled-desktop-form-container/styles';

export const FormPartnerRelations = () => {
  const {
    onClickNext,
    isLoading,
    initialClientData,
    setSubsteps,
    substeps,
    setFiles,
    setActualSubstep,
    actualSubstep,
    setInitialPartnerData,
    setInitialClientData,
    addingPartner,
    setAddingPartner,
    onClickBack: goBackStep,
  } = useRegisterForm();
  const { t } = useTranslation();
  const [partners, setPartners] = useState<Partner[]>([]);
  const [editingPartner, setEditingPartner] = useState(false);
  const [editedPartner, setEditedPartner] = useState<Partner>();
  const [showNoPartnersModal, setShowNoPartnersModal] = useState(false);

  useEffect(() => {
    const newPartners = initialClientData?.legalPerson?.partners;
    setPartners(newPartners ?? []);
  }, [initialClientData]);

  const onNext = () => {
    if (partners.length !== 0) {
      onClickNext({ ...initialClientData });
    } else {
      setShowNoPartnersModal(true);
    }
  };

  const onRemovePartner = (partner: Partner) => {
    const filteredPartners = initialClientData?.legalPerson?.partners.filter(p => p.cpf !== partner.cpf);
    setInitialClientData({ ...initialClientData, legalPerson: { ...initialClientData?.legalPerson!, partners: filteredPartners ?? [] } });
  };

  const onEditPartner = (partner: Partner) => {
    setEditedPartner(partner);
    setEditingPartner(true);
    partner.attachments && setFiles(partner.attachments);
  };

  const renderPartners = () => {
    return partners.map((partner, index) => (
      <PartnerItem>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <span className="title">{`${t('register.inputs.partner')} ${index + 1}`}</span>
          <span className="subtitle">{partner.name}</span>
        </div>
        <div>
          <EditButton onClick={() => onEditPartner(partner)}>
            <img className="button" src={GeneralImages.pencil} />
          </EditButton>
          <EditButton onClick={() => onRemovePartner(partner)}>
            <img className="button" src={GeneralImages.trash} />
          </EditButton>
        </div>
      </PartnerItem>
    ));
  };

  const goToPartnerForms = () => {
    setSubsteps(partnerPath);
    setActualSubstep(TypeSubStepCategory.PARTNER_GENERAL_DATA);
    setAddingPartner(true);
  };

  const onClickBack = () => {
    const nOrder = substeps.find(substep => substep.name === actualSubstep)?.stepOrder;
    if (nOrder && nOrder > 1) {
      const nSubstep = substeps.find(substep => substep.stepOrder === nOrder - 1)?.name;
      nSubstep !== undefined && setActualSubstep(nSubstep);
    } else {
      resetPartnerPath();
    }
  };

  const resetPartnerPath = () => {
    setEditingPartner(false);
    setAddingPartner(false);
    setInitialPartnerData(null);
    setSubsteps([]);
    setActualSubstep(TypeSubStepCategory.NONE);
    setFiles([]);
  };

  const onClickForward = () => {
    if (actualSubstep === TypeSubStepCategory.PARTNER_DOCUMENTS) {
      resetPartnerPath();
    } else {
      const nOrder = substeps.find(substep => substep.name === actualSubstep)?.stepOrder;
      if (nOrder) {
        const nSubstep = substeps.find(substep => substep.stepOrder === nOrder + 1)?.name;
        nSubstep !== undefined && setActualSubstep(nSubstep);
      }
    }
  };

  const renderPartnerForms = () => {
    switch (actualSubstep) {
      case TypeSubStepCategory.PARTNER_GENERAL_DATA:
        return <EditFormPartnerData onClickForward={onClickForward} onClickBack={onClickBack} />;
      case TypeSubStepCategory.PARTNER_ADDRESS:
        return <EditFormPartnerAddress onClickForward={onClickForward} onClickBack={onClickBack} />;
      case TypeSubStepCategory.PARTNER_INCOME:
        return <EditFormPartnerIncome onClickForward={onClickForward} onClickBack={onClickBack} />;
      case TypeSubStepCategory.PARTNER_DOCUMENTS:
        return <FormPartnerDocuments onClickForward={onClickForward} onClickBack={onClickBack} />;
      default:
        return <></>;
    }
  };

  const renderPartnerStep = () => {
    if (addingPartner) {
      return renderPartnerForms();
    } else if (editingPartner) {
      return (
        <EditFormProvider>
          <DynamicDesktopLegalPersonEditForm
            editingPartner={true}
            partner={editedPartner}
            onClickBack={() => setEditingPartner(false)}
            clientData={initialClientData}
            onEditClient={setInitialClientData}
            onEditPartner={setInitialPartnerData}
          />
        </EditFormProvider>
      );
    }
    return (
      <ContentContainer>
        {renderPartners()}
        <AddPartner onClick={goToPartnerForms}>
          <PlusIcon src={plus} />
          <span className="subtitle">{t('register.inputs.addPartner')}</span>
        </AddPartner>
        <ButtonContainer>
          <Button
            onClick={() => {
              resetPartnerPath();
              goBackStep();
            }}
          >
            <span>{t('global.button.backStep')}</span>
          </Button>
          <EnterCustomButton onClick={onNext} isLoading={isLoading} text={t('global.button.nextStep')} />
        </ButtonContainer>
      </ContentContainer>
    );
  };

  return (
    <Container>
      <CustomModalTwoButtons
        isShowModal={showNoPartnersModal}
        onCloseModal={() => setShowNoPartnersModal(false)}
        handleClickSecondButton={() => setShowNoPartnersModal(false)}
        secondButtonText={t('global.button.ok')}
        title={t('register.modals.title.noPartners')}
        message={t('register.modals.message.noPartners')}
      />
      {renderPartnerStep()}
    </Container>
  );
};
