import styled from 'styled-components/macro';

export const StepCard = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
  flex: none;
  order: 0;
  font-family: 'Satoshi';
  font-style: normal;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: #7f7f7f;
  cursor: pointer;
  user-select: none;
  min-width: 100px;
  height: 100%;
  padding-bottom: 8px;

  ${(props: { selected: boolean; theme: any }) =>
    props.selected && {
      transition: '500ms',
      color: '#333',
      fontFamily: 'Satoshi',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: '700',
      lineHeight: '150%',
      borderBottom: `2px solid ${props.theme.color.primaryColor}`,
    }}
`;
