import styled from 'styled-components/macro';

export const Container = styled.div`
  position: relative;
`;

export const Button = styled.button`
  background-color: transparent;
  border: none;

  padding: 8px 0px;
  border-bottom: 1px solid ${({ theme }) => theme.color.gray24};
  min-width: 154px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  & > span {
    display: inline-block;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: ${({ theme }) => theme.color.gray18};
    margin-left: 8px;
  }
`;

export const CalendarContainer = styled.div`
  position: absolute;
  top: 40px;
  left: -70%;
  z-index: 10;

  background-color: ${({ theme }) => theme.color.white};
  border: 1px solid ${({ theme }) => theme.color.borderGray};
  box-shadow: 0px 2px 12px ${({ theme }) => theme.color.shadow};
  border-radius: 8px;
  padding: 16px;

  & > div.react-calendar {
    max-width: 500px;
    border: none;
    font-family: 'Satoshi';

    abbr {
      text-decoration: none;
    }

    button {
      border-radius: 4px;
    }

    button.react-calendar__tile--now {
      background-color: ${({ theme }) => theme.color.brightnessPrimary};
    }

    button.react-calendar__tile--active {
      background-color: ${({ theme }) => theme.color.primaryColor};
    }

    button.react-calendar__tile:disabled {
      border-radius: 0px;
    }
  }
`;
