import { isEmpty } from 'lodash';
import { StyledContainer, StyledErrorMessage, StyledInnerContainer } from './styles';
import { ReactComponent as PaperClipsIconSvg } from 'images/paper-clips-icon.svg';
import { TypeAttachment } from 'model/credit-card';
import AlertIcon from 'images/alert-triangle.svg';

interface IProps {
  text: string;
  errorMessage?: string;
  disabled?: boolean;
  getFile?: (e?: TypeAttachment) => void;
}

const AttachDispute = ({ text, errorMessage, disabled, getFile }: IProps) => {
  const handleChange = (file: File) => {
    const reader = new FileReader();
    reader.onload = (e: ProgressEvent<FileReader>) => {
      if (getFile != null) {
        getFile({ fileName: file.name, file: String(e.target?.result).split(',')[1], contentType: file.type });
      }
    };
    reader.readAsDataURL(file);
  };

  const handleClick = () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = '.pdf';
    input.onchange = (event: Event) => {
      const target = event.target as HTMLInputElement;
      const file = target.files?.[0];
      if (file) {
        handleChange(file);
      }
    };
    input.click();
  };

  return (
    <StyledContainer error={!isEmpty(errorMessage)} onClick={handleClick} disabled={disabled}>
      <StyledInnerContainer>
        <PaperClipsIconSvg />
        {text}
      </StyledInnerContainer>

      {!isEmpty(errorMessage) && (
        <StyledErrorMessage>
          <img src={AlertIcon} style={{ marginRight: '5px' }} />
          {errorMessage}
        </StyledErrorMessage>
      )}
    </StyledContainer>
  );
};

export default AttachDispute;
