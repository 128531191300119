export enum PaymentType {
  PIX = 'PIX',
  BANK_ACCOUNT = 'BANK_ACCOUNT',
  BANK_SLIP = 'BANK_SLIP',
  MANUAL_PAYMENT = 'MANUAL_PAYMENT',
}

export enum PixType {
  CPF = 'CPF',
  CNPJ = 'CNPJ',
  DOCUMENT = 'DOCUMENT',
  EMAIL = 'EMAIL',
  PHONE = 'PHONE',
  RANDOM_KEY = 'RANDOM_KEY',
  EVP = 'EVP',
}
