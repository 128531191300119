import styled from 'styled-components/macro';

export const StlyedProcessingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 575.98px) {
    .MuiCircularProgress-svg {
      width: 40px;
      height: 40px;
    }
  }
`;

export const StyledProcessingTitle = styled.div`
  width: 480px;
  font-weight: 700;
  font-size: 24px;
  margin-top: 48px;
  color: ${({ theme }) => theme.color.black0};

  @media (max-width: 671px) {
    width: 272px;
    margin-top: 24px;
  }
`;

export const StyledProcessingText = styled.div`
  width: 480px;
  font-weight: 500;
  font-size: 16px;
  color: ${({ theme }) => theme.color.gray18};
  margin: 16px 0;

  @media (max-width: 671px) {
    width: 272px;
  }
`;

export const ProcessingLodingText = styled.div`
  width: 480px;
  font-weight: 700;
  font-size: 16px;
  text-align: center;
  color: ${({ theme }) => theme.color.black0};
  margin: 16px 0px 0px;

  @media (max-width: 671px) {
    width: 272px;
  }
`;

export const PaintSvg = styled.span`
  &.viewContratSvg {
    svg path:nth-child(12),
    svg path:nth-child(14),
    svg path:nth-child(24),
    svg path:nth-child(30) {
      fill: ${({ theme }) => theme.color.primaryColor};
    }
  }
`;
