import { useTheme } from 'styled-components';

export const OrderLogoIcon = ({ isWhite }) => {
  const theme = useTheme();

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.46479 10.8619C8.72514 10.6016 9.14725 10.6016 9.4076 10.8619L11.6029 13.0572L13.7981 10.8619C14.0585 10.6016 14.4806 10.6016 14.7409 10.8619C15.0013 11.1223 15.0013 11.5444 14.7409 11.8047L12.0743 14.4714C11.8139 14.7318 11.3918 14.7318 11.1315 14.4714L8.46479 11.8047C8.20444 11.5444 8.20444 11.1223 8.46479 10.8619Z"
        fill={isWhite ? '#fff' : theme.color.primaryColor}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.6029 4C11.9711 4 12.2695 4.29848 12.2695 4.66667V14C12.2695 14.3682 11.9711 14.6667 11.6029 14.6667C11.2347 14.6667 10.9362 14.3682 10.9362 14V4.66667C10.9362 4.29848 11.2347 4 11.6029 4Z"
        fill={isWhite ? '#fff' : theme.color.primaryColor}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.47271 5.13807C7.21236 5.39842 6.79025 5.39842 6.5299 5.13807L4.33464 2.94281L2.13937 5.13807C1.87903 5.39842 1.45691 5.39842 1.19657 5.13807C0.936216 4.87772 0.936216 4.45561 1.19657 4.19526L3.86323 1.5286C4.12358 1.26825 4.54569 1.26825 4.80604 1.5286L7.47271 4.19526C7.73306 4.45561 7.73306 4.87772 7.47271 5.13807Z"
        fill={isWhite ? '#fff' : theme.color.primaryColor}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.33464 12C3.96645 12 3.66797 11.7015 3.66797 11.3333L3.66797 2C3.66797 1.63181 3.96645 1.33333 4.33464 1.33333C4.70283 1.33333 5.0013 1.63181 5.0013 2L5.0013 11.3333C5.0013 11.7015 4.70283 12 4.33464 12Z"
        fill={isWhite ? '#fff' : theme.color.primaryColor}
      />
    </svg>
  );
};
