import styled from 'styled-components/macro';

interface Props {
  calcWidth?: string;
  noMargin?: boolean;
  isDisabled?: boolean;
}

export const PaymentContainer = styled.div`
  margin: 24px 0px 52px;
  min-height: 402px;

  position: relative;
`;

export const FormConfirmPayment = styled.div`
  width: 100%;
  height: 92px;

  display: flex;
  justify-content: space-between;

  border-bottom: 1px solid #e5e7e7;
`;

export const BoxData = styled.div`
  column-gap: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const ButtonPayment = styled.button<Props>`
  width: ${({ calcWidth }) => `calc(242px + ${calcWidth ?? '0px'})`};
  height: 48px;
  background: transparent;
  border: 1.5px solid #0524dd;
  border-radius: 24px;

  font-weight: 700;
  font-size: 16px;
  color: #0524dd;
  margin: ${({ noMargin }) => (noMargin ? '0px' : '20px 0px 0px')};

  :hover {
    color: #fff;
    background: #0524dd;
  }
`;
