import styled, { css } from 'styled-components/macro';

export interface StyledButtonProps {
  height?: number;
  width?: string;
  maxWidth?: string;
  isInvertColor?: boolean;
  freeContent?: boolean;
  justifyContent?: 'center' | 'space-between';
  disabled?: boolean;
  margin?: string;
}

export const StyledButton = styled.button<StyledButtonProps>`
  height: ${({ height }) => `${height ?? 40}px !important`};
  width: ${({ width }) => width};

  position: relative;
  display: flex;
  align-items: center;
  justify-content: ${({ justifyContent }) => justifyContent ?? 'space-between'};
  padding: ${({ freeContent }) => (freeContent ? '0px' : '0 18px')};
  max-width: ${({ maxWidth }) => maxWidth};
  background-color: ${({ theme, isInvertColor }) => (isInvertColor ? theme.color.grayBackground : theme.color.primaryColor)};
  border-radius: 24px;
  border: ${({ theme, isInvertColor }) =>
    isInvertColor ? `1.5px solid ${theme.color.primaryColor}` : `1px solid ${theme.color.primaryColor}`};
  font-style: normal;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: ${({ theme, isInvertColor }) => (isInvertColor ? theme.color.primaryColor : '#ffffff')};
  margin: ${({ margin }) => margin};

  :disabled {
    opacity: 0.6;
  }

  img {
    margin-right: 12px;
  }

  svg {
    width: 24px;
    height: 24px;
  }

  @media (max-width: 575.98px) {
    height: ${({ height }) => (height != null ? `${height - 3}px` : height)};
    font-size: 14px;
  }

  > div {
    height: max-content !important;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      justify-content: center;
    `};
`;

export const StyledButtonIcon = styled.div`
  margin-right: 12px;
`;
