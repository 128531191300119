import styled from 'styled-components/macro';

export const StyledContainer = styled.div`
  height: 100vh;
  padding-bottom: 55px;

  font-weight: 500;
  font-size: 16px;
  color: ${({ theme }) => theme.color.gray18};
  text-align: left;
  background-color: ${({ theme }) => theme.color.grayBackground};
  overflow-y: auto;
`;

export const StyledInnerContainer = styled.div`
  max-width: 480px;
  margin: 48px auto 0 auto;
  padding: 0 16px;
`;

export const StyledMainTitle = styled.div`
  font-weight: 700;
  font-size: 24px;
  color: #000000;

  @media (max-width: 575.98px) {
    font-size: 20px;
  }
`;

export const StyledText = styled.div`
  margin: 16px 0 20px 0;
`;

export const StyledCardContainer = styled.div`
  width: 100%;
  margin: 40px 0px 50px 0px;

  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(27, 25, 25, 0.08);
  background: #ffffff;

  padding: 40px;
`;

export const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
