import styled, { css } from 'styled-components/macro';

interface StyledProps {
  currentStep: boolean;
  stepCompleted: boolean;
}

export const Container = styled.div<StyledProps>`
  display: flex;
  align-items: center;
  column-gap: 4px;

  font-size: 12px;
  font-weight: 500;
  color: ${({ theme }) => theme.color.grayLight};

  svg circle:nth-child(2) {
    fill: ${({ theme }) => theme.color.primaryColor};
  }

  svg path {
    fill: ${({ theme }) => theme.color.primaryColor};
  }

  ${({ currentStep }) =>
    currentStep &&
    css`
      font-weight: 700;
      color: ${({ theme }) => theme.color.gray21};
    `};

  ${({ stepCompleted }) =>
    stepCompleted &&
    css`
      color: ${({ theme }) => theme.color.gray21};
    `};
`;

export const StepValue = styled.div`
  width: 16px;
  height: 16px;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 12px;
  font-weight: 500;
  color: ${({ theme }) => theme.color.grayLight};
  border-radius: 50%;
  background-color: ${({ theme }) => theme.color.lightGray1};
`;
