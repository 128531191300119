import { CalendarInput } from 'components/calendar-input';
import { CustomCheckBox } from 'components/custom-checkbox';
import { CreatedDates } from 'model/date';
import moment from 'moment';
import { useAdmin } from 'provider/admin-general';
import { MutableRefObject, useEffect, useRef, useState } from 'react';
import 'react-calendar/dist/Calendar.css';
import { useTranslation } from 'react-i18next';
import Popup from 'reactjs-popup';
import { useTheme } from 'styled-components';
import { FilterItem } from '../title-header-new/styles';
import { ButtonsContainer, CalendarInputsContainer, Container, DropDownFilter, FilterBox, SectionTitle } from './styles';
import { Ordertype } from 'model/enums/order-type';
import { StatusLogoIcon } from 'images/status-logo';
import { PopupActions } from 'reactjs-popup/dist/types';

interface FilterButtonProps {
  filterStatus: string[];
  onCleanFilter: () => void;
  onAppliedFilters: () => void;
  orderBy?: boolean;
  setOrderBy?: (value: boolean) => void;
}

export const StatusFilterButton = ({ filterStatus, onCleanFilter, onAppliedFilters, orderBy, setOrderBy }: FilterButtonProps) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const { statusFilters, handleSetNewStatus, resetStatusFilters, resetCreatedDates, setSaveCalendarDates, saveCalendarDates } = useAdmin();

  const ref: MutableRefObject<PopupActions | null> = useRef(null);
  const closeTooltip = () => ref.current?.close();

  const checkFilterIsActive = (filter: string) => {
    const filterActive = statusFilters.find(status => status === filter);

    if (filterActive) {
      return true;
    }

    return false;
  };

  const closeModal = () => setIsOpen(false);

  return (
    <Popup
      ref={ref}
      open={isOpen}
      closeOnDocumentClick
      onClose={closeModal}
      trigger={
        <FilterItem onClick={() => setIsOpen(!isOpen)}>
          <StatusLogoIcon isWhite={false} />
          <span>{t('admin.dashboard.status')}</span>
        </FilterItem>
      }
      position="bottom center"
      on="click"
    >
      <DropDownFilter>
        <FilterBox>
          <SectionTitle className="status">{t('admin.dashboard.selectOne')}</SectionTitle>
          {filterStatus.map((status, idx) => (
            <CustomCheckBox
              key={idx}
              text={t(`enum.filterAllStatus.${status}`)}
              value={status}
              checked={checkFilterIsActive(status)}
              reset={statusFilters.length === 0}
              onHandleClick={value => {
                if (value.check) {
                  handleSetNewStatus([...statusFilters, value.value]);
                } else {
                  handleSetNewStatus(statusFilters.filter(filter => filter !== value.value));
                }
              }}
            />
          ))}
        </FilterBox>
        <ButtonsContainer>
          <button
            className="clean"
            onClick={() => {
              resetStatusFilters();
              resetCreatedDates();
              setSaveCalendarDates(null);
              onCleanFilter();
              if (setOrderBy) {
                setOrderBy(false);
              }
            }}
          >
            {t('global.button.clean')}
          </button>
          <button
            className="apply"
            onClick={() => {
              onAppliedFilters();
              closeModal();
              closeTooltip();
            }}
          >
            {t('global.button.apply')}
          </button>
        </ButtonsContainer>
      </DropDownFilter>
    </Popup>
  );
};
