import { Authority } from 'model/enums/authority';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducer';

export const useAccountInfo = () => {
  const account = useSelector((state: IRootState) => state.authentication.account);
  return {
    account,
  };
};
