import { AxiosResponse } from 'axios';
import { AdminAnalyze } from 'model/admin-analyze';
import { Attachment, AttachmentTypeRefund } from 'model/attachment';
import { AuthBiometric, Biometrics } from 'model/auth-biometric';
import { BankAccount } from 'model/bank-account';
import { CreditEngineRequest, CreditEngineType } from 'model/credit-engine';
import { ProcedureDashboard } from 'model/dashboard';
import FinancingPaymentType from 'model/enums/financingPaymentType';
import TypesBanks from 'model/enums/types-banks';
import {
  BankAccountInFinancing,
  Contract,
  ContractType,
  Financing,
  FinancingConfirmed,
  FinancingSketch,
  ISendGuarantorsFinancing,
  MakePaymentResponse,
  Payment,
  PaymentDetailsResponse,
  PaymentType,
  Procedure,
  RequestCancelFinancing,
  StatusAuthenticationAndBiometrics,
  SubscriptionOption,
  TwoFactorAuthenticationStatus,
} from 'model/financing';
import { Installment } from 'model/installments';
import { PageableResponse } from 'model/pageable';
import { ProcedureRequest } from 'model/procedure';
import ProcedurePaymentStatusType from 'model/procedure-payment-status';
import { RequestProcedurePayment, ResponseProcedurePayment } from 'model/procedurePayment';
import { api } from './api';

const financingApi = () => {
  const createFinancing = (financingData: Financing): Promise<AxiosResponse<Financing>> => {
    return api.post<Financing>('/financing', financingData);
  };

  const createFinancingByAdmin = (financingData: Financing): Promise<AxiosResponse<Financing>> => {
    return api.post<Financing>('/financing/confirms/admin', financingData);
  };

  const twoFactorAuthRequest = (financingId: number): Promise<AxiosResponse<TwoFactorAuthenticationStatus>> => {
    return api.post<TwoFactorAuthenticationStatus>(`/financing/${financingId}/two-factor-auth-request`);
  };

  const biometricsRequest = (financingId: number): Promise<AxiosResponse<Biometrics>> => {
    return api.post<Biometrics>(`/financing/${financingId}/biometrics-request`);
  };

  const createProcedure = ({ choosedValue, medicalProcedure }: Procedure): Promise<AxiosResponse<Procedure>> => {
    return api.post<Procedure>('/financing/procedure/client', { choosedValue, medicalProcedure });
  };

  const getMyFinancing = (): Promise<AxiosResponse<PageableResponse<Financing>>> => {
    return api.get<PageableResponse<Financing>>('/financing');
  };

  const getClientFinancingsById = (clientId: number): Promise<AxiosResponse<PageableResponse<Financing>>> => {
    return api.get<PageableResponse<Financing>>(`/financing/clients/${clientId}`);
  };

  const getFinancingById = (id: number): Promise<AxiosResponse<Financing>> => {
    return api.get<Financing>(`/financing/${id}`);
  };

  const getFinancingBankAccount = (financingId: number): Promise<AxiosResponse<BankAccountInFinancing>> => {
    return api.get<BankAccountInFinancing>(`/financing/${financingId}/bank-account`);
  };

  const getFinancingContract = (financingId: number): Promise<AxiosResponse<ContractType>> => {
    return api.get<ContractType>(`/financing/${financingId}/contracts`);
  };

  const getFinancingPayments = (financingId: number): Promise<AxiosResponse<PaymentType>> => {
    return api.get<PaymentType>(`/financing/${financingId}/summary`);
  };

  const getIfFundingAlreadyConfirmed = (financingId: number): Promise<AxiosResponse<FinancingConfirmed>> => {
    return api.get<FinancingConfirmed>(`/financing/${financingId}/is-confirmed`);
  };

  const getProcedurePaymentStatus = (financingId: number): Promise<AxiosResponse<ProcedurePaymentStatusType>> => {
    return api.get<ProcedurePaymentStatusType>(`/financing/${financingId}/procedure-payment-status`);
  };

  const addGuarantorsInFinancing = (
    financingId: number,
    guarantors: ISendGuarantorsFinancing
  ): Promise<AxiosResponse<ISendGuarantorsFinancing>> => {
    return api.patch<ISendGuarantorsFinancing>(`/financing/${financingId}/guarantors`, guarantors);
  };

  const addBankAccountInFinancing = (financingId: number, bankAccountId: number): Promise<AxiosResponse<BankAccount>> => {
    return api.patch<BankAccount>(`/financing/${financingId}/bank-accounts/${bankAccountId}`);
  };

  const confirmFinancing = (financingId: number): Promise<AxiosResponse<Financing>> => {
    return api.patch<Financing>(`/financing/${financingId}/confirm`);
  };

  const updateAdminFinancing = (financingId: number, financing: Financing): Promise<AxiosResponse<Financing>> => {
    return api.patch<Financing>(`/financing/${financingId}/admin-update`, financing);
  };

  const getAllContractsFinancing = (financingId: number): Promise<AxiosResponse<PageableResponse<Contract>>> => {
    return api.get<PageableResponse<Contract>>(`/contracts/financings/${financingId}`);
  };

  const adminAnalyzeFinancing = (financingId: number, anlysisInfos: AdminAnalyze): Promise<AxiosResponse<Financing>> => {
    return api.patch<Financing>(`/financing/${financingId}/admin-analyze`, anlysisInfos);
  };

  const financingStatusToAdjusted = (financingId: number): Promise<AxiosResponse<Financing>> => {
    return api.patch<Financing>(`/financing/${financingId}/adjusted`);
  };

  const updateProcedure = ({ choosedValue, medicalProcedure, id }: Procedure): Promise<AxiosResponse<Procedure>> => {
    return api.put<Procedure>(`/financing/${id}/procedure/client`, { choosedValue, medicalProcedure, id });
  };

  const getPaymentProgress = (financingId: number): Promise<AxiosResponse<PaymentType>> => {
    return api.get<PaymentType>(`/financing/${financingId}/payment`);
  };

  const verifyAuthenticationAndBiometrics = (financingId: number): Promise<AxiosResponse<StatusAuthenticationAndBiometrics>> => {
    return api.get<StatusAuthenticationAndBiometrics>(`/financing/${financingId}/auth-and-biometrics`);
  };

  const getAuthenticateAndBiometry = (financingId: number): Promise<AxiosResponse<AuthBiometric>> => {
    return api.get<AuthBiometric>(`/financing/${financingId}/auth-and-biometrics/admin`);
  };

  const manuallyApproveBiometrics = (financingId: number): Promise<AxiosResponse<void>> => {
    return api.post<void>(`/financing/${financingId}/biometrics-manual-request`);
  };

  const postCreditEngine = (simulatorData: CreditEngineRequest): Promise<AxiosResponse<CreditEngineType>> => {
    return api.post<CreditEngineType>('/financing/credit-engine', simulatorData);
  };

  const createFinancingSketch = (financingSketch: FinancingSketch): Promise<AxiosResponse<Financing>> => {
    return api.post<Financing>('/financing/sketch', financingSketch);
  };

  const getFinancingInstallments = (
    id: number,
    size?: number,
    page?: number,
    statuses?: ('PAID' | 'PENDING' | 'OVERDUE')[]
  ): Promise<AxiosResponse<PageableResponse<Installment>>> => {
    let queryParams = `size=${size ?? 5}&page=${page ?? 0}`;

    if (statuses && statuses.length > 0) {
      statuses.forEach(status => {
        if (status === 'PENDING') {
          queryParams += '&status=contains(PENDING)&status=contains(NEW)';
        } else if (status === 'OVERDUE') {
          queryParams += '&status=contains(OVERDUE)';
        } else if (status === 'PAID') {
          queryParams += '&status=contains(PAID)';
        }
      });
    }

    return api.get<PageableResponse<Installment>>(`/installments/financing/${id}?${queryParams}`);
  };

  const createProcedureAdmin = (procedure: ProcedureRequest): Promise<AxiosResponse<ProcedureDashboard>> => {
    return api.post<ProcedureDashboard>('/financing/procedure/admin', procedure);
  };

  const approvePaymentManually = (procedureId: number): Promise<AxiosResponse<ProcedureDashboard>> => {
    return api.post<ProcedureDashboard>(`/procedure-payment/financing/${procedureId}/approve-payment-manually`);
  };

  const approveProcedureRefund = (procedureId: number, file?: AttachmentTypeRefund): Promise<AxiosResponse<ProcedureDashboard>> => {
    return api.post<ProcedureDashboard>(`/procedure-refund/financing/${procedureId}/approve-refund-manually`, { attachment: file });
  };

  const updateProcedureAdmin = (procedureId: number, procedure: ProcedureRequest): Promise<AxiosResponse<ProcedureDashboard>> => {
    return api.put<ProcedureDashboard>(`/financing/${procedureId}/procedure/admin`, procedure);
  };

  const adminAnalyzeProcedure = (procedureId: number, analyze: AdminAnalyze): Promise<AxiosResponse<Procedure>> => {
    return api.patch<Procedure>(`/financing/${procedureId}/procedure/admin-analyze`, analyze);
  };

  const getBanks = (): Promise<AxiosResponse<TypesBanks[]>> => {
    return api.get<TypesBanks[]>('/financing/list-banks ');
  };

  const getProcedurePayment = (procedureId: number): Promise<AxiosResponse<ResponseProcedurePayment>> => {
    return api.get<ResponseProcedurePayment>(`/financing/${procedureId}/procedure-payment/admin`);
  };

  const getListPaymentType = (): Promise<AxiosResponse<FinancingPaymentType[]>> => {
    return api.get<FinancingPaymentType[]>('/financing/list-payment-type');
  };

  const payProcedurePayment = (
    procedureId: number,
    procedurePay: RequestProcedurePayment
  ): Promise<AxiosResponse<ResponseProcedurePayment>> => {
    return api.post<ResponseProcedurePayment>(`/financing/${procedureId}/pay-procedure/admin`, procedurePay);
  };

  const cancelFinancing = (financeId: number, cancelReason: RequestCancelFinancing): Promise<AxiosResponse<Financing>> => {
    return api.patch<Financing>(`/financing/cancel/${financeId}`, cancelReason);
  };

  const confirmFinancingByAdmin = (financeId: number): Promise<AxiosResponse<Financing>> => {
    return api.patch<Financing>(`/financing/${financeId}/confirm/admin`);
  };

  const confirmPayment = (instalmentId: number): Promise<AxiosResponse<Financing>> => {
    return api.patch<Financing>(`/installments/${instalmentId}/confirm-payment`);
  };

  const makePayment = (paymentData: Payment): Promise<AxiosResponse<MakePaymentResponse>> => {
    return api.post<MakePaymentResponse>(`/financing/pay-procedure/admin`, paymentData);
  };

  const getPaymentDetails = (id: number): Promise<AxiosResponse<PaymentDetailsResponse>> => {
    return api.get<PaymentDetailsResponse>(`/financing/${id}/summary`);
  };

  const getMeat = (financeId: number): Promise<AxiosResponse<Attachment>> => {
    return api.get<PaymentDetailsResponse>(`/financing/${financeId}/generate-carnet-to-download`);
  };

  const getSignatureOptions = (): Promise<AxiosResponse<SubscriptionOption[]>> => {
    return api.get<SubscriptionOption[]>('/signature-options');
  };

  const listPayment = (id: number): Promise<AxiosResponse<MakePaymentResponse>> => {
    return api.get<MakePaymentResponse>(`/financing/${id}/procedure-payment/admin`);
  };

  const refoundPayment = (refoundData: Payment, financingId?: number): Promise<AxiosResponse<ProcedureDashboard>> => {
    if (Object.keys(refoundData).length > 0) {
      return api.post<ProcedureDashboard>(`/procedure-refund/financing/${financingId}/approve-refund-manually`, refoundData);
    } else {
      return api.post<ProcedureDashboard>(`/procedure-refund/financing/${financingId}/approve-refund-manually`);
    }
  };

  return {
    createFinancing,
    createFinancingByAdmin,
    twoFactorAuthRequest,
    biometricsRequest,
    createProcedure,
    approvePaymentManually,
    approveProcedureRefund,
    getMyFinancing,
    getFinancingBankAccount,
    getFinancingContract,
    getFinancingPayments,
    getIfFundingAlreadyConfirmed,
    getProcedurePaymentStatus,
    addGuarantorsInFinancing,
    addBankAccountInFinancing,
    confirmFinancing,
    getFinancingById,
    updateAdminFinancing,
    getAllContractsFinancing,
    getClientFinancingsById,
    adminAnalyzeFinancing,
    financingStatusToAdjusted,
    updateProcedure,
    getPaymentProgress,
    verifyAuthenticationAndBiometrics,
    getAuthenticateAndBiometry,
    postCreditEngine,
    getFinancingInstallments,
    createProcedureAdmin,
    updateProcedureAdmin,
    adminAnalyzeProcedure,
    getBanks,
    getProcedurePayment,
    getListPaymentType,
    payProcedurePayment,
    cancelFinancing,
    confirmFinancingByAdmin,
    confirmPayment,
    makePayment,
    getPaymentDetails,
    getMeat,
    getSignatureOptions,
    listPayment,
    refoundPayment,
    createFinancingSketch,
    manuallyApproveBiometrics,
  };
};

export default financingApi();
