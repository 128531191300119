import LoadingScreen from 'components/loading-screen/loading-screen';
import { SimulatorFormData } from 'model/landing-page';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import { useTheme } from 'styled-components';
import { AdminInputType } from '../input';
import { InputStatus, StyledError, StyledInput, StyledLabel } from '../input/styled';
import { Arrow, Item, List, StyledDropdownBox } from './styles';

interface StyledType {
  borderRadius?: boolean;
  borderRight?: boolean;
}

interface AdminDropdownProps {
  styled?: StyledType;
  label: () => string;
  name: string;
  htmlFor: string;
  required: boolean;
  formData?: SimulatorFormData;
  setFormData: (formData) => void;
  maxLength?: number;
  mask?: (value: string) => string;
  list: string[];
  onChange?: (value: string) => void;
  objectList?: any[];
  handleOnScroll?: (e: any) => void;
  onOpen?: () => void;
  hasSearch?: boolean;
  calcWidth?: string;
  isList?: boolean;
  position?: number;
  type: string;
}

const AdminDropdown: React.FC<AdminDropdownProps> = ({
  styled,
  label,
  name,
  required,
  htmlFor,
  formData,
  setFormData,
  maxLength,
  mask,
  list,
  onChange,
  objectList,
  handleOnScroll,
  onOpen,
  hasSearch,
  calcWidth,
  isList,
  position,
  type,
}) => {
  const isActive = formData?.[name]?.length > 0;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const {
    register,
    formState: { errors },
    setError,
    reset,
    setValue,
  } = useFormContext();
  const theme = useTheme();

  const handleChange = (e, key) => {
    setFormData({ ...formData, [key]: e.target.value });
    setValue(key, e.target.value);
  };

  const handleSetData = (e, fieldName) => {
    if (objectList) {
      const value = objectList.find(item => item.name === e);
      setValue(`${[fieldName]}`, e, { shouldValidate: true });
      setFormData({ ...formData, [fieldName]: e, [`${fieldName}Object`]: value });
    } else {
      setValue(`${[fieldName]}`, e, { shouldValidate: true });
      setFormData({ ...formData, [fieldName]: e });
    }
  };

  const handleSetDataList = (e, fieldName) => {
    if (objectList) {
      const value = objectList.find(item => item.label === e);
      setValue(`${[fieldName]}`, e, { shouldValidate: true });
      setFormData?.(prevListaObjetos => {
        const updatedObjectList = [...prevListaObjetos];
        updatedObjectList[position ?? 0] = {
          ...updatedObjectList[position ?? 0],
          [fieldName]: e,
          [`${fieldName}Object`]: value,
        };
        return updatedObjectList;
      });
    } else {
      setValue(`${[fieldName]}`, e, { shouldValidate: true });
      setFormData?.(prevListaObjetos => {
        const updatedObjectList = [...prevListaObjetos];
        updatedObjectList[position ?? 0] = {
          ...updatedObjectList[position ?? 0],
          [fieldName]: e,
        };
        return updatedObjectList;
      });
    }
  };

  useEffect(() => {
    if (list.length === 1) {
      if (isList) {
        handleSetDataList(list[0], name);
      } else {
        handleSetData(list[0], name);
      }
    }
  }, []);

  const noList = list.length === 1;
  const isView = type ? type === AdminInputType.VIEW : undefined;

  const handleOpen = () => {
    if (!isView && !noList) {
      setIsOpen(!isOpen);

      if (isOpen === false) {
        if (onOpen) onOpen();
      }
    }
  };
  const handleSelection = (item: string) => {
    if (onChange) {
      onChange(item);
    }
  };

  const handleOnCLick = (item, name) => {
    setIsOpen(!isOpen);

    if (isList) {
      handleSetDataList(item, name);
    } else {
      handleSetData(item, name);
    }
  };

  const getInputType = () => {
    switch (type) {
      case AdminInputType.VIEW:
        return <InputStatus>{mask ? mask(formData?.[name]) : formData?.[name]}</InputStatus>;

      default:
        return (
          <>
            <StyledInput
              id={`#input-dropdown-${name}`}
              type={hasSearch ? 'text' : 'button'}
              {...register(name, { required: required })}
              isActive={isActive}
              hasError={errors[name]?.message.length > 0}
              name={name}
              value={formData?.[name] ?? ''}
              maxLength={maxLength}
              disabled={false}
              onChange={e => hasSearch && handleChange(e, name)}
            />
            {!noList && <Arrow>{isOpen ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}</Arrow>}
            {errors[name] && <StyledError>{errors[name]?.message}</StyledError>}
          </>
        );
    }
  };

  return (
    <StyledDropdownBox calcWidth={calcWidth}>
      <StyledLabel htmlFor={htmlFor} isActive={isActive}>
        {label}
      </StyledLabel>
      <div onClick={handleOpen}>{getInputType()}</div>
      {isOpen && (
        <List>
          <LoadingScreen isLoading={list.length === 0} minHeight="120px">
            {list.map((item, idx) => (
              <Item key={idx} onClick={e => handleOnCLick(item, name)} onMouseEnter={() => handleSelection(item)}>
                {item}
              </Item>
            ))}
          </LoadingScreen>
        </List>
      )}
    </StyledDropdownBox>
  );
};

export default AdminDropdown;
