import { ContractStatus } from 'model/enums/contract-status';
import QRCode from 'qrcode.react';
import { useTranslation } from 'react-i18next';
import { Container, Message, QRCodeContainer } from './styles';
import { FiCheckCircle } from 'react-icons/fi';
import { useTheme } from 'styled-components';
import { useEffect, useState } from 'react';
import { Loading } from 'components/loading';
import { StyledButton } from 'components/custom-button/styles';
import { Copy } from 'images/copy';

interface SignQRCodeProps {
  url: string;
  status?: ContractStatus;
}

export const SignQRCode = ({ url, status }: SignQRCodeProps) => {
  const { t } = useTranslation();
  const { color } = useTheme();
  const [pageLoading, setPageLoading] = useState(true);
  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    if (status != null) {
      setPageLoading(false);
    }
  }, [status]);

  if (pageLoading) {
    return <Loading />;
  }

  const copyToClipboard = () => {
    navigator.clipboard.writeText(url);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 3000);
  };

  return (
    <Container>
      {status === ContractStatus.FINISHED ? (
        <>
          <FiCheckCircle size={64} color={color.green} style={{ marginBottom: 16 }} />
          <Message>{t('admin.contractScreen.finishSign')}</Message>
        </>
      ) : (
        <>
          <Message>{t('admin.contractScreen.qrCodeText')}</Message>
          <QRCodeContainer>
            <QRCode renderAs="svg" style={{ width: '100%', height: '100%' }} value={url} />
          </QRCodeContainer>

          <StyledButton isInvertColor style={{ marginTop: '32px', marginBottom: '24px' }} onClick={copyToClipboard}>
            {!isCopied ? (
              <span>
                <Copy
                  style={{
                    marginRight: '8px',
                    width: '20px',
                    height: '20px',
                  }}
                />
                {t('admin.contractScreen.copyLink')}
              </span>
            ) : (
              <span>{t('admin.contractScreen.linkCopied')}</span>
            )}
          </StyledButton>
        </>
      )}
    </Container>
  );
};
