import { LandingPageType } from 'model/landing-page';
import { createContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from 'reducer';
import { landingPageTemplateRequest } from 'reducer/landing-page-template/actions';

interface LandingPageTemplateContextType {
  landingPageTemplate?: LandingPageType;
}

const DEFAULT_VALUE: LandingPageTemplateContextType = {
  landingPageTemplate: {},
};

const LandingPageTemplateContext = createContext<LandingPageTemplateContextType>(DEFAULT_VALUE);

const LandingPageTemplateProvider: React.FC = ({ children }) => {
  const landingPageTemplate = useSelector((state: IRootState) => state.landingPageTemplate.landingPageTemplate);
  const dispatch = useDispatch();

  useEffect(() => {
    if (landingPageTemplate == null) {
      dispatch(landingPageTemplateRequest());
    }
  }, [dispatch, landingPageTemplate]);

  return <LandingPageTemplateContext.Provider value={{ landingPageTemplate }}>{children}</LandingPageTemplateContext.Provider>;
};

export { LandingPageTemplateProvider };
export default LandingPageTemplateContext;
