import styled from 'styled-components/macro';

export const Container = styled.div`
  margin: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
export const Title = styled.span`
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  color: #4c4d4d;
`;
export const Step = styled.span`
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 150%;
  color: #000000;
`;

export const ProgressBar = styled.div`
  width: 100%;
  height: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 3.2px;
  margin-top: 8px;
`;

interface BarProps {
  active?: boolean;
}
export const Bar = styled.div<BarProps>`
  background: ${props => (props.active ? ({ theme }) => theme.color.primaryColor : '#e7e7e7')};
  width: 100%;
  height: 4px;
`;
