import styled from 'styled-components/macro';

export const StyledInnerContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 2rem 8rem;
  height: calc(100vh - 68px);
  max-width: 1000px;
  margin: auto;
`;

export const StyledQRCodeContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
  height: 80%;
  font-family: 'Open Sans', sans-serif;
  h1 {
    font-family: 'Open Sans', sans-serif;
    margin-bottom: 35px;
  }
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
`;

export const Title = styled.h1`
  font-size: 32px;
`;

export const QRCode = styled.div`
  width: 150px;
  height: 150px;
  background-color: #d9d9d9;
  color: #fc4f4f;
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 18px;
`;

export const StyledCardShowContractContainer = styled.div`
  width: 100%;
  padding: 0.5rem 0;
  display: flex;
  justify-content: center;
`;

export const Image = styled.img`
  align-self: center;
`;

export const CardTitle = styled.p`
  font-weight: 600;
  margin-bottom: 0;
`;

export const CardSubtitle = styled.p`
  margin-bottom: 10px;
`;

export const ArrowContainer = styled.div`
  display: flex;
  margin-bottom: 18px;
  span {
    display: flex;
    color: var(--gray-80, #393939);
    font-size: 14px;
    font-style: normal;
    font-weight: 700 !important;
    line-height: normal;
    align-items: center;
    margin-left: 8px;
  }
`;
