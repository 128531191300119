import authenticationApi from 'api/authentication-api';
import EnterCustomButton from 'components/enter-custom-button';
import ReactHookFormProvider, { FieldsError } from 'components/ReactHookFormProvider';
import InputText from 'components/styled-input';
import Stroke from 'images/stroke.svg';
import { Authority } from 'model/enums/authority';
import { ILogin } from 'model/login';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import {
  ThinBackground,
  ThinButtonContainer,
  ThinContainer,
  ThinHeader,
  ThinHeaderStroke,
  ThinInputContainer,
  ThinRequirementItem,
  ThinRequirementsContainer,
  ThinRequirementTitle,
  ThinSubTitle,
  ThinTitle,
} from 'styled-components/thin-styles';
import * as yup from 'yup';

const CreatePassword: React.FC = props => {
  const { t } = useTranslation();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [password, setPassword] = useState('');
  const { id } = useParams<{ id?: string }>();

  const upperCaseRegex = /^(?=.*[A-Z])/;
  const lowerCaseRegex = /^(?=.*[a-z])/;
  const atLeastOneNumberRegex = /^(?=.*[0-9])/;

  const schema = yup.object({
    password: yup
      .string()
      .required(t('global.errorMessage.required'))
      .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,})/, t('global.errorMessage.requirementsPassword')),
    confirmPassword: yup
      .string()
      .required(t('global.errorMessage.required'))
      .oneOf([yup.ref('password'), null], t('global.errorMessage.matchPasswords')),
  });

  const fieldsError: FieldsError[] = [
    { name: 'password', errorMessage: t('global.errorMessage.login') },
    { name: 'confirmPassword', errorMessage: t('global.errorMessage.login') },
  ];

  const onHandleSubmit = (data: ILogin) => {
    setIsLoading(true);

    if (id) {
      authenticationApi
        .finishPasswordReset(id, data.password)
        .then(res => {
          if (
            res.data.authorities.includes(Authority.ROLE_ADMIN) ||
            res.data.authorities.includes(Authority.ROLE_INTERNAL_ADMIN) ||
            res.data.authorities.includes(Authority.ROLE_MANAGER) ||
            res.data.authorities.includes(Authority.ROLE_ANALYST) ||
            res.data.authorities.includes(Authority.ROLE_FINANCIAL) ||
            res.data.authorities.includes(Authority.ROLE_ATTENDANT) ||
            res.data.authorities.includes(Authority.ROLE_INTERNAL)
          ) {
            history.push('/admin/login');
          } else {
            history.push('/login');
          }
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
    setIsLoading(false);
  };

  return (
    <ThinBackground>
      <ThinHeader onClick={() => history.goBack()}>
        <ThinHeaderStroke src={Stroke} />
        <span>{t('global.button.backStep')}</span>
      </ThinHeader>
      <ThinContainer>
        <ThinTitle>{t('login.recovery.createPassword.title')}</ThinTitle>
        <ThinSubTitle>{t('login.recovery.createPassword.subtitle')}</ThinSubTitle>
        <ThinRequirementsContainer>
          <ThinRequirementTitle>{t('login.recovery.createPassword.requirements.title')}</ThinRequirementTitle>
          <ThinRequirementItem isValid={password.length >= 6 ? true : false}>
            {t('login.recovery.createPassword.requirements.stepOne')}
          </ThinRequirementItem>
          <ThinRequirementItem isValid={lowerCaseRegex.test(password)}>
            {t('login.recovery.createPassword.requirements.stepTwo')}
          </ThinRequirementItem>
          <ThinRequirementItem isValid={upperCaseRegex.test(password)}>
            {t('login.recovery.createPassword.requirements.stepThree')}
          </ThinRequirementItem>
          <ThinRequirementItem isValid={atLeastOneNumberRegex.test(password)}>
            {t('login.recovery.createPassword.requirements.stepFour')}
          </ThinRequirementItem>
        </ThinRequirementsContainer>
      </ThinContainer>
      <ReactHookFormProvider schema={schema} onSubmit={onHandleSubmit} fieldsWithError={fieldsError}>
        <ThinInputContainer>
          <InputText
            onChange={e => setPassword(e.target.value)}
            width={300}
            inputType="password"
            name="password"
            label={t('login.label.newPassword')}
            placeholder={t('login.placeholder.newPassword')}
          />
          <br />
          <InputText
            width={300}
            inputType="password"
            name="confirmPassword"
            label={t('login.label.confirmPassword')}
            placeholder={t('login.placeholder.confirmPassword')}
          />
        </ThinInputContainer>
        <ThinButtonContainer>
          <EnterCustomButton type={'submit'} width={300} text={t('global.button.send')} disabled={false} isLoading={isLoading} />
        </ThinButtonContainer>
      </ReactHookFormProvider>
    </ThinBackground>
  );
};

export default CreatePassword;
