import React from 'react';
import { LandingPageTemplateProvider } from './landing-page-template';
import { OrganizationParametersProvider } from './organization';
import { PreRegisterContextProvider } from './pre-register';
import { ContractContextProvider } from './contract';
import { AdminFiltersProvider } from './admin-filters';
import { SimulatorContextProvider } from './simulator';
import { UseResponsiveContextProvider } from './use-responsive-context';
import { ConfirmSimulationContextProvider } from './confirm-simulation';

const GlobalContext: React.FC = ({ children }) => {
  return (
    <UseResponsiveContextProvider>
      <AdminFiltersProvider>
        <OrganizationParametersProvider>
          <LandingPageTemplateProvider>
            <SimulatorContextProvider>
              <ConfirmSimulationContextProvider>
                <ContractContextProvider>
                  <PreRegisterContextProvider>{children}</PreRegisterContextProvider>
                </ContractContextProvider>
              </ConfirmSimulationContextProvider>
            </SimulatorContextProvider>
          </LandingPageTemplateProvider>
        </OrganizationParametersProvider>
      </AdminFiltersProvider>
    </UseResponsiveContextProvider>
  );
};

export default GlobalContext;
