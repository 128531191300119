import { RoutePath } from 'model/enums/route-path';
import { useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTheme } from 'styled-components';

interface LocationParameters {
  CCBNumber: string;
  paymentStatus: string;
  valueAdd: string;
  assignmentFee: string;
  amountPay: string;
}

const useNewBalanceScreen = () => {
  const { color } = useTheme();
  const history = useHistory();
  const { state } = useLocation<LocationParameters>();

  const [isShowPaymentCancellationModal, setIsShowPaymentCancellationModal] = useState<boolean>(false);
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [pixKey, setPixKey] = useState<string>('');

  useMemo(() => {
    const isEssentialParameterMissing =
      state?.CCBNumber == null ||
      state?.paymentStatus == null ||
      state?.valueAdd == null ||
      state?.assignmentFee == null ||
      state?.amountPay == null;

    if (isEssentialParameterMissing) {
      history.push(RoutePath.ADMIN_HOME);
    }
  }, [state]);

  const goToPreviousScreen = () => {
    history.goBack();
  };

  const openCancelPaymentModal = () => {
    setIsShowPaymentCancellationModal(true);
  };

  const closeCancelPaymentModal = () => {
    setIsShowPaymentCancellationModal(false);
  };

  const handleCancelPayment = () => {
    // TODO: integrar com API para cancelar o pagamento
  };

  const handleAdvancementOfSteps = () => {
    // TODO: criar a lógica para avançar os steps
  };

  return {
    color,
    state,
    isShowPaymentCancellationModal,
    currentStep,
    pixKey,
    goToPreviousScreen,
    handleCancelPayment,
    openCancelPaymentModal,
    closeCancelPaymentModal,
    handleAdvancementOfSteps,
  };
};

export default useNewBalanceScreen;
