import { AxiosResponse } from 'axios';
import { api } from './api';
import { AddBalanceResponse, BalanceRequest, BalanceRequestTransactions, BalanceTransactions } from 'model/my-account';
import { PageableResponse } from 'model/pageable';

export const myAccountApi = () => {
  const getBalances = (subisidiaryId?: string): Promise<AxiosResponse<any>> => {
    if (subisidiaryId) {
      return api.get<any>(`/balances?subsidiaryId=${subisidiaryId}`);
    } else {
      return api.get<any>(`/balances`);
    }
  };

  const addBalance = (amount: BalanceRequest, subisidiaryId?: string): Promise<AxiosResponse<AddBalanceResponse>> => {
    if (subisidiaryId) {
      return api.post<AddBalanceResponse>(`/balance-request-transactions?subsidiaryId=${subisidiaryId}`, amount);
    } else {
      return api.post<AddBalanceResponse>(`/balance-request-transactions`, amount);
    }
  };

  const getBalancesTransactions = (subisidiaryId?: string): Promise<AxiosResponse<PageableResponse<BalanceTransactions>>> => {
    if (subisidiaryId) {
      return api.get<PageableResponse<BalanceTransactions>>(`/balance-transactions?subsidiaryId=${subisidiaryId}`);
    } else {
      return api.get<PageableResponse<BalanceTransactions>>(`/balance-transactions`);
    }
  };

  const getBalancesRequestTransactions = (subisidiaryId?: string): Promise<AxiosResponse<PageableResponse<BalanceRequestTransactions>>> => {
    if (subisidiaryId) {
      return api.get<PageableResponse<BalanceRequestTransactions>>(`/balance-request-transactions?subsidiaryId=${subisidiaryId}`);
    } else {
      return api.get<PageableResponse<BalanceRequestTransactions>>(`/balance-request-transactions`);
    }
  };

  return {
    getBalances,
    addBalance,
    getBalancesTransactions,
    getBalancesRequestTransactions,
  };
};

export default myAccountApi();
