import BlueCustomButtonNoBackground from 'components/blue-custom-button-no-background';
import { ReactComponent as EditIcon } from 'images/gray-edit-icon.svg';
import { ReactComponent as GreenIconDropdown } from 'images/green-icon-dropdown.svg';
import { ReactComponent as IconDropdown } from 'images/icon-dropdown.svg';
import { ReactComponent as Trash } from 'images/trash.svg';
import { BankAccount } from 'model/bank-account';
import { BankAccountType } from 'model/enums/bank-account-type';
import { Dispatch, SetStateAction } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import bankService from 'services/bank-service';
import { StyledLastSelected, StyledModalContainer, StyledModalData, StyledModalInnerContainer } from '../styles';

interface Props extends WithTranslation {
  isShowListDataModal: boolean;
  selectedBankAccount?: BankAccount;
  allBankAccounts: BankAccount[];
  setIsShowListDataModal: Dispatch<SetStateAction<boolean>>;
  setSelectedBankAccount: Dispatch<SetStateAction<BankAccount | undefined>>;
  setIsShowModalCreateBankAccount: Dispatch<SetStateAction<boolean>>;
  getRegisteredBanks: () => void;
  getFinancingBankAccount: () => void;
}

const BankAccountSelection = ({
  isShowListDataModal,
  selectedBankAccount,
  allBankAccounts,
  setIsShowListDataModal,
  setSelectedBankAccount,
  setIsShowModalCreateBankAccount,
  getRegisteredBanks,
  getFinancingBankAccount,
  t,
}: Props) => {
  const handleUpdatingBankAccount = () => {
    if (selectedBankAccount?.bank != null) {
      setIsShowModalCreateBankAccount(true);
    }
  };

  const deleteBankAccount = () => {
    if (selectedBankAccount?.id != null) {
      bankService.deleteBankAccount(selectedBankAccount.id).then(() => {
        setSelectedBankAccount(undefined);
        getRegisteredBanks();
        getFinancingBankAccount();
      });
    }
  };

  return (
    <StyledModalContainer>
      <StyledModalInnerContainer onClick={() => setIsShowListDataModal(!isShowListDataModal)}>
        <span>{t('dashboard.selectAccount')}</span>
        <IconDropdown />

        {isShowListDataModal && (
          <StyledModalData>
            {allBankAccounts.map(bankData => (
              <li
                key={`bank-data-${bankData.id}`}
                onClick={() => {
                  setSelectedBankAccount(bankData);
                }}
              >
                {`${bankData.type ? BankAccountType[bankData.type] : ''} ${bankData.bankAccountNumber}-${bankData.bankAccountDigit}`}
                {bankData.id === selectedBankAccount?.id && <GreenIconDropdown />}
              </li>
            ))}
          </StyledModalData>
        )}
      </StyledModalInnerContainer>
      <BlueCustomButtonNoBackground
        onClick={() => {
          setIsShowModalCreateBankAccount(true);
          setSelectedBankAccount(undefined);
        }}
      >
        {t('global.button.registerNewAccount')}
      </BlueCustomButtonNoBackground>
      {selectedBankAccount != null && (
        <StyledLastSelected>
          {`${selectedBankAccount.type ? BankAccountType[selectedBankAccount.type] : ''} ${selectedBankAccount.bankAccountNumber}-${
            selectedBankAccount.bankAccountDigit
          }`}
          <div>
            <EditIcon style={{ cursor: 'pointer', marginRight: '8px' }} onClick={() => handleUpdatingBankAccount()} />
            <Trash style={{ cursor: 'pointer' }} onClick={() => deleteBankAccount()} />
          </div>
        </StyledLastSelected>
      )}
    </StyledModalContainer>
  );
};

export default withTranslation()(BankAccountSelection);
