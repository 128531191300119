import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import * as S from './styles';

interface Props {
  name: string;
  label: string;
  helperText?: string;
  defaultValue?: string;
  maxLength?: number;
  disabled?: boolean;
  typeDate?: boolean;
}

const CustomTextField = ({ name, label, helperText, defaultValue, maxLength, disabled, typeDate }: Props) => {
  const { register } = useFormContext();

  const [valueEnteredInput, setValueEnteredInput] = useState<string>(defaultValue ?? '');

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    let formattedValue = value;

    if (typeDate) {
      formattedValue = formatDateString(value);
    }

    setValueEnteredInput(formattedValue);
  };

  const formatDateString = (value: string) => {
    const numericValue = value.replace(/\D/g, '');
    const formattedValue = numericValue.replace(/^(\d{2})(\d{2})(\d{4})$/, '$1/$2/$3').replace(/^(\d{2})(\d{2})/, '$1/$2');
    return formattedValue;
  };

  return (
    <S.ControlLabel
      label={label}
      labelPlacement="top"
      control={
        <S.InputText
          id={`#custom-text-${name}`}
          {...register(name)}
          disabled={disabled}
          variant="outlined"
          helperText={helperText}
          onChange={handleInputChange}
          value={valueEnteredInput}
          inputProps={{ maxLength: maxLength ?? 255 }}
          autoComplete="off"
        />
      }
    />
  );
};

export default CustomTextField;
