import { FormControlLabel, TextField } from '@mui/material';
import styled from 'styled-components/macro';

interface StyledProps {
  disabled?: boolean;
}

export const ControlLabel = styled(FormControlLabel)<StyledProps>`
  width: 100% !important;
  margin: 0 !important;
  align-items: flex-start !important;

  .MuiTextField-root {
    width: 100%;
    -webkit-text-fill-color: ${({ theme }) => `${theme.color.gray22}`};
  }

  .MuiFormControlLabel-label {
    font-family: 'Satoshi';
    font-size: 14px;
    font-weight: 500;
    color: ${({ theme }) => `${theme.color.gray22} !important`};
  }
`;

export const InputText = styled(TextField)<StyledProps>`
  input {
    height: 44px;
    font-family: 'Satoshi';
    font-size: 16px;
    font-weight: 400;
    padding: 0 14px;
  }

  input::placeholder {
    -webkit-text-fill-color: ${({ theme }) => theme.color.gray24};
    opacity: 1;
  }

  .Mui-disabled {
    background-color: #f3f3f3;
    border-radius: 8px !important;
    -webkit-text-fill-color: ${({ theme }) => `${theme.color.gray22} !important`};
  }

  .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border: ${({ theme }) => `1px solid ${theme.color.gray23}`};
    border: ${({ disabled }) => disabled && 'none !important'};
    border-radius: 8px;
  }

  .MuiOutlinedInput-root {
    padding: 0 !important;
  }

  .MuiAutocomplete-input {
    padding: 0 14px !important;
  }

  .MuiAutocomplete-popupIndicator {
    margin-top: 3px !important;
  }
`;
