import styled from 'styled-components/macro';

interface ContainerProps {
  required?: boolean;
}
export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  width: 100%;
  height: 80px;
  border: 1px dashed ${props => (props.required ? '#fc4f4f' : '#cccccc')};
  border-radius: 8px;
  background-color: #fff;

  .subtitle {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    display: flex;
    align-items: center;
    color: #cccccc;
  }
`;

export const DragAndDrop = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  height: 100%;
  padding-left: 16px;
`;

export const ContainerDataDocument = styled.div`
  height: 18px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background: transparent;
  border-radius: 6px;
  padding-left: 10px;
  padding-right: 10px;
  img {
    width: 12px;
    height: 12px;
  }
  a,
  span {
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    text-decoration: underline !important;
    color: #010101;
    cursor: pointer;
    margin-left: 10px;
    margin-right: 10px;
  }
`;

export const ContainerTitleAndData = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 350px;
  img {
    width: 24px;
  }
  .title {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    color: #000000;
    width: 100%;
  }
`;

export const Label = styled.label`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  gap: 8px;
  width: 180px;
  height: 48px;
  border: 1.5px solid #4b6fdd;
  border-radius: 24px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  color: #4b6fdd;
  cursor: pointer;
`;

export const LabelNotEdit = styled.label`
  width: 85px;
  height: 30px;
  background: transparent;
  border: 1px solid #999999;
  border-radius: 4px;
  color: #999999;
  margin: 14px 15px 0;
  font-weight: 600;
  font-size: 14px;
  text-align: center;

  display: flex;
  align-items: center;
  justify-content: center;

  > img {
    margin-right: 6px;
  }
`;

export const Upload = styled.label`
  width: 48px;
  height: 48px;
  background: #eff6ff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
`;
