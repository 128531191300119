import BlueCustomButtonNoBackground from 'components/blue-custom-button-no-background';
import CustomButton from 'components/custom-button';
import Status from 'components/status/Status';
import WhiteCustomTable from 'components/white-custom-table/WhiteCustomTable';
import { ReactComponent as ChevronRight } from 'images/blue-chevron-right.svg';
import { ReactComponent as PlusSvg } from 'images/white-plus.svg';
import BiometricsStatusAndClientAuthentication from 'model/biometrics-status-and-client-authentication';
import { Financing } from 'model/financing';
import { Dispatch, SetStateAction } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { formatDateToLocalFormat } from 'shared/util/date-utils';
import StringUtils from 'shared/util/string-utils';
import { StyledInnerContainer, StyledSubtitle, StyledTitle, StyledTitleContainer } from '../styles';

interface Props extends WithTranslation {
  myFinancing?: Financing[];
  biometricsStatusAndClientAuthentication?: BiometricsStatusAndClientAuthentication;
  setIsShowCreditLockModal: Dispatch<SetStateAction<boolean>>;
}

const Desktop = ({ myFinancing, biometricsStatusAndClientAuthentication, setIsShowCreditLockModal, t }: Props) => {
  const history = useHistory();

  const headerData = [
    t('myFinancing.requestNumber'),
    t('myFinancing.requestDate'),
    t('myFinancing.value'),
    t('myFinancing.installments'),
    t('myFinancing.status'),
    '',
  ];

  const myFinancingFormatsToShowTable = myFinancing?.map(financing => ({
    identification: financing.identification ?? '',
    createdDate: formatDateToLocalFormat(financing.createdDate),
    value:
      financing?.choosedValue != null && financing?.approvedValue != null
        ? `R$ ${StringUtils.maskAmount(
            financing.choosedValue > financing?.approvedValue ? financing?.approvedValue : financing.choosedValue
          )}`
        : `R$ ${StringUtils.maskAmount(financing?.choosedValue)}`,
    installments: `${financing.installmentsTotal}x R$${StringUtils.maskAmount(financing.installmentValue)}`,
    status:
      financing?.financingStatus != null ? (
        <Status
          status={StringUtils.configureCreditTableStatusView(
            financing.financingStatus,
            financing.contracts?.[0]?.status,
            financing.payment?.status
          )}
        />
      ) : (
        <></>
      ),
    access: (
      <BlueCustomButtonNoBackground
        onClick={() =>
          history.push('dashboard', {
            financingId: financing.id,
            financingStatus: financing.financingStatus,
            consultantComment: financing.consultantComment,
            organization: biometricsStatusAndClientAuthentication?.organization,
            biometricsIsNecessary: financing.biometricsIsNecessary,
          })
        }
      >
        {t('global.button.access')}
        <ChevronRight />
      </BlueCustomButtonNoBackground>
    ),
  }));

  return (
    <StyledInnerContainer>
      <StyledTitleContainer>
        <StyledTitle>{t('myFinancing.title')}</StyledTitle>
        <CustomButton
          disabled={biometricsStatusAndClientAuthentication == null}
          height={48}
          width="258px"
          onClick={() =>
            biometricsStatusAndClientAuthentication?.isVerified ? history.push('/novo-financiamento') : setIsShowCreditLockModal(true)
          }
        >
          <PlusSvg />
          {t('global.button.addNewCredit')}
        </CustomButton>
      </StyledTitleContainer>

      <StyledSubtitle>{t('myFinancing.subtitle')}</StyledSubtitle>

      {myFinancing != null && <WhiteCustomTable headerData={headerData} tableData={myFinancingFormatsToShowTable ?? []} />}
    </StyledInnerContainer>
  );
};

export default withTranslation()(Desktop);
