import styled from 'styled-components/macro';

interface GlobalProps {
  mobile?: boolean;
}

export const Container = styled.div<GlobalProps>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: ${props => !props.mobile && 'center'};
  margin-top: 20px;
  @media (max-width: 1080px) {
    max-width: 100%;
    div {
      max-width: 100%;
    }
  }
`;

export const Title = styled.span<GlobalProps>`
  font-style: normal;
  font-weight: 700;
  font-size: ${props => (props.mobile ? '20px' : '32px')};
  line-height: 150%;
  color: #000000;
  margin-left: 20px;
  @media (max-width: 1080px) {
    font-size: 28px;
    padding: 8px;
  }
`;

export const StyledHeader = styled.div<GlobalProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: ${props => props.mobile && '0px 16px'};
    img {
      width: 10px;
      width: 15px;
      cursor: pointer;
    }
  }
`;
interface EditButtonProps extends GlobalProps {
  textWidth?: number;
}

export const EditButton = styled.button<EditButtonProps>`
  width: max-content;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 20px;
  gap: 8px;
  height: 48px;
  border: 1.5px solid ${({ theme }) => theme.color.primaryColor};
  border-radius: 24px;
  background-color: #fff;
  min-width: 190px;

  img {
    width: 20px;
    height: 20px;
  }
  span {
    font-style: normal;
    font-weight: 700;
    font-size: ${props => (props.textWidth ? `${props.textWidth}px` : '16px')};
    line-height: 150%;
    color: ${({ theme }) => theme.color.primaryColor};
  }
`;

export const EditButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  gap: 8px;
  background: #ffffff;
  box-shadow: 0px -2px 12px rgba(0, 0, 0, 0.06);
  position: fixed;
  bottom: 0;
`;
