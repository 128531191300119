import styled from 'styled-components/macro';

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledInnerContainer = styled.div`
  height: calc(100vh - 87px);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  h1 {
    font-size: 20px;
    font-family: 'Satoshi';
    font-weight: 700;
  }
  & > span {
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    color: #393939;
  }

  .animationAndSvg {
    margin: auto 0;
    margin-top: 30px;
    text-align: -webkit-center;
  }

  .subtitle {
    padding-bottom: 2rem;
  }

  .waiting-step-footer {
    font-size: 12px;
    color: #8d8d8d;
    font-weight: 400px;
    /* margin-top: auto; */
  }

  .spin {
    animation: spin 2s linear infinite; /* Adjust animation duration as needed */
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const CentralizedContainer = styled.div`
  text-align: center;
  margin: auto;
`;

export const StyledPaintSvg = styled.div`
  &.files-folder-file-sort-man {
    svg path:nth-child(39) {
      stroke: ${({ theme }) => theme.color.primaryColor};
    }

    svg path:nth-child(43),
    svg path:nth-child(62) {
      fill: ${({ theme }) => theme.color.primaryColor};
    }
  }

  &.expired-subscription {
    svg path:nth-child(5),
    svg path:nth-child(12),
    svg path:nth-child(29) {
      fill: ${({ theme }) => theme.color.primaryColor};
    }
  }
`;
