import { yupResolver } from '@hookform/resolvers/yup';
import { Loading } from 'components/loading';
import InputText from 'components/styled-input';
import { Status } from 'features/admin/dashboard/components/status';
import { Authority } from 'model/enums/authority';
import FinancingStatusType from 'model/enums/financing-status-type';
import { GeneralStatus } from 'model/enums/general-status';
import { Financing } from 'model/financing';
import { Mask } from 'model/mask-types';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FiEdit, FiEdit2 } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducer';
import financingService from 'services/financing-service';
import StringUtils, { fillNumbers } from 'shared/util/string-utils';
import { hasAuthorities } from 'shared/util/utils';
import { useTheme } from 'styled-components';
import * as yup from 'yup';
import { Box, Button, ButtonBox, Container, Separator, TitleBox } from './styles';

interface Schema {
  procedureName: string;
  procedureValue: string;
  contractValue: string;
  fees: string;
  cet: string;
  agency: string;
  account: string;
  status: string;
  installmentsTotal: string;
}

interface Props {
  procedure?: Financing;
  getProcedure: () => void;
}

export const TabDetails = ({ procedure, getProcedure }: Props) => {
  const { color } = useTheme();
  const { t } = useTranslation();
  const [disabledToEdit, setDisabledToEdit] = useState(true);
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const account = useSelector((state: IRootState) => state.authentication.account);

  const schema = yup.object().shape({
    procedureName: yup.string(),
    procedureValue: yup.string(),
    contractValue: yup.string(),
    fees: yup.string(),
    cet: yup.string(),
    agency: yup.string(),
    account: yup.string(),
    status: yup.string(),
    installmentsTotal: yup.string(),
  });

  useEffect(() => {
    if (hasError) {
      setTimeout(() => {
        setLoading(false);
        setHasError(false);
      }, 200);
    }
  }, [hasError]);

  const methods = useForm<Schema>({ resolver: yupResolver(schema) });

  const saveEdit = async (values: Schema) => {
    const choosedValue = Number(StringUtils.removeMoneyFormat(values.procedureValue));
    const medicalProcedure = values.procedureName;
    const financingTax = Number(StringUtils.annualPercentageMask(values.fees));

    if (choosedValue === 0) {
      setHasError(true);
      setLoading(true);
      return methods.setError('procedureValue', { message: t('global.errorMessage.income') });
    }

    setLoading(true);

    if (procedure?.id && procedure.client?.id) {
      const res = await financingService.updateProcedureAdmin(procedure.id, {
        choosedValue,
        medicalProcedure,
        financingTax,
        id: procedure.id,
        client: {
          id: procedure.client?.id,
        },
      });

      if (res.id) {
        setLoading(false);
        getProcedure();
        setDisabledToEdit(true);
      }
    }
  };

  return (
    <Container>
      <FormProvider {...methods}>
        <Box>
          <InputText
            name="procedureName"
            label={t('admin.procedureScreen.details.procedureName')}
            disabled={disabledToEdit}
            defaultValue={procedure?.medicalProcedure ?? ''}
            activated={!disabledToEdit}
            flexWidth
            rightIcon={
              procedure?.financingStatus !== FinancingStatusType.APPROVED ? <FiEdit2 size={18} color={color.primaryColor} /> : <></>
            }
          />
          <InputText
            name="procedureValue"
            label={t('admin.procedureScreen.details.procedureValue')}
            disabled={disabledToEdit}
            defaultValue={StringUtils.moneyMaskPtbr(procedure?.choosedValue ?? 0)}
            activated={!disabledToEdit}
            flexWidth
            rightIcon={
              procedure?.financingStatus !== FinancingStatusType.APPROVED ? <FiEdit2 size={18} color={color.primaryColor} /> : <></>
            }
            mask={Mask.CURRENCY}
            onBlur={() => {
              const value = methods.getValues('procedureValue');
              methods.setValue('procedureValue', value);
            }}
          />
          <InputText
            name="contractValue"
            label={t('admin.procedureScreen.details.contractValue')}
            disabled
            defaultValue={StringUtils.moneyMaskPtbr(procedure?.financingValue ?? 0)}
          />
        </Box>

        {procedure?.financingStatus !== FinancingStatusType.APPROVED &&
          procedure?.financingStatus !== FinancingStatusType.CANCELLED &&
          procedure?.financingStatus !== FinancingStatusType.FINISHED &&
          hasAuthorities(account?.authorities, [Authority.ROLE_ADMIN, Authority.ROLE_MANAGER]) && (
            <ButtonBox>
              {!loading && !disabledToEdit && (
                <Button
                  type="button"
                  onClick={() => {
                    if (procedure?.choosedValue) {
                      methods.clearErrors('procedureValue');
                      methods.setValue('procedureValue', StringUtils.moneyMaskPtbr(procedure?.choosedValue));
                    }

                    if (procedure?.medicalProcedure) {
                      methods.clearErrors('procedureName');
                      methods.setValue('procedureName', procedure?.medicalProcedure);
                    }

                    setDisabledToEdit(true);
                  }}
                >
                  <span>{t('global.button.cancel')}</span>
                </Button>
              )}
              {disabledToEdit ? (
                <Button type="button" onClick={() => setDisabledToEdit(false)}>
                  <FiEdit size={20} color={color.primaryColor} />
                  <span>{t('admin.procedureScreen.details.editData')}</span>
                </Button>
              ) : (
                <Button onClick={methods.handleSubmit(saveEdit)}>
                  {loading ? <Loading /> : <span>{t('global.button.saveEdition')}</span>}
                </Button>
              )}
            </ButtonBox>
          )}

        <Separator />

        <Box>
          <InputText
            name="fees"
            label={t('admin.procedureScreen.details.fees')}
            disabled={disabledToEdit}
            defaultValue={t('admin.customerAnalysis.tabDetails.tax', { tax: procedure?.financingTax ?? 0 })}
            activated={!disabledToEdit}
            flexWidth
            rightIcon={
              procedure?.financingStatus !== FinancingStatusType.APPROVED ? <FiEdit2 size={18} color={color.primaryColor} /> : <></>
            }
            mask={disabledToEdit ? Mask.DEFAULT : Mask.ANNUAL_PERCENTAGE}
            onBlur={() => {
              const value = methods.getValues('fees');
              methods.setValue('fees', t('admin.customerAnalysis.tabDetails.tax', { tax: value }));
            }}
            onFocus={() => {
              const value = methods.getValues('fees');
              methods.setValue('fees', StringUtils.annualPercentageMask(value));
            }}
          />
          <InputText
            name="cet"
            label={t('admin.procedureScreen.details.cet')}
            disabled
            defaultValue={t('admin.customerAnalysis.tabDetails.tax', { tax: procedure?.monthlyTotalEffectiveCost ?? 0 })}
          />
          <InputText
            name="installmentValue"
            label={t('admin.procedureScreen.details.installmentValue')}
            disabled
            defaultValue={StringUtils.moneyMaskPtbr(procedure?.installmentValue ?? 0)}
          />
          <InputText
            name="installmentsTotal"
            label={t('myProcedures.numberOfInstallments')}
            disabled={disabledToEdit}
            activated={!disabledToEdit}
            defaultValue={procedure?.installmentsTotal?.toString() ?? '1'}
            rightIcon={
              procedure?.financingStatus !== FinancingStatusType.APPROVED ? <FiEdit2 size={18} color={color.primaryColor} /> : <></>
            }
          />
        </Box>

        <Separator />

        <TitleBox>
          <span>{t('admin.procedureScreen.details.bankAccount')}</span>
        </TitleBox>

        <Box>
          <InputText
            name="agency"
            label={t('admin.procedureScreen.details.agency')}
            disabled
            defaultValue={`${procedure?.bankAccount?.agencyNumber != null ? fillNumbers(procedure?.bankAccount.agencyNumber) : ''}${
              procedure?.bankAccount?.agencyDigit ? `-${procedure?.bankAccount?.agencyDigit}` : ''
            }`}
          />
          <InputText
            name="account"
            label={t('admin.procedureScreen.details.account')}
            disabled
            defaultValue={
              procedure?.bankAccount?.bankAccountNumber != null
                ? `${procedure?.bankAccount?.bankAccountNumber}-${procedure?.bankAccount?.bankAccountDigit}`
                : ''
            }
          />
          <InputText
            name="status"
            label={t('admin.procedureScreen.details.status')}
            disabled
            defaultValue={
              <Status
                color={procedure?.bankAccount != null ? GeneralStatus.APPROVED : GeneralStatus.PENDING}
                text={t(`enum.filterAllStatus.${procedure?.bankAccount != null ? GeneralStatus.APPROVED : GeneralStatus.PENDING}`)}
              />
            }
            isElement
          />
        </Box>
      </FormProvider>
    </Container>
  );
};
