import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { styled as styledMaterial } from '@mui/material/styles';
import styled, { useTheme } from 'styled-components';

export const QontoConnector = styledMaterial(StepConnector)(() => {
  const theme = useTheme();

  return {
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 10,
      left: 'calc(-50% + 16px)',
      right: 'calc(50% + 16px)',
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: theme.color.primaryColor,
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: theme.color.primaryColor,
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.color.gray20,
      borderTopWidth: 3,
      borderRadius: 1,
    },
  };
});

export const QontoStepIconRoot = styledMaterial('div')<{ ownerState: { active?: boolean } }>(({ ownerState }) => {
  const theme = useTheme();

  return {
    color: theme.color.gray20,
    display: 'flex',
    height: 20,
    alignItems: 'center',
    ...(ownerState.active && {
      color: theme.color.primaryColor,
    }),
    '& .QontoStepIcon-completedIcon': {
      width: 12,
      height: 12,
      borderRadius: '50%',
      backgroundColor: theme.color.primaryColor,
    },
    '& .QontoStepIcon-circle': {
      width: 12,
      height: 12,
      borderRadius: '50%',
      border: `2px solid currentColor`,
    },
    '& .QontoStepIcon-active': {
      width: 12,
      height: 12,
      borderRadius: '50%',
      backgroundColor: theme.color.primaryColorLight,
    },
  };
});

export const StyledStepperContainer = styled.div`
  .css-1bw0nnu-MuiStep-root {
    padding: 0;
  }

  .css-vnkopk-MuiStepLabel-iconContainer {
    padding: 0;
  }
`;

export const StyledContainer = styled.div`
  font-size: 16px;
  font-weight: 700;
  color: #000000;
  text-align: center;

  svg {
    margin: 20px 0;
  }
`;

export const StyledTitle = styled.div`
  font-size: 32px;
  text-align: left;

  @media (max-width: 575.98px) {
    font-size: 20px;
  }
`;

export const StyledSubtitle = styled.div`
  text-align: left;
  color: #1b1a1b;
`;

export const StyledText = styled.div`
  color: ${({ theme }) => theme.color.gray19};
  text-align: left;
  font-weight: 400;
`;

export const StyledFixSizeMobile = styled.span`
  svg {
    @media (max-width: 575.98px) {
      width: 160px;
      height: 172px;
    }
  }
`;
