import styled, { css } from 'styled-components';

interface Props {
  active?: boolean;
}

export const Container = styled.div`
  width: 70%;
  height: 68px;
  margin: 0 auto;
  margin-top: 32px;

  flex-direction: initial;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  padding: 12px 24px;

  border-radius: 8px;
  border: 1px solid #e5e7e7;
  background: #fff;
`;

export const ContainerSteps = styled.div`
  width: 100%;
  height: 20px;

  flex-direction: row;
  display: flex;
  align-items: center;
`;

export const TabTitleHeader = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  color: ${({ theme }) => theme.color.gray24};
  margin-bottom: 7px;
`;

export const TabHeaderInnerContainer = styled.div`
  height: 25px;
  display: flex;
  column-gap: 8px;
  align-items: center;
`;

export const Step = styled.div<Props>`
  display: flex;
  flex-direction: row;
  align-items: center;

  > span {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 150%;
    ${({ active }) =>
      css`
        color: ${({ theme }) => (active ? theme.color.gray21 : '#b3b2b3')};
      `}
    margin-left: 4px;
  }
`;

export const BoxIcon = styled.div<Props>`
  width: 18px;
  height: 18px;
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #eee;

  color: #b3b2b3;
  text-align: center;
  font-family: Satoshi;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
`;

export const SeparatorStep = styled.div<Props>`
  flex: 1;
  height: 1px;
  background-color: ${({ theme, active }) => (active ? theme.color.primaryColor : theme.color.gray20)};
  margin: 0px 4px;
`;

export const StyledCircle = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.color.primaryColor};
`;

export const StyledNumberCircle = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.color.primaryColor};
`;
