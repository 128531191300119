import { FormScreenImg } from 'images/form-screen';
import { SimulatorFormData } from 'model/landing-page';
import { useFormContext } from 'react-hook-form';
import { LabelInputBox, StyledError, StyledImg, StyledInput, StyledInputBox, StyledLabel } from './styled';

interface StyledType {
  borderRadius?: boolean;
  borderRight?: boolean;
}

interface InputProps {
  styled?: StyledType;
  label: JSX.Element;
  name: string;
  htmlFor: string;
  required: boolean;
  formData?: SimulatorFormData;
  setFormData: (formData) => void;
  maxLength?: number;
  mask?: (value: string) => string;
  addressError?: string;
}

const Input: React.FC<InputProps> = ({ styled, label, name, required, htmlFor, addressError, formData, setFormData, maxLength, mask }) => {
  const isActive = formData?.[name]?.length > 0;

  const {
    register,
    formState: { errors },
    setError,
    reset,
    setValue,
  } = useFormContext();

  const handleClick = () => {
    setFormData({ ...formData, [name]: '' });
    reset({ [name]: '' });
  };

  const handleChange = (e, key) => {
    if (mask) {
      setFormData({ ...formData, [key]: mask(e.target.value) });
      setValue(key, mask(e.target.value));
    } else {
      setFormData({ ...formData, [key]: e.target.value });
      setValue(key, e.target.value);
    }
  };

  return (
    <StyledInputBox borderRadius={styled?.borderRadius}>
      <LabelInputBox borderRight={styled?.borderRight}>
        <StyledLabel htmlFor={htmlFor} isActive={isActive}>
          {label}
        </StyledLabel>
        <StyledInput
          id={`input-${name}`}
          {...register(name, { required: required })}
          onChange={e => handleChange(e, name)}
          isActive={isActive}
          hasError={errors[name]?.message.length > 0}
          name={name}
          value={formData?.[name] ?? ''}
          maxLength={maxLength}
          autoComplete={'off'}
        />

        {errors[name] ? (
          isActive ? (
            <StyledImg src={FormScreenImg.formClear} alt="clear" onClick={handleClick} />
          ) : (
            <StyledImg src={FormScreenImg.formError} alt="error" />
          )
        ) : (
          isActive && <StyledImg src={FormScreenImg.formClear} alt="clear" onClick={handleClick} />
        )}
      </LabelInputBox>
      <StyledError>{addressError ? addressError : errors[name]?.message}</StyledError>
    </StyledInputBox>
  );
};

export default Input;
