import { AiOutlineArrowsAlt } from 'react-icons/ai';
import styled from 'styled-components/macro';

interface CampProps {
  isFullLineField?: boolean;
  custom?: any;
}

export const ModalContainer = styled.div`
  ${(props: { expanded: boolean }) => null}
  display: flex;
  pointer-events: ${(props: { expanded: boolean }) => (props.expanded ? '' : 'none')};
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  opacity: ${(props: { expanded: boolean }) => (props.expanded ? '1' : '0')};
  position: fixed;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  transition: 500ms;
`;

export const StyledExpandIcon = styled(AiOutlineArrowsAlt)`
  position: absolute;
  color: white;
  margin-top: 25px;
  margin-left: 40px;
  font-size: 20px;
  cursor: pointer;
`;

export const SelfieDisplayAssign = styled.img`
  ${(props: { expanded: boolean }) => null}
  width:  ${(props: { expanded: boolean }) => (props.expanded ? '' : '64px')};
  height: ${(props: { expanded: boolean }) => (props.expanded ? '' : '64px')};
  position: ${(props: { expanded: boolean }) => (props.expanded ? 'relative' : '')};
  top: ${(props: { expanded: boolean }) => (props.expanded ? '-350px' : '')};
  border-radius: 10px;
  cursor: pointer;
  transition: 500ms;
  max-width: 90vw;
  max-height: 450px;
`;

export const SelfieDisplay = styled.img`
  ${(props: { expanded: boolean }) => null}
  width:  ${(props: { expanded: boolean }) => (props.expanded ? '' : '64px')};
  height: ${(props: { expanded: boolean }) => (props.expanded ? '' : '64px')};
  position: ${(props: { expanded: boolean }) => (props.expanded ? 'absolute' : '')};
  left: ${(props: { expanded: boolean }) => (props.expanded ? '50%' : '')};
  top: ${(props: { expanded: boolean }) => (props.expanded ? '-130px' : '')};
  transform: ${(props: { expanded: boolean }) => (props.expanded ? 'translate(-50%, -50%)' : '')};
  border-radius: 10px;
  cursor: pointer;
  transition: 500ms;
  max-width: 90vw;
  max-height: 450px;
`;

export const ScoreAlignor = styled.div`
  display: flex;
  align-items: center;
`;

export const ScoreButton = styled.button`
  font-family: 'Satoshi';
  border: none;
  background-color: transparent;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: #4b6fdd;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin-top: -10px;
`;

export const Separator = styled.hr`
  width: 100%;
  position: absolute;
  bottom: 10px;
  background-color: ${(props: { red: boolean; score?: boolean }) => (props.red ? '#FC4F4F' : '#999999')};
  margin: 0;
  ${props =>
    props.score && {
      width: '200px',
    }}
`;

export const Alignor = styled.div`
  display: flex;
  word-break: break-all;
`;

export const AttachmentBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px;
  gap: 6px;
  height: 42px;
  background: #eff6ff;
  border-radius: 8px;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin-bottom: 8px;
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 150%;
  color: #333333;
  cursor: pointer;
`;

export const NoDataScreen = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 24px;

  svg {
    color: #f7b330;
    font-size: 34px;
  }

  & > p {
    font-family: 'Satoshi';
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    text-align: center;
    color: #333333;
  }
`;

export const CampContainer = styled.div<CampProps>`
  width: ${props => (props.isFullLineField ? '100%' : '300px')};
  min-height: 63px;
  position: relative;
  ${(props: { custom?: any }) => props.custom}
`;

export const SelfieContainer = styled.div`
  width: 100%;
  min-height: 150px;
  height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  > .button-container {
    display: flex;
    justify-content: space-between;
    width: 420px;
  }
`;

export const CampName = styled.h2`
  width: 288px;
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #999999;
`;

export const CampContent = styled.h4<CampProps>`
  width: ${props => (props.isFullLineField ? '100%' : '288px')};
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #333333;
`;

export const CampsContainer = styled.div`
  display: flex;
  margin-top: 40px;
  width: 100%;
  gap: 25px;
  align-items: center;
  flex-wrap: wrap;
`;
