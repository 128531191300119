import { ORGANIZATION_NAME } from 'config/constants';
import { useRedirect } from 'hooks/use-redirect/use-redirect';
import { useSystemParameters } from 'hooks/use-system-parameters';
import { Authority } from 'model/enums/authority';
import { LocalStorageKeys } from 'model/enums/local-storage-keys';
import { OrganizationName } from 'model/enums/organization-name';
import { RegistrationStatus } from 'model/enums/registration-status';
import { RoutePath } from 'model/enums/route-path';
import { FinancingSimpleSimulation } from 'model/financing';
import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { IRootState } from 'reducer';
import authenticationService from 'services/authentication-service';
import StringUtils from 'shared/util/string-utils';
import useResponsiveMobile from 'shared/util/use-responsive-hook';
import { ProcessingSimulatorData, useSendFinancingData } from './handler/send-financing-data';
import { ProcessingClientData, useClientData } from './hooks/use-client-data';
import { SimulatorProcessingMoment, useSimulatorState } from './hooks/use-simulator-state';
import Desktop from './layout/desktop';
import Mobile from './layout/mobile';

const ProcessingSimulator = () => {
  const isMobile = useResponsiveMobile().isTablet;
  const { systemParameters } = useSystemParameters();
  const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };
  const query = useQuery();
  const { clientId } = useParams<{ clientId: string }>();
  const authorities = useSelector((state: IRootState) => state.authentication.account?.authorities);
  const registrationKey = query.get(LocalStorageKeys.REGISTRATION_KEY);
  const history = useHistory();

  const { getClientData } = useClientData();
  const { replace } = useRedirect();
  const { sendSimulator, isNewCredit } = useSimulatorState();

  const simulatorProcessingStep = useMemo(() => {
    return history.location.pathname.includes(RoutePath.SIMULATOR_PROCESSING)
      ? SimulatorProcessingMoment.BEFORE_SIMULATION
      : SimulatorProcessingMoment.AFTER_SIMULATION;
  }, [history.location.pathname]);

  const sendFinancingData = useSendFinancingData();

  const allowedFunctions = [Authority.ROLE_ADMIN, Authority.ROLE_INTERNAL_ADMIN, Authority.ROLE_MANAGER, Authority.ROLE_FINANCIAL];
  const isAdmin = allowedFunctions.some(role => authorities?.includes(role));

  const handleClientData = async (registrationKey: string) => {
    const clientData: ProcessingClientData | undefined = await getClientData(registrationKey);

    const clientCpf = clientData?.clientRegistration?.physicalPerson?.cpf.toString();
    const clientCnpj = clientData?.clientRegistration?.legalPerson?.cnpj.toString();

    const clientDocumentNumber = clientCpf ?? clientCnpj;

    if (clientData) {
      switch (clientData.registrationStatus) {
        case RegistrationStatus.NEW:
          authenticationService
            .checkIfClientAlreadyRegistered(StringUtils.removeNonNumbersFromMaskedValue(clientDocumentNumber ?? ''))
            .then(res => {
              if (res.isRegistered) {
                redirectToAuthenticate(clientDocumentNumber);
              } else {
                localStorage.setItem(LocalStorageKeys.REGISTRATION_KEY, JSON.stringify(registrationKey));

                if (ORGANIZATION_NAME !== OrganizationName.MULTTIPLO) {
                  const lastSimulation = clientData.client?.finances?.[0];

                  const sendSimulator: FinancingSimpleSimulation = {
                    choosedValue: lastSimulation?.choosedValue,
                    client: { id: clientData.client?.id },
                    id: lastSimulation?.id,
                    installmentsTotal: lastSimulation?.installmentsTotal,
                  };

                  return runSimulatorProcessing(sendSimulator, SimulatorProcessingMoment.BEFORE_SIMULATION);
                } else {
                  return history.replace(`${RoutePath.REGISTER}${registrationKey}`);
                }
              }
            });
          break;
        case RegistrationStatus.PRE_REJECTED:
          return history.replace(RoutePath.SIMULATOR_REPROVED);
        case RegistrationStatus.REJECTED:
          return history.replace(RoutePath.SIMULATOR_REPROVED);
        default:
          return history.replace(RoutePath.LOGIN);
      }
    } else {
      return history.replace(RoutePath.HOME);
    }
  };

  const redirectToAuthenticate = (clientDocumentNumber?: string) => {
    replace(RoutePath.LOGIN, { documentValue: clientDocumentNumber });
  };

  const runSimulatorProcessing = async (
    sendSimulatorProps?: FinancingSimpleSimulation,
    simulatorProcessingStepProp?: SimulatorProcessingMoment
  ) => {
    const isBeforeSimulation =
      simulatorProcessingStepProp === SimulatorProcessingMoment.BEFORE_SIMULATION ||
      simulatorProcessingStep === SimulatorProcessingMoment.BEFORE_SIMULATION;

    const dataToSendToSimulator: FinancingSimpleSimulation = sendSimulatorProps ?? sendSimulator;

    if (isBeforeSimulation) {
      try {
        const result: ProcessingSimulatorData = await sendFinancingData.execute({
          simulatorData: dataToSendToSimulator,
          parameters: {
            isNewCredit: isNewCredit,
            isAdmin: isAdmin,
          },
          redirect: {},
        });

        redirectFromResult(result);
      } catch (error) {
        redirectToErrorScreen();
      }
    } else {
      redirectToSuccess();
    }
  };

  const redirectFromResult = (result: ProcessingSimulatorData) => {
    if (result.redirect?.withoutLimit) {
      redirectToWithoutLimit();
    } else if (result.redirect?.approved) {
      redirectToSuccess();
    } else if (result.redirect?.confirmSimulation) {
      redirectToConfirmSimulation();
    } else {
      redirectToDisapproved();
    }
  };

  const redirectToConfirmSimulation = () => {
    isAdmin
      ? replace(`${RoutePath.CONFIRM_SIMULATION_ADMIN}/${clientId}`, { isNewCredit: isNewCredit })
      : replace(RoutePath.CONFIRM_SIMULATION, { isNewCredit: isNewCredit });
  };

  const redirectToSuccess = () => {
    isAdmin ? replace(`/admin/simulador-aprovado/${clientId}`, { isNewCredit: isNewCredit }) : replace(RoutePath.SIMULATOR_APPROVED);
  };

  const redirectToDisapproved = () => {
    return isAdmin ? replace(`/admin/simulador-reprovado/${clientId}`) : replace(RoutePath.SIMULATOR_REPROVED);
  };

  const redirectToWithoutLimit = () => {
    replace(RoutePath.ORG_WITHOUT_LIMIT);
  };

  const redirectToErrorScreen = () => {
    replace(RoutePath.ERROR);
  };

  useEffect(() => {
    if (systemParameters?.formType) {
      if (registrationKey != null) {
        handleClientData(registrationKey);
      } else {
        runSimulatorProcessing();
      }
    }
  }, [systemParameters]);

  return !isMobile ? <Desktop /> : <Mobile />;
};

export default ProcessingSimulator;
