import dashboardApi from 'api/dashboard-api';
import { AxiosResponse } from 'axios';
import { Client } from 'model/client';
import {
  ContractDashboard,
  FinancingDashboard,
  InitialDashboard,
  PaymentDashboard,
  ProcedureDashboard,
  SignatureDashboard,
} from 'model/dashboard';
import { CreatedDates } from 'model/date';
import HttpStatus from 'model/enums/http-status';
import StepType from 'model/enums/step-type';
import { PageableResponse } from 'model/pageable';

export const DashboardService = () => {
  const getInitialDashboardInfos = async (financingType?: StepType): Promise<InitialDashboard> => {
    try {
      const result: AxiosResponse<InitialDashboard> = await dashboardApi.getInitialDashboardInfos(financingType);

      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };

  const getClientsList = async (
    name?: string,
    size?: number,
    page?: number,
    registrationStatus?: string[],
    createdDates?: CreatedDates,
    order?: boolean,
    subsidiaryName?: string
  ): Promise<PageableResponse<Client>> => {
    try {
      const result: AxiosResponse<PageableResponse<Client>> = await dashboardApi.getDashboardClients(
        name,
        size,
        page,
        registrationStatus,
        createdDates,
        order,
        subsidiaryName
      );

      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };

  const getFinancingsList = async (
    name?: string,
    size?: number,
    page?: number,
    financingStatus?: string[],
    createdDates?: CreatedDates,
    identification?: string,
    order?: boolean,
    subsidiaryName?: string
  ): Promise<PageableResponse<FinancingDashboard>> => {
    try {
      const result: AxiosResponse<PageableResponse<FinancingDashboard>> = await dashboardApi.getDashboardFinancings(
        name,
        size,
        page,
        financingStatus,
        createdDates,
        identification,
        order,
        subsidiaryName
      );

      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };
  const getFinancingsListHcred = async (
    name?: string,
    size?: number,
    page?: number,
    financingStatus?: string[],
    createdDates?: CreatedDates,
    identification?: string,
    order?: boolean,
    subsidiaryName?: string
  ): Promise<PageableResponse<FinancingDashboard>> => {
    try {
      const result: AxiosResponse<PageableResponse<FinancingDashboard>> = await dashboardApi.getDashboardFinancingsHcred(
        name,
        size,
        page,
        financingStatus,
        createdDates,
        identification,
        order,
        subsidiaryName
      );

      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };
  const getContractsList = async (
    name?: string,
    size?: number,
    page?: number,
    contractStatus?: string[],
    createdDates?: CreatedDates,
    order?: boolean,
    subsidiaryName?: string
  ): Promise<PageableResponse<ContractDashboard>> => {
    try {
      const result: AxiosResponse<PageableResponse<ContractDashboard>> = await dashboardApi.getDashboardContracts(
        name,
        size,
        page,
        contractStatus,
        createdDates,
        order,
        subsidiaryName
      );

      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };

  const getPaymentsList = async (
    name?: string,
    size?: number,
    page?: number,
    paymentStatus?: string[],
    createdDates?: CreatedDates,
    order?: boolean,
    subsidiaryName?: string
  ): Promise<PageableResponse<PaymentDashboard>> => {
    try {
      const result: AxiosResponse<PageableResponse<PaymentDashboard>> = await dashboardApi.getDashboardPayments(
        name,
        size,
        page,
        paymentStatus,
        createdDates,
        order,
        subsidiaryName
      );

      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };

  const getDashboardProcedures = async (
    name?: string,
    size?: number,
    page?: number,
    financingStatus?: string[],
    createdDates?: CreatedDates,
    order?: boolean,
    subsidiaryName?: string
  ): Promise<PageableResponse<ProcedureDashboard>> => {
    try {
      const result: AxiosResponse<PageableResponse<ProcedureDashboard>> = await dashboardApi.getDashboardProcedures(
        name,
        size,
        page,
        financingStatus,
        createdDates,
        order,
        subsidiaryName
      );

      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };

  const getSiganturesList = async (
    name?: string,
    size?: number,
    page?: number,
    registrationStatus?: string[],
    createdDates?: CreatedDates,
    order?: boolean,
    subsidiaryName?: string,
    searchOption?: string
  ): Promise<PageableResponse<SignatureDashboard>> => {
    try {
      const result: AxiosResponse<PageableResponse<SignatureDashboard>> = await dashboardApi.getDashboardSigantures(
        name,
        size,
        page,
        registrationStatus,
        createdDates,
        order,
        subsidiaryName,
        searchOption
      );

      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };

  return {
    getInitialDashboardInfos,
    getClientsList,
    getFinancingsList,
    getContractsList,
    getPaymentsList,
    getDashboardProcedures,
    getFinancingsListHcred,
    getSiganturesList,
  };
};

export default DashboardService();
