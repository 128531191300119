import { GeneralStatus } from 'model/enums/general-status';
import SystemStepCategory from 'model/enums/system-step-category';
import { useTranslation } from 'react-i18next';
import { FiCheck, FiX } from 'react-icons/fi';
import { useTheme } from 'styled-components';
import { BoxIcon, Container, ContainerSteps, SeparatorStep, Step, StyledCircle, TabHeaderInnerContainer, TabTitleHeader } from './styles';

interface StepBarAssignProgress {
  stepStatus: StepStatus[];
}

interface StepStatus {
  stepName: SystemStepCategory | string;
  order: number;
  status: string | null;
}

const actualStepStatus = [GeneralStatus.IN_PROGRESS];

const successStepStatus = [GeneralStatus.FINISHED];

const rejectedStepStatus = [GeneralStatus.CANCELLED];

export const StepBarSignatureProgress = ({ stepStatus }: StepBarAssignProgress) => {
  const { color } = useTheme();
  const { t } = useTranslation();

  const verifyActualStatus = (step: StepStatus, statusList: GeneralStatus[]): boolean => {
    if (statusList.includes(step?.status as GeneralStatus)) {
      if (step.order !== 0) {
        const prevsStatus = stepStatus.slice(0, step.order).map(st => st.status);

        if (prevsStatus.length > 0 && prevsStatus.every(prevStatus => successStepStatus.includes(prevStatus as GeneralStatus))) {
          return true;
        }

        return false;
      }
      return true;
    } else {
      if (step.order !== 0) {
        const prevStatus = stepStatus[step.order - 1]?.status;
        if (prevStatus != null && successStepStatus.includes(prevStatus as GeneralStatus) && step?.status == null) {
          return true;
        }
      }
    }
    return false;
  };

  const getBoxIcon = (step: StepStatus) => {
    if (verifyActualStatus(step, actualStepStatus)) {
      return <StyledCircle />;
    }
    if (verifyActualStatus(step, successStepStatus)) {
      return <FiCheck size={14} color={color.primaryColor} />;
    }
    if (verifyActualStatus(step, rejectedStepStatus)) {
      return <FiX size={14} color={color.primaryColor} />;
    }

    return <>{step.order + 1}</>;
  };

  const getIsActive = (step: StepStatus): boolean => {
    return (
      verifyActualStatus(step, actualStepStatus) ||
      verifyActualStatus(step, successStepStatus) ||
      verifyActualStatus(step, rejectedStepStatus)
    );
  };

  return (
    <Container>
      <TabTitleHeader>{t('admin.signatures.signingSteps')}</TabTitleHeader>
      <ContainerSteps>
        {stepStatus.map(step => (
          <>
            {step.order !== 0 && <SeparatorStep active={getIsActive(step)} />}
            <Step active={getIsActive(step)}>
              <BoxIcon active={getIsActive(step)}>{getBoxIcon(step)}</BoxIcon>
              <span>{t(`${step.stepName}`)}</span>
            </Step>
          </>
        ))}
      </ContainerSteps>
    </Container>
  );
};
