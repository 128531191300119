import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';
import myAccountApi from 'api/my-account-api';
import { AddBalanceResponse, BalanceRequest, BalanceRequestTransactions, BalanceTransactions } from 'model/my-account';
import { PageableResponse } from 'model/pageable';

export const myAccountService = () => {
  const getBalances = async (subisidiaryId?: string): Promise<any> => {
    try {
      const result: AxiosResponse<any> = await myAccountApi.getBalances(subisidiaryId);
      if (result.data != null && result.status === HttpStatus.OK) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };

  const addBalance = async (amount: BalanceRequest, subisidiaryId?: string): Promise<AddBalanceResponse> => {
    try {
      const result: AxiosResponse<AddBalanceResponse> = await myAccountApi.addBalance(amount, subisidiaryId);
      if ((result.data != null && result.status === HttpStatus.OK) || result.status === HttpStatus.CREATED) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };

  const getBalancesTransactions = async (subisidiaryId?: string): Promise<PageableResponse<BalanceTransactions>> => {
    try {
      const result: AxiosResponse<PageableResponse<BalanceTransactions>> = await myAccountApi.getBalancesTransactions(subisidiaryId);
      if (result.data != null && result.status === HttpStatus.OK) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };

  const getBalancesRequestTransactions = async (subisidiaryId?: string): Promise<PageableResponse<BalanceRequestTransactions>> => {
    try {
      const result: AxiosResponse<PageableResponse<BalanceRequestTransactions>> = await myAccountApi.getBalancesRequestTransactions(
        subisidiaryId
      );
      if (result.data != null && result.status === HttpStatus.OK) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };

  return {
    getBalances,
    addBalance,
    getBalancesTransactions,
    getBalancesRequestTransactions,
  };
};

export default myAccountService();
