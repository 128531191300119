import { StyledButton } from 'components/custom-button/styles';
import { NO_LANDING_PAGE } from 'config/constants';
import AccessDeniedIcon from 'images/accessDenied.svg';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ThinBackground, ThinButtonContainer, ThinContainer, ThinSubTitle, ThinTitle } from 'styled-components/thin-styles';

interface IAccessDeniedProps {
  // TODO
}

const redirectTo = NO_LANDING_PAGE ? '/login' : '/admin/login';

const AccessDenied = (props: IAccessDeniedProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  return (
    <ThinBackground>
      <ThinContainer>
        <ThinTitle>{t('accessDenied.title')}</ThinTitle>
      </ThinContainer>
      <img src={AccessDeniedIcon} />
      <ThinContainer>
        <ThinSubTitle>{t('accessDenied.subtitle')}</ThinSubTitle>
      </ThinContainer>

      <ThinButtonContainer>
        <StyledButton
          onClick={() => {
            history.push(redirectTo);
          }}
          justifyContent="center"
        >
          {t('global.button.backStep')}
        </StyledButton>
      </ThinButtonContainer>
    </ThinBackground>
  );
};

export default AccessDenied;
