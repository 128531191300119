import registerApi from 'api/register-api';
import ReactHookFormProvider from 'components/ReactHookFormProvider';
import { Client } from 'model/client';
import { useRegisterForm } from 'provider/register-form';
import { useSelectLists } from 'provider/select-list';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { mailingDestinationType } from 'shared/util/select-utils';
import StringUtils from 'shared/util/string-utils';
import * as yup from 'yup';
import { FormContainer } from '../styles';
import { ContainerInputsAddress } from './inputs-address';
import { City, State } from 'model/address';

export const FormAddressMonjua = () => {
  const { onClickNext, initialClientData } = useRegisterForm();
  const { t } = useTranslation();
  const { states, cities, getResidenceType, residenceType, getCities } = useSelectLists();

  useEffect(() => {
    getResidenceType();
    const uf = initialClientData?.address?.state?.acronym;
    const city = initialClientData?.address?.city?.name;
    if (uf && city) {
      getStateAndCityId(uf, city);
    }
  }, []);

  const schema = yup.object({
    zipcode: yup.string().min(9, t('global.errorMessage.zipcode')).required(t('global.errorMessage.required')),
    street: yup.string().required(t('global.errorMessage.required')),
    number: yup.string().required(t('global.errorMessage.required')),
    complement: yup.string(),
    district: yup.string().required(t('global.errorMessage.required')),
    state: yup.string().required(t('global.errorMessage.required')),
    city: yup.string().required(t('global.errorMessage.required')),
    residenceType: yup
      .string()
      .required(t('global.errorMessage.required'))
      .test('valid-reference', t('global.errorMessage.invalidReference'), value => {
        if (value) {
          return residenceType
            .map(item => item.displayValue.toLowerCase())
            .includes(StringUtils.reverseDisplayValue(value.toLowerCase()) ?? '');
        } else {
          return false;
        }
      }),
    yearsOfResidence: yup.string().required(t('global.errorMessage.required')),
  });

  const getStateAndCityId = async (uf: string, city: string) => {
    const stateRes = await registerApi.getStates(7, 0, '', uf);
    const stateId = states.find(state => state.acronym === uf)?.id;
    if (stateId) {
      getCities(stateId, { name: city, page: 0, size: 7 }, true);
    } else {
      getCities(stateRes.data.content[0].id, { name: city, page: 0, size: 7 }, true);
    }
  };

  return (
    <FormContainer>
      <ReactHookFormProvider
        schema={schema}
        onSubmit={res => {
          if (onClickNext) {
            getStateAndCityId(res.state, res.city);
            const city = {
              name: res.city,
              id: cities.find(city => city.name === res.city)?.id,
            } as City;

            const state = {
              name: res.state,
              id: states.find(state => state.name === res.state)?.id,
              acronym: states.find(state => state.name === res.state)?.acronym,
            } as State;

            const newRes = {
              homePhone: res.homePhone,
              address: {
                ...initialClientData?.address,
                zipcode: res.zipcode,
                street: res.street,
                number: res.number,
                complement: res.complement,
                district: res.district,
                reference: res.reference,
                residenceType: {
                  id: residenceType.find(type => type.displayValue === StringUtils.reverseDisplayValue(res.residenceType))?.id,
                },
                yearsOfResidence: Number(res.yearsOfResidence),
                city,
                state,
              },
            };

            onClickNext(newRes as Client);
          }
        }}
      >
        <ContainerInputsAddress />
      </ReactHookFormProvider>
    </FormContainer>
  );
};
