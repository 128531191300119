import { InputBaseComponentProps } from '@mui/material';
import TypesMask from 'model/enums/types-mask';
import { ElementType, forwardRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { InputAttributes, NumericFormat, NumericFormatProps } from 'react-number-format';
import { ControlLabel, InputText } from 'components/custom-text-field/styles';

interface Props {
  name: string;
  label: string;
  helperText?: string;
  mask?: TypesMask;
  defaultValue?: string;
  maxLength?: number;
  placeholder?: string;
}

const OnlyNumbers = forwardRef<HTMLElement, NumericFormatProps<InputAttributes>>((props, inputRef) => {
  const { ...other } = props;
  return <NumericFormat {...other} getInputRef={inputRef} />;
});

const MonetaryValue = forwardRef<HTMLElement, NumericFormatProps<InputAttributes>>((props, inputRef) => {
  const { ...other } = props;
  return (
    <NumericFormat
      {...other}
      getInputRef={inputRef}
      fixedDecimalScale
      decimalScale={2}
      prefix="R$"
      decimalSeparator=","
      thousandSeparator="."
    />
  );
});

const CustomInputNumbersOnly = ({ name, label, helperText, mask, defaultValue, maxLength, placeholder }: Props) => {
  const { register } = useFormContext();

  const [valueEnteredInput, setValueEnteredInput] = useState<string>(defaultValue ?? '');

  const inputWithMask = (typeMask?: TypesMask) => {
    if (typeMask === TypesMask.MONETARY_VALUE) {
      return MonetaryValue as ElementType<InputBaseComponentProps>;
    }

    return OnlyNumbers as ElementType<InputBaseComponentProps>;
  };

  return (
    <ControlLabel
      label={label}
      labelPlacement="top"
      control={
        <InputText
          {...register(name)}
          variant="outlined"
          onBlur={({ target }) => setValueEnteredInput(target.value)}
          value={valueEnteredInput}
          helperText={helperText}
          error={!!helperText}
          inputProps={{ maxLength: maxLength ?? 255 }}
          InputProps={{ inputComponent: inputWithMask(mask) }}
          placeholder={placeholder}
        />
      }
    />
  );
};

export default CustomInputNumbersOnly;
