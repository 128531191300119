import { Alert, Snackbar } from '@mui/material';
import CustomButton from 'components/custom-button';
import _ from 'lodash';
import { Authority } from 'model/enums/authority';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducer';
import { hasAuthorities } from 'shared/util/utils';
import { ReactComponent as PlusIconSvg } from 'images/white-plus.svg';
import {
  BalanceLabel,
  BalancesContainer,
  FormContainer,
  InputContent,
  PaintSvg,
  StyledTitleContainer,
  Title,
  ValueBalance,
} from './styles';
import StringUtils from 'shared/util/string-utils';
import { TableListSeries, TableValues } from './components/table-list-series';
import CustomModalTwoButtons from 'components/custom-modal-two-buttons';
import * as yup from 'yup';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import InputText from 'components/styled-input';
import { Mask } from 'model/mask-types';
import myAccountService from 'services/my-account-service';
import { BalanceAnswer } from 'model/my-account';
import { Status } from '../../components/status';
import moment from 'moment';
import { SubsidiariesService } from 'services/subsidiaries-service';

interface Schema {
  value: string;
  valueWithTaxes: string;
}

interface IContainerInputsMyCompanyProps {
  id?: string;
}

export const MyAccountList: React.FC<IContainerInputsMyCompanyProps> = props => {
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openAlert, setOpenAlert] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const account = useSelector((state: IRootState) => state.authentication.account);
  const { t } = useTranslation();
  const [isLoadingTable, setIsLoadingTable] = useState<boolean>(false);
  const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false);
  const [balances, setBalances] = useState<BalanceAnswer>();
  const [balancesTransactions, setBalancesTransactions] = useState<TableValues[]>([]);
  const [balancesRequestTransactions, setBalancesRequestTransactions] = useState<TableValues[]>([]);
  const [sessionTaxes, setSessionTaxes] = useState<number>(0);
  const [subsidiaryName, setSubsidiaryName] = useState<string>();

  const closeModalAndResetInternal = () => {
    setOpenCreateModal(false);
  };

  const getBranchInfos = async (Id: number) => {
    await SubsidiariesService()
      .getBranchInfos(Id)
      .then(res => {
        setSessionTaxes(Number(res?.assignmentFee ?? 0));
        setSubsidiaryName(res?.name);
      });
  };

  const schema = yup.object().shape({
    value: yup.string(),
    valueWithTaxes: yup.string(),
  });

  const methods = useForm<Schema>({ resolver: yupResolver(schema) });

  const addNewBalance = async (values: Schema) => {
    setIsButtonLoading(true);
    await myAccountService.addBalance({ amount: parseFloat(StringUtils.removeMoneyFormat(values.value)) }, props.id).then(res => {
      getData();
      setIsButtonLoading(false);
    });
    closeModalAndResetInternal();
  };

  const getData = () => {
    myAccountService.getBalances(props.id).then(res => {
      setBalances(res);
    });
    myAccountService.getBalancesTransactions(props.id).then(res => {
      setBalancesTransactions([
        { header: t('admin.myAccount.ccb'), values: res.content.map(line => StringUtils.moneyMaskPtbr(line.balance.balanceAmount)) },
        { header: t('admin.myAccount.value'), values: res.content.map(line => StringUtils.moneyMaskPtbr(line.amount)) },
        {
          header: t('admin.myAccount.solicitationDate'),
          values: res.content.map(line => moment(line.createdDate).format('DD/MM/YY')),
        },
        {
          header: t('admin.dashboard.tableHeaders.status'),
          values: res.content.map(mt => <Status text={t(`enum.financingStatus.${mt.status}`)} color={mt.status ?? ''} />),
        },
      ]);
    });
    myAccountService.getBalancesRequestTransactions(props.id).then(res => {
      setBalancesRequestTransactions([
        { header: t('admin.myAccount.balance'), values: res.content.map(line => StringUtils.moneyMaskPtbr(line.amount)) },
        {
          header: t('admin.myAccount.solicitationDate'),
          values: res.content.map(line => moment(line.createdDate).format('DD/MM/YY')),
        },
        {
          header: t('admin.myAccount.valueAdd'),
          values: res.content.map(line => StringUtils.moneyMaskPtbr(line.amountToPay)),
        },
        {
          header: t('admin.dashboard.tableHeaders.status'),
          values: res.content.map(mt => <Status text={t(`enum.financingStatus.${mt.status}`)} color={mt.status ?? ''} />),
        },
      ]);
    });

    if (props?.id) {
      getBranchInfos(Number(props?.id));
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <Snackbar open={openAlert} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert severity="error">{errorMessage}</Alert>
      </Snackbar>
      <StyledTitleContainer>
        {subsidiaryName ? <Title>{`Conta ${subsidiaryName}`}</Title> : <Title>{t('admin.dashboard.myAccount')}</Title>}
        {hasAuthorities(account?.authorities, [Authority.ROLE_ADMIN]) && (
          <CustomButton onClick={() => setOpenCreateModal(true)}>
            <PaintSvg className="plusSvg">
              <PlusIconSvg />
            </PaintSvg>
            {t('admin.myAccount.addBalance')}
          </CustomButton>
        )}
      </StyledTitleContainer>

      <BalancesContainer>
        <BalanceLabel>
          <span>{t('admin.myAccount.recentBalance')}</span>
          <ValueBalance>{StringUtils.moneyMaskPtbr(balances?.balanceAmount)}</ValueBalance>
        </BalanceLabel>
        <BalanceLabel>
          <span>{t('admin.myAccount.blockBalance')}</span>
          <ValueBalance>{StringUtils.moneyMaskPtbr(balances?.balanceTransactionAmount)}</ValueBalance>
        </BalanceLabel>
        <BalanceLabel>
          <span>{t('admin.myAccount.balanceToBerelease')}</span>
          <ValueBalance>{StringUtils.moneyMaskPtbr(balances?.balanceRequestTransactionAmount)}</ValueBalance>
        </BalanceLabel>
      </BalancesContainer>

      <TableListSeries
        loadingTable={isLoadingTable}
        balancesTransactions={balancesTransactions}
        balancesRequestTransactions={balancesRequestTransactions}
      />
      <CustomModalTwoButtons
        isShowModal={openCreateModal}
        title={t('admin.myAccount.addBalance')}
        firstButtonText={t('global.button.cancel')}
        secondButtonText={t('admin.myAccount.generatePayment')}
        onCloseModal={closeModalAndResetInternal}
        handleClickFirstButton={closeModalAndResetInternal}
        handleClickSecondButton={methods.handleSubmit(addNewBalance)}
        freeContent
        loading={isButtonLoading}
      >
        <>
          <FormProvider {...methods}>
            <FormContainer>
              <InputContent>
                <InputText
                  name="value"
                  label={t('admin.myAccount.valueToAdd')}
                  mask={Mask.CURRENCY}
                  onChange={e => {
                    const removedValue = StringUtils.removeMoneyFormat(e.target.value);
                    const calculatedValue = parseFloat(removedValue) + parseFloat(removedValue) * (sessionTaxes / 100);
                    methods.setValue('valueWithTaxes', StringUtils.currencyPtBrMask(calculatedValue.toString()));
                  }}
                />
                <BalanceLabel>
                  <span>{t('admin.myAccount.sessionTaxes')}</span>
                  <ValueBalance>{Number(sessionTaxes)?.toLocaleString('pt-BR') + ' %'}</ValueBalance>
                </BalanceLabel>
                <InputText
                  name="valueWithTaxes"
                  label={t('admin.myAccount.valueToPay')}
                  mask={Mask.CURRENCY}
                  onChange={e => {
                    const removedValue = StringUtils.removeMoneyFormat(e.target.value);
                    const dividend = parseFloat(removedValue) / (sessionTaxes / 100);
                    const calculatedValue = dividend / (1 + 1 / (sessionTaxes / 100));
                    methods.setValue('value', StringUtils.currencyPtBrMask(calculatedValue.toString()));
                  }}
                />
              </InputContent>
            </FormContainer>
          </FormProvider>
        </>
      </CustomModalTwoButtons>
    </>
  );
};
