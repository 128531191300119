import * as S from './styles';

interface Props {
  title: string;
  message: string;
}

const CustomLoadingStep = ({ title, message }: Props) => {
  return (
    <S.Container>
      <S.Title>{title}</S.Title>
      <S.Message>{message}</S.Message>

      <S.LoadingContainer>
        <S.Dot animationDelay="0.1s" />
        <S.Dot animationDelay="0.2s" />
        <S.Dot animationDelay="0.3s" />
        <S.Dot animationDelay="0.4s" />
        <S.Dot animationDelay="0.5s" />
      </S.LoadingContainer>
    </S.Container>
  );
};

export default CustomLoadingStep;
