import { useEffect, useRef, useState } from 'react';
import Calendar from 'react-calendar';
import { FiCalendar } from 'react-icons/fi';
import { CalendarContainer, FilterItem, OptionContainer } from './style';

interface CalendarOptionProps {
  placeholder: string;
  onChangeValue: (date: Date) => void;
  defaultValue?: string;
  reset?: boolean;
  isActive?: boolean;
  date: Date | null;
  setDate: (date: Date | null) => void;
}

export const CalendarOption = ({ date, setDate, placeholder, onChangeValue, defaultValue, reset, isActive }: CalendarOptionProps) => {
  const [showCalendar, setShowCalendar] = useState(false);
  const filterItemRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (reset) {
      setDate(null);
    }
  }, [reset]);

  const handleClick = () => {
    setShowCalendar(!showCalendar);
  };

  return (
    <OptionContainer>
      <FilterItem onClick={handleClick} ref={filterItemRef} isActive={isActive}>
        {date
          ? date.toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' })
          : defaultValue
          ? defaultValue
          : placeholder}
        <FiCalendar size={20} />
      </FilterItem>
      {showCalendar && (
        <CalendarContainer filterItemOffsetLeft={filterItemRef?.current?.offsetLeft ?? 0} width={filterItemRef?.current?.offsetWidth ?? 0}>
          <Calendar
            onChange={(value: Date) => {
              if (onChangeValue) {
                onChangeValue(value);
              }
              setDate(value);
              setShowCalendar(false);
            }}
            value={date}
            maxDate={new Date()}
          />
        </CalendarContainer>
      )}
    </OptionContainer>
  );
};
