import { HttpRequestStatus } from 'model/enums/http-request-status';
import { Reducer } from 'redux';
import { GetSubsidiariesActionTypes, SubsidiaryState as SubsidiaryState } from './types';

export const initialState: SubsidiaryState = {
  GetSubsidiariesStatus: HttpRequestStatus.NOOP,
  organizationSubsidiaries: [],
};

const reducer: Reducer<SubsidiaryState> = (state = initialState, action) => {
  switch (action.type) {
    case GetSubsidiariesActionTypes.GET_SUBSIDIARIES_REQUEST: {
      return { ...state, GetSubsidiariesStatus: HttpRequestStatus.ON_GOING };
    }
    case GetSubsidiariesActionTypes.GET_SUBSIDIARIES_SUCCESS: {
      return { ...state, GetSubsidiariesStatus: HttpRequestStatus.SUCCESS, organizationSubsidiaries: action.payload };
    }
    case GetSubsidiariesActionTypes.GET_SUBSIDIARIES_ERROR: {
      return { ...state, GetSubsidiariesStatus: HttpRequestStatus.ERROR, errors: action.payload };
    }
    default: {
      return state;
    }
  }
};

export { reducer as organizationSubsidiaryReducer };
