import { HeaderVariants } from 'model/enums/header-variants';
import { ScreenDimensions } from 'model/enums/screen-dimensions-types';
import styled, { css } from 'styled-components/macro';

interface PropsMenuOpen {
  menuOpen?: boolean;
}
interface customStyleType {
  margin?: string;
  width?: string;
}
interface HeaderProps {
  variant?: string;
  isAuth?: boolean;
  customStyle?: customStyleType;
  menuOpen?: boolean;
}

export const HeaderContainer = styled.div<HeaderProps>`
  width: 100%;
  height: 68px;
  background: ${({ theme, variant }) => (variant === HeaderVariants.PRIMARY ? theme.color.lpColors.headerLp : theme.color.primaryColor)};
  ${({ variant }) => variant === HeaderVariants.PRIMARY && 'box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.06);'}
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const HeaderContent = styled.div<HeaderProps>`
  max-width: 1440px;
  width: 100%;
  min-width: 480px;

  height: 68px;

  padding: ${({ variant }) => (variant ? '0px 132px' : '0px 240px')};
  display: flex;
  align-items: center;
  justify-content: ${({ variant, isAuth }) =>
    variant === HeaderVariants.SECONDARY ? (isAuth ? 'flex-end' : 'space-between') : 'space-between'};

  ${({ variant }) =>
    variant === HeaderVariants.TERTIARY &&
    css`
      justify-content: center;
    `};

  @media (max-width: ${ScreenDimensions.TABLET}) {
    padding: ${({ variant }) => (variant ? '0px 32px' : '0px 64px')};
  }

  @media (max-width: ${ScreenDimensions.MID_TABLET}) {
    max-width: 480px;
    width: 100%;
    min-width: 280px;
    padding: ${({ variant }) => (variant ? '0px 16px' : '0px 32px')};
  }
`;

export const AlignBox = styled.div`
  max-width: 818px;
  width: 100%;
  display: flex;
`;

export const LogoBox = styled.div`
  max-width: 140px;
  display: flex;
  cursor: pointer;
  align-items: center;
`;

export const Nav = styled.div`
  max-width: 678px;
  width: 100%;

  margin: 0px 24px 0px 72px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: ${ScreenDimensions.MID_TABLET}) {
    width: 80px;
    margin: 0px 12px 0px 12px;
    align-items: flex-start;
    justify-content: center;
    margin: 8px 0px 0px;
  }
`;

export const Link = styled.button<HeaderProps>`
  background: transparent;
  border: transparent;
  padding: 0px;
  color: ${({ theme }) =>
    theme.color.lpColors?.['headerTextColor'] ? theme.color.lpColors?.['headerTextColor'] : theme.color.primaryColor};

  font-weight: 700;
  font-size: 16px;

  :hover {
    text-decoration: underline;
  }

  @media (max-width: ${ScreenDimensions.TABLET}) {
    /* width: 80px; */
    font-size: 14px;
  }
  @media (max-width: 671px) {
    color: ${({ theme }) =>
      theme.color.lpColors?.['headerTextColor'] ? theme.color.lpColors?.['headerTextColor'] : theme.color.primaryColor};
    padding: 4px 0px;
  }
`;

// LP deslogado
export const Login = styled.button<HeaderProps>`
  ${({ customStyle }) => (customStyle?.width ? `width: ${customStyle.width}` : `width: 100px`)};

  height: 48px;
  background: transparent;
  border: ${({ theme }) =>
    `1.5px solid ${theme.color.lpColors?.['headerTextColor'] ? theme.color.lpColors?.['headerTextColor'] : theme.color.primaryColor}`};
  border-radius: 24px;
  color: ${({ theme }) =>
    theme.color.lpColors?.['headerTextColor'] ? theme.color.lpColors?.['headerTextColor'] : theme.color.primaryColor};
  ${({ customStyle }) => customStyle?.margin && `margin: ${customStyle.margin};`};
  padding: 0px;
  font-weight: 700;
  font-size: 16px;

  :hover {
    background: ${({ theme }) =>
      theme.color.lpColors?.['headerTextColor'] ? theme.color.lpColors?.['headerTextColor'] : theme.color.primaryColor};
    color: ${({ theme }) => (theme.color.lpColors?.['headerTextColor'] ? theme.color.primaryColor : theme.color.white)};
  }

  @media (max-width: 671px) {
    ${({ customStyle }) => (customStyle?.width ? `width: ${customStyle.width}` : `width: 100px`)};
  }
`;

// OUT + IN
export const LoggedUserBox = styled.button<HeaderProps>`
  height: 48px;
  padding: 8px;

  background: ${({ theme }) => theme.color.primaryColor};
  border: 1px solid #eeeeee;
  border-radius: 8px;

  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s ease;
  width: ${({ menuOpen }) => (menuOpen ? '159px' : '48px')};
  text-overflow: clip;
  overflow: hidden;

  :hover {
    background: ${({ theme }) => theme.color.primaryColor};
  }
`;

export const ProfileImage = styled.img`
  width: 32px;
  height: 32px;
  margin: 0px;
`;

export const StyledText = styled.div<PropsMenuOpen>`
  font-weight: 500;
  font-size: 16px;
  margin-left: 8px;
  color: ${({ theme }) => theme.color.white};

  ${({ menuOpen }) =>
    !menuOpen &&
    css`
      display: none;
      text-overflow: clip;
    `};
`;

export const DropdownButton = styled.button`
  background: transparent;
  border: none;
`;

export const DropdownBox = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 99;
  margin: 30px 0px 0px;
  background: #fff;
  border-radius: 5px;
`;

export const StyledIcon = styled.img``;
