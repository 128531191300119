import styled from 'styled-components/macro';

interface StyledProps {
  loading?: boolean;
}

export const ContentHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 32px;
`;

export const CardTitle = styled.span`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  color: ${({ theme }) => theme.color.black0};
`;

export const Button = styled.button<StyledProps>`
  background: transparent;
  border: none;

  display: flex;
  flex-direction: row;
  align-items: center;

  opacity: ${({ loading }) => (loading ? 0.5 : 1)};
  cursor: ${({ loading }) => !loading && 'pointer'};

  & > span {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    color: ${({ theme }) => theme.color.primaryColor};

    display: inline-block;
    margin-left: 8px;
  }
`;

export const StyledContestationButtonsContainer = styled.div`
  display: flex;
`;
