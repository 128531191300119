import Header from './components/header';

const Report = () => {
  return (
    <>
      <Header />

      {/* TODO: criar o gráfico */}
    </>
  );
};

export default Report;
