import vaduApi from 'api/vadu-api';
import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';
import { VaduCreditEngine, VaduCreditEngineId } from 'model/vadu-credit-engine';

export const VaduService = () => {
  const createAnalysis = async (financingId: number, clientId: number): Promise<VaduCreditEngineId> => {
    try {
      const result: AxiosResponse<VaduCreditEngineId> = await vaduApi.createAnalysis(financingId, clientId);
      if (result.status === HttpStatus.CREATED) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };

  const analysisCpf = async (cpf: string): Promise<boolean> => {
    try {
      const result: AxiosResponse<boolean> = await vaduApi.analysisCpf(cpf);
      if (result.status !== HttpStatus.OK) {
        return true;
      } else {
        return false;
      }
    } catch (error: any) {
      console.error(error);
      return true;
    }
  };

  const getAnalysisInfo = async (financingId: number): Promise<VaduCreditEngine> => {
    try {
      const result: AxiosResponse<VaduCreditEngine> = await vaduApi.getAnalysisInfo(financingId);
      if (result.status === HttpStatus.OK) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };
  return {
    createAnalysis,
    getAnalysisInfo,
    analysisCpf,
  };
};

export default VaduService();
