import styled from 'styled-components/macro';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1000px;
  padding: 16px;
  color: ${({ theme }) => theme.color.black1b};
`;

export const Title = styled.span`
  font-size: 20px;
  font-weight: 700;
`;

export const SelfieText = styled.span`
  font-size: 16px;
  font-weight: 400;
  color: ${({ theme }) => theme.color.gray18};
  padding: 16px 0;
`;

export const Subtitle = styled.span`
  font-size: 16px;
  font-weight: 700;
`;

export const SelfieImage = styled.img`
  max-width: 68px;
`;

export const InstructionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
  margin-top: 16px;
`;

export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 16px;
`;

export const List = styled.ul``;

export const ListItem = styled.li`
  margin-bottom: 10px;
  color: ${({ theme }) => theme.color.gray18};
  font-weight: 400;
  font-size: 14px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
