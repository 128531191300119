import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

export interface FieldsError {
  name: string;
  errorMessage: string;
}

interface ReactHookFormProviderProps {
  schema: any;
  onSubmit: (data) => void;
  defaultValues?: any;
  shouldReset?: boolean;
  id?: string;
  isModeAll?: boolean;
  fieldsReset?: {};
  hasError?: boolean;
  fieldsWithError?: FieldsError[];
}

const ReactHookFormProvider: React.FC<ReactHookFormProviderProps> = ({
  children,
  schema,
  defaultValues,
  onSubmit,
  shouldReset = false,
  id = '',
  isModeAll = true,
  fieldsReset,
  hasError = false,
  fieldsWithError,
}) => {
  const methods = useForm({ resolver: yupResolver(schema), defaultValues: defaultValues ?? {}, mode: 'onBlur' });

  useEffect(() => {
    if (shouldReset) {
      methods.reset(fieldsReset);
    }
  }, [shouldReset]);

  useEffect(() => {
    if (hasError && fieldsWithError) {
      fieldsWithError.map(field => methods.setError(field.name, { message: field.errorMessage }));
    }
  }, [hasError]);

  const handleSubmit = data => {
    methods.handleSubmit(onSubmit)(data);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={e => handleSubmit(e)} id={id} style={{ width: '100%' }}>
        {children}
      </form>
    </FormProvider>
  );
};

export default ReactHookFormProvider;
