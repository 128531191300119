import i18n from 'config/i18n';
import { ReactComponent as CardSvg } from 'images/blue-card.svg';
import { ReactComponent as ContractSvg } from 'images/blue-contract.svg';
import { ReactComponent as FinancingAnalysisIconSvg } from 'images/blue-financing-analysis.svg';
import { ReactComponent as PaymentIconSvg } from 'images/blue-payment.svg';
import { BankAccountType } from 'model/enums/bank-account-type';
import { ContractStatus } from 'model/enums/contract-status';
import { ICardData } from 'model/enums/dashboard';
import FinancingStatusType from 'model/enums/financing-status-type';
import SystemStepCategory from 'model/enums/system-step-category';
import { BankAccountInFinancing, ContractType, PaymentType } from 'model/financing';
import TypeDashbordCard from 'model/type-dashboard-card';
import statusListCannotEditData from 'shared/util/dashboard-status-not-allow-change';
import { StyledChevronRightContainer } from './styles';
import procedurePaymentStatus from 'model/procedure-payment-status';
import { ProcedurePaymentStatus } from 'model/enums/procedure-payment-status';

interface Props {
  step: SystemStepCategory;
  procedureStatus: FinancingStatusType;
  bankAccountInProcedure?: BankAccountInFinancing;
  procedureContracts?: ContractType;
  procerurePayments?: PaymentType;
  procedurePaymentStatus?: procedurePaymentStatus;
  previousStep?: ICardData;
}

const StepsController = ({
  step,
  procedureStatus,
  bankAccountInProcedure,
  procedureContracts,
  procerurePayments,
  procedurePaymentStatus,
  previousStep,
}: Props): ICardData | null => {
  const card: TypeDashbordCard = {
    BANK_ACCOUNT_DATA_MULTTIPLO: {
      key: step,
      stepCategory: step,
      icon: <CardSvg />,
      title: i18n.t('dashboard.bankData'),
      content:
        previousStep == null || (previousStep?.isDataFilled && previousStep?.isActive) ? (
          <StyledChevronRightContainer>
            {bankAccountInProcedure?.bankAccount == null ? (
              <span>{i18n.t('proceduresDashboard.accountPendingCreation')}</span>
            ) : (
              <div>{`${bankAccountInProcedure.bankAccount.type ? BankAccountType[bankAccountInProcedure.bankAccount.type] : ''} ${
                bankAccountInProcedure.bankAccount.bankAccountNumber
              }-${bankAccountInProcedure.bankAccount.bankAccountDigit}`}</div>
            )}
          </StyledChevronRightContainer>
        ) : (
          <span>{i18n.t('proceduresDashboard.accountPendingCreation')}</span>
        ),
      isActive:
        (previousStep?.isDataFilled && previousStep?.isActive) ||
        (previousStep == null && !statusListCannotEditData.includes(procedureStatus)),
      isDataFilled: bankAccountInProcedure?.bankAccount?.id != null,
    },
    CONTRACT_INTERNAL: {
      key: step,
      stepCategory: step,
      icon: <ContractSvg />,
      title: i18n.t('dashboard.contract'),
      content:
        procedureContracts?.contracts == null ||
        procedureContracts?.contracts.length < 1 ||
        procedureContracts?.contracts?.map(contract => contract.status).includes(ContractStatus.NEW) ? (
          <span>{i18n.t('proceduresDashboard.waitContractProcess')}</span>
        ) : (
          <div>
            {procedureContracts?.contracts?.map(contract => contract.status).includes(ContractStatus.FINISHED)
              ? i18n.t('proceduresDashboard.confirmedSubscription')
              : procedureContracts?.contracts[0].contractSign.contractSignatories[0].signed
              ? i18n.t('proceduresDashboard.pleaseWaitReviewContract')
              : i18n.t('proceduresDashboard.scanQRCode')}
          </div>
        ),
      isActive:
        procedureContracts?.contracts?.map(contract => contract.status).includes(ContractStatus.FINISHED) ||
        (previousStep?.isDataFilled && previousStep?.isActive) ||
        (previousStep == null && !statusListCannotEditData.includes(procedureStatus)),
      isDataFilled: procedureContracts?.contracts?.map(contract => contract.status).includes(ContractStatus.FINISHED) ?? false,
      isShowArrow: procedureContracts?.[0]?.id != null,
      redirectPathClickArrow: procedureContracts?.[0]?.id != null ? `/meus-contratos/${procedureContracts[0].id}` : '',
    },
    PAYMENT_MULTTIPLO_CLINIC: {
      key: step,
      stepCategory: step,
      icon: <PaymentIconSvg />,
      title: i18n.t('proceduresDashboard.paymentProcedure'),
      content: (
        <span>
          {procedurePaymentStatus?.procedurePayment?.procedurePaymentStatus !== ProcedurePaymentStatus.PAID
            ? i18n.t('proceduresDashboard.waitPaymentProcessing')
            : i18n.t('proceduresDashboard.confirmedProcedurePayment')}
        </span>
      ),
      isActive:
        (previousStep?.isDataFilled && previousStep?.isActive) ||
        (previousStep == null && !statusListCannotEditData.includes(procedureStatus) && procedureStatus === FinancingStatusType.FINISHED),
      isDataFilled: procedurePaymentStatus?.procedurePayment?.procedurePaymentStatus === ProcedurePaymentStatus.PAID,
    },
    PAYMENT_MULTTIPLO_REFUND: {
      key: step,
      stepCategory: step,
      icon: <PaymentIconSvg />,
      title: i18n.t('proceduresDashboard.refundPayment'),
      content: (
        <span>
          {procedurePaymentStatus?.procedureRefund?.procedureRefundStatus !== ProcedurePaymentStatus.PAID
            ? i18n.t('proceduresDashboard.awaitingRefundPayment')
            : i18n.t('dashboard.paymentCompleted')}
        </span>
      ),
      isActive:
        (previousStep?.isDataFilled && previousStep?.isActive) ||
        (previousStep == null && !statusListCannotEditData.includes(procedureStatus) && procedureStatus === FinancingStatusType.FINISHED),
      isDataFilled: procedurePaymentStatus?.procedureRefund?.procedureRefundStatus === ProcedurePaymentStatus.PAID,
    },
    PROCEDURE_ANALYSIS: {
      key: step,
      stepCategory: step,
      icon: <FinancingAnalysisIconSvg />,
      title: i18n.t('proceduresDashboard.procedureAnalysis'),
      content: (
        <span>
          {procedureStatus === FinancingStatusType.APPROVED || procedureStatus === FinancingStatusType.FINISHED
            ? i18n.t('proceduresDashboard.completedProcedureAnalysis')
            : procedureStatus === FinancingStatusType.ADJUST
            ? i18n.t('proceduresDashboard.weNeedAdjustments')
            : procedureStatus === FinancingStatusType.REJECTED
            ? i18n.t('proceduresDashboard.unfortunatelyContinue')
            : i18n.t('proceduresDashboard.waitConfirmationContinue')}
        </span>
      ),
      isActive:
        (previousStep?.isDataFilled && previousStep?.isActive) ||
        (previousStep == null && !statusListCannotEditData.includes(procedureStatus)),
      isDataFilled: procedureStatus === FinancingStatusType.APPROVED || procedureStatus === FinancingStatusType.FINISHED,
    },
  };

  return card[step] ?? null;
};

export default StepsController;
