import { APP_LOCAL_DATE_FORMAT, APP_LOCAL_ISO_FORMAT } from 'config/constants';
import i18n from 'config/i18n';
import { differenceInYears, format, parse } from 'date-fns';
import { isNil } from 'lodash';
import { Attachment } from 'model/attachment';
import { ContestationStatus } from 'model/credit-card';
import { ContractStatus } from 'model/enums/contract-status';
import FinancingStatusType from 'model/enums/financing-status-type';
import { OcrAttachmentType } from 'model/enums/ocr-type';
import { IOcrAttachments } from 'model/ocr';

class StringUtils {
  static isStringEmpty = (event?: string) => {
    return isNil(event) || event === '';
  };

  static getFirstName = (fullName: string) => {
    if (fullName === '') return '';

    return fullName.split(' ')?.[0];
  };

  static getCaptureAttachment = (getCapture: string, documentNumber: string, isBack: boolean, type: OcrAttachmentType) => {
    let docName = `${documentNumber}-front`;
    const firstSplit = `${getCapture}`.split('data:');
    const secondSplit = `${firstSplit[1]}`.split(';base64,');

    if (isBack) {
      docName = `${documentNumber}-verse`;
    }

    const document: IOcrAttachments = {
      contentType: secondSplit[0],
      fileName: docName,
      file: secondSplit[1],
      type: type,
    };

    return document;
  };

  static isPasswordInvalid = (event: string) => {
    return StringUtils.isStringEmpty(event) || event.length < 5 || event.length > 250;
  };

  static isSyntaxEmpty = (event?: string) => {
    return event === '${}' || event === '#{}';
  };

  static isSizeInvalid = (size: number, event?: string) => {
    if (StringUtils.isStringEmpty(event)) return true;
    return event?.length !== size;
  };

  static generateFileUrl = (file: string, type: string) => {
    const blobObject = StringUtils.b64toBlob(file, type, null);
    return URL.createObjectURL(blobObject);
  };

  static b64toBlob = (b64Data, contentType, size) => {
    const sliceSize = size ?? 512;

    const byteCharacters = atob(b64Data);
    const byteArrays: Uint8Array[] = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType ?? '' });
  };
  static dateFormatMask(value: string) {
    const separate = value.split(' ');
    return separate[0].split('-').reverse().join('/');
  }
  static dateTimestampFormatMask(timestamp: Date) {
    return format(timestamp, APP_LOCAL_DATE_FORMAT);
  }
  static dateFormatMaskToApi(value: string) {
    return value.split('/').reverse().join('-');
  }
  static apiDateFormatMask(value?: string) {
    if (value) {
      return value.split('-').reverse().join('/');
    } else {
      return '-/-/-';
    }
  }
  static isEmailInvalid = (event?: string) => {
    if (StringUtils.isStringEmpty(event)) return true;
    const emailRegex = /\S+@\S+\.\S+/;
    return !emailRegex.test(event!);
  };

  static isEmailValid = (event?: string) => {
    if (StringUtils.isStringEmpty(event)) return true;
    const emailRegex = /\S+@\S+\.\S+/;
    return emailRegex.test(event!);
  };

  static toObject = (key: string, event: any) => {
    const result = {};
    result[key] = event;
    return result;
  };

  static randomColor = () => {
    const colors: string[] = ['#14b071', '#4f35a5', '#1e98d6'];
    return colors[Math.floor(Math.random() * colors.length)];
  };

  static randomString = () => {
    return (
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15)
    );
  };

  static b64EncodeUnicode = (str: string) => {
    return btoa(
      encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function toSolidBytes(match, p1) {
        return String.fromCharCode(Number(`0x${p1}`));
      })
    );
  };

  static removeAccentToLowerCase = (event?: string) => {
    if (StringUtils.isStringEmpty(event)) return '';

    let withoutAccent;

    withoutAccent = event!.replace(new RegExp('[Ç]', 'gi'), 'c');
    withoutAccent = withoutAccent.replace(new RegExp('[ÉÈÊ]', 'gi'), 'e');
    withoutAccent = withoutAccent.replace(new RegExp('[ÍÌÎ]', 'gi'), 'i');
    withoutAccent = withoutAccent.replace(new RegExp('[ÚÙÛ]', 'gi'), 'u');
    withoutAccent = withoutAccent.replace(new RegExp('[ÓÒÔÕ]', 'gi'), 'o');
    withoutAccent = withoutAccent.replace(new RegExp('[ÁÀÂÃ]', 'gi'), 'a');

    return withoutAccent.toLowerCase();
  };
  static cpfMask(documentNumber?: string) {
    if (documentNumber == null) {
      return '';
    }
    documentNumber = documentNumber.replace(/\D/g, '');

    return documentNumber.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4');
  }
  static cnpjMask(documentNumber?: string) {
    if (documentNumber == null) {
      return '';
    }
    documentNumber = documentNumber.replace(/\D/g, '');

    return documentNumber.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '$1.$2.$3/$4-$5');
  }
  static currencyMask = (event?: string) => {
    let value = String(event);

    value = value.replace(/\D/g, '');
    value = value.replace(/^0+(\.)?(0+)?/g, '');
    const newValue = Number(value) / 100;

    if (value.length > 2) {
      value = value.replace(/(\d)(\d{2})$/, '$1,$2');
    } else if (value.length === 2) {
      value = value.replace(/(\d+)$/, '0,$1');
    } else if (value.length === 1) {
      value = value.replace(/(\d)$/, '0,0$1');
    }

    value = value.replace(/(?=(\d{3})+(\D))\B/g, '.');

    return `R$ ${value}`;
  };
  static verifyMinAndMaxValue = (value: string, min?: number, max?: number) => {
    value = value.replace(/\D/g, '');
    value = value.replace(/^0+(\.)?(0+)?/g, '');
    const newValue = Number(value) / 100;

    if (min) {
      if (newValue < min) {
        value = min.toFixed(2);
        value = value.replace(/\D/g, '');
      }
    }
    if (max) {
      if (newValue > max) {
        value = max.toFixed(2);
        value = value.replace(/\D/g, '');
      }
    }

    return value;
  };

  static currencyPtBrMask = (value: any) => {
    if (value == null) {
      return '';
    }

    return Number(value)?.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });
  };
  static phoneMask(phone?: string) {
    if (phone == null) {
      return '';
    }

    if (phone.length === 10 || phone.length === 14) {
      phone = phone.replace(/\D/g, '');
      return phone.replace(/(\d{2})(\d{4})(\d{4})/g, '($1) $2-$3');
    } else if (phone.length === 11 || phone.length === 15) {
      phone = phone.replace(/\D/g, '');
      return phone.replace(/(\d{2})(\d{1})(\d{4})(\d{4})/g, '($1) $2$3-$4');
    } else {
      phone = phone.replace(/\D/g, '');
      return phone;
    }
  }
  static zipCodeMask = (value?: string) => {
    if (value == null) {
      return '';
    }

    value = StringUtils.removeNonNumbersFromMaskedValue(value);
    return value.replace(/(.{5})(\d)/, '$1-$2');
  };
  static landlineMask(phone?: string) {
    if (phone == null) {
      return '';
    }

    phone = StringUtils.removeNonNumbersFromMaskedValue(phone);
    phone = phone.replace(/^(\d{2})(\d)/g, '($1) $2');
    return phone.replace(/(\d)(\d{4})$/, '$1-$2');
  }
  static percentageMask(percentage?: string) {
    if (percentage == null) {
      return '';
    }

    percentage = percentage.replace(/\D/g, '');

    if (percentage.length <= 2) {
      return percentage;
    }

    const wholePart = percentage.slice(0, -2);
    const decimalPart = percentage.slice(-2);
    return `${wholePart}.${decimalPart}`;
  }
  static percentagePtMask = (event?: string, min?: number, max?: number) => {
    let value = String(event);

    value = value.replace(/\D/g, '');
    value = value.replace(/^0+(\.)?(0+)?/g, '');
    const newValue = Number(value) / 100;

    if (value.length > 2) {
      value = value.replace(/(\d)(\d{2})$/, '$1,$2');
    } else if (value.length === 2) {
      value = value.replace(/(\d+)$/, '0,$1');
    } else if (value.length === 1) {
      value = value.replace(/(\d)$/, '0,0$1');
    }

    value = value.replace(/(?=(\d{3})+(\D))\B/g, '.');

    return value;
  };

  static removeNonNumbersFromMaskedValue = (value: string) => {
    return value.replace(/[^\d]/g, '');
  };
  static rgMask(documentNumber?: string) {
    if (documentNumber == null) {
      return '';
    }
    documentNumber = documentNumber.replace(/\D/g, '');
    return documentNumber.replace(/(\d{2})(\d{3})(\d{3})(\d{1})/g, '$1.$2.$3-$4');
  }
  static birthdayMask(date?: string) {
    if (date == null) {
      return '';
    }

    date = date.replace(/\D/g, '');

    return date.replace(/(\d{2})(\d{2})(\d{4})/g, '$1/$2/$3');
  }
  static monthYearMask(date?: string) {
    if (date == null) {
      return '';
    }

    date = date.replace(/\D/g, '');

    return date.replace(/(\d{2})(\d{4})/g, '$1/$2');
  }
  static parseMonthYearDate(date?: string): Date {
    if (date == null) {
      return new Date();
    }
    const parts = date.split(/[/-]/);
    const month = parseInt(parts[0], 10) - 1;
    const year = parseInt(parts[1], 10);
    return new Date(year, month);
  }
  static parseMonthYearString(date?: Date): string {
    if (date == null) {
      return '';
    }
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${month}/${year}`;
  }

  static padMonthYearString(date?: String): string {
    if (date == null) {
      return '';
    }
    const separator = date.includes('-') ? '-' : '/';
    const [month, year] = date.split(separator);
    const paddedMonth = month.padStart(2, '0');
    const paddedDate = `01${separator}${paddedMonth}${separator}${year}`;
    return paddedDate;
  }

  static lineBreak = (text: string) => {
    return text.split('\n');
  };

  static fullNameMask = (text: string) => {
    if (text.match('/^[a-zA-Z ]+$/')) {
      return text;
    }
  };

  static maskAmount = (amount?: number) => {
    if (amount != null) {
      return amount.toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    }

    return '';
  };
  static getFileOrUrl(attachment?: Attachment) {
    return attachment?.presignedUrl
      ? attachment.presignedUrl
      : attachment?.url
      ? attachment.url
      : `data:${attachment?.contentType};base64, ${attachment?.file}`;
  }

  static getFileFromUri = (uri: string) => {
    return uri.split(',')[1];
  };

  static moneyMaskPtbr = (money?: number, onlyValue?: boolean) => {
    if (money == null) {
      return '';
    }

    const moneyFormatted = money.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });

    if (onlyValue) {
      return moneyFormatted.replace('R$', '');
    } else {
      return moneyFormatted;
    }
  };

  static removeMoneyFormat = (money?: string) => {
    if (money == null) {
      return '';
    }
    const removed = money.replace('R$', '').replace('%', '').replace('.', '').replace(',', '.');
    return removed;
  };

  static characterLimit(text, limit) {
    const hasLimit = text.length >= limit;

    return hasLimit ? `${text.slice(0, limit)}...` : text;
  }

  static changeDotToComma = (value: string) => {
    return value.replace('.', ',');
  };

  static replaceDisplayValue = (value: string) => {
    return value.replaceAll('_', ' ');
  };

  static reverseDisplayValue = (value: string) => {
    if (value) {
      return value.replaceAll(' ', '_');
    }
  };

  static replaceToCapitalize = (value?: string) => {
    if (value) {
      return value?.charAt(0).toUpperCase() + value?.slice(1);
    }
    return '';
  };

  static annualPercentageMask = (value: string) => {
    return value.replace(/[^0-9.]|([a-z])\.|\.m/g, (match, group1) => {
      if (group1) {
        return group1;
      } else {
        return '';
      }
    });
  };

  static configureCreditTableStatusView = (
    financingStatus: FinancingStatusType,
    contractStatus?: ContractStatus,
    paymentStatus?: FinancingStatusType
  ) => {
    if (financingStatus === FinancingStatusType.FINISHED && contractStatus !== ContractStatus.FINISHED) {
      return FinancingStatusType.CONTRACT;
    }

    if (
      financingStatus === FinancingStatusType.FINISHED &&
      contractStatus === ContractStatus.FINISHED &&
      paymentStatus !== FinancingStatusType.FINISHED
    ) {
      return FinancingStatusType.PAYMENT;
    }

    if (
      financingStatus === FinancingStatusType.FINISHED &&
      contractStatus === ContractStatus.FINISHED &&
      paymentStatus === FinancingStatusType.FINISHED
    ) {
      return FinancingStatusType.FINISHED;
    }

    return financingStatus;
  };

  static contestationStatusText = (status: ContestationStatus) => {
    if (!status?.isAnotherCardAdded) {
      return i18n.t('admin.customerAnalysis.contestationStatus.sentCustomer');
    }

    if (status?.isAnotherCardAdded && !status?.isPayedInCielo) {
      return i18n.t('admin.customerAnalysis.contestationStatus.awaitingPayment');
    }

    if (status?.isPayedInCielo && !status?.isContractSigned) {
      return i18n.t('admin.customerAnalysis.contestationStatus.awaitingSignature');
    }

    if (status?.isContractSigned) {
      return i18n.t('admin.customerAnalysis.contestationStatus.finished');
    }
  };

  static calculateAge = (dateBirth: string | Date): number => {
    if (typeof dateBirth === 'string') {
      dateBirth = parse(dateBirth, APP_LOCAL_ISO_FORMAT, new Date());
    }

    const currentDate = new Date();
    return differenceInYears(currentDate, dateBirth);
  };
}

export default StringUtils;

export function validateCpf(value: string) {
  if (value == null || value === '') {
    return false;
  }
  const cpf = value.replace(/[^\d]+/g, '');
  let add = 0;
  let rev = 0;
  if (cpf === '') {
    return false;
  }
  if (
    cpf.length !== 11 ||
    cpf === '00000000000' ||
    cpf === '11111111111' ||
    cpf === '22222222222' ||
    cpf === '33333333333' ||
    cpf === '44444444444' ||
    cpf === '55555555555' ||
    cpf === '66666666666' ||
    cpf === '77777777777' ||
    cpf === '88888888888' ||
    cpf === '99999999999'
  ) {
    return false;
  }
  add = 0;
  for (let i = 0; i < 9; i++) {
    add += parseInt(cpf.charAt(i), 10) * (10 - i);
  }
  rev = 11 - (add % 11);
  if (rev === 10 || rev === 11) {
    rev = 0;
  }
  if (rev !== parseInt(cpf.charAt(9), 10)) {
    return false;
  }
  add = 0;
  for (let i = 0; i < 10; i++) {
    add += parseInt(cpf.charAt(i), 10) * (11 - i);
  }
  rev = 11 - (add % 11);
  if (rev === 10 || rev === 11) {
    rev = 0;
  }
  if (rev !== parseInt(cpf.charAt(10), 10)) {
    return false;
  }
  return true;
}

export const validateCNPJ = cnpj => {
  cnpj = cnpj.replace(/[^\d]+/g, '');
  if (cnpj == '') {
    return false;
  }
  if (cnpj.length != 14) {
    return false;
  }
  if (
    cnpj == '00000000000000' ||
    cnpj == '11111111111111' ||
    cnpj == '22222222222222' ||
    cnpj == '33333333333333' ||
    cnpj == '44444444444444' ||
    cnpj == '55555555555555' ||
    cnpj == '66666666666666' ||
    cnpj == '77777777777777' ||
    cnpj == '88888888888888' ||
    cnpj == '99999999999999'
  ) {
    return false;
  }
  // Valida DVs
  let size;
  let number;
  let digits;
  let sum;
  let position;
  let result;
  size = cnpj.length - 2;
  number = cnpj.substring(0, size);
  digits = cnpj.substring(size);
  sum = 0;
  position = size - 7;
  for (let i = size; i >= 1; i--) {
    sum += number.charAt(size - i) * position--;
    if (position < 2) {
      position = 9;
    }
  }
  result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
  if (result != digits.charAt(0)) {
    return false;
  }
  size = size + 1;
  number = cnpj.substring(0, size);
  sum = 0;
  position = size - 7;
  for (let i = size; i >= 1; i--) {
    sum += number.charAt(size - i) * position--;
    if (position < 2) {
      position = 9;
    }
  }
  result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
  if (result != digits.charAt(1)) {
    return false;
  }
  return true;
};

export const fillNumbers = (numberToFill: string) => {
  const maxNumbers = 4;
  const splitNumber = numberToFill.toString().split('');
  const fillNumber: string[] = [];

  for (let i = 0; i < maxNumbers; i++) {
    if (splitNumber[i] != null) {
      fillNumber.push(splitNumber[i]);
    } else {
      fillNumber.splice(0, 0, '0');
    }
  }

  return fillNumber.join('');
};

export const validatePhone = phone => {
  phone = phone.replace(/[^\d]+/g, '');
  if (phone == '') {
    return false;
  }
  if (phone.length < 11 || phone.length > 12) {
    return false;
  }
  if (
    phone == '00000000000' ||
    phone == '11111111111' ||
    phone == '22222222222' ||
    phone == '33333333333' ||
    phone == '44444444444' ||
    phone == '55555555555' ||
    phone == '66666666666' ||
    phone == '77777777777' ||
    phone == '88888888888' ||
    phone == '99999999999'
  ) {
    return false;
  }

  return true;
};
