import { AxiosResponse } from 'axios';
import { api } from './api';
import { buildUrl } from 'shared/util/api-utils';
import FilterParameters from 'model/filter-parameters';

const analysisReportApi = () => {
  const generateReport = (filterParameters: FilterParameters): Promise<AxiosResponse<Blob>> => {
    const url = buildUrl('/analysis-report/', filterParameters);
    return api.get<Blob>(url);
  };

  return {
    generateReport,
  };
};

export default analysisReportApi();
