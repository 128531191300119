import { AxiosResponse } from 'axios';
import { Attachment } from 'model/attachment';
import BiometricsStatusAndClientAuthentication from 'model/biometrics-status-and-client-authentication';
import { Client } from 'model/client';
import { ContractAccept } from 'model/contract-accept';
import { CreditCard, FinancingCreditCard } from 'model/credit-card';
import { OrganizationSubsidiaries } from 'model/landing-page';
import { SelfieVM } from 'model/selfie-info';
import { VerifyIdentity } from 'model/verify-identity';
import { api } from './api';
import { ApprovalData, CanRetryMessage, Signatures } from 'model/signatures';
import { CreateQueueDocFraud } from 'model/create-queue-doc-fraud';

export const clientApi = () => {
  const clientData = (): Promise<AxiosResponse<Client>> => {
    return api.get<Client>('/clients');
  };
  const getAllClientData = (id: number): Promise<AxiosResponse<Client>> => {
    return api.get<Client>(`/clients/${id}/summary`);
  };
  const sendSelfie = (attachment: Attachment, selfieKey: string): Promise<AxiosResponse<Client>> => {
    return api.post<Client>(`/clients/attachments/selfie/${selfieKey}`, attachment);
  };
  const sendSelfieAdmin = (attachment: Attachment, selfieKey: string): Promise<AxiosResponse<Client>> => {
    return api.post<Client>(`/clients/attachments/selfie/${selfieKey}/admin`, attachment);
  };
  const selfieAnalysisAdmin = (id: number, payload: CreateQueueDocFraud): Promise<AxiosResponse<Client>> => {
    return api.post<Client>(`/doc-fraud-queue/client/${id}/admin`, payload);
  };
  const generateSelfieKey = (): Promise<AxiosResponse<SelfieVM>> => {
    return api.patch<SelfieVM>(`/clients/selfie-key/generate`);
  };
  const generateSelfieKeyAdmin = (id: number): Promise<AxiosResponse<SelfieVM>> => {
    return api.patch<SelfieVM>(`/clients/${id}/selfie-key/generate/admin`);
  };
  const getSelfieStatus = (): Promise<AxiosResponse<SelfieVM>> => {
    return api.get<SelfieVM>(`/clients/selfie/verify`);
  };
  const getSelfieStatusAdmin = (id: number): Promise<AxiosResponse<SelfieVM>> => {
    return api.get<SelfieVM>(`/clients/${id}/selfie/verify`);
  };
  const finishUpdate = (): Promise<AxiosResponse<Client>> => {
    return api.patch<Client>(`/clients/finish-update`);
  };
  const cancelCreditCard = (id: number): Promise<AxiosResponse<Client>> => {
    return api.patch<Client>(`/credit-card/cancel/${id}`);
  };
  const getBasicInfo = (cpf: string): Promise<AxiosResponse<Client>> => {
    return api.get<Client>(`/clients/${cpf}/basic-info`);
  };
  const getCreditCardFromClient = (clientId: number): Promise<AxiosResponse<CreditCard[]>> => {
    return api.get<CreditCard[]>(`/credit-card/client/${clientId}`);
  };
  const getCreditCardFromFinancing = (financingId: number): Promise<AxiosResponse<FinancingCreditCard>> => {
    return api.get<FinancingCreditCard>(`/financing/${financingId}/credit-cards`);
  };
  const acceptTerms = (clientId: number): Promise<AxiosResponse> => {
    return api.post<ContractAccept>(`/clients/${clientId}/accept-terms`);
  };
  const getTerms = (clientId: number): Promise<AxiosResponse<ContractAccept>> => {
    return api.get<ContractAccept>(`/clients/${clientId}/accept-terms`);
  };

  const getBiometricsStatusAndClientAuthentication = (
    clientId: number
  ): Promise<AxiosResponse<BiometricsStatusAndClientAuthentication>> => {
    return api.get<BiometricsStatusAndClientAuthentication>(`/clients/${clientId}/is-verified`);
  };

  const getIfClientNeedsToDoBiometricsOrAuthentication = (clientId: number): Promise<AxiosResponse<VerifyIdentity>> => {
    return api.get<VerifyIdentity>(`/clients/${clientId}/is-verify-identity`);
  };

  const reactivateAccountAdmin = (clientId: number): Promise<AxiosResponse<BiometricsStatusAndClientAuthentication>> => {
    return api.patch<BiometricsStatusAndClientAuthentication>(`/clients/${clientId}/is-verified/admin`);
  };

  const changeClientSubsidiary = (clientId: number, subsidiaryId: number): Promise<AxiosResponse<OrganizationSubsidiaries>> => {
    return api.patch<OrganizationSubsidiaries>(`/clients/${clientId}/organization-subsidiary/${subsidiaryId}`);
  };

  const getSignatureData = (id: number): Promise<AxiosResponse<Signatures>> => {
    return api.get<Signatures>(`/person-signature/${id}`);
  };

  const enableRetry = (id: number, message: CanRetryMessage): Promise<AxiosResponse<Signatures>> => {
    return api.patch<Signatures>(`/person-signature/${id}/enable-retry`, message);
  };

  const cancelContract = (id: number): Promise<AxiosResponse<Signatures>> => {
    return api.patch<Signatures>(`/person-signature/cancel/${id}`);
  };

  const resendEmail = (id: number): Promise<AxiosResponse<Signatures>> => {
    return api.post<Signatures>(`/person-signature/${id}/resend-signature-solicitation-email`);
  };

  const manualApproval = (approvalData: ApprovalData): Promise<AxiosResponse<Signatures>> => {
    return api.post<Signatures>(`/person-signature/manual-approval`, approvalData);
  };
  const getClientRegistration = (registrationKey: string): Promise<AxiosResponse<Client>> => {
    return api.get<Client>(`/clients/registration/${registrationKey}`);
  };

  return {
    clientData,
    getAllClientData,
    sendSelfie,
    sendSelfieAdmin,
    selfieAnalysisAdmin,
    finishUpdate,
    generateSelfieKey,
    generateSelfieKeyAdmin,
    getSelfieStatus,
    getBasicInfo,
    getCreditCardFromClient,
    getCreditCardFromFinancing,
    acceptTerms,
    getTerms,
    getBiometricsStatusAndClientAuthentication,
    getIfClientNeedsToDoBiometricsOrAuthentication,
    reactivateAccountAdmin,
    getSelfieStatusAdmin,
    changeClientSubsidiary,
    cancelCreditCard,
    getSignatureData,
    enableRetry,
    cancelContract,
    resendEmail,
    manualApproval,
    getClientRegistration,
  };
};

export default clientApi();
