import { addDays, format, isValid } from 'date-fns';
import brazilianLocale from 'date-fns/locale/pt-BR';
import moment from 'moment';

import { APP_LOCAL_DATE_FORMAT, APP_LOCAL_ISO_FORMAT, APP_LOCAL_ISO_FORMAT_Z } from 'config/constants';

export const convertDateTimeFromServer = (date?: Date | string) => {
  if (date != null) {
    date = new Date(date);

    if (isValid(date)) {
      return format(new Date(date), APP_LOCAL_ISO_FORMAT);
    }

    return '';
  }

  return '';
};

export const convertDateTimeToServer = (
  date: string | number | void | moment.Moment | Date | (string | number)[] | moment.MomentInputObject | undefined
) => (date ? moment(date, APP_LOCAL_ISO_FORMAT_Z).toDate() : null);

export const formatDateToLocalFormat = (date?: string | Date) => {
  if (date != null) {
    const validDate = new Date(date);
    return format(validDate, APP_LOCAL_DATE_FORMAT);
  }

  return '';
};

export const addIntegerToCurrentDate = (numberDays: number) => {
  return format(addDays(new Date(), numberDays), APP_LOCAL_DATE_FORMAT);
};

export const getNameMonthPtbr = (date: Date) => {
  const month = format(date, 'MMMM', { locale: brazilianLocale });

  return month.toLowerCase();
};
