import styled from 'styled-components/macro';

import Popup from 'reactjs-popup';

interface Props {
  error?: boolean;
  isFocused?: boolean;
  activated?: boolean;
  width?: number;
  flexWidth?: boolean;
  large?: boolean;
}

export const StyledPopUp = styled(Popup)`
  &-overlay {
    background: ${({ theme }) => theme.color.shadow};
  }
  &-content {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const StyledContainer = styled.div<Props>`
  width: ${({ large }) => (large ? '630px' : 'max-content')};
  max-height: 416px;

  padding: ${({ large }) => (large ? '40' : '24')}px;
  background-color: #fff;
  text-align: center;
  border: 1px solid #eeeeee;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
`;

export const StyledXIconContainer = styled.div`
  text-align: right;
`;

export const StyledTitle = styled.div`
  font-size: 24px;
  font-weight: 700;
  margin: 15px 0 24px 0;
`;

export const StyledMessage = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: ${({ theme }) => theme.color.gray19};
`;

export const StyledButtonsContainer = styled.div`
  width: 100%;

  display: flex;
  justify-content: center;
  column-gap: 16px;
  margin-top: 40px;
`;

export const IconContainer = styled.div`
  margin-top: 20px;
`;

export const InputsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 24px;
`;
