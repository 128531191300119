import styled from 'styled-components/macro';

export interface StyledProps {
  width: string;
}

export const StyledLink = styled.a<StyledProps>`
  height: 48px;
  width: ${({ width }) => width};

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${({ theme }) => theme.color.grayBackground};
  border-radius: 24px;
  border: ${({ theme }) => `1.5px solid ${theme.color.primaryColor}`};
  font-style: normal;
  font-size: 16px;
  font-weight: 700;
  color: ${({ theme }) => theme.color.primaryColor};
  text-decoration: none;

  &:hover {
    color: ${({ theme }) => theme.color.primaryColor};
  }

  @media (max-width: 575.98px) {
    height: 45px;
    font-size: 14px;
  }
`;
