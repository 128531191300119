import Check from '@mui/icons-material/Check';
import { toLower } from 'lodash';
import { useRegisterForm } from 'provider/register-form';
import { useTranslation } from 'react-i18next';
import { Container, CustomStep, StepContainer, StepLabel, StepLabelContainer } from './styles';

interface IStepperProps {
  activeStep: number;
}

const Stepper: React.FC<IStepperProps> = props => {
  const { t } = useTranslation();
  const { steps, actualStep } = useRegisterForm();

  return (
    <Container>
      {steps &&
        steps.map((step, i) => (
          <>
            <StepContainer key={i} active={actualStep === i}>
              <CustomStep active={actualStep === i}>
                {actualStep > i ? (
                  <div className="Complete-StepIcon-circle">
                    <Check className="StepIcon-completedIcon" />
                  </div>
                ) : (
                  <div className="StepIcon-circle">
                    <span>{i + 1}</span>
                  </div>
                )}
              </CustomStep>
              <StepLabelContainer>
                <StepLabel active={actualStep === i}>{t(`stepper.title.${toLower(step?.systemStep?.step)}`)}</StepLabel>
              </StepLabelContainer>
            </StepContainer>
          </>
        ))}
    </Container>
  );
};

export default Stepper;
