import { NavLink } from 'react-router-dom';
import styled from 'styled-components/macro';

export const ThinBackground = styled.div`
  max-width: 300px;
  height: 100vh;
  margin: auto;
  padding: 40px 0;
`;
export const ThinHeader = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 64px;

  span {
    font-family: 'Satoshi';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.color.primaryColor};
  }
`;
export const ThinHeaderStroke = styled.img`
  width: 8px;
  margin-right: 15px;
  fill: #4b6fdd;
`;
export const ThinContainer = styled.div`
  margin: 50px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
export const ThinInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: 40px;

  & > label + label {
    margin-top: 16px;
  }
`;
export const ThinButtonContainer = styled.div`
  button {
    width: 300px;
  }
`;
export const ThinTitle = styled.span`
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 43px;
  letter-spacing: 0.5px;
  color: #000000;
  margin-bottom: 10px;
  text-align: start;
`;
export const ThinSubTitle = styled.span`
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  text-align: start;
  color: #999999;
`;
export const ThinTextWithLink = styled.span`
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  text-align: start;
  color: #999999;
  display: flex;
  align-items: center;
  margin-top: 10px;
`;

export const ThinRequirementsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 10px;
`;
export const ThinRequirementTitle = styled.span`
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #7f7f7f;
`;

interface ThinRequirementItemProps {
  isValid?: boolean;
}
export const ThinRequirementItem = styled.li<ThinRequirementItemProps>`
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: ${props => (props.isValid ? '#0DB052' : '#7f7f7f')};
`;

export const Link = styled(NavLink)`
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.color.primaryColor};
  text-decoration: none;
`;

export const LinkButton = styled.a`
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.color.primaryColor};
  text-decoration: none;
  cursor: pointer;
`;
