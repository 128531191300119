import { yupResolver } from '@hookform/resolvers/yup';
import { Grid } from '@mui/material';
import CustomButton from 'components/custom-button';
import CustomInputNumbersOnly from 'components/custom-input-numbers-only';
import CustomTextField from 'components/custom-text-field';
import Header from 'components/general-components/header';
import { HeaderVariants } from 'model/enums/header-variants';
import TypesMask from 'model/enums/types-mask';
import { Procedure } from 'model/financing';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { WithTranslation, withTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import financingService from 'services/financing-service';
import StringUtils from 'shared/util/string-utils';
import * as yup from 'yup';
import { StyledButtonContainer, StyledCardContainer, StyledContainer, StyledInnerContainer, StyledMainTitle, StyledText } from './styles';

const AddProcedure = ({ t }: WithTranslation) => {
  const history = useHistory();
  const location = useLocation<Procedure>();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const schema = yup.object({
    choosedValue: yup.string().required(),
    medicalProcedure: yup.string().required(),
  });

  const methods = useForm<Procedure>({
    resolver: yupResolver(schema),
    mode: 'all',
  });

  const handleCreateProcedure = (formData: Procedure) => {
    setIsLoading(true);

    formData.choosedValue = StringUtils.removeMoneyFormat(formData.choosedValue);

    financingService.createProcedure(formData).then(response => {
      setIsLoading(false);

      history.push('/procedimentos/dashboard', {
        procedureId: response.id,
        procedureStatus: response.financingStatus,
        choosedValue: response.choosedValue,
        medicalProcedure: response.medicalProcedure,
      });
    });
  };

  const handleUpdateProcedure = (formData: Procedure) => {
    formData.choosedValue = StringUtils.removeMoneyFormat(formData.choosedValue);
    formData.id = location.state.id;
    financingService.updateProcedure(formData).then(response =>
      history.push('/procedimentos/dashboard', {
        procedureId: response.id,
        procedureStatus: response.financingStatus,
        choosedValue: `R$ ${StringUtils.maskAmount(Number(response.choosedValue))}`,
        medicalProcedure: response.medicalProcedure,
      })
    );
  };

  return (
    <StyledContainer>
      <Header variant={HeaderVariants.SECONDARY} />
      <StyledInnerContainer>
        <StyledMainTitle>
          {location.state?.id != null ? t('myProcedures.editProcedure') : t('myProcedures.addNewProcedure')}
        </StyledMainTitle>
        <StyledText>{t('myProcedures.fillProcedureDataToProceed')}</StyledText>
        <StyledCardContainer>
          <FormProvider {...methods}>
            <form
              onSubmit={methods.handleSubmit(formData =>
                location.state?.id != null ? handleUpdateProcedure(formData) : handleCreateProcedure(formData)
              )}
              id="procedure-form"
            >
              <Grid container rowSpacing={1} columnSpacing={3}>
                <Grid item xs={12}>
                  <CustomTextField
                    name="medicalProcedure"
                    label={t('myProcedures.procedure')}
                    helperText={methods.formState.errors.medicalProcedure?.message}
                    defaultValue={location.state?.medicalProcedure}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CustomInputNumbersOnly
                    name="choosedValue"
                    label={t('myProcedures.procedureValue')}
                    helperText={methods.formState.errors.choosedValue?.message}
                    mask={TypesMask.MONETARY_VALUE}
                    defaultValue={location.state?.choosedValue}
                    maxLength={9}
                  />
                </Grid>
              </Grid>
            </form>
          </FormProvider>
        </StyledCardContainer>
        <StyledButtonContainer>
          <CustomButton
            style={{ justifyContent: 'center' }}
            height={48}
            width="160px"
            onClick={() => history.push('/procedimentos')}
            isInvertColor
          >
            {t('global.button.cancel')}
          </CustomButton>
          <CustomButton style={{ justifyContent: 'center' }} height={48} width="160px" form="procedure-form" loading={isLoading}>
            {location.state?.id != null ? t('global.button.save') : t('global.button.add')}
          </CustomButton>
        </StyledButtonContainer>
      </StyledInnerContainer>
    </StyledContainer>
  );
};

export default withTranslation()(AddProcedure);
