import { AUTHORITIES } from 'config/constants';
import jwt_decode from 'jwt-decode';
import { LocalStorageKeys } from 'model/enums/local-storage-keys';
import { RegistrationStatus } from 'model/enums/registration-status';
import { RoutePath } from 'model/enums/route-path';
import { User } from 'model/user';
import { UserParameters } from 'model/user-parameters';
import { ORGANIZATION_NAME } from 'config/constants';
import { OrganizationName } from 'model/enums/organization-name';

class AuthUtils {
  static isAuthenticated = () => {
    return localStorage.getItem(LocalStorageKeys.AUTH_TOKEN_KEY) !== null;
  };

  static isUser = () => {
    if (!AuthUtils.isAuthenticated()) return false;
    // @ts-ignore
    return jwt_decode(AuthUtils.getToken()!).auth.includes(AUTHORITIES.USER);
  };

  static isAdmin = () => {
    if (!AuthUtils.isAuthenticated()) return false;
    // @ts-ignore
    const decode = jwt_decode(AuthUtils.getToken()!).auth;
    if (
      decode.includes(AUTHORITIES.ADMIN) ||
      decode.includes(AUTHORITIES.INTERNAL_ADMIN) ||
      decode.includes(AUTHORITIES.PARTNER) ||
      decode.includes(AUTHORITIES.INTERNAL)
    ) {
      return true;
    }
    return false;
  };

  static getToken = () => {
    return localStorage.getItem(LocalStorageKeys.AUTH_TOKEN_KEY);
  };
  static setAccount = (user: User) => {
    localStorage.setItem(LocalStorageKeys.ACCOUNT_KEY, JSON.stringify(user));
  };

  static getAccount = (): User => {
    const account = localStorage.getItem(LocalStorageKeys.ACCOUNT_KEY);
    return account ? JSON.parse(account) : null;
  };
  static removeAccount = () => {
    localStorage.removeItem(LocalStorageKeys.ACCOUNT_KEY);
  };

  static setToken = (token: string) => {
    localStorage.setItem(LocalStorageKeys.AUTH_TOKEN_KEY, token);
  };

  static removeToken = () => {
    localStorage.removeItem(LocalStorageKeys.AUTH_TOKEN_KEY);
  };

  static getMainPath = () => {
    if (AuthUtils.isAdmin()) {
      return RoutePath.ADMIN_HOME;
    }

    if (AuthUtils.isUser()) {
      if (ORGANIZATION_NAME === OrganizationName.MULTTIPLO) {
        return RoutePath.PROCEDURES;
      }

      return RoutePath.CREDITS;
    }

    return RoutePath.HOME;
  };

  static checkUrl = (url: string) => {
    return window.location.pathname.includes(url);
  };

  static redirectToPath = (registrationStatus: string, history, accountParameters?: UserParameters) => {
    const redirectPath = AuthUtils.getMainPath();

    if (accountParameters?.hasContractAccountStep && accountParameters?.needsToAcceptTheTerms) {
      return history.push('/termos-de-uso');
    } else if (accountParameters?.needsToTakeFirstSelfie) {
      return history.push('/selfie');
    }

    switch (registrationStatus) {
      case RegistrationStatus.VALIDATION:
        if (this.checkUrl('/analise-cadastro/status/validacao')) {
          return;
        }
        return history.push('/analise-cadastro/status/validacao');
      case RegistrationStatus.ADJUST:
        if (this.checkUrl('/analise-cadastro/status/ajustes')) {
          return;
        }
        return history.push('/analise-cadastro/status/ajustes');
      case RegistrationStatus.ADJUSTED:
        if (this.checkUrl('/analise-cadastro/status/validacao')) {
          return;
        }
        return history.push('/analise-cadastro/status/validacao');
      case RegistrationStatus.REJECTED:
        if (this.checkUrl('/analise-cadastro/status/reprovado')) {
          return;
        }
        return history.push('/analise-cadastro/status/reprovado');
      default:
        if (this.checkUrl(redirectPath)) {
          return;
        }
        return history.replace(redirectPath);
    }
  };
}

export default AuthUtils;
