import styled from 'styled-components/macro';

export const StyledTable = styled.table`
  width: 100%;

  border-collapse: separate;
  border-spacing: 0;

  background: #ffffff;
  border: 1px solid #eeeeee;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  margin-top: 24px;
  padding: 0 24px;
  text-align: left;

  .removeBorder {
    td {
      border-bottom: none;
    }
  }
`;

export const StyledTableRow = styled.tr`
  height: 56px;

  th,
  td {
    border-bottom: 1px solid #e5e7e7;
  }

  td {
    color: #4c4d4d;
    font-weight: 400;
  }

  td:nth-last-child(1) {
    height: 56px;
    display: flex;
    justify-content: end;
  }
`;

export const StyledTableRowNoData = styled.tr`
  height: 56px;

  td {
    text-align: center;
  }
`;
