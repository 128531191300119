import ReactHookFormProvider from 'components/ReactHookFormProvider';
import { Client } from 'model/client';
import { useRegisterForm } from 'provider/register-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import { FormContainer, ImageContainer } from '../styles';
import { ContainerInputsPassword } from './inputs-password';

export const FormPassword = () => {
  const { onClickNext } = useRegisterForm();
  const { t } = useTranslation();
  const history = useHistory();

  const schema = yup.object({
    password: yup
      .string()
      .required(t('global.errorMessage.required'))
      .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,})/, t('global.errorMessage.requirementsPassword')),
    confirmPassword: yup
      .string()
      .required(t('global.errorMessage.required'))
      .oneOf([yup.ref('password'), null], t('global.errorMessage.matchPasswords')),
  });

  return (
    <FormContainer>
      <ReactHookFormProvider
        schema={schema}
        onSubmit={res => {
          const newRes = {
            user: {
              password: res.password,
            },
          };
          onClickNext(newRes as Client);
        }}
      >
        <ContainerInputsPassword />
      </ReactHookFormProvider>
    </FormContainer>
  );
};
