import styled from 'styled-components/macro';

interface IStyledProps {
  isDisabled: boolean;
}

export const StyledContainer = styled.div`
  background-color: #f4f4f4;
  padding-bottom: 160px;
  overflow: hidden;
`;

export const StyledHeaderContainer = styled.div`
  height: 60px;
  background-color: #f4f4f4;
  margin-bottom: 24px;
`;

export const StyledHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;

  max-width: 960px;
  height: 100%;

  margin: auto;

  @media (max-width: 1000.98px) {
    padding: 0 4vh;
    padding-top: 20px;
  }
`;

export const StyledContractContainer = styled.main`
  height: calc(100vh - 200px);
  overflow-y: auto;

  .react-pdf__Page__canvas {
    max-width: 960px;
    margin-bottom: 15px;
  }

  @media (max-width: 1000.98px) {
    padding: 0 1.8vh;
  }
`;

export const StyledFooter = styled.footer`
  height: 96px;
  width: 100%;

  position: fixed;
  bottom: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 5%;
  background-color: ${({ theme }) => theme.color.primaryColorLight};
  .mobile-button {
    border-radius: 8px;
    width: 80%;
    display: flex;
    justify-content: center;
    font-size: 16px;
    box-shadow: 0px -1px 9px 0px rgba(0, 0, 0, 0.21);
  }
`;

export const StyledLink = styled.a<IStyledProps>`
  pointer-events: ${({ isDisabled }) => isDisabled && 'none'};
  margin-right: 14px;

  @media (max-width: 575.98px) {
    margin: 0px;

    svg {
      height: 30px;
    }
  }
`;

export const StyledButton = styled.button`
  border: none;
  background: none;
  padding: 0;

  svg {
    @media (max-width: 575.98px) {
      height: 30px;
    }
  }
`;

export const StyledLoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const StyledPdfDownloadButton = styled.button`
  border: none;
  background: none;
  padding: 0;

  &:focus-visible {
    outline: none;
  }

  svg {
    @media (max-width: 575.98px) {
      height: 30px;
    }
  }
`;
