import styled from 'styled-components/macro';

export const Container = styled.div`
  justify-content: center;
  width: fit-content;
  display: flex;
  align-items: center;

  button {
    border: none;
    background-color: transparent;
    padding: 0;
    margin: 0;
  }
`;

export const DropDownContainer = styled.div`
  display: flex;
  width: 150px;
  padding: 10px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  border-radius: 8px;
  background: #fff;
  position: absolute;
  border: 1px solid #e5e7e7;
  transform: translate(8px, 42px);
  button {
    width: 100%;
    text-align: left;
    border-bottom: 1px solid #e5e7e7;
    padding-bottom: 5px;
  }
  button:last-child {
    border-bottom: none;
    padding-bottom: 0px;
  }
`;
