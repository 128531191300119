import { CardList } from 'components/simulator-result/card-list';
import { InputContainer, ScreenContent, StyledIcon } from 'components/simulator-result/styled';
import { StyledTitle } from 'features/simulator-result/my-credits/styles';
import { RoutePath } from 'model/enums/route-path';
import { IMyPayments } from 'model/simulator-result';
import { useTranslation } from 'react-i18next';
import { BiSearch } from 'react-icons/bi';
import { useHistory } from 'react-router-dom';
import { formatDateToLocalFormat } from 'shared/util/date-utils';
import StringUtils from 'shared/util/string-utils';
import { useTheme } from 'styled-components';

interface MobileProps {
  filterTable: IMyPayments[];
  setSearchByIdentification: (value: string) => void;
  searchByIdentification: string;
  page: string;
  setPage: (value: string) => void;
  totalPages: number;
  numberedPage: number;
  isLoading: boolean;
}

const Mobile: React.FC<MobileProps> = ({
  filterTable,
  setSearchByIdentification,
  searchByIdentification,
  setPage,
  page,
  totalPages,
  numberedPage,
  isLoading,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const history = useHistory();

  const cardData: { [key: string]: string | number }[] = filterTable.map((payment, idx) => ({
    idNumber: payment.identification ?? '',
    text: `${formatDateToLocalFormat(payment.createdDate)} | ${payment.installmentsTotal}x R$${StringUtils.maskAmount(
      payment.installmentValue
    )}`,
    status: payment.payment?.status ?? '',
    id: payment?.id ?? 0,
    consultantComment: payment?.consultantComment ?? '',
  }));

  {
  }

  return (
    <ScreenContent>
      <StyledTitle style={{ margin: '24px 0px 16px 0px' }}>{t('admin.dashboard.payments')}</StyledTitle>
      <InputContainer>
        <StyledIcon>
          <BiSearch color={theme.color.primaryColor} size={24} />
        </StyledIcon>
        <input
          type="text"
          placeholder={t('admin.dashboard.search')}
          value={searchByIdentification}
          onChange={e => setSearchByIdentification(e.target.value)}
        />
      </InputContainer>
      <CardList
        cardData={cardData}
        page={page}
        setPage={setPage}
        totalPages={totalPages}
        numberedPage={numberedPage}
        isLoading={isLoading}
        redirect={RoutePath.PAYMENTS}
      />
    </ScreenContent>
  );
};

export default Mobile;
