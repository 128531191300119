import { yupResolver } from '@hookform/resolvers/yup';
import { Grid } from '@mui/material';
import Checkbox from 'components/checkbox';
import CustomAutocomplete from 'components/custom-autocomplete';
import CustomInputNumbersOnly from 'components/custom-input-numbers-only';
import { Bank, BankAccount } from 'model/bank-account';
import { BankAccountType, ProcedureBankAccountType } from 'model/enums/bank-account-type';
import { Pagination } from 'model/pageable';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { WithTranslation, withTranslation } from 'react-i18next';
import bankService from 'services/bank-service';
import * as yup from 'yup';
import { StyledSubTitle } from './styles';
import CustomTextField from 'components/custom-text-field';
import StringUtils from 'shared/util/string-utils';
import { PhysicalPerson } from 'model/physical-person';

interface Props extends WithTranslation {
  selectedBankAccount?: BankAccount;
  setSelectedBankAccount: Dispatch<SetStateAction<BankAccount | undefined>>;
  setIsShowModalCreateBankAccount: Dispatch<SetStateAction<boolean>>;
  getRegisteredBanks?: () => void;
  setIsLoadingBankAccount: Dispatch<SetStateAction<boolean>>;
  isRegistrationByAdmin?: boolean;
  clientId?: number;
  financingId?: number;
  getBankAccount?: () => void;
  paymentData?: any;
  clientData?: PhysicalPerson;
}

export interface BankAccountForm {
  bank: string;
  accountType: string;
  bankAccountNumber: string;
  bankAccountDigit: string;
  agencyNumber: string;
  jointAccount: boolean;
}

const bankAccountTypes = [BankAccountType.CHECKING_ACCOUNT, BankAccountType.SALARY_ACCOUNT, BankAccountType.SAVING_ACCOUNT];

enum PlaceholderInputs {
  ACCOUNT = '000000',
  DIGIT = '0',
  AGENCY = '0000',
}

const BankAccountForm = ({
  selectedBankAccount,
  setSelectedBankAccount,
  getRegisteredBanks,
  setIsShowModalCreateBankAccount,
  setIsLoadingBankAccount,
  isRegistrationByAdmin,
  clientId,
  financingId,
  getBankAccount,
  t,
  paymentData,
  clientData,
}: Props) => {
  const [bankNames, setBankNames] = useState<Bank[]>([]);
  const [selectedBank, setSelectedBank] = useState<Bank>();
  const [selectedBankAccountType, setSelectedBankAccountType] = useState<BankAccountType | null>(null);

  const [searchTextSendApiBankNames, setSearchTextSendApiBankNames] = useState<string>('');
  const [timeoutSearchBankNames, setTimeoutSearchBankNames] = useState<NodeJS.Timeout | null>(null);
  const [pagination, setPagination] = useState<Pagination>({
    page: 0,
    totalPages: 0,
  });

  const schema = yup.object({
    bank: yup.string().test('test-bank', t('global.errorMessage.required'), () => selectedBank != null),
    accountType: yup.string().test('test-account-type', t('global.errorMessage.required'), () => selectedBankAccountType != null),
    bankAccountNumber: yup.string().required(),
    bankAccountDigit: yup.string().required(),
    agencyNumber: yup.string().required(),
  });

  const methods = useForm<BankAccountForm>({
    resolver: yupResolver(schema),
    mode: 'all',
  });

  useEffect(() => {
    if (selectedBankAccount?.bank?.name != null) {
      setSelectedBank(selectedBankAccount.bank);
      if (selectedBankAccount?.type) {
        setSelectedBankAccountType(BankAccountType[selectedBankAccount.type]);
      }
    }
  }, [selectedBankAccount]);

  useEffect(() => {
    getBankNames();
  }, [pagination.page]);

  useEffect(() => {
    getBankNames();
  }, [searchTextSendApiBankNames]);

  const getBankNames = () => {
    const byParam = paymentData?.procedureBankAccountType === ProcedureBankAccountType.IUGU ? 'iugu' : '';
    bankService.getAllBankNames(pagination.page, searchTextSendApiBankNames, byParam).then(response => {
      setPagination({
        page: response.data.pageable.pageNumber,
        totalPages: response.data.totalPages,
      });

      if (pagination.page > 0) {
        setBankNames([...bankNames, ...response.data.content]);
      } else {
        setBankNames(response.data.content);
      }
    });
  };

  const handleBankAccountCreation = (formData: BankAccountForm) => {
    if (selectedBank?.id != null) {
      setIsLoadingBankAccount(true);
      const bankAccountTypeIndex = Object.values(BankAccountType).indexOf(selectedBankAccountType as unknown as BankAccountType);
      const keyBankAccountType = Object.keys(BankAccountType)[bankAccountTypeIndex];

      const formattedBankAccount: BankAccount = {
        ...formData,
        type: keyBankAccountType,
        bank: {
          id: selectedBank.id,
        },
      };

      isRegistrationByAdmin && clientId != null
        ? bankService.createBankAccountByAdmin(clientId, formattedBankAccount).then(response => {
            setSelectedBankAccount(response);

            if (response.id != null && financingId != null) {
              bankService.linkBankAccountWithFinancingByAdmin(financingId, response.id, clientId).then(() => {
                if (getBankAccount != null) {
                  getBankAccount();
                  setIsShowModalCreateBankAccount(false);
                  setIsLoadingBankAccount(false);
                }
              });
            }
          })
        : bankService.createBankAccount(formattedBankAccount).then(response => {
            setSelectedBankAccount(response);
            setIsShowModalCreateBankAccount(false);
            setIsLoadingBankAccount(false);

            if (getRegisteredBanks != null) {
              getRegisteredBanks();
            }
          });
    }
  };

  const handleBankAccountUpdate = (formData: BankAccountForm) => {
    if (selectedBank?.id != null) {
      const bankAccountTypeIndex = Object.values(BankAccountType).indexOf(selectedBankAccountType as unknown as BankAccountType);
      const keyBankAccountType = Object.keys(BankAccountType)[bankAccountTypeIndex];

      const formattedBankAccount: BankAccount = {
        ...formData,
        id: selectedBankAccount?.id,
        type: keyBankAccountType,
        bank: {
          id: selectedBank.id,
        },
      };

      bankService.updateBankDetails(formattedBankAccount).then(response => {
        setSelectedBankAccount(response);
        setIsShowModalCreateBankAccount(false);

        if (getRegisteredBanks != null) {
          getRegisteredBanks();
        }
      });
    }
  };

  const handleChangeBank = (value: string) => {
    if (timeoutSearchBankNames != null) {
      clearTimeout(timeoutSearchBankNames);
    }

    setTimeoutSearchBankNames(
      setTimeout(() => {
        setSearchTextSendApiBankNames(value);
      }, 500)
    );
  };

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(formData =>
          selectedBankAccount?.id != null ? handleBankAccountUpdate(formData) : handleBankAccountCreation(formData)
        )}
        id="bank-account-creation-form"
      >
        <Grid container rowSpacing={1.5} columnSpacing={3}>
          <Grid item xs={12} sm={6}>
            <CustomTextField name="name" label={t('dashboard.fullName')} defaultValue={clientData?.name} disabled />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomTextField name="cpf" label={t('dashboard.cpf')} defaultValue={StringUtils.cpfMask(clientData?.cpf)} disabled />
          </Grid>

          <Grid item xs={12}>
            <StyledSubTitle>{t('dashboard.paymentIsMadeInNameAndCpf')}</StyledSubTitle>
          </Grid>

          <Grid item xs={12} sm={6}>
            <CustomAutocomplete
              data={bankNames}
              keysValuesToDisplayed={['number', 'name']}
              label={t('dashboard.bank')}
              pagination={pagination}
              setPagination={setPagination}
              inputValue={handleChangeBank}
              optionSelected={setSelectedBank}
              defaultValue={selectedBankAccount?.bank}
              placeholder={t('dashboard.selectYourBank')}
              errorMessage={methods.formState.errors.bank?.message}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomAutocomplete
              data={bankAccountTypes}
              label={t('dashboard.accountType')}
              optionSelected={setSelectedBankAccountType}
              defaultValue={selectedBankAccount?.type != null ? BankAccountType[selectedBankAccount.type] : null}
              placeholder={t('dashboard.selectAccountType')}
              errorMessage={methods.formState.errors.accountType?.message}
            />
          </Grid>

          <Grid item xs={9} sm={4}>
            <CustomInputNumbersOnly
              maxLength={4}
              name="agencyNumber"
              label={t('dashboard.agency')}
              helperText={methods.formState.errors.agencyNumber?.message}
              defaultValue={selectedBankAccount?.agencyNumber}
              placeholder={PlaceholderInputs.AGENCY}
            />
          </Grid>
          <Grid item xs={3} sm={2}>
            <CustomInputNumbersOnly
              maxLength={1}
              name="agencyDigit"
              label={t('dashboard.digit')}
              defaultValue={selectedBankAccount?.agencyDigit}
              placeholder={PlaceholderInputs.DIGIT}
            />
          </Grid>
          <Grid item xs={9} sm={4}>
            <CustomInputNumbersOnly
              name="bankAccountNumber"
              label={t('dashboard.account')}
              helperText={methods.formState.errors.bankAccountNumber?.message}
              defaultValue={selectedBankAccount?.bankAccountNumber}
              placeholder={PlaceholderInputs.ACCOUNT}
            />
          </Grid>
          <Grid item xs={3} sm={2}>
            <CustomInputNumbersOnly
              maxLength={1}
              name="bankAccountDigit"
              label={t('dashboard.digit')}
              helperText={methods.formState.errors.bankAccountDigit?.message}
              defaultValue={selectedBankAccount?.bankAccountDigit}
              placeholder={PlaceholderInputs.DIGIT}
            />
          </Grid>
          <Grid item xs={12} style={{ textAlign: 'left' }}>
            <Checkbox name="jointAccount" label={t('dashboard.isJointAccount')} isChecked={selectedBankAccount?.jointAccount} />
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default withTranslation()(BankAccountForm);
