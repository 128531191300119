import styled from 'styled-components/macro';
export const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: 'Open Sans', sans-serif;

  width: 100%;
  height: 80px;
  padding: 0 32px;

  color: #5c5c5c;
  font-size: 16px;
  box-shadow: 0px 6px 10px 4px rgba(0, 0, 0, 0.05), 0px 2px 3px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  box-sizing: border-box;

  & > svg {
    width: 23px;
    height: 23px;
    path {
      fill: ${({ theme }) => theme.color.primaryColor};
    }
  }

  @media (max-width: 575.98px) {
    font-size: 12px;
    padding: 0 16px;
  }
`;

export const StyledInnerContainer = styled.div`
  display: flex;
  text-align: left;
  align-items: center;

  circle {
    fill: ${({ theme }) => theme.color.primaryColorLight};
  }

  rect {
    fill: ${({ theme }) => theme.color.primaryColorLight};
  }
`;

export const IconContainer = styled.div`
  background-color: ${({ theme }) => theme.color.primaryColorLight};
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
`;

export const StyledTextContainer = styled.div`
  line-height: 1.3;
  margin-left: 12px;

  @media (max-width: 575.98px) {
    margin-left: 8px;
  }
`;

export const StyledTitle = styled.span`
  display: block;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 14px;
`;
