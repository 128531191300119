import Stroke from 'images/stroke.svg';
import QRCode from 'qrcode.react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import clientService from 'services/client-service';
import AuthUtils from 'shared/util/auth-utils';
import { ThinBackground, ThinContainer, ThinHeader, ThinHeaderStroke, ThinSubTitle, ThinTitle } from 'styled-components/thin-styles';
import { useSelfieContext } from '../provider';
import { QrCodeContainer } from './styles';
import { StyledButton } from 'components/custom-button/styles';
import { Copy } from 'images/copy';

const SelfieQrCode = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { pathname } = useLocation();
  const { checkMobileAndSelfieStep } = useSelfieContext();
  const [isCopied, setIsCopied] = useState(false);

  const key = useParams<{ key: string }>().key;
  const id = useParams<{ id: string }>().id ?? '0';
  const isAdmin = pathname.includes('admin');

  useEffect(() => {
    if (key) {
      if (checkMobileAndSelfieStep) {
        checkMobileAndSelfieStep(key);
      }
      const interval = setInterval(() => {
        if (AuthUtils.isAuthenticated() && AuthUtils.isAdmin()) {
          clientService.getSelfieStatusAdmin(Number(id)).then(response => {
            if (response.isSelfieTaken) {
              history.push(`/selfie/${id}/enviado`);
            }
          });
        } else {
          clientService.getSelfieStatus().then(response => {
            if (response.isSelfieTaken) {
              history.push('/selfie/enviado');
            }
          });
        }
      }, 5000);
      return () => clearInterval(interval);
    }
  }, [key]);

  const url = window.location.href.replace('/qrcode', `/instrucoes`);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(url);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 3000);
  };

  return (
    <ThinBackground>
      <ThinHeader
        onClick={() => {
          if (isAdmin) {
            history.push(`/admin/cadastros/${id}`);
          } else {
            history.goBack();
          }
        }}
      >
        <ThinHeaderStroke src={Stroke} />
        <span>{t('global.button.backStep')}</span>
      </ThinHeader>
      <ThinContainer>
        <ThinTitle>{t('selfie.qrCode.title')}</ThinTitle>
        <ThinSubTitle>{t('selfie.qrCode.subtitle')}</ThinSubTitle>
      </ThinContainer>
      <QrCodeContainer>{key && <QRCode renderAs="svg" style={{ width: '100%', height: '100%' }} value={url} />}</QrCodeContainer>
      <StyledButton isInvertColor style={{ margin: 'auto', marginTop: '32px', marginBottom: '24px' }} onClick={copyToClipboard}>
        {!isCopied ? (
          <span>
            <Copy
              style={{
                marginRight: '8px',
                width: '20px',
                height: '20px',
              }}
            />
            {t('admin.contractScreen.copyLink')}
          </span>
        ) : (
          <span>{t('admin.contractScreen.linkCopied')}</span>
        )}
      </StyledButton>
    </ThinBackground>
  );
};

export default SelfieQrCode;
