import styled from 'styled-components/macro';

export const StyledContainer = styled.div`
  position: relative;

  font-size: 16px;
  background-color: ${({ theme }) => theme.color.grayBackground};
`;

export const StyledInnerContainer = styled.div`
  height: 100vh;
  max-width: 962px;
  margin: 48px auto 0 auto;
  padding: 0 16px;
  overflow: hidden;
`;

export const StyledTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledTitle = styled.div`
  font-weight: 700;
  font-size: 32px;
  color: #000000;

  @media (max-width: 575.98px) {
    font-size: 20px;
  }
`;

export const StyleCardInnerContainer = styled.div`
  width: 100%;
  margin-right: 10px;

  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

export const StyledHeaderCardContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledCardTitle = styled.span`
  font-weight: 700;
`;

export const StyledCardText = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.color.gray19};
`;

export const StyledCardsContainerMobile = styled.div`
  max-height: 570px;
  overflow-y: auto;

  @media (min-width: 575.98px) {
    padding-bottom: 75px;
  }
`;

export const StyledFooterMobile = styled.div`
  display: flex;
  justify-content: center;
  position: fixed;
  bottom: 0;

  width: 100%;
  background: #ffffff;
  box-shadow: 0px -2px 12px rgba(0, 0, 0, 0.06);
  padding: 16px 0;
`;

export const StyledCardContainer = styled.div`
  width: 100%;
  min-height: 96px;

  padding: 16px;
  margin-left: auto;
  margin-bottom: 8px;
  border: ${({ theme }) => `1px solid ${theme.color.gray20}`};
  border-radius: 8px;
  font-size: 16px;
  color: '#000000';
  text-align: left;
  background-color: #ffffff;
  cursor: pointer;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 991.98px) {
    margin-left: auto;
    margin-right: auto;
  }
`;

export const StyledNoDataContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  row-gap: 15px;
  width: 100%;
  height: calc(100% - 335px);
`;
