import UserDropdown from 'components/general-components/header/dropdown';
import { LogoBox } from 'components/general-components/header/styles';
import AdminFiltersContext from 'context/admin-filters';
import { Logo } from 'images/custom-organization';
import { RoutePath } from 'model/enums/route-path';
import { useAdmin } from 'provider/admin-general';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import AuthUtils from 'shared/util/auth-utils';
import { setStorageFilters } from 'shared/util/filter-utils';
import { ReactComponent as ArrowIconSvg } from '../../images/white_arrow_forward.svg';
import { useCustomSideMenu } from './hooks';
import { ButtonsContainer, Container, ContainerArrow, ContainerUser, StyledArrow, TabButton } from './styles';

export const CustomSideMenu = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { menuOpen, setMenuOpen, matchUrlSelectedTab, returnDropdonw } = useCustomSideMenu();
  const { resetFilters } = useContext(AdminFiltersContext);
  const { tabsMenu } = useAdmin();
  const { resetStatusFilters, resetCreatedDates } = useAdmin();

  return (
    <Container menuOpen={menuOpen}>
      <ContainerArrow>
        {menuOpen && (
          <LogoBox onClick={() => (AuthUtils.isAdmin() ? history.push(RoutePath.ADMIN_HOME) : history.push(RoutePath.HOME))}>
            <Logo isWhite={true} />
          </LogoBox>
        )}
        <StyledArrow
          menuOpen={menuOpen}
          onClick={() => {
            setMenuOpen(!menuOpen);
          }}
        >
          <ArrowIconSvg />
        </StyledArrow>
      </ContainerArrow>
      <ButtonsContainer
        onClick={() => {
          resetStatusFilters();
          resetCreatedDates();
          resetFilters();
        }}
      >
        {tabsMenu.map((tab, idx) => (
          <TabButton
            menuOpen={menuOpen}
            selected={matchUrlSelectedTab(tab)}
            key={`${tab.label}-${idx}`}
            onClick={() => {
              if (tab?.url) {
                history.push(tab.url);
              }
              setStorageFilters({ statusFilters: [], page: 0, search: '' });
              resetStatusFilters();
              resetCreatedDates();
              resetFilters();
            }}
          >
            {tab?.logo ?? ''}
            {menuOpen && t(tab?.label ?? '')}
          </TabButton>
        ))}
      </ButtonsContainer>
      <ContainerUser>
        <UserDropdown menuOpen={menuOpen} list={returnDropdonw()} position={'top left'} />
      </ContainerUser>
    </Container>
  );
};
