import styled from 'styled-components/macro';

export const InputContainer = styled.button`
  padding: 12px 25px;
  border: 1.5px solid ${({ theme }) => theme.color.primaryColor};
  border: 1px solid var(--gray-light-3, #e5e7e7);
  background: var(--gray-bw-white, #fff);
  border-radius: 8px;
  display: flex;
  width: 280px;
  padding: 8px 12px;

  > input {
    border: none;
    background-color: transparent;

    max-width: 150px;

    color: ${({ theme }) => theme.color.gray21};
    font-family: Satoshi;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */

    :focus {
      outline: none;
    }

    ::placeholder {
      color: ${({ theme }) => theme.color.primaryColor};
      color: var(--gray-dark-5, #7f7f7f);
    }
  }
`;

interface FilterItemProps {
  isActive?: boolean;
}

export const FilterItem = styled.button<FilterItemProps>`
  height: 40px;

  padding: 4px 12px;
  background: ${({ theme }) => theme.color.white};

  border: ${({ theme, isActive }) => (isActive ? `2px solid ${theme.color.primaryColor}` : `2px solid ${theme.color.gray20}`)};
  border-radius: 16px;
  color: ${({ theme }) => theme.color.gray18};

  border-radius: 8px;

  font-weight: 700;
  font-size: 14px;

  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 8px;

  :hover {
    border: 1px solid ${({ theme }) => theme.color.primaryColor};
  }
`;

export const DropDownFilter = styled.div`
  background-color: ${({ theme }) => theme.color.white};
  padding: 16px 16px;
  border: 1px solid ${({ theme }) => theme.color.gray20};
  border-radius: 8px;

  width: max-content;
  margin-top: 4px;
  display: flex;
  flex-direction: column;
`;

export const CustomRadioOption = styled.div`
  width: 146px;
  color: var(--gray-dark-3, #4c4d4d);
  font-family: Satoshi;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 21px */
  display: flex;
  padding: 4px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  span {
    font-size: 14px;
  }
  svg {
    width: 24px;
  }
`;

export const SectionTitle = styled.span`
  font-style: normal;
  font-family: Satoshi;
  font-weight: 700;
  font-size: 12px;
  line-height: 150%;
  color: ${({ theme }) => theme.color.gray18};
  margin-bottom: 16px;
`;
