import { AxiosResponse } from 'axios';
import { VaduCreditEngine, VaduCreditEngineId } from 'model/vadu-credit-engine';
import { api } from './api';

const vaduApi = () => {
  const BASE_URL = '/vadu-credit-engine';
  const createAnalysis = (financingId: number, clientId: number): Promise<AxiosResponse<VaduCreditEngineId>> => {
    const url = `${BASE_URL}/financing/${financingId}/client/${clientId}`;
    return api.post<VaduCreditEngineId>(url);
  };

  const analysisCpf = (cpf: string): Promise<AxiosResponse<boolean>> => {
    const url = `${BASE_URL}/available/${cpf}`;
    return api.post<boolean>(url);
  };

  const getAnalysisInfo = (clientId: number): Promise<AxiosResponse<VaduCreditEngine>> => {
    const url = `${BASE_URL}/client/${clientId}`;
    return api.get<VaduCreditEngine>(url);
  };

  return {
    createAnalysis,
    getAnalysisInfo,
    analysisCpf,
  };
};

export default vaduApi();
