import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import i18n from 'config/i18n';
import { AdminAnalyzeStatus } from 'model/enums/admin-analyze-status';
import { ORGANIZATION_NAME } from 'config/constants';
import { OrganizationName } from 'model/enums/organization-name';
import { useEffect, useState } from 'react';
import StringUtils from 'shared/util/string-utils';
import FinancingPaymentType from 'model/enums/financingPaymentType';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducer';
import { AdminAnalyze } from 'model/admin-analyze';
import { useParams } from 'react-router-dom';
import financingService from 'services/financing-service';
import { isEmpty } from 'lodash';
import { SubscriptionOption } from 'model/financing';
import { Organization } from 'model/organization';
import organizationService from 'services/organization-service';

interface Schema {
  action: string;
  comment?: string;
  payment?: string;
  subscriptionOption?: string;
  antiFraud?: string;
}

const antiFraudOptions = [
  i18n.t('admin.customerAnalysis.antiFraudOptions.withAntiFraud'),
  i18n.t('admin.customerAnalysis.antiFraudOptions.noAntiFraud'),
];

const defaultOptionAntiFraud = antiFraudOptions[1];

const useModalAnalyzingCredit = () => {
  const account = useSelector((state: IRootState) => state.authentication.account);
  const { financingId } = useParams<{ financingId: string }>();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [signatureOptions, setSignatureOptions] = useState<SubscriptionOption[]>([]);
  const [listPaymentType, setListPaymentType] = useState<FinancingPaymentType[]>([]);
  const [organizationData, setOrganizationData] = useState<Organization>();

  const schema = yup.object().shape({
    action: yup.string().required(),
    comment: yup.string(),
    payment: yup.string().when('action', {
      is: (value: string) =>
        value === i18n.t(`admin.customerAnalysis.analysisOptions.${AdminAnalyzeStatus.APPROVED}`) &&
        ORGANIZATION_NAME === OrganizationName.MONJUA,
      then: yup.string().required(),
    }),
  });

  const methods = useForm<Schema>({ resolver: yupResolver(schema) });

  useEffect(() => {
    getListPaymentType();
    getSignatureOptions();
    getOrganizationData();
  }, []);

  const getListPaymentType = () => {
    financingService.getListPaymentType().then(setListPaymentType);
  };

  const getSignatureOptions = () => {
    financingService.getSignatureOptions().then(setSignatureOptions);
  };

  const sendAnalysis = async (values: Schema) => {
    setIsLoading(true);

    const analyze: AdminAnalyze = {
      id: Number(financingId),
      consultantComment: values?.comment,
      financingStatus: formatAnalysisStatus(values.action),
      financingPaymentType: formatPaymentType(values.payment),
      biometricsIsNecessary: values.antiFraud !== defaultOptionAntiFraud,
    };

    if (!isEmpty(values?.subscriptionOption)) {
      const signatureOption = {
        id: signatureOptions.filter(signatureOption => signatureOption.description === values.subscriptionOption)[0].id,
      };

      analyze.signatureOption = signatureOption;
    }

    await financingService.adminAnalyzeFinancing(Number(financingId), analyze).then(() => {
      window.location.reload();
    });
  };

  const getOrganizationData = () => {
    organizationService.getCurrentOrganization().then(setOrganizationData);
  };

  const formatAnalysisStatus = (text: string) => {
    switch (text) {
      case i18n.t(`admin.customerAnalysis.analysisOptions.${AdminAnalyzeStatus.ADJUST}`):
        return AdminAnalyzeStatus.ADJUST;

      case i18n.t(`admin.customerAnalysis.analysisOptions.${AdminAnalyzeStatus.APPROVED}`):
        return AdminAnalyzeStatus.APPROVED;

      default:
        return AdminAnalyzeStatus.REJECTED;
    }
  };

  const formatPaymentType = (text?: string) => {
    switch (text) {
      case i18n.t(`admin.customerAnalysis.paymentOptions.organization`, {
        type: StringUtils.replaceToCapitalize(account?.organization ?? ''),
      }):
        return FinancingPaymentType.ORGANIZATION;
      case i18n.t(`admin.customerAnalysis.paymentOptions.dealer`, {
        type: StringUtils.replaceToCapitalize(account?.organization ?? ''),
      }):
        return FinancingPaymentType.DEALER;
      default:
        return FinancingPaymentType.CLIENT;
    }
  };

  const clearForm = () => {
    methods.setValue('action', '');
    methods.setValue('comment', '');
    methods.setValue('payment', '');
  };

  return {
    isLoading,
    listPaymentType,
    signatureOptions,
    organizationData,
    antiFraudOptions,
    defaultOptionAntiFraud,
    methods,
    sendAnalysis,
    clearForm,
  };
};
export default useModalAnalyzingCredit;
