import styled from 'styled-components/macro';

export const StyledButton = styled.button`
  display: flex;
  align-items: center;
  column-gap: 8px;

  padding: 0;
  border: none;
  background: none;
  font-size: 16px;
  font-weight: 700;
  color: ${({ theme }) => theme.color.primaryColor};

  svg path {
    fill: ${({ theme }) => theme.color.primaryColor};
  }
`;
