import { ButtonHTMLAttributes } from 'react';
import '@fortawesome/fontawesome-svg-core/styles.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { StyledButton, StyledButtonProps } from 'components/custom-button/styles';
import { IconContainer } from './styles';

interface IProps extends ButtonHTMLAttributes<HTMLButtonElement>, StyledButtonProps {
  isLoading?: boolean;
}

const CustomLoadingButton = ({ height, width, isInvertColor, isLoading, freeContent, ...rest }: IProps) => {
  return (
    <StyledButton disabled={isLoading} isInvertColor={isInvertColor} height={height} width={width} freeContent={freeContent} {...rest}>
      {rest.children}
      <IconContainer>{isLoading && <FontAwesomeIcon icon={faSpinner} spin />}</IconContainer>
    </StyledButton>
  );
};

export default CustomLoadingButton;
