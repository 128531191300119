import styled from 'styled-components/macro';

interface GlobalProps {
  mobile?: boolean;
}

export const ContainerDesktop = styled.div<GlobalProps>`
  width: 100%;
  height: ${props => !props.mobile && '100%'};
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  padding: ${props => props.mobile && '40px 10px'};
  margin-top: ${props => !props.mobile && '100px'};

  .header {
    display: flex;

    img {
      margin-right: 10px;
    }
  }

  .img-container {
    margin: 20px 0;
  }
`;
export const StyledSideMenu = styled.div`
  width: 600px;
  max-width: 600px;
  height: 100vh;
  background: ${({ theme }) => theme.color.primaryColor};
  min-width: 400px;
  padding: 40px 40px;

  .second-img {
    transform: rotate(-90deg);
    margin-top: 50%;
  }
`;

export const Logo = styled.div`
  width: 150px;
  height: 28px;
  border-radius: 5px;
  margin-bottom: 50px;
  img {
    max-height: 58px;
  }
`;
export const TitleDesktop = styled.span<GlobalProps>`
  font-style: normal;
  font-weight: 700;
  font-size: ${props => (props.mobile ? '20px' : '32px')};
  line-height: 150%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #000000;
`;
export const SubTitleDesktop = styled.span<GlobalProps>`
  font-style: normal;
  font-weight: 500;
  font-size: ${props => (props.mobile ? '14px' : '16px')};
  line-height: 150%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #656666;
  max-width: 600px;
  margin-bottom: 10px;
`;
export const Button = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  gap: 8px;
  width: 160px;
  height: 48px;
  background: ${({ theme }) => theme.color.primaryColor};
  border: 1px solid ${({ theme }) => theme.color.primaryColor};
  border-radius: 24px;
  margin-top: 20px;

  span {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    display: flex;
    align-items: center;
    text-align: center;
    color: #ffffff;
  }
`;

export const AttentionContainer = styled.div<GlobalProps>`
  width: ${props => (props.mobile ? '100%' : '640px')};
  height: 100px;
  background: #fff4f2;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${props => (props.mobile ? '0px 16px' : '0px 90px')};
  margin: 15px 0px;

  span {
    font-family: 'Satoshi';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    display: flex;
    align-items: center;
    color: #333333;
    margin-left: ${props => props.mobile && '10px'};
  }
`;
