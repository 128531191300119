import { SliderThumb } from '@mui/material/Slider';
import { GeneralImages } from 'images/general-images';
import { Arrows } from './styled';

interface SliderIconProps {}

const SliderIcon: React.FC<SliderIconProps> = ({ children, ...other }) => {
  return (
    <SliderThumb {...other}>
      {children}
      <Arrows src={GeneralImages.sliderIcon} />
    </SliderThumb>
  );
};

export default SliderIcon;
