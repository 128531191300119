import BlueCustomButtonNoBackground from 'components/blue-custom-button-no-background';
import i18n from 'config/i18n';
import { ReactComponent as AuthenticationIconSvg } from 'images/authentication-icon.svg';
import { ReactComponent as BiometricsIconSvg } from 'images/biometrics-icon.svg';
import { ReactComponent as CardSvg } from 'images/blue-card.svg';
import { ReactComponent as ContractSvg } from 'images/blue-contract.svg';
import { ReactComponent as FinancingAnalysisIconSvg } from 'images/blue-financing-analysis.svg';
import { ReactComponent as FinancingSimulationSvg } from 'images/blue-financing-simulation.svg';
import { ReactComponent as FraudAnalysisSvg } from 'images/blue-fraud-analysis.svg';
import { ReactComponent as GuarantorsSvg } from 'images/blue-guarantors.svg';
import { ReactComponent as PaymentIconSvg } from 'images/blue-payment.svg';
import { ReactComponent as PlusSvg } from 'images/blue-plus.svg';
import { ReactComponent as InfoSvg } from 'images/gray-info.svg';
import { Organization } from 'model/organization';
import { BankAccountType } from 'model/enums/bank-account-type';
import { BiometryStatus } from 'model/enums/biometric-status';
import { ContractStatus } from 'model/enums/contract-status';
import { ICardData } from 'model/enums/dashboard';
import FinancingStatusType from 'model/enums/financing-status-type';
import FinancingPaymentType from 'model/enums/financingPaymentType';
import { InstallmentStatus } from 'model/enums/installment-status';
import SystemStepCategory from 'model/enums/system-step-category';
import TypeVerifyIdentity from 'model/enums/type-verify-identity';
import { BankAccountInFinancing, ContractType, PaymentType, StatusAuthenticationAndBiometrics } from 'model/financing';
import { Guarantor } from 'model/guarantor';
import TypeDashbordCard from 'model/type-dashboard-card';
import { Dispatch, SetStateAction } from 'react';
import statusListCannotEditData from 'shared/util/dashboard-status-not-allow-change';
import { StyledButtonAddGuarantorContainer, StyledChevronRightContainer, StyledGuarantorsContainer, StyledList } from './styles';

interface Props {
  step: SystemStepCategory;
  financingStatus: FinancingStatusType;
  isFundingAlreadyConfirmed: boolean;
  allGuarantorsInFinancing: Guarantor[];
  setIsShowModalAddGuarantor: Dispatch<SetStateAction<boolean>>;
  bankAccountInFinancing?: BankAccountInFinancing;
  setIsShowModalSelectBankAccount: Dispatch<SetStateAction<boolean>>;
  financingContracts?: ContractType;
  financingPayments?: PaymentType;
  statusAuthenticationAndBiometrics?: StatusAuthenticationAndBiometrics;
  previousStep?: ICardData;
  organization?: Organization;
  verifyIdentity?: TypeVerifyIdentity;
}

const StepsController = ({
  step,
  financingStatus,
  isFundingAlreadyConfirmed,
  allGuarantorsInFinancing,
  setIsShowModalAddGuarantor,
  bankAccountInFinancing,
  setIsShowModalSelectBankAccount,
  financingContracts,
  financingPayments,
  statusAuthenticationAndBiometrics,
  previousStep,
  organization,
  verifyIdentity,
}: Props): ICardData | null => {
  const card: TypeDashbordCard = {
    SIMULATOR: {
      key: step,
      stepCategory: step,
      icon: <FinancingSimulationSvg />,
      title: i18n.t('dashboard.creditConfirmation'),
      content:
        ((previousStep?.isDataFilled && previousStep?.isActive) || previousStep == null) &&
        !statusListCannotEditData.includes(financingStatus) ? (
          isFundingAlreadyConfirmed || financingStatus === FinancingStatusType.FINISHED ? (
            <span>{i18n.t('dashboard.completedCreditConfirmation')}</span>
          ) : (
            <span>{i18n.t('dashboard.runSimulation')}</span>
          )
        ) : (
          <span>{i18n.t('dashboard.youCannotConfirmCredit')}</span>
        ),
      isActive:
        ((previousStep?.isDataFilled && previousStep?.isActive) || previousStep == null) &&
        !statusListCannotEditData.includes(financingStatus),
      isDataFilled: isFundingAlreadyConfirmed || financingStatus === FinancingStatusType.FINISHED,
    },
    GUARANTORS: {
      key: step,
      stepCategory: step,
      icon: <GuarantorsSvg />,
      title: i18n.t('dashboard.myGuarantors'),
      content:
        allGuarantorsInFinancing?.length > 0 ? (
          <StyledGuarantorsContainer>
            <StyledList>
              {allGuarantorsInFinancing.map(guarantor => (
                <li key={`guarantor-${guarantor.id}`}>{guarantor.name}</li>
              ))}
            </StyledList>
          </StyledGuarantorsContainer>
        ) : (previousStep?.isDataFilled && previousStep?.isActive) || previousStep == null ? (
          <StyledButtonAddGuarantorContainer>
            <span>{i18n.t('dashboard.youDidHaveGuarantors')}</span>
            <BlueCustomButtonNoBackground style={{ marginTop: '-45px' }} onClick={() => setIsShowModalAddGuarantor(true)}>
              <PlusSvg />
              {i18n.t('global.button.add')}
            </BlueCustomButtonNoBackground>
          </StyledButtonAddGuarantorContainer>
        ) : (
          <span>{i18n.t('dashboard.youCannotAddGuarantors')}</span>
        ),
      isActive:
        (previousStep?.isDataFilled && previousStep?.isActive) ||
        (previousStep == null && !statusListCannotEditData.includes(financingStatus)),
      isDataFilled: allGuarantorsInFinancing?.length > 0,
    },
    BANK_ACCOUNT_DATA: {
      key: step,
      stepCategory: step,
      icon: <CardSvg />,
      title: i18n.t('dashboard.bankData'),
      content:
        previousStep == null || (previousStep?.isDataFilled && previousStep?.isActive) ? (
          <StyledChevronRightContainer>
            {bankAccountInFinancing?.financingPaymentType === FinancingPaymentType.ORGANIZATION ? (
              <span>{i18n.t('dashboard.paymentOrganization', { organizationName: organization?.name })}</span>
            ) : bankAccountInFinancing?.bankAccount == null ? (
              <>
                <span>{i18n.t('dashboard.addYourBankDetails')}</span>
                <BlueCustomButtonNoBackground
                  style={{ position: 'relative', top: '-18px', right: '-16%' }}
                  onClick={() => setIsShowModalSelectBankAccount(true)}
                >
                  <PlusSvg />
                  {i18n.t('global.button.add')}
                </BlueCustomButtonNoBackground>
              </>
            ) : (
              <div>{`${bankAccountInFinancing.bankAccount.type ? BankAccountType[bankAccountInFinancing.bankAccount.type] : ''} ${
                bankAccountInFinancing.bankAccount.bankAccountNumber
              }-${bankAccountInFinancing.bankAccount.bankAccountDigit}`}</div>
            )}
          </StyledChevronRightContainer>
        ) : (
          <span>{i18n.t('dashboard.youStillAddYourData')}</span>
        ),
      isActive:
        (previousStep?.isDataFilled && previousStep?.isActive) ||
        (previousStep == null && !statusListCannotEditData.includes(financingStatus)),
      isDataFilled:
        bankAccountInFinancing?.bankAccount?.id != null ||
        bankAccountInFinancing?.financingPaymentType === FinancingPaymentType.ORGANIZATION,
    },
    CONTRACT: {
      key: step,
      stepCategory: step,
      icon: <ContractSvg />,
      title: i18n.t('dashboard.contract'),
      content:
        financingContracts?.contracts == null ||
        financingContracts?.contracts?.map(contract => contract.status).includes(ContractStatus.NEW) ? (
          <span>{i18n.t('dashboard.noContractAvailable')}</span>
        ) : (
          <div>
            {financingContracts?.contracts?.map(contract => contract.status).includes(ContractStatus.FINISHED)
              ? i18n.t('dashboard.completedContract')
              : i18n.t('dashboard.contractAvailable')}
          </div>
        ),
      isActive:
        financingContracts?.contracts?.map(contract => contract.status).includes(ContractStatus.FINISHED) ||
        (previousStep?.isDataFilled && previousStep?.isActive) ||
        (previousStep == null && !statusListCannotEditData.includes(financingStatus) && isFundingAlreadyConfirmed),
      isDataFilled: financingContracts?.contracts?.map(contract => contract.status).includes(ContractStatus.FINISHED) ?? false,
      isShowArrow: financingContracts?.contracts?.[0].id != null,
      redirectPathClickArrow: financingContracts?.contracts?.[0].id != null ? `/meus-contratos/${financingContracts.contracts[0].id}` : '',
    },
    PAYMENT: {
      key: step,
      stepCategory: step,
      icon: <PaymentIconSvg />,
      title: i18n.t('dashboard.payment'),
      content: (
        <span>
          {financingPayments?.installments == null || financingPayments?.payment?.status === FinancingStatusType.NEW
            ? i18n.t('dashboard.noInstallments')
            : financingPayments?.payment?.status === FinancingStatusType.FINISHED
            ? i18n.t('dashboard.congratulationsPaymentCompleted')
            : financingPayments?.installments[0]?.status === InstallmentStatus.PENDING
            ? i18n.t('dashboard.firstInstallmentAvailable')
            : i18n.t('dashboard.installmentsAvailableForPayment')}
        </span>
      ),
      isActive:
        (previousStep?.isDataFilled && previousStep?.isActive) ||
        (previousStep == null &&
          !statusListCannotEditData.includes(financingStatus) &&
          (isFundingAlreadyConfirmed || financingStatus === FinancingStatusType.FINISHED)),
      isDataFilled: financingPayments?.payment?.status === FinancingStatusType.FINISHED,
      isShowArrow: financingPayments?.id != null,
      redirectPathClickArrow: financingPayments?.id != null ? `/meus-pagamentos/${financingPayments?.id}` : '',
    },
    FRAUD_ANALYSIS: {
      key: step,
      stepCategory: step,
      icon: <FraudAnalysisSvg />,
      title: i18n.t('dashboard.fraudAnalysis'),
      content: <span>{i18n.t('global.errorMessage.undefined')}</span>,
      isActive:
        (previousStep?.isDataFilled && previousStep?.isActive) ||
        (previousStep == null && !statusListCannotEditData.includes(financingStatus)),
      isDataFilled: false,
    },
    DOC_FRAUD_ANALYSIS: {
      key: step,
      stepCategory: step,
      icon: <InfoSvg />,
      title: step,
      content: <span>{i18n.t('global.errorMessage.undefined')}</span>,
      isActive:
        (previousStep?.isDataFilled && previousStep?.isActive) ||
        (previousStep == null && !statusListCannotEditData.includes(financingStatus)),
      isDataFilled: false,
    },
    FINANCING_ANALYSIS: {
      key: step,
      stepCategory: step,
      icon: <FinancingAnalysisIconSvg />,
      title: i18n.t('dashboard.creditAnalysis'),
      content: (
        <span>
          {financingStatus === FinancingStatusType.APPROVED ||
          financingStatus === FinancingStatusType.FINISHED ||
          financingStatus === FinancingStatusType.IN_PROGRESS ||
          financingStatus === FinancingStatusType.PENDING_PAYMENT ||
          financingStatus === FinancingStatusType.PENDING_SIGN
            ? i18n.t('dashboard.completedCreditAnalysis')
            : i18n.t('dashboard.waitCreditAnalysis')}
        </span>
      ),
      isActive:
        (previousStep?.isDataFilled && previousStep?.isActive) ||
        (previousStep == null && !statusListCannotEditData.includes(financingStatus)),
      isDataFilled:
        financingStatus === FinancingStatusType.APPROVED ||
        financingStatus === FinancingStatusType.FINISHED ||
        financingStatus === FinancingStatusType.IN_PROGRESS ||
        financingStatus === FinancingStatusType.PENDING_PAYMENT ||
        financingStatus === FinancingStatusType.PENDING_SIGN,
    },
    FRAUD_ANALYSIS_2FA: {
      key: step,
      stepCategory: step,
      icon: <AuthenticationIconSvg />,
      title: i18n.t('dashboard.authentication'),
      content: (
        <span>
          {verifyIdentity === TypeVerifyIdentity.IS_NOT_NECESSARY
            ? i18n.t('dashboard.authenticationNotRequired')
            : statusAuthenticationAndBiometrics?.twoFactorAuth?.[0]?.verified
            ? i18n.t('dashboard.completedAuthentication')
            : i18n.t('dashboard.validationToken')}
        </span>
      ),
      isActive:
        (previousStep?.isDataFilled && previousStep?.isActive) ||
        (previousStep == null && !statusListCannotEditData.includes(financingStatus)),
      isDataFilled:
        statusAuthenticationAndBiometrics?.twoFactorAuth?.[0]?.verified || verifyIdentity === TypeVerifyIdentity.IS_NOT_NECESSARY,
    },
    FRAUD_ANALYSIS_BIOMETRY: {
      key: step,
      stepCategory: step,
      icon: <BiometricsIconSvg />,
      title: i18n.t('dashboard.biometrics'),
      content: (
        <span>
          {verifyIdentity === TypeVerifyIdentity.IS_NOT_NECESSARY
            ? i18n.t('dashboard.biometricsNotRequired')
            : statusAuthenticationAndBiometrics?.biometricsStatus?.status === BiometryStatus.DONE &&
              statusAuthenticationAndBiometrics?.biometricsStatus.score != null
            ? i18n.t('dashboard.completedBiometrics')
            : i18n.t('dashboard.customerBiometrics')}
        </span>
      ),
      isActive:
        (previousStep?.isDataFilled && previousStep?.isActive) ||
        (previousStep == null && !statusListCannotEditData.includes(financingStatus)),
      isDataFilled:
        (statusAuthenticationAndBiometrics?.biometricsStatus?.status === BiometryStatus.DONE &&
          statusAuthenticationAndBiometrics?.biometricsStatus.score != null) ||
        verifyIdentity === TypeVerifyIdentity.IS_NOT_NECESSARY,
    },
  };

  return card[step] ?? null;
};

export default StepsController;
