import styled from 'styled-components/macro';

interface Props {
  isSelected?: boolean;
}

export const PageContainer = styled.div`
  background-color: ${({ theme }) => theme.color.grayBackground};
  height: 100vh;
  overflow: auto;
`;

export const TitleHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  margin-top: 48px;
  max-width: 70%;
`;

export const Title = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 150%;

  > span {
    display: inline-block;
    font-weight: 700;
    margin-left: 16px;
  }

  > button {
    background-color: transparent;
    border: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;

export const Container = styled.div`
  background-color: ${({ theme }) => theme.color.white};
  padding: 16px 24px 24px 24px;
  border: 1px solid ${({ theme }) => theme.color.borderGray};
  box-shadow: 0px 2px 12px ${({ theme }) => theme.color.shadow};
  border-radius: 8px;
  max-width: 70%;
  margin: 0 auto;
  margin-top: 32px;
`;

export const DocIconBox = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.color.brightnessPrimary};

  display: flex;
  align-items: center;
  justify-content: center;

  margin-right: 6px;
`;

export const FileHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;

  & > div.textsBox {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
  }
`;

export const FileTitle = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 24px;

  > span.fileName {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    color: ${({ theme }) => theme.color.black0};

    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 700px;

    @media only screen and (max-width: 1400px) {
      max-width: 500px;
    }

    @media only screen and (max-width: 1290px) {
      max-width: 300px;
    }

    @media only screen and (max-width: 1000px) {
      max-width: 150px;
    }
  }

  > span.date {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    color: ${({ theme }) => theme.color.gray19};
  }
`;

interface TabBoxProps {
  isMobile?: boolean;
}
export const TabBox = styled.div<TabBoxProps>`
  background-color: ${({ theme }) => theme.color.grayBackground};
  padding: 4px;
  border-radius: 8px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  margin: ${props => (props.isMobile ? '16px 0px' : '32px 0px')};
`;

export const Tab = styled.button<Props>`
  background-color: ${({ theme, isSelected }) => (isSelected ? theme.color.white : 'transparent')};
  border: ${({ theme, isSelected }) => (isSelected ? `1px solid ${theme.color.gray20}` : 'none')};
  box-shadow: ${({ theme, isSelected }) => (isSelected ? `0px 2px 12px ${theme.color.shadow}` : 'none')};
  border-radius: 6px;
  padding: 8px;

  flex: 1;

  > span {
    font-style: normal;
    font-weight: ${({ isSelected }) => (isSelected ? '700' : '400')};
    font-size: 16px;
    line-height: 150%;
    color: ${({ theme, isSelected }) => (isSelected ? theme.color.primaryColor : theme.color.gray18)};
  }
`;

export const TabContent = styled.div``;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  & > button {
    border: none;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 8px;

    > span {
      margin-left: 8px;

      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 150%;
      color: ${({ theme }) => theme.color.primaryColor};
    }
  }
`;
