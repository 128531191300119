import Popup from 'reactjs-popup';
import styled from 'styled-components/macro';

export const StyledPopup = styled(Popup)`
  &-overlay {
    background: rgba(0, 0, 0, 0.5);
  }
  &-content {
    background: #fff;
    border-radius: 10px;
    padding: 32px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const StyledPopupTitle = styled.div`
  font-weight: 700;
  font-size: 24px;
  color: #1b1a1b;
`;
export const StyledPopupDescription = styled.div`
  font-weight: 400;
  font-size: 16px;
  color: #656666;
`;
