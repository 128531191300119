import styled from 'styled-components/macro';

export const Alignor = styled.div`
  display: flex;
`;

export const StepsContainer = styled.div`
  width: 100%;
  margin-top: 24px;
  background: #fafafa;
  border-radius: 8px;
  padding: 4px;
  display: flex;
  flex-wrap: wrap;
`;

export const PartnerItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px 16px 24px;
  margin: 0px 0px 10px 0px;
  gap: 10px;
  height: 80px;
  width: 100%;
  border: 1.5px solid #e5e7e7;
  border-radius: 8px;
  background-color: #fff;
  .title {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    color: #333;
  }
  .subtitle {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #7f7f7f;
  }
`;

export const StyledSubtitle = styled.div`
  width: 100%;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
`;
