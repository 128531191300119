import styled from 'styled-components/macro';

interface Props {
  hasError?: boolean;
  isActive?: boolean;
  borderRadius?: boolean;
  borderRight?: boolean;
}

export const StyledInputBox = styled.div<Props>`
  width: 100%;
  height: 64px;
  background: #fff;
  position: relative;

  display: flex;

  overflow: hidden;

  @media (max-width: 671px) {
    width: 272px;
  }
`;

export const StyledImg = styled.img`
  width: 20px;
  margin: 20px 0px 0px 0px;
  cursor: pointer;
`;

export const LabelInputBox = styled.div<Props>`
  width: 100%;
  height: 46px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  position: relative;
  border-bottom: 1px solid #525252;

  &:focus-within label {
    height: 16px;
    transform: translate(0, -16px) scale(1);
    font-size: 12px;
    margin: 8px 0px 0px 0px;
  }

  @media (max-width: 671px) {
    width: 272px;
  }
`;

export const StyledInput = styled.input<Props>`
  width: 158px;
  height: 20px;
  border: none;

  font-weight: 400;
  font-size: 12px;
  color: ${({ hasError }) => (hasError ? '#FF8389' : '#525252')};

  background: none;
  outline: none;

  margin: 20px 0px 0px 0px;

  padding: 0px;
  z-index: 2;

  transition: all 0.2s ease-out;
  cursor: ${({ disabled }) => (disabled ? 'pointer' : '')};

  @media (max-width: 671px) {
    width: 248px;
  }
`;

export const StyledError = styled.div`
  width: 188px;
  position: absolute;

  font-weight: 400;
  font-size: 12px;
  color: #ffb3b8;

  margin: 46px 0px 0px 0px;

  @media (max-width: 671px) {
    width: 272px;
  }
`;

export const StyledLabel = styled.label<Props>`
  width: 158px;

  height: ${({ isActive }) => (isActive ? '15px' : '20px')};

  font-weight: 400;
  font-size: ${({ isActive }) => (isActive ? '12px' : '14px')};
  color: #8d8d8d;
  position: absolute;

  margin: ${({ isActive }) => (isActive ? '8px 0px 0px 0px' : '20px 0px 0px 0px')};

  display: flex;
  align-items: center;

  transform: ${({ isActive }) => (isActive ? 'translate(0, -16px) scale(1) ' : 'translate(0px, 0px) scale(1)')};

  transition: all 0.2s ease-out;
`;
