import styled from 'styled-components/macro';

interface InputContainerProps {
  isMobile?: boolean;
  error?: boolean;
}
export const InputContainer = styled.div<InputContainerProps>`
  margin-bottom: 0.625rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: ${props => (props.isMobile ? '100%' : '306px')};
  max-width: 100%;

  span {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    color: ${props => (props.error ? '#FC4F4F' : '#656666')};
    margin-bottom: 5px;
  }

  span + span {
    margin-left: 0.125rem;
    color: #f0654a;
  }

  @media (max-width: 675px) {
    min-width: 256px;
    max-width: 256px;
    width: 256px;
  }
`;
