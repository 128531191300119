import styled from 'styled-components/macro';

export const Container = styled.div`
  display: flex;
  justify-content: center;

  max-height: 500px;

  overflow: hidden;
  .react-pdf__Document {
    max-width: 100% !important;
    overflow: scroll;
  }

  div.react-pdf__Page__textContent {
    display: none;
  }

  div.react-pdf__Page__annotations {
    height: 0px !important;
  }
`;
