import styled from 'styled-components/macro';

interface Props {
  calcWidth?: string;
}

export const StyledDropdownBox = styled.div<Props>`
  width: ${({ calcWidth }) => `calc(200px + ${calcWidth ?? '0px'})`};
  height: 68px;
  background: #fff;
  row-gap: 4px;
  display: flex;
  flex-direction: column;

  position: relative;
`;

export const Arrow = styled.div`
  width: 40px;
  height: 44px;

  position: absolute;
  top: 24px;
  right: 0px;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 24px;

  svg {
    fill: #656666;
  }
`;

export const List = styled.div`
  width: 100%;
  height: 114px;
  position: absolute;
  z-index: 99;
  background: ${({ theme }) => theme.color.grayBackground};

  margin-top: 72px;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 8px; /* width of the entire scrollbar */
  }

  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.color.borderGray};
  }

  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.color.gray18};
  }
`;

export const Item = styled.div`
  width: 100%;
  min-height: 20px;
  padding: 8px 12px;
  cursor: pointer;
  font-weight: 400;
  font-size: 14px;
  :hover {
    background: ${({ theme }) => theme.color.borderGray};
  }
`;
