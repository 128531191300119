import styled from 'styled-components/macro';

export const DropDownFilter = styled.div`
  background-color: ${({ theme }) => theme.color.white};
  padding: 16px 16px;
  border: 1px solid ${({ theme }) => theme.color.gray20};
  border-radius: 8px;

  width: max-content;
  margin-top: 4px;
`;

export const FilterBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  border-bottom: 1px solid ${({ theme }) => theme.color.gray20};

  padding-bottom: 16px;

  > span.status {
    margin-top: 16px;
  }
`;

export const SectionTitle = styled.span`
  font-style: normal;
  font-family: Satoshi;
  font-weight: 700;
  font-size: 12px;
  line-height: 150%;
  color: ${({ theme }) => theme.color.gray18};

  &.customStyle {
    margin-bottom: 16px;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;

  button {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 150%;
    background-color: transparent;
    border: none;
  }

  > button.clean {
    color: ${({ theme }) => theme.color.rejected};
  }

  > button.apply {
    color: ${({ theme }) => theme.color.primaryColor};
  }
`;

export const PaintSvg = styled.span`
  display: flex;

  svg path {
    fill: ${({ theme }) => theme.color.primaryColor};
  }
`;

export const SearchContainer = styled.div`
  margin-bottom: 16px;
`;
