import styled, { css } from 'styled-components';

export const verifyIfIos = ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform);
interface Props {
  error?: boolean;
  isFocused?: boolean;
  activated?: boolean;
  width?: number;
  flexWidth?: boolean;
}

export const Container = styled.div`
  text-align: start;
  margin-bottom: 10px;
`;

export const InputContainer = styled.div<Props>`
  min-width: 270px;
  max-width: 100%;
  margin-top: 2px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 12px;
  gap: 8px;
  height: 44px;
  border: ${props => (props.error ? '2px solid #FC4F4F' : props.activated ? props.theme.color.primaryColor : '1px solid #999999')};
  border-radius: 8px;
  ${({ flexWidth }) =>
    !flexWidth &&
    css`
      min-width: 280px;
    `};

  @media (max-width: 675px) {
    min-width: 256px;
    max-width: 256px;
    width: 256px;
  }
`;

export const Label = styled.label<Props>`
  max-width: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  color: #7f7f7f;
  text-align: start;
  ${({ theme, activated }) =>
    activated &&
    css`
      background-color: ${theme.color.grayBackground};
    `}

  position: relative;
`;

export const Title = styled.span<Props>`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: ${props => (props.error ? '#FC4F4F' : '#656666')};
  margin-bottom: 5px;
`;

export const Required = styled.span`
  margin-left: 0.125rem;
  color: #f0654a;
`;
export const Blank = styled.span`
  color: transparent;
`;

export const Input = styled.input<Props>`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: ${props => (props.disabled && !verifyIfIos ? '#999999' : '#333333')};
  border: none;
  width: 100%;
  ::placeholder,
  ::-webkit-input-placeholder {
    color: #999999;
  }
  :-ms-input-placeholder {
    color: #999999;
  }
`;
export const EyeButton = styled.img`
  cursor: pointer;
`;

export const ErrorMessageBox = styled.div`
  /* position: absolute; */
  display: flex;
  align-items: center;
  margin-top: 5px;
`;

export const Spin = styled.div`
  width: 21px;
  height: 20px;
  background: transparent;
  border-radius: 50%;
  animation: spin 2s infinite linear;
  border: 2px solid ${({ theme }) => theme.color.primaryColor};
  border-top: 2px solid #fff;

  @keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
`;
