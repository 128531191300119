import { MutableRefObject, useEffect, useRef, useState } from 'react';
import 'react-calendar/dist/Calendar.css';
import { useTranslation } from 'react-i18next';
import Popup from 'reactjs-popup';
import { FilterItem } from '../title-header-new/styles';
import { ButtonsContainer, CalendarInputsContainer, Container, CustomRadioOption, DropDownFilter, FilterBox, SectionTitle } from './styles';
import { PopupActions } from 'reactjs-popup/dist/types';
import { OrderLogoIcon } from 'images/order-logo';
import { RadioFillIcon } from 'images/radio-fill';
import { ReactComponent as RadioEmptyIcon } from 'images/radio-empty.svg';

interface FilterButtonProps {
  labelTitle?: string;
  labelDefaultOption?: string;
  labelOption?: string;
  orderBy?: boolean;
  setOrderBy?: (value: boolean) => void;
}

export const OrderFilterButton = ({ labelTitle, labelDefaultOption, labelOption, orderBy, setOrderBy }: FilterButtonProps) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const ref: MutableRefObject<PopupActions | null> = useRef(null);

  const closeModal = () => setIsOpen(false);

  return (
    <Popup
      ref={ref}
      open={isOpen}
      closeOnDocumentClick
      onClose={closeModal}
      trigger={
        <FilterItem onClick={() => setIsOpen(!isOpen)}>
          <OrderLogoIcon isWhite={false} />
          <span>{t('admin.dashboard.orderBy')}</span>
        </FilterItem>
      }
      position="bottom center"
      on="click"
    >
      <DropDownFilter>
        <SectionTitle className="status">{labelTitle ? labelTitle : t('admin.dashboard.creationDate')}</SectionTitle>
        <CustomRadioOption
          onClick={() => {
            if (setOrderBy) {
              setOrderBy(false);
            }
          }}
        >
          {!orderBy ? <RadioFillIcon isWhite={false} /> : <RadioEmptyIcon />}
          <span>{labelDefaultOption ? labelDefaultOption : t('admin.dashboard.moreRecent')}</span>
        </CustomRadioOption>
        <CustomRadioOption
          onClick={() => {
            if (setOrderBy) {
              setOrderBy(true);
            }
          }}
        >
          {orderBy ? <RadioFillIcon isWhite={false} /> : <RadioEmptyIcon />}
          <span>{labelOption ? labelOption : t('admin.dashboard.moreOld')}</span>
        </CustomRadioOption>
      </DropDownFilter>
    </Popup>
  );
};
