import ButtonVariants from 'model/enums/buttonVariants';
import { ScreenDimensions } from 'model/enums/screen-dimensions-types';
import styled from 'styled-components/macro';

export const SimulatorContainer = styled.div`
  width: 366px;
  min-height: 350px;
  display: flex;
  flex-direction: column;

  border-radius: 30px;
  box-shadow: 0px 4px 8px rgba(27, 25, 25, 0.08);
  color: ${({ theme }) => theme.color.white};

  padding: 28px 40px;

  z-index: 10;
  background: #fff;
  margin-top: auto;
  margin-bottom: auto;
  @media (max-width: 1055px) {
    height: auto;
  }

  @media (max-width: 671px) {
    width: 296px;
    padding: 32px 16px;

    height: auto;
  }
  @media (min-width: 1200px) {
    margin-right: 95px;
  }
`;

export const StyledFoundedData = styled(SimulatorContainer)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const SimulatorTitle = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  color: #333;
  text-align: center;
  margin-bottom: 24px;
`;

export const FoundDataTitle = styled(SimulatorTitle)`
  margin: 48px 0px 0px;
  text-align: left;
`;

export const SimulatorText = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.color.gray24};
  text-align: center;
`;

export const FoundDataText = styled(SimulatorText)`
  margin: 16px 0px 0px;
  text-align: left;
`;

export const SliderContainer = styled.div`
  display: flex;
  flex-direction: column;

  margin: 24px 0px 0px;
  height: auto;
`;

export const SliderValue = styled.input`
  height: 38px;
  padding: 0px;
  color: #333;
  text-align: center;
  font-weight: 500;
  font-size: 40px;
  border: none;
  outline: none;

  @media (max-width: ${ScreenDimensions.MID_TABLET}) {
    font-size: 32px;
  }
`;

export const SliderMinValue = styled.div`
  height: 100%;

  font-weight: 500;
  font-size: 16px;
  color: ${({ theme }) => theme.color.black0};
  margin: 0px 4px 0px 0px;

  display: flex;
  align-items: flex-end;
`;

interface SliderBoxTagProps {
  hasMin?: boolean | undefined;
}

export const SliderBoxTag = styled.div<SliderBoxTagProps>`
  width: 100%;
  /* height: 40px; */
  margin: 0px;
  display: flex;
  flex-wrap: wrap;

  align-items: center;
  justify-content: ${({ hasMin }) => (!hasMin ? 'start' : 'space-around')};
  column-gap: ${({ hasMin }) => (!hasMin ? '24px' : '0px')};

  row-gap: 16px;
`;

interface ButtonStyledType {
  isActive?: boolean;
}
interface CustomTag {
  customStyle: ButtonStyledType;
}

export const SliderTag = styled.div<CustomTag>`
  width: 74px;
  height: 40px;
  background: ${({ theme, customStyle }) => (!customStyle.isActive ? theme.color.white : theme.color.primaryColor)};

  border: ${({ theme }) => `1px solid ${theme.color.primaryColor}`};
  border-radius: 10px;

  font-weight: 700;
  font-size: 14px;
  line-height: 21px;

  color: ${({ theme, customStyle }) => (!customStyle.isActive ? theme.color.primaryColor : theme.color.white)};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const PaginationBox = styled.div`
  width: 100%;
  height: 12px;
  display: flex;
  align-items: center;
  justify-content: center;

  margin: 24px 0px 0px;

  @media (max-width: 671px) {
    width: 272px;
    margin: 24px 0px 0px;
  }
`;

interface ButtonStyledType {
  width?: string;
  variant?: string;
}
interface CustomButton {
  customStyle: ButtonStyledType;
}

export const SliderButton = styled.button<CustomButton>`
  width: ${({ customStyle }) => (customStyle.width ? customStyle.width : '390px')};
  height: 48px;

  margin: 16px 0px 0px;
  border: none;
  border-radius: 24px;
  padding: 0px;

  &:disabled {
    background-color: rgba(0, 0, 0, 0.5);
  }

  ${({ theme, customStyle }) => {
    switch (customStyle.variant) {
      case ButtonVariants.DEFAULT:
        return `
          background: ${theme.color.lpColors.simulatorButton};
          color: #fff`;
      case ButtonVariants.CHANGED:
        return `background: ${theme.color.lpColors.color2};
        color: ${theme.color.lpColors.whatIsScreenTextColor};`;
      case ButtonVariants.CANCEL:
        return `background: ${theme.color.rejected};
            color: ${theme.color.lpColors.whatIsScreenTextColor};`;
      case ButtonVariants.DISABLED:
        return `background: rgba(0, 0, 0, 0.5);
                  color: ${theme.color.lpColors.simulatorButton};`;
      default:
        return ``;
    }
  }}

  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  font-weight: 700;
  font-size: 16px;
  line-height: 24px;

  color: white;

  &:active {
    ${({ theme, customStyle }) => {
      switch (customStyle.variant) {
        case ButtonVariants.DEFAULT:
          return `background:  #fff;
                  border: 1px solid ${theme.color.lpColors.simulatorButton};
                  color: ${theme.color.lpColors.simulatorButton};`;
        case ButtonVariants.CHANGED:
          return `background: ${theme.color.lpColors.color2};
                  color: ${theme.color.lpColors.whatIsScreenTextColor};`;
        case ButtonVariants.CANCEL:
          return `background: ${theme.color.canceled};
                      color: ${theme.color.lpColors.whatIsScreenTextColor};`;
        case ButtonVariants.DISABLED:
          return `background: rgba(0, 0, 0, 0.5);
                            color: ${theme.color.lpColors.whatIsScreenTextColor};`;

        default:
          return ``;
      }
    }}
  }

  @media (max-width: 671px) {
    width: 272px;
  }
`;

export const StyledForm = styled.form`
  background: #ffffff;
  row-gap: 32px;

  @media (max-width: 1055px) {
    height: auto;
    border-radius: 0px 0px 10px 10px;
  }
  @media (max-width: 671px) {
    width: 272px;
    height: auto;
    display: flex;
    grid-gap: 0px;
    row-gap: 0px;
    margin: 24px 0px 0px;

    flex-direction: column;
    align-items: center;
  }
`;
export const Terms = styled.div`
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  color: ${({ theme }) => theme.color.gray24};
  text-align: center;
  margin: 16px 0px;

  color: var(--gray-dark-6, #999);
  text-align: center;
  font-family: Satoshi;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;

  a {
    color: ${({ theme }) => theme.color.primaryColor};
    font-family: Satoshi;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    text-decoration-line: underline;
  }

  a:hover {
    :hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
`;

export const ButtonBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;

  margin: 64px 0px 0px;

  @media (max-width: ${ScreenDimensions.MOBILE}) {
    width: 320px;
    flex-direction: column;
    align-items: center;
  }
`;

export const BoxImage = styled.div`
  margin: 32px 0px 0px;
`;

export const BoxSvg = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 24px 0px 0px;
`;

export const StyledSubtitle = styled.div`
  color: var(--gray-dark-2, #333);
  text-align: center;
  font-family: Satoshi;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
`;

interface Props {
  customStyle?: any;
  isActive?: boolean;
}

export const DesiredValueButtons = styled.button<Props>`
  width: 24px;
  height: 24px;
  border-radius: 38px;
  border: 1px solid var(--gray-light-4, #eee);
  background: var(--gray-bw-white, #fff);
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.12);
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: none;
  padding: 0px;
  ${({ customStyle }) => customStyle?.backgroundImage && `background-image: url(${({ customStyle }) => customStyle?.backgroundImage});`}
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  svg {
    width: 20px;
    height: 20px;
    path {
      fill: ${({ isActive, theme }) => (isActive ? theme.color.primaryColor : theme.color.gray18)};
    }
  }
`;

export const DesiredValueContainer = styled.div`
  align-items: center;
  width: 100%;
  min-height: 52px;
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
  @media (max-width: 671px) {
    width: 240px;
    height: auto;
  }
`;

export const DesiredTime = styled.input`
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #333;
  text-align: center;
  border: 0px;
  @media (max-width: 671px) {
    width: 192px;
    height: auto;
  }
`;

export const StyledPreSimulatorButton = styled.div`
  border-radius: 24px;
  display: flex;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  cursor: pointer;
  width: 100%;
  margin-top: 16px;
  color: #fff;
  ${({ theme }) => {
    return `
          background: ${theme.color.lpColors.simulatorButton}; `;
  }}
`;

export const StyledFirstValueContainer = styled.div`
  border-radius: 12px;
  border: 1px solid var(--gray-light-4, #eee);
  background: var(--gray-bw-white, #fff);
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  color: var(--gray-dark-4, #656666);
  text-align: center;
  font-family: Satoshi;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  display: flex;
  justify-content: center;
`;
