import ContainerDocumentMobile from 'components/container-document-mobile';
import ContainerUploadedDocument from 'components/container-uploaded-document';
import ContainerUploadedDocumentMobile from 'components/container-uploaded-document-mobile';
import ReactHookFormProvider from 'components/ReactHookFormProvider';
import { Button, ButtonsContainer } from 'components/register-forms-mobile/styles';
import { Attachment } from 'model/attachment';
import { AttachmentType } from 'model/enums/attachment-types';
import { useRegisterForm } from 'provider/register-form';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import StringUtils from 'shared/util/string-utils';
import * as yup from 'yup';
import { Container, IdentityContainer, ModalVerseContainer, Subtitle, Title } from './styles';

interface IIdentificationScreenProps {
  attachmentIdentification?: Attachment;
  actualStep: number;
  setActualStep: (step: number) => void;
  handleVerifyEmptyFields: (field: string) => boolean;
}

export const IdentificationScreen: React.FC<IIdentificationScreenProps> = props => {
  const { t } = useTranslation();
  const { files } = useRegisterForm();
  const [showModalVerse, setShowModalVerse] = useState<boolean>(false);

  const schema = yup.object({
    attachmentIdentification: yup.mixed(),
  });

  const [requiredError, setRequiredError] = useState<boolean>(false);

  useEffect(() => {
    if (files.find(file => file.type === AttachmentType.IDENTIFICATION_CARD)) {
      setRequiredError(false);
    }
    if (files.length == 1) {
      setShowModalVerse(true);
    } else {
      setShowModalVerse(false);
    }
  }, [files]);

  const handleSubmit = () => {
    if (!props.handleVerifyEmptyFields(AttachmentType.IDENTIFICATION_CARD)) {
      props.setActualStep(props.actualStep + 1);
    } else {
      setRequiredError(true);
    }
  };

  return (
    <>
      {showModalVerse && (
        <ModalVerseContainer>
          <span>{t('documents.identity.warning')}</span>
        </ModalVerseContainer>
      )}
      <ReactHookFormProvider schema={schema} onSubmit={() => handleSubmit()}>
        <Container>
          <div style={{ margin: '0px 16px 8px' }}>
            <Title>{t('documents.identity.title')}</Title>
            <Subtitle>{t('documents.identity.subtitle')}</Subtitle>
          </div>
          <div style={{ padding: '35px 16px' }}>
            <ContainerDocumentMobile
              requiredError={requiredError}
              titleDocument="documents.identification"
              editDocument
              nameInput="doc1"
              name="attachmentIdentification"
              type={AttachmentType.IDENTIFICATION_CARD}
              title={files && files.find(att => att.type === AttachmentType.IDENTIFICATION_CARD)?.fileName}
              docUrl={StringUtils.getFileOrUrl(files.find(att => att.type === AttachmentType.IDENTIFICATION_CARD))}
            />
            <ContainerUploadedDocumentMobile editDocument attachmentType={AttachmentType.IDENTIFICATION_CARD} />
          </div>
          <ButtonsContainer>
            {props.actualStep !== 0 ? (
              <Button onClick={() => props.setActualStep(props.actualStep - 1)}>
                <span>{t('global.button.backStep')}</span>
              </Button>
            ) : (
              <div />
            )}
            <Button primary type="submit">
              <span>{t('global.button.nextStep')}</span>
            </Button>
          </ButtonsContainer>
        </Container>
      </ReactHookFormProvider>
    </>
  );
};
