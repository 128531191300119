import { GrClose } from 'react-icons/gr';
import styled from 'styled-components/macro';

export const AssignedInternalsContainer = styled.div`
  display: flex;
  height: 60px;
  flex-direction: column;
  width: 100%;
  overflow: auto;

  /* width */
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export const AssignedInteralsCard = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  gap: 8px;
  width: 306px;
  height: 56px;
  background: #ffffff;
  border: 1px solid #eeeeee;
  border-radius: 8px;
  margin-top: 5px;
  margin-bottom: 5px;

  & > svg {
    cursor: pointer;
  }
`;
interface FloatLabelProps {
  empty: boolean;
  isRequired?: boolean;
}

export const FloatLabel = styled.label<FloatLabelProps>`
  position: absolute;
  margin-top: ${(props: { empty: boolean }) => (props.empty ? '100px' : '115px')};
  margin-left: 9px;
  transition: 200ms;
  pointer-events: none;
  color: ${props => (props.isRequired ? ' #fc4f4f' : '#999999')};
`;

interface StyledTextFieldProps {
  isRequired?: boolean;
}
export const StyledTextField = styled.input<StyledTextFieldProps>`
  width: 390px;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  border: none;
  border-bottom: 1px solid ${props => (props.isRequired ? ' #fc4f4f' : '#999999')};
  cursor: pointer;
  margin-left: 8px;
  &:focus {
    outline: none;
  }

  svg {
    fill: #656666;
    font-size: 20px;
  }
`;

export const DenialAlignor = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const IconWrapper = styled.div`
  color: #f7b330;
  font-size: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px;
`;

export const ButtonAlignor = styled.div`
  display: flex;
  margin-top: 60px;
  gap: 20px;
`;

export const HorizontalDivisor = styled.hr`
  width: 90%;
  margin: auto;
`;

export const InnerText = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  user-select: none;

  & > span:nth-child(1) {
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 150%;
    text-align: center;
    color: #000000;
  }

  & > span:nth-child(2) {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    text-align: center;
    color: #7f7f7f;
  }
`;

export const StyledGrClose = styled(GrClose)`
  margin-left: 98%;
  cursor: pointer;
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ModalTitle = styled.h2`
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 150%;
  text-align: center;
  color: #333333;
`;

export const ModalDescription = styled.h4`
  width: 516px;
  height: 24px;

  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: #4c4d4d;
  margin-bottom: 24px;
`;

export const BaseModalContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 24px;
  width: 630px;
  height: 416px;
  background: #ffffff;
  border: 1px solid #eeeeee;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
`;

export const DarkModalContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  transition: 500ms;
`;

export const Alignor = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #7f7f7f;
  transition: 500ms;
`;

export const NewAnalistButton = styled.button`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: ${(props: { theme: any }) => props.theme.color.primaryColor};
  background-color: transparent;
  border: none;
  transition: 200ms;
  margin-top: -20px;
`;

export const ErrorMessage = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  color: #fc4f4f;
  margin-left: 7px;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 2px;
  width: 306px;
  min-height: 67px;
  margin-top: 20px;
`;
export const Label = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: #656666;
`;
export const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 12px;
  gap: 8px;
  width: 306px;
  min-height: 44px;
  max-height: 92px;
  border: 1px solid #cccccc;
  border-radius: 8px;
  overflow: overlay;
`;
export const ContentContainerExtends = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 12px;
  gap: 8px;
  width: 306px;
  min-height: 92px;
  border: 1px solid #cccccc;
  border-radius: 8px;
  overflow: overlay;
`;
