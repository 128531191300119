import ReactHookFormProvider from 'components/ReactHookFormProvider';
import ContainerEditDocumentMobile from 'components/container-edit-document-mobile';
import ContainerEditUploadedDocumentMobile from 'components/container-edit-uploaded-document-mobile';
import { Column, Row } from 'components/register-forms-mobile/styles';
import { Client } from 'model/client';
import { AttachmentType } from 'model/enums/attachment-types';
import { useEditForm } from 'provider/edit-form';
import { useTranslation } from 'react-i18next';
import StringUtils from 'shared/util/string-utils';
import * as yup from 'yup';
import { Container, DocumentsContainer, IdentityContainer, Title } from './styles';
interface Props {
  onlyView?: boolean;
  visible?: boolean;
  mobile?: boolean;
}

export const EditFormLegalDocuments = ({ onlyView, visible, mobile }: Props) => {
  const { t } = useTranslation();
  const { onClickNext, files, isLastForm, isLoading } = useEditForm();
  const schema = yup.object({
    attachmentResume: yup.mixed(),
  });

  const renderMobile = () => {
    return (
      <Column>
        <div>
          <Title>{t('documents.socialContract.title')}</Title>
          {!onlyView && (
            <ContainerEditDocumentMobile
              titleDocument="documents.identification"
              editDocument
              nameInput="doc1"
              name="attachmentSocialContract"
              type={AttachmentType.SOCIAL_CONTRACT}
              title={files && files.find(att => att.type === AttachmentType.SOCIAL_CONTRACT)?.fileName}
              docUrl={StringUtils.getFileOrUrl(files.find(att => att.type === AttachmentType.SOCIAL_CONTRACT))}
            />
          )}
          <ContainerEditUploadedDocumentMobile editDocument={!onlyView} attachmentType={AttachmentType.SOCIAL_CONTRACT} />
        </div>
        <div>
          <Title>{t('documents.revenueDeclaration.title')}</Title>
          {!onlyView && (
            <ContainerEditDocumentMobile
              titleDocument="documents.revenueDeclaration"
              editDocument
              nameInput="doc2"
              name="attachmentRevenueDeclaration"
              type={AttachmentType.REVENUE_DECLARATION}
              title={files && files.find(att => att.type === AttachmentType.REVENUE_DECLARATION)?.fileName}
              docUrl={StringUtils.getFileOrUrl(files.find(att => att.type === AttachmentType.REVENUE_DECLARATION))}
            />
          )}
          <ContainerEditUploadedDocumentMobile editDocument={!onlyView} attachmentType={AttachmentType.REVENUE_DECLARATION} />
        </div>
      </Column>
    );
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', marginLeft: '0px', width: '100%' }}>
      <Container visible={visible}>
        <ReactHookFormProvider schema={schema} onSubmit={() => onClickNext({ attachments: files } as Client)}>
          <IdentityContainer>
            {mobile ? (
              renderMobile()
            ) : (
              <>
                <Row>
                  <DocumentsContainer>
                    <Title>{t('documents.socialContract.title')}</Title>
                    {!onlyView && (
                      <ContainerEditDocumentMobile
                        titleDocument="documents.identification"
                        editDocument
                        nameInput="doc1"
                        name="attachmentSocialContract"
                        type={AttachmentType.SOCIAL_CONTRACT}
                        title={files && files.find(att => att.type === AttachmentType.SOCIAL_CONTRACT)?.fileName}
                        docUrl={StringUtils.getFileOrUrl(files.find(att => att.type === AttachmentType.SOCIAL_CONTRACT))}
                      />
                    )}
                    <ContainerEditUploadedDocumentMobile editDocument={!onlyView} attachmentType={AttachmentType.SOCIAL_CONTRACT} />
                  </DocumentsContainer>
                  <DocumentsContainer>
                    <Title>{t('documents.revenueDeclaration.title')}</Title>
                    {!onlyView && (
                      <ContainerEditDocumentMobile
                        titleDocument="documents.revenueDeclaration"
                        editDocument
                        nameInput="doc2"
                        name="attachmentRevenueDeclaration"
                        type={AttachmentType.REVENUE_DECLARATION}
                        title={files && files.find(att => att.type === AttachmentType.REVENUE_DECLARATION)?.fileName}
                        docUrl={StringUtils.getFileOrUrl(files.find(att => att.type === AttachmentType.REVENUE_DECLARATION))}
                      />
                    )}
                    <ContainerEditUploadedDocumentMobile editDocument={!onlyView} attachmentType={AttachmentType.REVENUE_DECLARATION} />
                  </DocumentsContainer>
                </Row>
              </>
            )}
          </IdentityContainer>
        </ReactHookFormProvider>
      </Container>
    </div>
  );
};
