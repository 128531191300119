import styled from 'styled-components/macro';

export const Container = styled.div`
  width: 100%;
  height: 48px;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.06);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
`;
export const Logo = styled.div``;
export const Button = styled.div`
  border: none;
  outline: none;
`;
