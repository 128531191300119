import { ClieloStatus, ContestationStatus, CreditCardContest, FinancingCreditCards, TypeAttachment } from 'model/credit-card';
import { Fragment, useState } from 'react';
import { Button, CardTitle, ContentHeader, StyledContestationButtonsContainer } from './styles';
import { WithTranslation, withTranslation } from 'react-i18next';
import CodeStatusCielo from 'model/enums/code-status-cielo';
import { FiPlus, FiEdit2 } from 'react-icons/fi';
import { useTheme } from 'styled-components';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import StringUtils from 'shared/util/string-utils';
import { convertDateTimeFromServer } from 'shared/util/date-utils';
import { parse } from 'date-fns';
import creditCardService from 'services/credit-card-service';
import { Grid } from '@mui/material';
import AttachDispute from '../attach-dispute';
import InputText from 'components/styled-input';
import ContestationFile from '../contestation-file';
import ConfirmationModal from 'components/confirmation-modal';
import { Mask } from 'model/mask-types';

interface IProps extends WithTranslation {
  financingCreditCard: FinancingCreditCards;
  cardIndex: number;
  cieloStatus?: ClieloStatus;
  handleClickConfirmationModal: () => Promise<void>;
}

interface Schema {
  disputeDate: string;
  amountPaid: string;
  installments: string;
  reasonObjection: string;
  signatureContract: string;
}

const CardContestionForm = ({ financingCreditCard, cardIndex, cieloStatus, handleClickConfirmationModal, t }: IProps) => {
  const { color } = useTheme();

  const [isSendData, setIsSendData] = useState<boolean>(false);
  const [isShowConfirmationModal, setIsShowConfirmationModal] = useState<boolean>(false);
  const [isShowContestationInputs, setIsShowContestationInputs] = useState<boolean>(financingCreditCard?.isContested ?? false);
  const [isEditContestationInputs, setIsEditContestationInputs] = useState<boolean>(false);
  const [isLoadingContest, setIsLoadingContest] = useState<boolean>(false);
  const [errorMessageSignatureContract, setErrorMessageSignatureContract] = useState<string>('');

  const [signatureContract, setSignatureContract] = useState<TypeAttachment | undefined>(
    financingCreditCard?.signatureContract ?? undefined
  );
  const [contestationDocument, setContestationDocument] = useState<TypeAttachment | undefined>(
    financingCreditCard?.contestationDocument ?? undefined
  );

  const schema = yup.object().shape({
    disputeDate: yup.string().required(),
    amountPaid: yup.string().required(),
    installments: yup.string().required(),
    signatureContract: yup.string().test('signature-contract', () => checkAttachmentSignatureContract()),
  });

  const methods = useForm<Schema>({
    resolver: yupResolver(schema),
    mode: 'all',
  });

  const onHandleSubmit = (values: Schema, creditCardId?: number) => {
    if (signatureContract != null && isSendData) {
      setIsLoadingContest(true);

      const formatData: CreditCardContest = {
        id: creditCardId,
        contestationDocument: contestationDocument,
        signatureContract: signatureContract,
        amountToBePaid: Number(StringUtils.removeMoneyFormat(values.amountPaid)),
        installmentsNumber: Number(values.installments),
        contestationDate: convertDateTimeFromServer(parse(values.disputeDate, 'dd/MM/yyyy', new Date())),
        isContested: true,
        comment: values.reasonObjection,
      };

      creditCardService.contest(formatData).then(() => setIsShowConfirmationModal(true));
    }
  };

  const checkAttachmentSignatureContract = (signatureContract?: TypeAttachment) => {
    if (signatureContract == null) {
      setErrorMessageSignatureContract(t('global.errorMessage.mandatory'));
    }

    return true;
  };

  const resetHookData = () => {
    setIsShowConfirmationModal(false);
    setIsEditContestationInputs(false);
    setIsSendData(false);
    setErrorMessageSignatureContract('');
    setIsLoadingContest(true);
  };

  return (
    <Fragment>
      <ContentHeader>
        <CardTitle>
          {financingCreditCard?.isContested
            ? t('admin.creditCardScreen.blockedDispute', { number: cardIndex })
            : t('admin.creditCardScreen.cardTitle', { number: cardIndex })}
        </CardTitle>

        {!financingCreditCard?.isContested && !isShowContestationInputs && cieloStatus?.returnCode === CodeStatusCielo.SUCCESS ? (
          <Button
            onClick={() => {
              setIsShowContestationInputs(true);
              setIsEditContestationInputs(true);
            }}
          >
            <FiPlus size={20} color={color.primaryColor} />
            <span>{t('admin.creditCardScreen.addDispute')}</span>
          </Button>
        ) : financingCreditCard?.isContested && cieloStatus?.returnCode !== CodeStatusCielo.SUCCESS && !isEditContestationInputs ? (
          <StyledContestationButtonsContainer>
            <Button
              onClick={() => {
                setIsEditContestationInputs(true);
                setIsShowContestationInputs(true);
              }}
              type="button"
            >
              <span>{t('admin.creditCardScreen.editDispute')}</span>
            </Button>
            {isEditContestationInputs && (
              <Button
                onClick={() => {
                  setErrorMessageSignatureContract('');
                  setIsShowContestationInputs(false);
                  setIsEditContestationInputs(false);
                }}
              >
                <span style={{ color: '#FC4F4F' }}>{t('global.button.cancelEdit')}</span>
              </Button>
            )}
          </StyledContestationButtonsContainer>
        ) : isEditContestationInputs && isShowContestationInputs ? (
          <StyledContestationButtonsContainer>
            <Button
              onClick={() => {
                setIsSendData(true);
                setIsEditContestationInputs(true);
              }}
              form={`contestation-form-${cardIndex}`}
              loading={isLoadingContest}
            >
              <span>{t('admin.creditCardScreen.saveDispute')}</span>
            </Button>
            {isShowContestationInputs && (
              <Button
                onClick={() => {
                  setErrorMessageSignatureContract('');
                  setIsShowContestationInputs(false);
                  setIsEditContestationInputs(false);
                }}
              >
                <span style={{ color: '#FC4F4F' }}>{t('global.button.cancelEdit')}</span>
              </Button>
            )}
          </StyledContestationButtonsContainer>
        ) : (
          <></>
        )}
      </ContentHeader>

      <FormProvider {...methods}>
        <form
          id={`contestation-form-${cardIndex}`}
          onSubmit={methods.handleSubmit(formData => {
            onHandleSubmit(formData, financingCreditCard.id);
          })}
        >
          <Grid container rowSpacing={2} columnSpacing={3}>
            {isShowContestationInputs && contestationDocument == null && (
              <Grid item xs={6}>
                <AttachDispute
                  text={t('admin.creditCardScreen.attachDispute')}
                  disabled={!isEditContestationInputs}
                  getFile={file => setContestationDocument(file)}
                />
              </Grid>
            )}
            {isShowContestationInputs && signatureContract == null && (
              <Grid item xs={6}>
                <AttachDispute
                  text={t('admin.creditCardScreen.attachContractSignature')}
                  disabled={!isEditContestationInputs}
                  getFile={file => {
                    setSignatureContract(file);
                    setErrorMessageSignatureContract('');
                  }}
                  errorMessage={errorMessageSignatureContract}
                />
              </Grid>
            )}

            <Grid item xs={12} />

            <Grid item lg={6} xl={4}>
              <InputText
                name="cardNumber"
                label={t('admin.creditCardScreen.cardNumber')}
                disabled
                defaultValue={t('admin.customerAnalysis.textStepCards.creditCard', {
                  number: financingCreditCard.creditCard?.number,
                })}
              />
            </Grid>
            <Grid item lg={6} xl={4}>
              <InputText
                name="validity"
                label={t('admin.creditCardScreen.validity')}
                disabled
                defaultValue={t('admin.creditCardScreen.expirationDate', {
                  month: financingCreditCard.creditCard?.expirationMonth,
                  year: financingCreditCard.creditCard?.expirationYear,
                })}
              />
            </Grid>
            <Grid item lg={6} xl={4}>
              <InputText
                name="cvv"
                label={t('admin.creditCardScreen.cvv')}
                disabled
                defaultValue={financingCreditCard.creditCard?.cvv ?? '***'}
              />
            </Grid>
            <Grid item lg={6} xl={4}>
              <InputText
                name="cardholderName"
                label={t('admin.creditCardScreen.cardholderName')}
                disabled
                defaultValue={financingCreditCard.creditCard?.cardholderName}
              />
            </Grid>
            <Grid item lg={6} xl={4}>
              <InputText
                name="cpf"
                label={t('admin.creditCardScreen.cpf')}
                disabled
                defaultValue={financingCreditCard.creditCard?.cardholderDocument}
              />
            </Grid>
            {isShowContestationInputs && (
              <>
                <Grid item lg={6} xl={4}>
                  <InputText
                    name="disputeDate"
                    maxLength={10}
                    mask={Mask.BIRTHDAY}
                    disabled={!isEditContestationInputs}
                    label={t('admin.creditCardScreen.disputeDate')}
                    errorMessage={methods.formState.errors.disputeDate?.message}
                    defaultValue={
                      financingCreditCard?.contestationDate != null ? StringUtils.dateFormatMask(financingCreditCard?.contestationDate) : ''
                    }
                    rightIcon={<FiEdit2 size={18} color={color.primaryColor} />}
                  />
                </Grid>
                <Grid item lg={6} xl={4}>
                  <InputText
                    name="amountPaid"
                    mask={isShowContestationInputs ? Mask.DEFAULT : Mask.NUMBERS}
                    disabled={!isEditContestationInputs}
                    label={t('admin.creditCardScreen.amountPaid')}
                    errorMessage={methods.formState.errors.amountPaid?.message}
                    onBlur={() => {
                      const value = methods.getValues('amountPaid');
                      methods.setValue('amountPaid', StringUtils.moneyMaskPtbr(Number(value)));
                    }}
                    onFocus={() => {
                      const value = methods.getValues('amountPaid');
                      methods.setValue('amountPaid', StringUtils.removeMoneyFormat(value));
                    }}
                    defaultValue={StringUtils.moneyMaskPtbr(financingCreditCard.amountToBePaid ?? 0)}
                    rightIcon={<FiEdit2 size={18} color={color.primaryColor} />}
                  />
                </Grid>
                <Grid item lg={6} xl={4}>
                  <InputText
                    name="installments"
                    maxLength={3}
                    disabled={!isEditContestationInputs}
                    label={t('admin.creditCardScreen.installments')}
                    mask={Mask.NUMBERS}
                    defaultValue={financingCreditCard?.installmentsNumber != null ? String(financingCreditCard.installmentsNumber) : ''}
                    errorMessage={methods.formState.errors.installments?.message}
                    rightIcon={<FiEdit2 size={18} color={color.primaryColor} />}
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputText
                    name="reasonObjection"
                    disabled={!isEditContestationInputs}
                    label={t('admin.creditCardScreen.reasonObjection')}
                    defaultValue={financingCreditCard?.comment}
                    rightIcon={<FiEdit2 size={18} color={color.primaryColor} />}
                  />
                </Grid>

                {contestationDocument != null && (
                  <Grid item xs={4}>
                    <ContestationFile
                      attachment={contestationDocument}
                      disabled={!isEditContestationInputs}
                      handleClick={() => setContestationDocument(undefined)}
                    />
                  </Grid>
                )}
                {signatureContract != null && (
                  <Grid item xs={4}>
                    <ContestationFile
                      attachment={signatureContract}
                      disabled={!isEditContestationInputs}
                      handleClick={() => setSignatureContract(undefined)}
                    />
                  </Grid>
                )}
              </>
            )}
          </Grid>
        </form>
      </FormProvider>

      <ConfirmationModal
        isShowModal={isShowConfirmationModal}
        text={t('admin.creditCardScreen.dataSuccessfully')}
        handleClick={() => handleClickConfirmationModal().then(() => resetHookData())}
        handleClose={() => handleClickConfirmationModal().then(() => resetHookData())}
      />
    </Fragment>
  );
};

export default withTranslation()(CardContestionForm);
