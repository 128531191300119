import styled from 'styled-components/macro';

export const Container = styled.div`
  border: 1.5px solid ${({ theme }) => theme.color.primaryColor};
  background-color: ${({ theme }) => theme.color.primaryColor};
  padding: 12px 25px;
  border-radius: 24px;
  display: flex;
  align-items: center;

  > button {
    border: none;
    background-color: transparent;
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0;
    > span {
      font-style: normal;
      font-size: 16px;
      color: ${({ theme }) => theme.color.white};
      font-weight: 700;
      margin-left: 8px;
    }
  }
`;

export const DropDownFilter = styled.div`
  background-color: ${({ theme }) => theme.color.white};
  padding: 16px 16px;
  border: 1px solid ${({ theme }) => theme.color.gray20};
  border-radius: 8px;

  width: max-content;
  margin-top: 4px;
  display: flex;
  flex-direction: column;
`;

export const FilterBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  border-bottom: 1px solid ${({ theme }) => theme.color.gray20};

  padding-bottom: 16px;

  > span.status {
    margin-top: 16px;
  }
`;

export const SectionTitle = styled.span`
  font-style: normal;
  font-family: Satoshi;
  font-weight: 700;
  font-size: 12px;
  line-height: 150%;
  color: ${({ theme }) => theme.color.gray18};
  margin-bottom: 16px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;

  button {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 150%;
    background-color: transparent;
    border: none;
  }

  > button.clean {
    color: ${({ theme }) => theme.color.gray24};
  }

  > button.apply {
    color: ${({ theme }) => theme.color.primaryColor};
  }
`;

export const CalendarInputsContainer = styled.div`
  margin-bottom: 12px;
`;

export const CustomRadioOption = styled.div`
  width: 146px;
  color: var(--gray-dark-3, #4c4d4d);
  font-family: Satoshi;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 21px */
  display: flex;
  padding: 4px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  span {
    font-size: 14px;
  }
  svg {
    width: 24px;
  }
`;
