import styled from 'styled-components/macro';

interface DesktopContainerProps {
  onlyView?: boolean;
}
interface ContainerProps {
  visible?: boolean;
}
export const Container = styled.div<ContainerProps>`
  width: 100%;
  padding-bottom: 50px;
  display: ${props => !props.visible && 'none'};
`;
export const Title = styled.span`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  display: flex;
  align-items: center;
  color: #1b1a1b;
  margin-bottom: 10px;
`;
export const IdentityContainer = styled.div`
  margin-top: 10px;
  margin-bottom: 30px;
  max-height: 520px;
`;
export const ProofsContainer = styled.div`
  margin-top: 10px;
  margin-bottom: 30px;
`;
export const InfoContainer = styled.div`
  display: flex;
  ul {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;
export const DesktopContainer = styled.div<DesktopContainerProps>`
  width: 100%;
  display: flex;
  flex-direction: ${props => (props.onlyView ? 'column' : 'row')};
  align-items: ${props => (props.onlyView ? 'center' : 'space-around')};
  /* justify-content: space-around; */
  flex-wrap: wrap;
  row-gap: 0px;
  padding: 18px;
`;
export const ItemContainer = styled.div<DesktopContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: ${props => (props.onlyView ? 'center' : 'space-around')};
`;
