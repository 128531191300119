import { Button } from 'components/register-forms/styles';
import DocumentBack from 'images/document-back.svg';
import DocumentFront from 'images/document-front.svg';
import IncomeProof from 'images/income-proof.svg';
import { useRegisterForm } from 'provider/register-form';
import { useTranslation } from 'react-i18next';
import { Container, IdentityContainer, InfoContainer, ProofsContainer, Title, ButtonsContainer } from './styles';

interface IInstructionsScreenProps {
  actualStep: number;
  setActualStep: (step: number) => void;
  onClickBack: () => void;
}

export const InstructionsScreen: React.FC<IInstructionsScreenProps> = props => {
  const { t } = useTranslation();
  const { isFirstForm } = useRegisterForm();

  return (
    <>
      <Container>
        <div style={{ display: 'flex' }}>
          <IdentityContainer>
            <Title>{t('documents.identity.title')}</Title>
            <InfoContainer>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <img src={DocumentFront} style={{ marginBottom: '10px' }} />
                <img src={DocumentBack} />
              </div>
              <ul>
                <li>{t('documents.identity.steps.one')}</li>
                <li>{t('documents.identity.steps.two')}</li>
                <li>{t('documents.identity.steps.three')}</li>
                <li>{t('documents.identity.steps.four')}</li>
                <li>{t('documents.identity.steps.five')}</li>
                <li>{t('documents.identity.steps.six')}</li>
              </ul>
            </InfoContainer>
          </IdentityContainer>
          <ProofsContainer>
            <Title>{t('documents.proofs.title')}</Title>
            <InfoContainer>
              <img src={IncomeProof} />
              <ul>
                <li>{t('documents.proofs.steps.one')}</li>
                <li>{t('documents.proofs.steps.two')}</li>
                <li>{t('documents.proofs.steps.three')}</li>
                <li>{t('documents.proofs.steps.four')}</li>
              </ul>
            </InfoContainer>{' '}
          </ProofsContainer>
        </div>
      </Container>
      <ButtonsContainer>
        {!isFirstForm() ? (
          <Button onClick={props.onClickBack}>
            <span>{t('global.button.backStep')}</span>
          </Button>
        ) : (
          <div />
        )}
        <Button primary onClick={() => props.setActualStep(props.actualStep + 1)}>
          <span>{t('global.button.nextStep')}</span>
        </Button>
      </ButtonsContainer>
    </>
  );
};
