enum FinancingStatusType {
  NEW = 'NEW',
  ADJUSTED = 'ADJUSTED',
  ADJUST = 'ADJUST',
  IN_ANALYSIS = 'IN_ANALYSIS',
  IN_PROGRESS = 'IN_PROGRESS',
  FINISHED = 'FINISHED',
  REJECTED = 'REJECTED',
  APPROVED = 'APPROVED',
  VALIDATION = 'VALIDATION',
  CONTRACT = 'CONTRACT',
  PAYMENT = 'PAYMENT',
  CANCELLED = 'CANCELLED',
  PENDING = 'PENDING',
  DONE = 'Done',
  DONE1 = 'done',
  PENDING_PAYMENT = 'PENDING_PAYMENT',
  PENDING_SIGN = 'PENDING_SIGN',
}

export default FinancingStatusType;
