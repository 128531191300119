import styled from 'styled-components/macro';

interface StyledProps {
  rotation: number;
  zoom: number;
}

export const StyledContainer = styled.div<StyledProps>`
  margin-top: 20px;
  height: 576px;

  img {
    height: 100%;
    object-fit: scale-down;
    transform: ${({ rotation, zoom }) => `rotate(${rotation}deg) scale(${zoom / 100})`};
  }
`;

export const ButtonsContainer = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;
  display: flex;
  column-gap: 10px;
`;

export const IconContainer = styled.div`
  position: absolute;
  left: 47%;
  bottom: 10px;
`;
