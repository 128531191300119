import registerApi from 'api/register-api';
import ReactHookFormProvider from 'components/ReactHookFormProvider';
import { isDate, parse } from 'date-fns';
import { Address } from 'model/address';
import { Client } from 'model/client';
import { useRegisterForm } from 'provider/register-form';
import { useSelectLists } from 'provider/select-list';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { mailingDestinationType } from 'shared/util/select-utils';
import { validateCpf } from 'shared/util/string-utils';
import * as yup from 'yup';
import { FormContainer, ImageContainer } from '../styles';
import { ContainerInputsAddress } from './inputs-address';

export const FormAddressTopOne = () => {
  const { onClickNext, initialClientData } = useRegisterForm();
  const { t } = useTranslation();
  const { getResidenceType, residenceType } = useSelectLists();

  useEffect(() => {
    getResidenceType();
  }, []);

  const schema = yup.object({
    zipcode: yup.string().min(9, t('global.errorMessage.zipcode')).required(t('global.errorMessage.required')),
    street: yup.string().required(t('global.errorMessage.required')),
    number: yup.string().required(t('global.errorMessage.required')),
    complement: yup.string(),
    district: yup.string().required(t('global.errorMessage.required')),
    state: yup.string().required(t('global.errorMessage.required')),
    city: yup.string().required(t('global.errorMessage.required')),
    residenceType: yup
      .string()
      .required(t('global.errorMessage.required'))
      .test('valid-reference', t('global.errorMessage.invalidReference'), value => {
        if (value) {
          return residenceType.map(item => item.displayValue && item.displayValue.toLowerCase()).includes(value.toLowerCase());
        } else {
          return false;
        }
      }),
    mailingDestinationType: yup
      .string()
      .required(t('global.errorMessage.required'))
      .test('valid-reference', t('global.errorMessage.invalidReference'), value => {
        if (value) {
          return mailingDestinationType.map(item => item.label && item.label.toLowerCase()).includes(value.toLowerCase());
        } else {
          return false;
        }
      }),
  });

  const getStateId = async (uf: string) => {
    const res = await registerApi.getStates(7, 0, '', uf);
  };

  return (
    <FormContainer>
      <ReactHookFormProvider
        schema={schema}
        onSubmit={res => {
          getStateId(res.state);
          if (onClickNext) {
            const address = {
              ...initialClientData?.address,
              zipcode: res.zipcode,
              street: res.street,
              number: res.number,
              complement: res.complement,
              district: res.district,
              reference: res.reference,
              residenceType: { id: residenceType.find(type => type.displayValue === res.residenceType)?.id },
              mailingDestinationType: mailingDestinationType.find(type => type.label === res.mailingDestinationType)?.value,
            };

            onClickNext({ address } as Client);
          }
        }}
      >
        <ContainerInputsAddress />
      </ReactHookFormProvider>
    </FormContainer>
  );
};
