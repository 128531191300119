import { isEmpty } from 'lodash';
import SystemStepCategory from 'model/enums/system-step-category';
import { FiChevronRight } from 'react-icons/fi';
import ReactLoading from 'react-loading';
import { useHistory } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { StyledContainer, StyledContentContainer, StyledInnerContainer, StyledTitle } from './styles';

interface IProps {
  title: string;
  content: JSX.Element;
  icon: JSX.Element;
  isActive: boolean;
  flexCard?: boolean;
  hide?: boolean;
  arrowButton?: boolean;
  disableClick?: boolean;
  loading?: boolean;
  step?: SystemStepCategory;
  onHandleClick: () => void;
  redirectPathClickArrow?: string;
}

const CardForDashboard = ({
  title,
  content,
  icon,
  isActive,
  flexCard,
  arrowButton,
  disableClick,
  loading,
  onHandleClick,
  redirectPathClickArrow,
  hide,
}: IProps) => {
  const { color } = useTheme();
  const history = useHistory();

  if (hide) {
    return <></>;
  }

  return (
    <StyledContainer
      isActive={isActive && !loading}
      flexCard={flexCard}
      onClick={() => {
        if (isActive && !loading) {
          onHandleClick();
        }
      }}
      clickable={!disableClick}
      loading={loading}
    >
      <div>
        <StyledInnerContainer>
          {icon}
          <StyledTitle>{title}</StyledTitle>
        </StyledInnerContainer>
        {!loading && <StyledContentContainer isActive={isActive}>{content}</StyledContentContainer>}
      </div>

      {loading ? (
        <ReactLoading type="spinningBubbles" color={color.primaryColor} width="20px" height="20px" />
      ) : (
        arrowButton && (
          <FiChevronRight
            color={color.primaryColor}
            size={24}
            onClick={() => {
              if (!isEmpty(redirectPathClickArrow)) {
                history.push(redirectPathClickArrow!);
              }
            }}
            style={{ pointerEvents: 'auto', cursor: 'pointer' }}
          />
        )
      )}
    </StyledContainer>
  );
};

export default CardForDashboard;
