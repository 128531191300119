import CustomButton from 'components/custom-button';
import Header from 'components/general-components/header';
import { Status } from 'features/admin/dashboard/components/status';
import AttentionIcon from 'images/attention-yellow.svg';
import CheckIcon from 'images/check.svg';
import { DownloadSvg } from 'images/download';
import { Authority } from 'model/enums/authority';
import { ContractStatus } from 'model/enums/contract-status';
import { HeaderVariants } from 'model/enums/header-variants';
import { Contract } from 'model/financing';
import { useTranslation } from 'react-i18next';
import { FiChevronLeft } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { IRootState } from 'reducer';
import { hasAuthorities } from 'shared/util/utils';
import { useTheme } from 'styled-components';
import { ContractPdf } from '../components/contractPdf';
import { SignQRCode } from '../components/signqrcode';
import { Tab, TabBox, TabContent } from '../styles';
import {
  Button,
  ButtonsContainer,
  Container,
  ContainerHeader,
  ContractDetailsContainer,
  ContractDetailsHeader,
  DownloadButton,
  SignatureContainer,
  SignatureDetails,
} from './styles';

interface MobileProps {
  contract: Contract | null;
  date: {
    day: string;
    month: string;
    year: string;
  };
  tabSelected: number;
  setTabSelected: (value: number) => void;
  downloadPdf: (value: string) => void;
  setIsLoading: (value: boolean) => void;
  isLoading: boolean;
  resendEmail: () => Promise<void>;
  signatureLink: string | null;
}
export const Mobile = ({
  contract,
  date,
  tabSelected,
  setTabSelected,
  downloadPdf,
  setIsLoading,
  isLoading,
  resendEmail,
  signatureLink,
}: MobileProps) => {
  const history = useHistory();
  const { color } = useTheme();
  const { t } = useTranslation();
  const account = useSelector((state: IRootState) => state.authentication.account);

  const getTabContent = () => {
    if (signatureLink != null) {
      switch (tabSelected) {
        case 1:
          return <SignQRCode status={contract?.status} url={signatureLink} />;
        case 2:
          return (
            <>
              {contract?.contractSign?.contractSignatories.map((item, key) => (
                <SignatureContainer key={key}>
                  <SignatureDetails>
                    <span className="type">{t(`enum.signatoryType.${item.signatoryType}`)}</span>
                    <span className="name">{item.signatory?.name}</span>
                  </SignatureDetails>
                  <div>{item.signed ? <img src={CheckIcon} /> : <img src={AttentionIcon} />}</div>
                </SignatureContainer>
              ))}
            </>
          );
        case 3:
          return <ContractPdf url={contract?.contractSign?.attachment?.presignedUrl ?? contract?.attachment?.presignedUrl ?? ''} />;
        default:
          return <></>;
      }
    } else {
      switch (tabSelected) {
        case 1:
          return (
            <>
              {contract?.contractSign?.contractSignatories.map((item, key) => (
                <SignatureContainer key={key}>
                  <SignatureDetails>
                    <span className="type">{t(`enum.signatoryType.${item.signatoryType}`)}</span>
                    <span className="name">{item.signatory?.name}</span>
                  </SignatureDetails>
                  <div>{item.signed ? <img src={CheckIcon} /> : <img src={AttentionIcon} />}</div>
                </SignatureContainer>
              ))}
            </>
          );
        case 2:
          return <ContractPdf url={contract?.contractSign?.attachment?.presignedUrl ?? contract?.attachment?.presignedUrl ?? ''} />;
        default:
          return <></>;
      }
    }
  };

  return (
    <>
      <Header variant={HeaderVariants.SECONDARY} />
      <Container>
        <ContainerHeader>
          <button
            onClick={() => {
              history.goBack();
            }}
          >
            <FiChevronLeft color={color.primaryColor} />
          </button>
          <span>{t('admin.contractScreen.title')}</span>
        </ContainerHeader>
        <ContractDetailsContainer>
          <ContractDetailsHeader>
            <span>Contrato 1</span>
            <Status text={t(`enum.filterAllStatus.${contract?.status}`)} color={contract?.status ?? ''} />
          </ContractDetailsHeader>
          <span className="date">
            {t('admin.contractScreen.date', {
              day: date.day,
              month: date.month,
              year: date.year,
            })}
          </span>
        </ContractDetailsContainer>
        <TabBox isMobile>
          {signatureLink != null && (
            <Tab isSelected={tabSelected === 1} onClick={() => setTabSelected(1)}>
              <span>{t('admin.contractScreen.tabs.sign')}</span>
            </Tab>
          )}
          <Tab
            isSelected={signatureLink != null ? tabSelected === 2 : tabSelected === 1}
            onClick={() => (signatureLink != null ? setTabSelected(2) : setTabSelected(1))}
          >
            <span>{t('admin.contractScreen.tabs.signatories')}</span>
          </Tab>
          <Tab
            isSelected={signatureLink != null ? tabSelected === 3 : tabSelected === 2}
            onClick={() => (signatureLink != null ? setTabSelected(3) : setTabSelected(2))}
          >
            <span>{t('admin.contractScreen.tabs.document')}</span>
          </Tab>
        </TabBox>
        <TabContent>{getTabContent()}</TabContent>
      </Container>
      <ButtonsContainer>
        {contract?.contractSign?.attachment?.presignedUrl ? (
          <DownloadButton onClick={() => downloadPdf(contract?.contractSign?.attachment?.presignedUrl ?? '')}>
            <DownloadSvg />
            <span>{t('global.button.download')}</span>
          </DownloadButton>
        ) : (
          <DownloadButton onClick={() => downloadPdf(contract?.attachment?.presignedUrl ?? '')}>
            <DownloadSvg />
            <span>{t('global.button.download')}</span>
          </DownloadButton>
        )}
        {contract?.status !== ContractStatus.FINISHED &&
          hasAuthorities(account?.authorities, [Authority.ROLE_ADMIN, Authority.ROLE_MANAGER]) && (
            <CustomButton
              loading={isLoading}
              onClick={() => {
                setIsLoading(true);
                resendEmail();
              }}
            >
              {t('admin.contractScreen.buttonText')}
            </CustomButton>
          )}
      </ButtonsContainer>
    </>
  );
};
