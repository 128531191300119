import styled from 'styled-components/macro';

interface FilterItemProps {
  isActive?: boolean;
}

export const OptionContainer = styled.div`
  position: relative;
`;

export const FilterItem = styled.button<FilterItemProps>`
  padding: 4px 12px;
  background: ${({ theme, isActive }) => (isActive ? theme.color.primaryColor : theme.color.white)};

  border: ${({ theme, isActive }) => (isActive ? `1px solid ${theme.color.primaryColor}` : `1px solid ${theme.color.gray18}`)};
  border-radius: 16px;
  color: ${({ theme, isActive }) => (isActive ? theme.color.white : theme.color.gray18)};

  font-weight: 700;
  font-size: 14px;

  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 8px;

  :hover {
    background: ${({ theme }) => theme.color.primaryColor};
    color: ${({ theme, isActive }) => theme.color.white};
    border: 1px solid ${({ theme }) => theme.color.primaryColor};
    & > svg {
      color: ${({ theme }) => theme.color.white};
    }
  }
`;

interface CalendarContainerProps {
  width: number;
  filterItemOffsetLeft: number;
}

export const CalendarContainer = styled.div<CalendarContainerProps>`
  position: absolute;
  top: 40px;
  left: ${({ filterItemOffsetLeft, width }) => filterItemOffsetLeft - width / 2}px;
  z-index: 10;
  background: ${({ theme }) => theme.color.white};
  border: 1px solid ${({ theme }) => theme.color.borderGray};
  box-shadow: 0px 2px 12px ${({ theme }) => theme.color.shadow};
  border-radius: 8px;
  padding: 16px;

  & > div.react-calendar {
    width: 240px;
    border: none;

    display: flex;
    flex-direction: column;
    align-items: center;

    font-style: normal;
    font-size: 16px;
    font-weight: 700;
    margin-left: 8px;

    abbr {
      text-decoration: none;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    button {
      border-radius: 4px;
      padding: 8px;
      background: none;
      border: none;
      font-size: 14px;
    }

    button.react-calendar__tile:hover {
      background-color: ${({ theme }) => theme.color.lightGray1};
    }

    button.react-calendar__tile--now {
      background-color: ${({ theme }) => theme.color.lightBlue};
    }

    button.react-calendar__tile--active {
      background-color: ${({ theme }) => theme.color.verylightBlue};
    }

    button.react-calendar__tile:disabled {
      border-radius: 0px;
    }

    button.react-calendar__tile:disabled:hover {
      background-color: transparent;
    }
  }

  .react-calendar__month-view__days__day--weekend {
    color: ${({ theme }) => theme.color.rejected};
  }
`;
