import FinancingStatusType from 'model/enums/financing-status-type';
import { CSSProperties } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { StyledContainer } from './styles';

interface IProps extends WithTranslation {
  status: FinancingStatusType;
  style?: CSSProperties;
}

const Status = ({ status, style, t }: IProps) => {
  return (
    <StyledContainer status={status} style={style}>
      {t(`status.${status.toLowerCase()}`)}
    </StyledContainer>
  );
};

export default withTranslation()(Status);
