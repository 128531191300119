import styled from 'styled-components/macro';

export const PageContainer = styled.div`
  background-color: ${({ theme }) => theme.color.grayBackground};
  height: 100vh;
  overflow: auto;

  & > div.tableContainer {
    max-width: 70%;
    margin: 0 auto;
  }
`;

export const TitleHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  margin: 0 auto;
  margin-top: 48px;
  max-width: 70%;
`;

export const Title = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 150%;

  > span.title {
    display: inline-block;
    font-weight: 700;
    margin-left: 16px;
  }

  > button {
    background-color: transparent;
    border: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;

export const EditContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ButtonsContainer = styled.div`
  button {
    border: none;
    background-color: transparent;
    padding: 0;
    margin: 0;
  }

  > button.edit {
    margin-right: 20px;
  }
`;
