import styled from 'styled-components/macro';

export const StyledContestationFile = styled.div`
  width: 100%;
  max-width: 306px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 12px;
  background-color: ${({ theme }) => theme.color.verylightBlue};
  color: ${({ theme }) => theme.color.black1};
  font-size: 14px;
  font-weight: 500;
  border-radius: 8px;

  cursor: pointer;
`;

export const StyledContestationInnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  column-gap: 5px;
`;
