import { Container } from 'reactstrap';
import { color, font } from 'shared/util/styled-component-utils';
import { createGlobalStyle } from 'styled-components';
import styled from 'styled-components/macro';
import 'styles/constants/fonts.scss';

interface Props {
  customStyle?: any;
}

const GlobalStyle = createGlobalStyle`
  html body {
    font-family: ${font`default`};
    color: ${color`primary`};
  }
  h1 {
    font-size: 2rem;
    font-family: ${font`title`};
    color: ${color`title`};
    font-weight: 600;
  }
  button[type=submit]:not(:disabled), button:not(:disabled) {
    outline: 0 !important;
  }
  *:focus {
    outline: 0 !important;
  }
  button:focus {
    /* box-shadow: 0 0 2px 4px rgb(255 0 0 / 25%); */
  }
`;

export const TagItems = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const MinHeightScreeningContainer = styled(Container)`
  min-height: calc(100vh - 38px);
  padding-top: 68px;
  padding-left: 200px;
`;
export const MinHeightScreeningContainerPj = styled(Container)`
  min-height: calc(100vh - 106px);
  padding-left: 260px;
  padding-top: 92px;
`;

export const TitleText = styled.div`
  color: black;
  font-size: 1rem;
  font-weight: 600;
  display: flex;
  font-family: ${font`title`};
`;

interface ICustomStyle {
  flexDirection?: string;
  backgroundColor?: string;
}
interface IPageProps {
  customStyle?: ICustomStyle;
}

export const StyledPage = styled.div<IPageProps>`
  width: 100vw;
  height: auto;
  margin: 0px;
  padding: 0px;
  display: flex;
  flex-direction: ${({ customStyle }) => (customStyle?.flexDirection ? customStyle.flexDirection : 'column')};

  background: ${({ customStyle }) => (customStyle?.backgroundColor ? customStyle.backgroundColor : '#FFFFFF')};
  align-items: center;
  justify-content: center;
  position: relative;
`;

export const StyledScreenContainer = styled.div<Props>`
  width: 100%;
  height: ${({ customStyle }) => (customStyle.height ? customStyle.height : '500px')};
  ${({ customStyle }) => customStyle.minHeight && `min-height: ${customStyle.minHeight}`};
  ${({ customStyle }) => customStyle.padding && `padding: ${customStyle.padding};`}

  ${({ customStyle }) => {
    if (customStyle.backgroundImage) {
      return `
      background-image: url(${customStyle.backgroundImage});
      background-position: center center;
      background-size: cover;
      transition: all 1s ease;
      `;
    } else {
      return `  background: ${customStyle.background ? customStyle.background : '#FFFFFF'};
      `;
    }
  }} 
  ${({ customStyle }) => customStyle.backgroundPosition && `background-position: ${customStyle.backgroundPosition}`};
  ${({ customStyle }) => customStyle.backgroundSize && `background-size: ${customStyle.backgroundSize}`};

  background-repeat: no-repeat;
  display: flex;
  flex-direction: ${({ customStyle }) => (customStyle.flexDirection ? customStyle.flexDirection : 'row')};
  align-items: ${({ customStyle }) => (customStyle.alignItems ? customStyle.alignItems : 'flex-start')};
  justify-content: ${({ customStyle }) => (customStyle.justifyContent ? customStyle.justifyContent : 'flex-start')};
  position: relative;

  overflow-x: hidden;
`;

export default GlobalStyle;
