import { Logo } from 'images/custom-organization';
import { GeneralImages } from 'images/general-images';
import { MenuIcon } from 'images/header';
import { HeaderVariants } from 'model/enums/header-variants';
import { RoutePath } from 'model/enums/route-path';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Popup from 'reactjs-popup';
import AuthUtils from 'shared/util/auth-utils';
import { goTo } from 'shared/util/go-to';
import { LinkListType } from '..';
import UserDropdown, { ListType } from '../dropdown';
import { DropdownContainer, DropdownItem, DropdownText, Separator } from '../dropdown/style';
import { AlignBox, HeaderContainer, HeaderContent, LoggedUserBox, LogoBox, ProfileImage, StyledText } from '../styles';

interface MobileProps {
  variant: string;
  linkList?: LinkListType[];
  LoggedDropdonw?: ListType[];
  hideMenu?: boolean;
}

const Mobile: React.FC<MobileProps> = ({ variant, linkList, LoggedDropdonw, hideMenu }: MobileProps) => {
  const { t } = useTranslation();

  const history = useHistory();

  const mobileDropdonwLp = linkList?.concat({
    name: 'Entrar',
    link: RoutePath.LOGIN,
  });
  const loggedMobileDropdonwLp = LoggedDropdonw && linkList?.concat(LoggedDropdonw);

  const renderContent = () => {
    switch (variant) {
      case HeaderVariants.PRIMARY:
        return (
          <HeaderContainer variant={variant}>
            <HeaderContent variant={variant}>
              <AlignBox>
                <LogoBox onClick={() => history.push(RoutePath.HOME)}>
                  <Logo isWhite={false} />
                </LogoBox>
              </AlignBox>
              <Popup
                trigger={
                  <LogoBox>
                    <MenuIcon />
                  </LogoBox>
                }
                position="bottom right"
                arrow={false}
              >
                {AuthUtils.isAuthenticated() ? (
                  <DropdownContainer>
                    {loggedMobileDropdonwLp?.map((item, idx, arr) => (
                      <div key={`link-list-${idx}`}>
                        <DropdownItem onClick={() => (item.link ? history.push(item.link) : goTo(item?.sectionId))}>
                          <DropdownText>{item.text ? item.text : item.name}</DropdownText>
                        </DropdownItem>
                        <Separator isLast={idx === arr.length - 1} />
                      </div>
                    ))}
                  </DropdownContainer>
                ) : (
                  <DropdownContainer>
                    {mobileDropdonwLp?.map((item, idx, arr) => (
                      <div key={`link-list-${idx}`}>
                        <DropdownItem onClick={() => (item.link ? history.push(item.link) : goTo(item?.sectionId))}>
                          <DropdownText>{item.name}</DropdownText>
                        </DropdownItem>
                        <Separator isLast={idx === arr.length - 1} />
                      </div>
                    ))}
                  </DropdownContainer>
                )}
              </Popup>
            </HeaderContent>
          </HeaderContainer>
        );

      case HeaderVariants.SECONDARY:
        return (
          <HeaderContainer variant={variant}>
            <HeaderContent variant={variant}>
              <LogoBox onClick={() => (AuthUtils.isAdmin() ? history.push(RoutePath.ADMIN_HOME) : history.push(RoutePath.HOME))}>
                <Logo isWhite={true} />
              </LogoBox>

              {!hideMenu && (
                <Popup trigger={<MenuIcon />} position="bottom center" on="click" arrow={false}>
                  {() => (
                    <DropdownContainer>
                      {linkList?.map((item, idx, arr) => (
                        <div key={`link-list-${idx}`}>
                          <DropdownItem>
                            <DropdownText>{item.name}</DropdownText>
                          </DropdownItem>
                          <Separator isLast={idx === arr.length - 1} />
                        </div>
                      ))}
                    </DropdownContainer>
                  )}
                </Popup>
              )}

              {AuthUtils.isAuthenticated() ? (
                <UserDropdown list={LoggedDropdonw} />
              ) : (
                <LoggedUserBox isAuth={AuthUtils.isAuthenticated()} onClick={() => history.push(RoutePath.LOGIN)}>
                  <ProfileImage src={GeneralImages.iconDefault} />
                  <StyledText>{t('global.button.login')}</StyledText>
                </LoggedUserBox>
              )}
            </HeaderContent>
          </HeaderContainer>
        );

      case HeaderVariants.TERTIARY:
        return (
          <HeaderContainer variant={variant}>
            <HeaderContent variant={variant}>
              <LogoBox onClick={() => (AuthUtils.isAdmin() ? history.push(RoutePath.ADMIN_HOME) : history.push(RoutePath.HOME))}>
                <Logo isWhite={true} />
              </LogoBox>

              <Popup trigger={<MenuIcon />} position="bottom center" on="click" arrow={false}>
                {() => (
                  <DropdownContainer>
                    {linkList?.map((item, idx, arr) => (
                      <div key={`link-list-${idx}`}>
                        <DropdownItem>
                          <DropdownText>{item.name}</DropdownText>
                        </DropdownItem>
                        <Separator isLast={idx === arr.length - 1} />
                      </div>
                    ))}
                  </DropdownContainer>
                )}
              </Popup>
            </HeaderContent>
          </HeaderContainer>
        );

      default:
        return <></>;
    }
  };

  return renderContent();
};

export default Mobile;
