import { yupResolver } from '@hookform/resolvers/yup';
import { Grid } from '@mui/material';
import CustomButton from 'components/custom-button';
import CustomInputNumbersOnly from 'components/custom-input-numbers-only';
import CustomTextField from 'components/custom-text-field';
import Header from 'components/general-components/header';
import { HeaderVariants } from 'model/enums/header-variants';
import TypesMask from 'model/enums/types-mask';
import { Procedure } from 'model/financing';
import { ProcedureRequest } from 'model/procedure';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import financingService from 'services/financing-service';
import StringUtils from 'shared/util/string-utils';
import * as yup from 'yup';
import { PageContainer, StyledButtonContainer, StyledCardContainer, StyledInnerContainer, StyledMainTitle, StyledText } from './styles';

export const AddProcedureScreen = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const [loading, setLoading] = useState(false);

  const schema = yup.object({
    choosedValue: yup.string().required(),
    medicalProcedure: yup.string().required(),
    installmentsTotal: yup
      .string()
      .required()
      .test('test-maxValue', t('global.errorMessage.max60'), value => validateMaxStringNumber(value!)),
  });

  function validateMaxStringNumber(value: string) {
    const numberValue = Number(value);
    if (numberValue > 60) {
      return false;
    }
    return true;
  }

  const methods = useForm<Procedure>({ resolver: yupResolver(schema), mode: 'onBlur' });

  const onHandleSubmit = async (values: Procedure) => {
    setLoading(true);

    values.choosedValue = StringUtils.removeMoneyFormat(values.choosedValue);
    const newProcedure: ProcedureRequest = {
      choosedValue: Number(values.choosedValue),
      installmentsTotal: Number(values.installmentsTotal),
      medicalProcedure: values.medicalProcedure,
      client: {
        id: Number(id),
      },
    };

    const res = await financingService.createProcedureAdmin(newProcedure);
    if (res.id) {
      setTimeout(() => {
        history.push(`/admin/cadastros/${id}`);
        setLoading(false);
      }, 500);
    }
  };

  return (
    <PageContainer>
      <Header variant={HeaderVariants.SECONDARY} />
      <StyledInnerContainer>
        <StyledMainTitle>{t('myProcedures.addNewProcedure')}</StyledMainTitle>
        <StyledText>{t('myProcedures.fillProcedureDataToProceed')}</StyledText>
        <StyledCardContainer>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(formData => onHandleSubmit(formData))} id="procedure-form">
              <Grid container rowSpacing={1} columnSpacing={3}>
                <Grid item xs={12}>
                  <CustomTextField
                    name="medicalProcedure"
                    label={t('myProcedures.procedure')}
                    helperText={methods.formState.errors.medicalProcedure?.message}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CustomInputNumbersOnly
                    name="choosedValue"
                    label={t('myProcedures.procedureValue')}
                    helperText={methods.formState.errors.choosedValue?.message}
                    mask={TypesMask.MONETARY_VALUE}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CustomInputNumbersOnly
                    name="installmentsTotal"
                    label={t('myProcedures.numberOfInstallments')}
                    helperText={methods.formState.errors.installmentsTotal?.message}
                  />
                </Grid>
              </Grid>
            </form>
          </FormProvider>
        </StyledCardContainer>
        <StyledButtonContainer>
          <CustomButton
            style={{ justifyContent: 'center' }}
            height={48}
            width="160px"
            onClick={() => history.push(`/admin/cadastros/${id}`)}
            isInvertColor
          >
            {t('global.button.cancel')}
          </CustomButton>
          <CustomButton style={{ justifyContent: 'center' }} height={48} width="160px" form="procedure-form" loading={loading}>
            {t('global.button.add')}
          </CustomButton>
        </StyledButtonContainer>
      </StyledInnerContainer>
    </PageContainer>
  );
};
