import styled from 'styled-components/macro';

export const EditContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
`;

export const ButtonsContainer = styled.div`
  button {
    border: none;
    background-color: transparent;
    padding: 0;
    margin: 0;
  }

  > button.edit {
    margin-right: 16px;
  }
`;

export const FormContainer = styled.form`
  > div {
    margin-bottom: 16px;
  }
`;

export const InputContent = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 8px;

  > label {
    min-width: 306px;
  }
`;

export const StyledAutocompleteContainer = styled.div`
  width: 306px;
`;

export const PaintSvg = styled.span`
  &.plusSvg {
    svg path:nth-child(1),
    svg path:nth-child(2) {
      fill: ${({ theme }) => theme.color.primaryColor};
    }
  }
`;
