import { useEffect, useRef, useState } from 'react';
import Calendar from 'react-calendar';
import { CalendarIcon } from 'images/calendar/index';
import { ButtonsContainer, CalendarContainer, FilterItem, OptionContainer, StyledHr } from './styles';
import { WithTranslation, withTranslation } from 'react-i18next';
import { ReactComponent as ChevronDownIconSvg } from 'images/chevron-down.svg';
import { ReactComponent as EllipseIconSvg } from 'images/red-ellipse.svg';

interface Props extends WithTranslation {
  placeholder: string;
  onChangeValue: (date: Date) => void;
  onCleanFilter: () => void;
  isActive?: boolean;
  date: Date | null;
}

const CustomFilterCalendar = ({ date, onCleanFilter, placeholder, onChangeValue, isActive, t }: Props) => {
  const [showCalendar, setShowCalendar] = useState(false);
  const filterItemRef = useRef<HTMLButtonElement>(null);
  const calendarContainerRef = useRef<HTMLDivElement>(null);

  const handleClick = () => {
    setShowCalendar(!showCalendar);
  };

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        calendarContainerRef.current &&
        filterItemRef.current &&
        !calendarContainerRef.current.contains(event.target as Node) &&
        !filterItemRef.current.contains(event.target as Node)
      ) {
        setShowCalendar(false);
      }
    }

    if (showCalendar) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showCalendar]);

  return (
    <OptionContainer>
      <FilterItem onClick={handleClick} ref={filterItemRef}>
        <CalendarIcon isWhite={false} />
        {placeholder}
        {isActive && <EllipseIconSvg />}
        <ChevronDownIconSvg style={{ marginBottom: '-2px' }} />
      </FilterItem>
      {showCalendar && (
        <CalendarContainer
          filterItemOffsetLeft={filterItemRef?.current?.offsetLeft ?? 0}
          width={filterItemRef?.current?.offsetWidth ?? 0}
          ref={calendarContainerRef}
        >
          <Calendar onChange={(value: Date) => onChangeValue(value)} value={date} maxDate={new Date()} />
          <StyledHr />
          <ButtonsContainer>
            <button
              className="clean"
              onClick={() => {
                onCleanFilter();
                setShowCalendar(false);
              }}
            >
              {t('global.button.clean')}
            </button>
            <button
              className="apply"
              onClick={() => {
                setShowCalendar(false);
              }}
            >
              {t('global.button.apply')}
            </button>
          </ButtonsContainer>
        </CalendarContainer>
      )}
    </OptionContainer>
  );
};

export default withTranslation()(CustomFilterCalendar);
