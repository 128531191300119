import styled from 'styled-components/macro';

export const Container = styled.div`
  padding: 5px 15px;

  display: flex;
  align-items: center;
  justify-content: center;

  > div,
  svg {
    width: 40px !important;
    height: 35px !important;
  }
`;
