import { CreateFinancingResponse } from 'model/create-financing-response';
import { Authority } from 'model/enums/authority';
import { RegistrationStatus } from 'model/enums/registration-status';
import { FinancingSimpleSimulation } from 'model/financing';
import React, { createContext, useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducer';
import { ORGANIZATION_NAME } from 'config/constants';
import { OrganizationName } from 'model/enums/organization-name';
import { useSendDataToSimulator } from 'components/simulator-data/hooks/use-send-data-to-simulator';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import useSimulatorStorage from 'hooks/use-simulator-storage';
import { useAuthorities } from 'hooks/use-authorities';
export enum SimulatorLoadingStage {
  NONE = 'NONE',
  SIMULATING = 'SIMULATING',
  SIMULATING_INSURANCE = 'SIMULATING_INSURANCE',
  SUBMITTING_SIMULATION = 'SUBMITTING_SIMULATION',
  SUBMITTING_SIMULATION_INSURANCE = 'SUBMITTING_SIMULATION_INSURANCE',
}

const DEFAULT_VALUE: ConfirmSimulationProps = {
  limitMonthError: false,
  hasSimulationValueChanged: false,
  newSimulation: {},
  loadingStage: SimulatorLoadingStage.NONE,
  setLimitMonthError: () => {},
  setHasSimulationValueChanged: () => {},
  setNewSimulation: () => {},
  setLoadingStage: () => {},
  resetLoadingState: () => {},
};

interface ConfirmSimulationProps {
  limitMonthError: boolean;
  hasSimulationValueChanged: boolean;
  newSimulation: FinancingSimpleSimulation;
  loadingStage: SimulatorLoadingStage;
  setLimitMonthError: (value: boolean) => void;
  setHasSimulationValueChanged: (value: boolean) => void;
  setNewSimulation: (value: FinancingSimpleSimulation) => void;
  setLoadingStage: (value: SimulatorLoadingStage) => void;
  resetLoadingState: () => void;
}

const ConfirmSimulationContext = createContext<ConfirmSimulationProps>(DEFAULT_VALUE);
const useConfirmSimulationContext = () => useContext(ConfirmSimulationContext);

const ConfirmSimulationContextProvider: React.FC = ({ children }) => {
  const [hasSimulationValueChanged, setHasSimulationValueChanged] = useState<boolean>(false);
  const [newSimulation, setNewSimulation] = useState<FinancingSimpleSimulation>({});
  const [limitMonthError, setLimitMonthError] = useState<boolean>(false);
  const [loadingStage, setLoadingStage] = useState<SimulatorLoadingStage>(SimulatorLoadingStage.NONE);

  const resetLoadingState = () => {
    setLoadingStage(SimulatorLoadingStage.NONE);
  };

  return (
    <ConfirmSimulationContext.Provider
      value={{
        limitMonthError,
        hasSimulationValueChanged,
        newSimulation,
        loadingStage,
        setHasSimulationValueChanged,
        setLoadingStage,
        setLimitMonthError,
        setNewSimulation,
        resetLoadingState,
      }}
    >
      {children}
    </ConfirmSimulationContext.Provider>
  );
};

export { ConfirmSimulationContextProvider, useConfirmSimulationContext };
