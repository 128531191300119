import styled from 'styled-components/macro';

interface Props {
  hasError?: boolean;
  isActive?: boolean;
  borderRadius?: boolean;
  borderRight?: boolean;
  calcWidth?: string;
}

export const StyledInputBox = styled.div<Props>`
  width: ${({ calcWidth }) => `calc(200px + ${calcWidth ?? '0px'})`};
  height: 68px;
  position: relative;

  display: flex;
  flex-direction: column;

  row-gap: 4px;
  @media (max-width: 671px) {
    width: 272px;
  }
`;

export const StyledImg = styled.img`
  width: 24px;
  margin: 20px 0px 0px 0px;
  cursor: pointer;
`;

export const StyledInput = styled.input<Props>`
  width: 100%;
  height: 44px;

  font-weight: 400;
  font-size: 16px;
  color: ${({ hasError }) => (hasError ? '#FF8389' : '#525252')};

  border: 1px solid #cccccc;
  border-radius: 8px;

  background: none;
  outline: none;

  padding: 12px 24px 12px 12px;

  z-index: 2;

  transition: all 0.2s ease-out;
  cursor: ${({ disabled }) => (disabled ? 'pointer' : '')};

  @media (max-width: 671px) {
    width: 248px;
  }
`;

export const StyledError = styled.div`
  width: 188px;
  position: absolute;

  font-weight: 400;
  font-size: 12px;
  color: #ffb3b8;

  bottom: -18px;
  @media (max-width: 671px) {
    width: 272px;
  }
`;

export const StyledLabel = styled.label<Props>`
  height: 20px;

  font-weight: 500;
  font-size: 14px;
  color: #656666;

  display: flex;
  align-items: center;
`;

export const InputStatus = styled.div`
  width: 100%;
  height: 44px;

  border: 1px solid #cccccc;
  border-radius: 8px;

  padding: 12px;
`;
