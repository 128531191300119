import styled from 'styled-components/macro';

export const Container = styled.div`
  width: 100%;
  margin-bottom: 100px;
`;
export const Title = styled.span`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  display: flex;
  align-items: center;
  color: #1b1a1b;
`;
export const Subtitle = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  display: flex;
  align-items: center;
  color: #1b1a1b;
  max-width: 650px;
`;
export const IdentityContainer = styled.div`
  width: 100%;
  padding: 5px 16px;
  max-height: 520px;
`;
export const ModalVerseContainer = styled.div`
  width: 98%;
  padding: 5px 16px;
  position: absolute;
  background-color: white;
  height: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 27px;
  border: 1px solid red;
  span {
    font-size: 15px;
    text-align: center;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    display: flex;
    align-items: center;
    color: #1b1a1b;
  }
`;
export const ProofsContainer = styled.div`
  margin-top: 10px;
  padding: 15px 16px;
`;
export const InfoContainer = styled.div`
  display: flex;
  ul {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;
