import SystemStepCategory from 'model/enums/system-step-category';
import { StepCard } from './styles';
import { Myaccounthistories } from 'model/enums/my-account';
import { useTranslation } from 'react-i18next';

interface StepDisplayProps {
  step: Myaccounthistories;
  selectedStep: Myaccounthistories | null;
  setSelectedStep: (step: Myaccounthistories) => void;
  stepName: string;
}

export const StepDisplay = ({ step, selectedStep, setSelectedStep, stepName }: StepDisplayProps) => {
  const { t } = useTranslation();

  return (
    <>
      <StepCard onClick={() => setSelectedStep(step)} selected={selectedStep === step}>
        {t(`admin.myAccount.${stepName}`)}
      </StepCard>
    </>
  );
};
