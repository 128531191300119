import styled from 'styled-components/macro';

export const StyledFoundData = styled.div`
  width: 480px;

  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 671px) {
    width: 320px;
    padding: 0px 12px;
  }
`;
