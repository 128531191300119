import styled from 'styled-components/macro';

export const PhotoContainer = styled.div`
  align-items: center;
  justify-content: center;
  padding: 16px 0;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 16px;
`;

export const Title = styled.span`
  font-weight: 700;
  font-size: 20px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 30px;
`;

export const CameraContainer = styled.div`
  video {
    max-height: 350px;
    max-width: 100%;
  }
`;
