import { ORGANIZATION_NAME, ORGANIZATION_TYPE } from 'config/constants';
import AdminFiltersContext from 'context/admin-filters';
import _ from 'lodash';
import { SignatureDashboard } from 'model/dashboard';
import { CreatedDates } from 'model/date';
import { OrganizationName } from 'model/enums/organization-name';
import { useAdmin } from 'provider/admin-general';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { DashboardService } from 'services/dashboard-service';
import { getStorageFilter } from 'shared/util/filter-utils';
import { Status } from '../../components/status';
import { TableId, NewTableList, TableValues } from '../../components/table-list-new';
import { PageableResponse } from 'model/pageable';
import { NewTitleHeader } from '../../components/title-header-new';
import { PersonSignatureStatusTypes } from 'model/enums/person-signature-status';
import StringUtils from 'shared/util/string-utils';

const filterStatusValues = [
  PersonSignatureStatusTypes.AWAITING_SUBMISSION_FOR_SIGNATURE,
  PersonSignatureStatusTypes.SENT_FOR_SIGNATURE,
  PersonSignatureStatusTypes.ERROR_ANTI_FRAUD_OCR,
  PersonSignatureStatusTypes.ERROR_ANTI_FRAUD_FACE_MATCH,
  PersonSignatureStatusTypes.ERROR_ANTI_FRAUD_LIVENESS,
  PersonSignatureStatusTypes.DONE,
  PersonSignatureStatusTypes.CANCELED,
];

export const SignatureresList = () => {
  const [signatures, setSigantures] = useState<SignatureDashboard[]>([]);
  const [signaturesResponse, setSiganturesResponse] = useState<PageableResponse<SignatureDashboard>>();

  const [searchName, setSearchName] = useState<string>('');
  const [searchOption, setSearchOption] = useState<string>('name');
  const [searchSubsidiaryName, setSearchSubsidiaryName] = useState<string>('');
  const [page, setPage] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const { statusFilters, createdDates, handleSetNewStatus } = useAdmin();
  const { t } = useTranslation();
  const history = useHistory();
  const storageFilters = getStorageFilter();
  const [orderBy, setOrderBy] = useState<boolean>(false);
  const [loadingTable, setLoadingTable] = useState<boolean>(false);

  const isNovoSaque = ORGANIZATION_NAME === OrganizationName.NOVOSAQUE;

  const { filters } = useContext(AdminFiltersContext);

  useEffect(() => {
    verifyStorageAndGetClients();
  }, [searchName, searchOption, page, orderBy, searchSubsidiaryName, filters]);

  const verifyStorageAndGetClients = () => {
    let defaultFilters = statusFilters;

    if (storageFilters?.statusFilters && storageFilters?.statusFilters?.length > 0 && !statusFilters.length) {
      defaultFilters = storageFilters.statusFilters;
      handleSetNewStatus(defaultFilters);
    }

    getSiganturesList({
      name: searchName,
      page,
      registrationStatus: defaultFilters,
      createdDates: filters.dateRange ?? createdDates,
      subsidiaryName: searchSubsidiaryName,
      searchOption: searchOption,
    });
  };

  const getSiganturesList = async (props?: {
    name?: string;
    size?: number;
    page?: number;
    registrationStatus?: string[];
    createdDates?: CreatedDates;
    subsidiaryName?: string;
    searchOption?: string;
  }) => {
    setLoadingTable(true);
    const res = await DashboardService().getSiganturesList(
      props?.name,
      10,
      props?.page,
      props?.registrationStatus,
      props?.createdDates,
      orderBy,
      props?.subsidiaryName,
      props?.searchOption
    );
    setLoadingTable(false);
    setSiganturesResponse(res);
    setSigantures(res.content);
    setPage(res.pageable.pageNumber);
    setTotalPages(res.totalPages);
  };

  const setTableValues = () => {
    const tableValues: TableValues[] = [
      {
        header: t('admin.dashboard.tableHeaders.name'),
        values: signatures.map(signature => signature.client?.physicalPerson?.name ?? ''),
        ids: signatures.map(signature => ({ first: signature.id })),
        onClick: (ids?: TableId) => {
          if (ids) {
            history.push(`/admin/assinaturas/${ids.first}`);
          }
        },
      },
      {
        header: t('admin.dashboard.tableHeaders.shipmentDate'),
        values: signatures.map(signature => StringUtils.apiDateFormatMask(signature.contractSignatory?.contractSign?.sendToSignDate)),
        ids: signatures.map(signature => ({ first: signature.id })),
        onClick: (ids?: TableId) => {
          if (ids) {
            history.push(`/admin/assinaturas/${ids.first}`);
          }
        },
      },
      {
        header: t('admin.dashboard.tableHeaders.deadline'),
        values: signatures.map(signature => StringUtils.apiDateFormatMask(signature.contractSignatory?.contractSign?.deadline) ?? ''),
        ids: signatures.map(signature => ({ first: signature.id })),
        onClick: (ids?: TableId) => {
          if (ids) {
            history.push(`/admin/assinaturas/${ids.first}`);
          }
        },
      },
      {
        header: t('admin.dashboard.tableHeaders.contract'),
        values: signatures.map(signature => signature.contractSignatory?.contractSign?.contract?.attachment?.fileName ?? ''),
        ids: signatures.map(signature => ({ first: signature.id })),
        onClick: (ids?: TableId) => {
          if (ids) {
            history.push(`/admin/assinaturas/${ids.first}`);
          }
        },
      },
      {
        header: t('admin.dashboard.tableHeaders.status'),
        values: signatures.map(signature => (
          <Status
            key={signature.id}
            text={
              signature?.personSignatureStatus
                ? t(`enum.signatureStatus.${signature.personSignatureStatus}`)
                : t(`enum.signatureStatus.AWAITING_SUBMISSION_FOR_SIGNATURE`)
            }
            color={signature.personSignatureStatus ?? 'AWAITING_SUBMISSION_FOR_SIGNATURE'}
          />
        )),
        ids: signatures.map(signature => ({ first: signature.id })),
        onClick: (ids?: TableId) => {
          if (ids) {
            history.push(`/admin/assinaturas/${ids.first}`);
          }
        },
      },
    ];

    return tableValues;
  };

  const updateSearchName = _.debounce((name: string) => {
    setSearchName(name);
  }, 500);

  const updateSubsidiaryName = _.debounce((name: string) => {
    setSearchSubsidiaryName(name);
  }, 500);

  const setNewPage = _.debounce((newPage: number) => {
    if (newPage >= totalPages) {
      return setPage(page);
    }
    setPage(newPage);
  }, 500);

  const setNewFilter = () => {
    getSiganturesList({ name: searchName, registrationStatus: statusFilters, createdDates, searchOption });
  };

  const cleanAppliedFilters = () => {
    getSiganturesList({ name: searchName });
  };

  return (
    <>
      <NewTitleHeader
        title={t('admin.dashboard.signatures')}
        setSearch={updateSearchName}
        setSubsidiarySearch={updateSubsidiaryName}
        setFilters={setNewFilter}
        filterStatusValues={filterStatusValues}
        cleanFilters={cleanAppliedFilters}
        orderBy={orderBy}
        setOrderBy={setOrderBy}
        searchOption={searchOption}
        setSearchOption={setSearchOption}
      />
      <NewTableList
        setLoadingTable={setLoadingTable}
        loadingTable={loadingTable}
        tableValues={setTableValues()}
        page={page}
        signaturesResponse={signaturesResponse}
        setNewPage={setNewPage}
        noPagination={totalPages === 1}
      />
    </>
  );
};
