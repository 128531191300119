import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.color.brightnessPrimary};
  padding: 20px 32px;
  border: 1px solid ${({ theme }) => theme.color.gray20};
  border-radius: 8px;
  box-shadow: 0px 2px 12px ${({ theme }) => theme.color.shadow};
  min-width: 470px;
  min-height: 180px;
  position: relative;
  overflow: hidden;
`;

export const SvgBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const Title = styled.span`
  font-weight: 500;
  font-size: 24px;
  line-height: 150%;
  color: ${({ theme }) => theme.color.gray19};
`;

export const RedirectButton = styled(Link)`
  text-decoration: none;
  font-weight: 700;

  font-size: 14px;
  line-height: 150%;
  color: ${({ theme }) => theme.color.primaryColor};

  :hover {
    color: ${({ theme }) => theme.color.primaryColorDarker};
  }
`;

export const QuantityContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const Total = styled.span`
  font-style: normal;
  font-weight: 700;
  font-size: 64px;
  line-height: 150%;
  color: ${({ theme }) => theme.color.black0};
`;

export const StatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-height: 90px;
  width: 250px;

  align-items: stretch;

  div > span {
    font-size: 12px;
    line-height: 150%;
  }

  div > span.quantity {
    color: ${({ theme }) => theme.color.gray19};
    margin-right: 4px;
    font-weight: 700;
  }

  div > span.statusLabel {
    color: ${({ theme }) => theme.color.gray22};
    font-weight: 500;
  }
`;
