import styled from 'styled-components/macro';

export const Container = styled.div`
  display: flex;
  border: 1px solid #eeeeee;
  border-radius: 8px;
  padding: 8px 0;
`;

export const ContainerInput = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
`;

export const Input = styled.input`
  display: none;
`;

export const PaintSvg = styled.span`
  svg path {
    border: 1px solid red !important;
    fill: ${({ theme }) => theme.color.primaryColor};
  }

  svg circle {
    fill: ${({ theme }) => theme.color.primaryColor};
  }
`;

export const Title = styled.div`
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  color: #393939;
`;

export const Text = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: #393939;
`;
