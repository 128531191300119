import { AnchorHTMLAttributes } from 'react';
import { StyledLink } from './styles';

interface Props extends AnchorHTMLAttributes<HTMLAnchorElement> {
  url: string;
  width: string;
}

const LinkWithButtonAppearance = ({ url, width, ...rest }: Props) => {
  return (
    <StyledLink href={url} width={width} target="_blank" {...rest}>
      {rest.children}
    </StyledLink>
  );
};

export default LinkWithButtonAppearance;
