import Slider from 'components/slider';
import { SimulatorImages } from 'images/simulator';
import TypesPlusMinus from 'model/enums/types-plus-minus';
import { FinancingSimpleSimulation } from 'model/financing';
import { SimulatorParameters } from 'model/landing-page';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import landingPageService from 'services/landing-page-service';
import { unMaskedCurrency } from 'shared/util/register-utils';
import StringUtils from 'shared/util/string-utils';
import SimulatorButton from '../forms/form-button';
import {
  DesiredTime,
  DesiredValueButtons,
  DesiredValueContainer,
  SimulatorContainer,
  SimulatorTitle,
  SliderValue,
  StyledFirstValueContainer,
  StyledPreSimulatorButton,
  StyledSubtitle,
  Terms,
} from '../style';

interface SimulatorSliderProps {
  steps: string[];
  currentStep: number;
  setCurrentStep: (step: number) => void;

  simulatorData?: SimulatorParameters;
  simulatorSaveData?: FinancingSimpleSimulation;

  setSimulatorSaveData: (data: FinancingSimpleSimulation) => void;
  isAdmin?: boolean;
}

const SimulatorSlider: React.FC<SimulatorSliderProps> = ({
  steps,
  currentStep,
  setCurrentStep,
  simulatorData,
  simulatorSaveData,
  setSimulatorSaveData,
  isAdmin = false,
}) => {
  const { t } = useTranslation();
  const simulatorInstallmentOptions = simulatorData?.simulatorInstallmentOptions.sort((a, b) => a.installmentValue - b.installmentValue);
  const [indexChosenMounth, setindexChosenMounth] = useState<number>(0);
  const [firstPaymentValue, setFirstPaymentValue] = useState<number>();

  const setTag = tag => {
    if (simulatorSaveData?.installmentsTotal !== tag) {
      setSimulatorSaveData({ ...simulatorSaveData, installmentsTotal: tag });
    }
  };

  useEffect(() => {
    if (simulatorSaveData?.choosedValue === undefined && simulatorData) {
      setSimulatorSaveData({
        ...simulatorSaveData,
        choosedValue: simulatorData.minValue,
      });
    }
    if (!simulatorSaveData?.installmentsTotal && simulatorData) {
      setSimulatorSaveData({
        ...simulatorSaveData,
        installmentsTotal: simulatorData?.simulatorInstallmentOptions?.[0]?.installmentValue,
      });
    }
  }, [simulatorData, simulatorSaveData]);

  const handleChange = (event: React.SyntheticEvent | Event, newValue: number | number[]) => {
    if (typeof newValue === 'number') {
      setSimulatorSaveData({ ...simulatorSaveData, choosedValue: newValue });
    }
  };

  const valueChange = e => {
    if (simulatorData) {
      setSimulatorSaveData({ ...simulatorSaveData, choosedValue: unMaskedCurrency(e.target.value) / 100 });
    }
  };

  const handleBlur = e => {
    setSimulatorSaveData({
      ...simulatorSaveData,
      choosedValue:
        unMaskedCurrency(StringUtils.verifyMinAndMaxValue(e.target.value, simulatorData?.minValue, simulatorData?.maxValue)) / 100,
    });
  };

  const canPlus = indexChosenMounth !== (simulatorInstallmentOptions?.length || 0) - 1;
  const canMinus = indexChosenMounth !== 0;

  const buttonAction = action => {
    if (simulatorData) {
      if (action === TypesPlusMinus.PLUS) {
        if (canPlus) {
          setindexChosenMounth(indexChosenMounth + 1);
        }
      } else {
        if (canMinus) {
          setindexChosenMounth(indexChosenMounth - 1);
        }
      }
    }
  };

  useEffect(() => {
    if (simulatorInstallmentOptions) {
      setTag(simulatorInstallmentOptions[indexChosenMounth]?.installmentValue);
    }
  }, [indexChosenMounth]);

  const sendPreSimulator = () => {
    if (simulatorInstallmentOptions && simulatorSaveData?.choosedValue) {
      landingPageService
        .preSimulation({
          installmentsTotal: simulatorInstallmentOptions[indexChosenMounth].installmentValue,
          choosedValue: simulatorSaveData.choosedValue,
        })
        .then(response => setFirstPaymentValue(response.valor_primeira_parcela));
    } else if (simulatorInstallmentOptions && simulatorData?.minValue) {
      landingPageService
        .preSimulation({
          installmentsTotal: simulatorInstallmentOptions[indexChosenMounth].installmentValue,
          choosedValue: simulatorData?.minValue,
        })
        .then(response => setFirstPaymentValue(response.valor_primeira_parcela));
    }
  };

  useEffect(() => {
    if (firstPaymentValue) {
      setFirstPaymentValue(undefined);
    }
  }, [simulatorSaveData, indexChosenMounth]);

  return (
    <>
      <SimulatorContainer>
        <SimulatorTitle>{t('landingPage.openingScreen.whatValue')}</SimulatorTitle>
        <SliderValue
          value={
            simulatorData?.fixedValue
              ? StringUtils.currencyMask(simulatorData?.fixedValue?.toFixed(2))
              : simulatorSaveData?.choosedValue && StringUtils.currencyMask(simulatorSaveData?.choosedValue?.toFixed(2))
          }
          onChange={valueChange}
          onBlur={handleBlur}
        />
        <Slider
          min={simulatorData?.minValue}
          max={simulatorData?.maxValue}
          value={(simulatorData?.fixedValue ? simulatorData?.fixedValue : simulatorSaveData?.choosedValue) ?? 0}
          handleChange={handleChange}
          disabled={!(simulatorData?.fixedValue == null)}
        />
        <StyledSubtitle>{t('landingPage.openingScreen.timeToPay')}</StyledSubtitle>
        <DesiredValueContainer>
          <DesiredValueButtons isActive={canMinus} onClick={() => buttonAction(TypesPlusMinus.MINUS)} disabled={!canMinus}>
            <SimulatorImages.minus2 />
          </DesiredValueButtons>
          <DesiredTime
            disabled
            value={
              simulatorInstallmentOptions && simulatorInstallmentOptions[indexChosenMounth]
                ? simulatorInstallmentOptions[indexChosenMounth].installmentValue > 1
                  ? `${simulatorInstallmentOptions[indexChosenMounth].installmentValue} ${t(
                      'landingPage.openingScreen.simulatorSlider.meses'
                    )}`
                  : `${simulatorInstallmentOptions[indexChosenMounth].installmentValue} ${t(
                      'landingPage.openingScreen.simulatorSlider.mes'
                    )}`
                : '--'
            }
          />
          <DesiredValueButtons isActive={canPlus} onClick={() => buttonAction(TypesPlusMinus.PLUS)} disabled={!canPlus}>
            <SimulatorImages.plus2 />
          </DesiredValueButtons>
        </DesiredValueContainer>
        {firstPaymentValue && (
          <div>
            <StyledSubtitle>{t('landingPage.openingScreen.valueOfPortion')}</StyledSubtitle>
            <StyledFirstValueContainer>{StringUtils.currencyMask(firstPaymentValue.toFixed(2))}</StyledFirstValueContainer>
          </div>
        )}
        {!isAdmin && (
          <Terms>
            {t('landingPage.openingScreen.simulatorSlider.mensTerm')}
            <a href="https://credit-flow-logos-public-access.s3.amazonaws.com/TERMOS+DE+USO+DA+PLATAFORMA+CREDITFLOW.pdf" target={'_blank'}>
              {t('landingPage.openingScreen.simulatorSlider.termsOfUse')}
            </a>
            {t('landingPage.openingScreen.simulatorSlider.and')}
            <a href="https://esparta.s3.amazonaws.com/Pol%C3%ADtica+de+Privacidade+-+CreditFlow.pdf" target={'_blank'}>
              {t('landingPage.openingScreen.simulatorSlider.privacyPolicy')}
            </a>
          </Terms>
        )}
        {simulatorData?.hasPreSimulator && !firstPaymentValue ? (
          <StyledPreSimulatorButton onClick={sendPreSimulator}>
            {t('landingPage.openingScreen.simulateCreditPlus')}
          </StyledPreSimulatorButton>
        ) : (
          <SimulatorButton
            isLast={false}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            disabled={!simulatorSaveData?.installmentsTotal}
          />
        )}
      </SimulatorContainer>
    </>
  );
};

export default SimulatorSlider;
