import React, { ReactNode, createContext, useEffect, useState } from 'react';
import WaitPageStatus from 'model/enums/wait-page-status';
import IGeolocalization from 'model/geolocalization';
import networkDataService from 'services/network-data-service';
import IContractDetails from 'model/contract-details';
import { isEmpty } from 'lodash';
import { IdentityDocumentTypes } from 'model/enums/identity-document-type';

interface PropsContext {
  contractResponse: IContractDetails | null;
  setContractResponse: React.Dispatch<React.SetStateAction<IContractDetails | null>>;
  personSignatureId: number | null;
  setPersonSignatureId: React.Dispatch<React.SetStateAction<number | null>>;
  localization: IGeolocalization;
  setLocalization: React.Dispatch<React.SetStateAction<IGeolocalization>>;
  signatoryIp: string;
  setSignatoryIp: React.Dispatch<React.SetStateAction<string>>;
  waitPageStatus: WaitPageStatus;
  setWaitPageStatus: React.Dispatch<React.SetStateAction<WaitPageStatus>>;
  documentPresignUrl?: string;
  setDocumentPresignUrl: React.Dispatch<React.SetStateAction<string | undefined>>;
  signatureKey?: string;
  setSignatureKey: React.Dispatch<React.SetStateAction<string | undefined>>;
  getGeolocalization: () => void;
  getSignatoryIp: () => Promise<void>;
  signatoryDocument?: string;
  setSignatoryDocument: React.Dispatch<React.SetStateAction<string | undefined>>;
  sendSignatoryLocationData: () => void;
  identityDocument?: IdentityDocumentTypes;
  setIdentityDocument: React.Dispatch<React.SetStateAction<IdentityDocumentTypes | undefined>>;
}

const LOCAL_STORAGE_SIGNATURE_KEY = 'signatureKey';

const ContractContext = createContext({} as PropsContext);

const ContractContextProvider: React.FC = ({ children }) => {
  const [contractResponse, setContractResponse] = useState<IContractDetails | null>(null);
  const [personSignatureId, setPersonSignatureId] = useState<number | null>(null);
  const [localization, setLocalization] = useState<IGeolocalization>({ latitude: null, longitude: null });
  const [signatoryIp, setSignatoryIp] = useState<string>('');
  const [signatoryDocument, setSignatoryDocument] = useState<string>();
  const [waitPageStatus, setWaitPageStatus] = useState<WaitPageStatus>(WaitPageStatus.WAIT_NEW_LIBERATION);
  const [documentPresignUrl, setDocumentPresignUrl] = useState<string>();
  const [signatureKey, setSignatureKey] = useState<string | undefined>(localStorage.getItem(LOCAL_STORAGE_SIGNATURE_KEY) ?? undefined);
  const [identityDocument, setIdentityDocument] = useState<IdentityDocumentTypes>();

  const getGeolocalization = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          setLocalization({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
        },
        erro => {
          console.error('Erro ao obter a geolocalização:', erro.message);
        }
      );
    } else {
      console.error('Geolocalização não suportada neste navegador.');
    }
  };

  const getSignatoryIp = async () => {
    const resultGetIp = await networkDataService.getInternetProtocol();
    if (resultGetIp.ip != null) {
      setSignatoryIp(resultGetIp?.ip);
    } else {
      networkDataService.getPublicApiInternetProtocol().then(response => setSignatoryIp(response));
    }
  };

  const sendSignatoryLocationData = () => {
    const latitude = `${localization.latitude}`;
    const longitude = `${localization.longitude}`;

    if (personSignatureId != null) {
      const data = {
        id: personSignatureId,
        locationData: {
          lat: latitude,
          lng: longitude,
          ip: signatoryIp,
        },
      };
      networkDataService.sendLocationData(data);
    }
  };

  useEffect(() => {
    if (contractResponse?.id != null) {
      setPersonSignatureId(contractResponse.id);
    }
  }, [contractResponse, setContractResponse]);

  useEffect(() => {
    if (personSignatureId != null && localization.latitude != null && localization.longitude != null && !isEmpty(signatoryIp)) {
      sendSignatoryLocationData();
    }
  }, [signatoryIp, localization, personSignatureId]);

  useEffect(() => {
    if (signatureKey) {
      localStorage.setItem(LOCAL_STORAGE_SIGNATURE_KEY, signatureKey);
    }
  }, [signatureKey]);

  return (
    <ContractContext.Provider
      value={{
        contractResponse,
        setContractResponse,
        personSignatureId,
        setPersonSignatureId,
        localization,
        setLocalization,
        signatoryIp,
        setSignatoryIp,
        waitPageStatus,
        setWaitPageStatus,
        documentPresignUrl,
        setDocumentPresignUrl,
        signatureKey,
        setSignatureKey,
        getGeolocalization,
        getSignatoryIp,
        sendSignatoryLocationData,
        signatoryDocument,
        setSignatoryDocument,
        identityDocument,
        setIdentityDocument,
      }}
    >
      {children}
    </ContractContext.Provider>
  );
};

export { ContractContextProvider };
export default ContractContext;
