import styled from 'styled-components/macro';

interface Props {
  noHeader?: boolean;
  blank?: boolean;
  internal?: boolean;
  fullLength?: boolean;
}

interface PropsColumn {
  maxWidth?: string;
}

export const Container = styled.div`
  display: flex;
  padding: 24px;
  margin-top: 24px;
  flex-direction: column;
  border-radius: 8px;
  border: 1px solid var(--gray-light-4, #eee);
  background: var(--gray-bw-white, #fff);
  min-height: 300px;
`;

export const TitleContainer = styled.div<Props>`
  width: 100%;
  gap: 40px;
  padding: 4px;
  padding-bottom: 0;
  display: flex;
  flex-wrap: wrap;
  border-bottom: 2px solid #e5e7e7;
  margin-bottom: 24px;
`;

export const StyledSeeMoreButton = styled.div`
  cursor: pointer;
  display: flex;
  height: 24px;
  width: 98px;
  gap: 8px;
  margin-left: auto;
  text-align: center;
  font-family: Satoshi;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  color: ${({ theme }) => theme.color.primaryColor};
  svg {
    width: 24px;
    height: 24px;
  }
`;
