import ReactHookFormProvider from 'components/ReactHookFormProvider';
import ContainerEditDocumentMobile from 'components/container-edit-document-mobile';
import ContainerEditUploadedDocument from 'components/container-edit-uploaded-document';
import ContainerEditUploadedDocumentMobile from 'components/container-edit-uploaded-document-mobile';
import { Column } from 'components/register-forms-mobile/styles';
import { Partner } from 'model/dashboard';
import { AttachmentType } from 'model/enums/attachment-types';
import { useEditForm } from 'provider/edit-form';
import { useTranslation } from 'react-i18next';
import StringUtils from 'shared/util/string-utils';
import * as yup from 'yup';
import { Container, IdentityContainer, ItemContainer, Title } from './styles';
import { DesktopContainer } from './styles';

interface Props {
  onlyView?: boolean;
  visible?: boolean;
  mobile?: boolean;
}

export const EditFormPartnerDocuments = ({ onlyView, visible, mobile }: Props) => {
  const { t } = useTranslation();
  const { setInitialPartnerData, initialPartnerData, onClickNext, files, isLastForm, isLoading } = useEditForm();
  const schema = yup.object({
    attachmentResume: yup.mixed(),
  });

  const onSubmit = () => {
    const partner = {
      ...initialPartnerData,
      attachments: files,
    } as Partner;
    setInitialPartnerData(partner);
  };

  const renderMobile = () => {
    return (
      <Column>
        <div>
          <Title>{t('documents.identity.title')}</Title>
          {!onlyView && (
            <ContainerEditDocumentMobile
              titleDocument="documents.identification"
              editDocument
              nameInput="doc1"
              name="attachmentIdentification"
              type={AttachmentType.IDENTIFICATION_CARD}
              title={!!files && files.find(att => att.type === AttachmentType.IDENTIFICATION_CARD)?.fileName}
              docUrl={StringUtils.getFileOrUrl(files.find(att => att.type === AttachmentType.IDENTIFICATION_CARD))}
            />
          )}
          <ContainerEditUploadedDocumentMobile editDocument={!onlyView} attachmentType={AttachmentType.IDENTIFICATION_CARD} />
        </div>
        <div>
          <Title>{t('documents.proofs.incomeProof.title')}</Title>
          {!onlyView && (
            <ContainerEditDocumentMobile
              titleDocument="documents.incomeProof"
              editDocument
              nameInput="doc2"
              name="attachmentIncome"
              type={AttachmentType.PROOF_INCOME}
              title={!!files && files.find(att => att.type === AttachmentType.PROOF_INCOME)?.fileName}
              docUrl={StringUtils.getFileOrUrl(files.find(att => att.type === AttachmentType.PROOF_INCOME))}
            />
          )}
          <ContainerEditUploadedDocumentMobile editDocument={!onlyView} attachmentType={AttachmentType.PROOF_INCOME} />
        </div>
        <div>
          <Title>{t('documents.proofs.residenceProof.title')}</Title>
          {!onlyView && (
            <ContainerEditDocumentMobile
              titleDocument="documents.residenceProof"
              editDocument
              nameInput="doc3"
              name="attachmentResidence"
              type={AttachmentType.PROOF_RESIDENCE}
              title={!!files && files.find(att => att.type === AttachmentType.PROOF_RESIDENCE)?.fileName}
              docUrl={StringUtils.getFileOrUrl(files.find(att => att.type === AttachmentType.PROOF_RESIDENCE))}
            />
          )}
          <ContainerEditUploadedDocumentMobile editDocument={!onlyView} attachmentType={AttachmentType.PROOF_RESIDENCE} />
        </div>
      </Column>
    );
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', marginLeft: '0px', width: '100%' }}>
      <Container visible={visible}>
        <ReactHookFormProvider schema={schema} onSubmit={onSubmit}>
          <IdentityContainer>
            {mobile ? (
              renderMobile()
            ) : (
              <>
                <DesktopContainer onlyView={onlyView}>
                  <ItemContainer onlyView={onlyView}>
                    <Title>{t('documents.identity.title')}</Title>
                    {!onlyView && (
                      <ContainerEditDocumentMobile
                        titleDocument="documents.identification"
                        editDocument
                        nameInput="doc1"
                        name="attachmentIdentification"
                        type={AttachmentType.IDENTIFICATION_CARD}
                        title={!!files && files.find(att => att.type === AttachmentType.IDENTIFICATION_CARD)?.fileName}
                        docUrl={StringUtils.getFileOrUrl(files.find(att => att.type === AttachmentType.IDENTIFICATION_CARD))}
                      />
                    )}
                    <ContainerEditUploadedDocument
                      editDocument={!onlyView}
                      attachmentType={AttachmentType.IDENTIFICATION_CARD}
                      onlyView={onlyView}
                    />
                  </ItemContainer>
                  <ItemContainer onlyView={onlyView}>
                    <Title>{t('documents.proofs.incomeProof.title')}</Title>
                    {!onlyView && (
                      <ContainerEditDocumentMobile
                        titleDocument="documents.incomeProof"
                        editDocument
                        nameInput="doc2"
                        name="attachmentIncome"
                        type={AttachmentType.PROOF_INCOME}
                        title={!!files && files.find(att => att.type === AttachmentType.PROOF_INCOME)?.fileName}
                        docUrl={StringUtils.getFileOrUrl(files.find(att => att.type === AttachmentType.PROOF_INCOME))}
                      />
                    )}
                    <ContainerEditUploadedDocument
                      editDocument={!onlyView}
                      attachmentType={AttachmentType.PROOF_INCOME}
                      onlyView={onlyView}
                    />
                  </ItemContainer>
                  <ItemContainer onlyView={onlyView}>
                    <Title>{t('documents.proofs.residenceProof.title')}</Title>
                    {!onlyView && (
                      <ContainerEditDocumentMobile
                        titleDocument="documents.residenceProof"
                        editDocument
                        nameInput="doc3"
                        name="attachmentResidence"
                        type={AttachmentType.PROOF_RESIDENCE}
                        title={!!files && files.find(att => att.type === AttachmentType.PROOF_RESIDENCE)?.fileName}
                        docUrl={StringUtils.getFileOrUrl(files.find(att => att.type === AttachmentType.PROOF_RESIDENCE))}
                      />
                    )}
                    <ContainerEditUploadedDocument
                      editDocument={!onlyView}
                      attachmentType={AttachmentType.PROOF_RESIDENCE}
                      onlyView={onlyView}
                    />
                  </ItemContainer>
                </DesktopContainer>
              </>
            )}
          </IdentityContainer>
        </ReactHookFormProvider>
      </Container>
    </div>
  );
};
