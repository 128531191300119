import styled from 'styled-components/macro';
interface Props {
  hasError?: boolean;
  isActive?: boolean;
  borderRadius?: boolean;
  borderRight?: boolean;
  calcWidth?: string;
}
const getColor = props => {
  if (props.isDragAccept) {
    return '#00e676';
  }
  if (props.isDragReject) {
    return '#ff1744';
  }
  if (props.isFocused) {
    return '#2196f3';
  }
  return '#cccccc';
};

export const Container = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  padding: 16px;
  border: 1px dashed ${props => getColor(props)};
  border-radius: 8px;

  background-color: transparent;
  color: #bdbdbd;

  outline: none;
  transition: border 0.24s ease-in-out;

  display: flex;
  justify-content: space-between;

  cursor: pointer;
  position: relative;
`;

export const UpladContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 8px;
`;

export const UploadIcon = styled.div`
  width: 48px;
  height: 48px;

  border-radius: 100%;
  background: #eff6ff;
  font-size: 24px;
  color: ${({ theme }) => theme.color.primaryColor};

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
`;
export const UploadTitle = styled.div`
  font-weight: 500;
  font-size: 16px;
  color: #4c4d4d;
`;
export const UploadDescription = styled.div`
  font-weight: 500;
  font-size: 12px;
  color: #b3b2b3;
`;

export const UploadButton = styled.div`
  width: 248px;
  height: 48px;

  border: 1.5px solid #0524dd;
  border-radius: 24px;

  font-weight: 700;
  font-size: 16px;
  color: ${({ theme }) => theme.color.primaryColor};

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const UploadedBox = styled.div`
  width: 288px;
  height: 44px;
  background: #eff6ff;
  border-radius: 8px;

  display: flex;
  align-items: center;

  padding: 0px 12px;

  column-gap: 8px;

  position: relative;
`;
export const UploadedImage = styled.div`
  font-size: 16px;
  color: #656666;
`;

export const DeleteUpload = styled.button`
  position: absolute;

  border: none;
  background: none;

  font-size: 16px;

  right: 12px;
`;

export const StyledInputBox = styled.div<Props>`
  width: ${({ calcWidth }) => `calc(200px + ${calcWidth ?? '0px'})`};
  height: 68px;
  position: relative;

  display: flex;
  flex-direction: column;

  row-gap: 4px;
  @media (max-width: 671px) {
    width: 272px;
  }
`;
export const StyledLabel = styled.label<Props>`
  height: 20px;

  font-weight: 500;
  font-size: 14px;
  color: #656666;

  display: flex;
  align-items: center;
`;

export const UploadedFileName = styled.div`
  font-weight: 500;
  font-size: 14px;
  color: #1b1a1b;

  cursor: pointer;
`;

export const UploadedFileLink = styled.a`
  color: #1b1a1b;
`;
export const StyledError = styled.div`
  width: 188px;
  position: absolute;

  font-weight: 400;
  font-size: 12px;
  color: #ffb3b8;

  bottom: -18px;
  left: 0px;

  @media (max-width: 671px) {
    width: 272px;
  }
`;
