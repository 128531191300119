import { yupResolver } from '@hookform/resolvers/yup';
import Header from 'components/general-components/header';
import { SearchSelectInput } from 'components/search-selected-input';
import InputText from 'components/styled-input';
import { TextInput } from 'components/text-input';
import { ORGANIZATION_NAME } from 'config/constants';
import { City, State } from 'model/address';
import { Bank } from 'model/bank-account';
import { BankAccountType } from 'model/enums/bank-account-type';
import { HeaderVariants } from 'model/enums/header-variants';
import { OrganizationName } from 'model/enums/organization-name';
import { PixType } from 'model/enums/payment-type';
import { Mask } from 'model/mask-types';
import { PaymentCompany, Subsidiary } from 'model/subsidiaries';
import { useEditForm } from 'provider/edit-form';
import { useSelectLists } from 'provider/select-list';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FiChevronLeft } from 'react-icons/fi';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import bankService from 'services/bank-service';
import { SubsidiariesService } from 'services/subsidiaries-service';
import StringUtils from 'shared/util/string-utils';
import { useTheme } from 'styled-components';
import * as yup from 'yup';
import { StyledButton } from '../../clientData/styles';
import { CheckBox } from '../../dashboard/screens/report/components/checkbox';
import { ButtonContainer, ContentTitle, InputBox, PageContainer, Separator, TabContainer, TabContent, Title, TitleHeader } from '../styles';

interface Schema {
  name: string;
  city: string;
  state: string;
  cnpj: string;
  partnerCode: string;
  paymentCompany: string;
  bankAccountNumber: string;
  bankAccountDigit: string;
  agencyNumber: string;
  agencyDigit: string;
  bankType: string;
  pixType: string;
  pixKey: string;
  street: string;
  number: string;
  zipcode: string;
  complement: string;
  referencePoint: string;
  district: string;
  assignmentFee: string;
  retentionPercentage: string;
  phone: string;
  email: string;
}

const bankAccountTypesOptions = [
  { label: BankAccountType.CHECKING_ACCOUNT, value: 'CHECKING_ACCOUNT' },
  { label: BankAccountType.SALARY_ACCOUNT, value: 'SALARY_ACCOUNT' },
  { label: BankAccountType.SAVING_ACCOUNT, value: 'SAVING_ACCOUNT' },
];

const pixOptions = [
  { label: 'CPF', value: PixType.DOCUMENT },
  { label: 'CNPJ', value: PixType.DOCUMENT },
  { label: 'E-mail', value: PixType.EMAIL },
  { label: 'Número de telefone', value: PixType.PHONE },
  { label: 'Chave aleatória', value: PixType.RANDOM_KEY },
];

export const Desktop = () => {
  const { color } = useTheme();
  const history = useHistory();
  const location = useLocation<{ edit: boolean }>();
  const [editMode, setEditMode] = useState<boolean>(false);
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const [branchData, setBranchData] = useState<Subsidiary>();
  const { states, cities, stateID, handleSetStateID, getStates, getCities, statesTotalPages, citiesTotalPages } = useSelectLists();
  const [statePage, setStatePage] = useState(0);
  const [cityPage, setCityPage] = useState(0);
  const [stateName, setStateName] = useState('');
  const [cityName, setCityName] = useState('');
  const [jointAccount, setJointAccount] = useState<boolean>(false);
  const [bankName, setBankName] = useState('');
  const [bankNames, setBankNames] = useState<Bank[]>([]);
  const [bankPage, setBankPage] = useState(0);
  const [chosenBankId, setChosenBankId] = useState<number>();
  const [banksTotalPages, setBanksTotalPages] = useState(0);
  const [defaultValuePixType, setDefaultValuePixType] = useState<string>('');
  const [paymentCompanyOptions, setPaymentCompanyOptions] = useState<PaymentCompany[]>([]);

  useEffect(() => {
    SubsidiariesService()
      .getPaymentCompanies()
      .then(res => {
        setPaymentCompanyOptions(res);
      });
  }, []);

  const { getCepData, initialClientData } = useEditForm();

  useEffect(() => {
    if (branchData?.bankAccount?.pixType === PixType.DOCUMENT) {
      if (branchData?.bankAccount?.pixType.length > 13) {
        setDefaultValuePixType('CNPJ');
      } else {
        setDefaultValuePixType('CPF');
      }
    } else {
      setDefaultValuePixType(pixOptions.find(item => item.value === branchData?.bankAccount?.pixType)?.label ?? '');
    }
  }, [branchData]);

  useEffect(() => {
    if (id != null) {
      getBranchInfos(Number(id));
    }
  }, []);

  useEffect(() => {
    if (location.state?.edit) {
      setEditMode(true);
    }
  }, []);

  useEffect(() => {
    getStates({ name: stateName, page: statePage, size: 7, ac: '' }, true);
  }, [statePage]);

  useEffect(() => {
    if (stateID) {
      getCities(stateID, { name: cityName, page: cityPage, size: 7 }, true);
    }
  }, [cityPage, statePage]);

  const getBankNames = (searchTextSendApiBankNames = '') => {
    bankService.getAllBankNames(bankPage, searchTextSendApiBankNames, '').then(response => {
      setBanksTotalPages(response.data.totalPages);
      if (bankPage > 0) {
        setBankNames([...bankNames, ...response.data.content]);
      } else {
        setBankNames(response.data.content);
      }
    });
  };

  useEffect(() => {
    getBankNames(bankName);
  }, [bankPage]);

  const getBranchInfos = async (Id: number) => {
    await SubsidiariesService()
      .getBranchInfos(Id)
      .then(res => {
        setBranchData(res);
        if (res?.bankAccount?.jointAccount) {
          setJointAccount(res.bankAccount.jointAccount);
        }
      });
  };

  const schema = yup.object().shape({
    name: yup.string().required(t('global.errorMessage.required')),
    cnpj: yup.string().required(t('global.errorMessage.required')),
    assignmentFee: yup
      .string()
      .when({ is: () => ORGANIZATION_NAME === OrganizationName.MULTTIPLO, then: yup.string().required(t('global.errorMessage.required')) }),
    phone: yup.string().required(t('global.errorMessage.required')),
    email: yup.string().required(t('global.errorMessage.required')),
    partnerCode: yup.string(),
    paymentCompany: yup.string(),
    retentionPercentage: yup.string(),
    zipcode: yup.string().required(t('global.errorMessage.required')),
    street: yup.string().required(t('global.errorMessage.required')),
    number: yup.string().required(t('global.errorMessage.required')),
    complement: yup.string(),
    referencePoint: yup.string(),
    district: yup.string().required(t('global.errorMessage.required')),
    bankAccountNumber: yup.string().required(t('global.errorMessage.required')),
    bankAccountDigit: yup.string().required(t('global.errorMessage.required')),
    agencyNumber: yup.string().required(t('global.errorMessage.required')),
    agencyDigit: yup.string(),
    bankType: yup.string().required(t('global.errorMessage.required')),
    pixType: yup.string(),
    pixKey: yup.string(),
    city: yup.string().required(t('global.errorMessage.required')),
    state: yup.string().required(t('global.errorMessage.required')),
  });

  const methods = useForm<Schema>({ resolver: yupResolver(schema) });

  const updateBranch = async (values: Schema) => {
    const city = {
      name: values.city,
      id: cities.find(city => city.name === values.city)?.id,
    } as City;

    const state = {
      name: values.state,
      id: states.find(state => state.name === values.state)?.id,
      acronym: states.find(state => state.name === values.state)?.acronym,
    } as State;

    let newBranch: Subsidiary = {
      id: Number(id),
      name: values.name,
      cnpj: values.cnpj,
      assignmentFee: values.assignmentFee && StringUtils.removeMoneyFormat(values.assignmentFee),
      phone: values.phone,
      email: values.email,
      partnerCode: values.partnerCode,
      paymentCompany: paymentCompanyOptions.find(item => item.name === values.paymentCompany),
      retentionPercentage: parseFloat(StringUtils.removeMoneyFormat(values.retentionPercentage)),
      bankAccount: {
        id: Number(branchData?.bankAccount?.id),
        bankAccountNumber: values.bankAccountNumber,
        bankAccountDigit: values.bankAccountDigit,
        agencyNumber: values.agencyNumber,
        agencyDigit: values.agencyDigit,
        jointAccount: jointAccount,
        type: bankAccountTypesOptions.find(item => item.label === values.bankType)?.value,
        bank: {
          id: chosenBankId ?? branchData?.bankAccount?.bank?.id,
        },
        pixType: pixOptions.find(item => item.label === values.pixType)?.value,
        pixKey: values.pixKey,
      },
      address: {
        street: values.street,
        number: values.number,
        zipcode: values.zipcode,
        complement: values.complement,
        referencePoint: values.referencePoint,
        district: values.district,
        uf: state.acronym,
        city,
      },
    };

    await SubsidiariesService()
      .updateBranch(Number(id), newBranch)
      .then(res => setEditMode(false));
  };

  return (
    <>
      <PageContainer>
        <Header variant={HeaderVariants.SECONDARY} />
        <TitleHeader>
          <Title>
            <div className="firstTitle">
              <button
                onClick={() => {
                  history.push('/admin/filial');
                }}
              >
                <FiChevronLeft color={color.primaryColor} />
              </button>
              <span className="title">{branchData?.name}</span>
            </div>
          </Title>
        </TitleHeader>

        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(formData => updateBranch(formData))}>
            <TabContainer>
              <TabContent>
                <ContentTitle>{t('stepper.title.general_data')}</ContentTitle>
                <InputBox>
                  <InputText
                    disabled={!editMode}
                    name="name"
                    label={t('register.inputs.branchName')}
                    placeholder={branchData?.name}
                    defaultValue={branchData?.name}
                  />

                  <InputText
                    name="cnpj"
                    label={t('register.inputs.cnpj')}
                    mask={Mask.CNPJ}
                    maxLength={19}
                    disabled={!editMode}
                    placeholder={branchData?.cnpj}
                    defaultValue={branchData?.cnpj}
                  />

                  <InputText
                    name="phone"
                    label={t('register.inputs.phone')}
                    placeholder={t('register.placeholder.phone')}
                    disabled={!editMode}
                    mask={Mask.PHONE}
                    maxLength={13}
                    defaultValue={branchData?.phone}
                  />
                  <InputText name="email" disabled={!editMode} label={t('register.inputs.email')} defaultValue={branchData?.email} />

                  <InputText
                    name="partnerCode"
                    label={t('register.inputs.partnerCode')}
                    placeholder={t('register.placeholder.insertPartnerCode')}
                    disabled={!editMode}
                    defaultValue={branchData?.partnerCode}
                  />
                </InputBox>
              </TabContent>

              <Separator />

              <TabContent>
                <ContentTitle>{t('stepper.title.address')}</ContentTitle>
                <InputBox>
                  <InputText
                    name="zipcode"
                    mask={Mask.CEP}
                    label={t('register.inputs.cep')}
                    placeholder={'00000-000'}
                    disabled={!editMode}
                    defaultValue={branchData?.address?.zipcode}
                    onChange={e => {
                      if (e.target.value.length === 9) {
                        getCepData(e.target.value);
                      }
                    }}
                  />
                  <InputText
                    name="street"
                    label={t('stepper.title.address')}
                    placeholder={t('stepper.title.address')}
                    disabled={!editMode}
                    defaultValue={initialClientData?.address?.street ?? branchData?.address?.street}
                  />
                  <InputText
                    name="number"
                    label={t('register.inputs.number')}
                    placeholder={'000'}
                    maxLength={5}
                    disabled={!editMode}
                    defaultValue={branchData?.address?.number}
                  />
                  <InputText
                    name="complement"
                    label={t('register.inputs.complement')}
                    placeholder={t('register.placeholder.complement')}
                    maxLength={15}
                    disabled={!editMode}
                    defaultValue={branchData?.address?.complement}
                  />
                  <InputText
                    name="referencePoint"
                    label={t('register.inputs.reference')}
                    placeholder={t('register.placeholder.reference')}
                    disabled={!editMode}
                    defaultValue={branchData?.address?.referencePoint}
                  />
                  <InputText
                    name="district"
                    label={t('register.inputs.district')}
                    placeholder={t('register.placeholder.district')}
                    maxLength={15}
                    disabled={!editMode}
                    defaultValue={initialClientData?.address?.district ?? branchData?.address?.district}
                  />
                  <TextInput name="state" label={t('register.inputs.state')} isRequired hasCustomInput>
                    <SearchSelectInput
                      name="state"
                      disabled={!editMode}
                      placeholder={t('register.placeholder.state')}
                      options={states.map(state => ({ label: state.name ?? '', value: state.id.toString() }))}
                      defaultOptions={states.map(state => ({ label: state.name ?? '', value: state.id.toString() }))}
                      handleChange={values => {
                        const state = states.find(st => st.name === values.name);
                        if (state != null) {
                          handleSetStateID(state.id);
                        }
                        setStateName(values.name);
                        setStatePage(0);
                        getStates({ name: values.name, size: 7 });
                      }}
                      handleOnScroll={() => {
                        if (statePage < statesTotalPages - 1) {
                          setStatePage(statePage + 1);
                        }
                      }}
                      defaultValue={initialClientData?.address?.city?.state?.name ?? branchData?.address?.city?.state?.name}
                    />
                  </TextInput>
                  <TextInput name="city" label={t('register.inputs.city')} isRequired hasCustomInput>
                    <SearchSelectInput
                      name="city"
                      disabled={!editMode}
                      placeholder={t('register.placeholder.city')}
                      options={cities.map(city => ({ label: city?.name ?? '', value: city.id.toString() }))}
                      defaultOptions={cities.map(city => ({ label: city?.name ?? '', value: city.id.toString() }))}
                      handleChange={values => {
                        setCityName(values.name);
                        setCityPage(0);
                        if (stateID) {
                          getCities(stateID, { name: values.name, size: 7 });
                        }
                      }}
                      handleOnScroll={() => {
                        if (cityPage < citiesTotalPages - 1) {
                          setCityPage(cityPage + 1);
                        }
                      }}
                      defaultValue={initialClientData?.address?.city?.name ?? branchData?.address?.city?.name}
                    />
                  </TextInput>
                </InputBox>
              </TabContent>

              <Separator />

              <TabContent>
                <ContentTitle>{t('stepper.title.bank_account_data')}</ContentTitle>
                <InputBox>
                  <InputText
                    name="name"
                    label={t('register.inputs.name')}
                    placeholder={t('register.placeholder.insertCompleteName')}
                    disabled
                    defaultValue={branchData?.name}
                  />
                  <InputText
                    name="cnpj"
                    disabled
                    defaultValue={branchData?.cnpj}
                    label={t('register.inputs.cpfcnpj')}
                    placeholder={t('register.placeholder.cnpj')}
                    mask={Mask.CNPJ}
                  />

                  <TextInput name="bank" label={t('register.inputs.bank')} isRequired hasCustomInput>
                    <SearchSelectInput
                      name="bank"
                      disabled={!editMode}
                      placeholder={t('register.placeholder.selectBank')}
                      options={bankNames.map(bank => ({ label: bank.name ?? '', value: bank?.id?.toString() ?? '' }))}
                      defaultOptions={bankNames.map(bank => ({ label: bank.name ?? '', value: bank?.id?.toString() ?? '' }))}
                      handleChange={values => {
                        setBankName(values.name);
                        setBankPage(0);
                        getBankNames(values.name);

                        if (values.value) {
                          setChosenBankId(parseInt(values.value));
                        }
                      }}
                      handleOnScroll={() => {
                        if (bankPage < banksTotalPages) {
                          setBankPage(bankPage + 1);
                        }
                      }}
                      defaultValue={branchData?.bankAccount?.bank?.name}
                    />
                  </TextInput>
                  <TextInput name="bankType" label={t('register.inputs.accountType')} isRequired hasCustomInput>
                    <SearchSelectInput
                      name="bankType"
                      disabled={!editMode}
                      placeholder={t('register.placeholder.selectBankType')}
                      options={bankAccountTypesOptions}
                      defaultOptions={bankAccountTypesOptions}
                      defaultValue={branchData?.bankAccount?.type && BankAccountType[branchData?.bankAccount?.type]}
                    />
                  </TextInput>
                  <InputText
                    name="bankAccountNumber"
                    label={t('register.inputs.account')}
                    placeholder={t('register.placeholder.account')}
                    mask={Mask.NUMBERS}
                    maxLength={7}
                    disabled={!editMode}
                    defaultValue={branchData?.bankAccount?.bankAccountNumber}
                  />
                  <InputText
                    name="bankAccountDigit"
                    label={t('register.inputs.digit')}
                    placeholder={t('register.placeholder.digit')}
                    mask={Mask.NUMBERS}
                    maxLength={3}
                    width={68}
                    flexWidth
                    disabled={!editMode}
                    defaultValue={branchData?.bankAccount?.bankAccountDigit}
                  />
                  <InputText
                    name="agencyNumber"
                    label={t('register.inputs.agency')}
                    placeholder={t('register.placeholder.account')}
                    mask={Mask.NUMBERS}
                    maxLength={7}
                    disabled={!editMode}
                    defaultValue={branchData?.bankAccount?.agencyNumber}
                  />
                  <InputText
                    name="agencyDigit"
                    label={t('register.inputs.digit')}
                    placeholder={t('register.placeholder.digit')}
                    mask={Mask.NUMBERS}
                    maxLength={3}
                    width={68}
                    flexWidth
                    disabled={!editMode}
                    defaultValue={branchData?.bankAccount?.agencyDigit}
                  />
                  <div style={{ placeSelf: 'self-start' }}>
                    <TextInput name="" label={t('register.inputs.isJoinAccount')} hasCustomInput>
                      <CheckBox
                        text=""
                        checked={jointAccount}
                        handleClick={() => {
                          editMode && setJointAccount(!jointAccount);
                        }}
                      />
                    </TextInput>
                  </div>

                  <TextInput name="pixType" label={t('register.inputs.pixType')} hasCustomInput>
                    <SearchSelectInput
                      defaultValue={defaultValuePixType}
                      disabled={!editMode}
                      name="pixType"
                      placeholder={t('register.placeholder.selctPixType')}
                      options={pixOptions}
                      defaultOptions={pixOptions}
                    />
                  </TextInput>
                  <InputText
                    name="pixKey"
                    label={t('register.inputs.pixKey')}
                    placeholder={t('register.placeholder.insertPix')}
                    disabled={!editMode}
                    defaultValue={branchData?.bankAccount?.pixKey}
                  />
                </InputBox>
              </TabContent>
              {(ORGANIZATION_NAME === OrganizationName.EVOLVE || ORGANIZATION_NAME === OrganizationName.MULTTIPLO) && (
                <>
                  <Separator />

                  <TabContent>
                    <ContentTitle>{t('stepper.title.add_infos')}</ContentTitle>
                    {ORGANIZATION_NAME === OrganizationName.MULTTIPLO && (
                      <InputBox>
                        <InputText
                          name="assignmentFee"
                          disabled={!editMode}
                          mask={Mask.PERCENTAGE}
                          defaultValue={parseFloat(branchData?.assignmentFee ?? '0')
                            ?.toFixed(2)
                            ?.toString()}
                          label={t('register.inputs.sessionTaxes')}
                          placeholder={'0,00%'}
                        />

                        <TextInput name="paymentCompany" label={t('register.inputs.depositCompany')} isRequired hasCustomInput>
                          <SearchSelectInput
                            name="paymentCompany"
                            disabled={paymentCompanyOptions.length === 0}
                            placeholder={t('register.placeholder.selectDepositCompany')}
                            options={paymentCompanyOptions.map(item => ({ label: item.name ?? '', value: item?.id?.toString() ?? '' }))}
                            defaultOptions={paymentCompanyOptions.map(item => ({
                              label: item.name ?? '',
                              value: item?.id?.toString() ?? '',
                            }))}
                            defaultValue={branchData?.paymentCompany && branchData?.paymentCompany.name}
                          />
                        </TextInput>
                      </InputBox>
                    )}
                    {ORGANIZATION_NAME === OrganizationName.EVOLVE && (
                      <InputBox>
                        <InputText
                          name="retentionPercentage"
                          disabled={!editMode}
                          mask={Mask.PERCENTAGE}
                          defaultValue={branchData?.retentionPercentage?.toString()}
                          label={t('register.inputs.retentionTaxes')}
                          placeholder={'0,00%'}
                        />
                      </InputBox>
                    )}
                  </TabContent>
                </>
              )}
            </TabContainer>
            <ButtonContainer>
              {editMode ? (
                <StyledButton type={'button'} onClick={() => setEditMode(!editMode)}>
                  {t('admin.dashboard.updateBranch')}
                </StyledButton>
              ) : (
                <StyledButton type={'submit'} onClick={() => setEditMode(!editMode)}>
                  {t('admin.dashboard.editBranch')}
                </StyledButton>
              )}
            </ButtonContainer>
          </form>
        </FormProvider>
      </PageContainer>
    </>
  );
};
