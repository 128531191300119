import ContainerEditDocument from 'components/container-edit-document';
import ContainerEditUploadedDocument from 'components/container-edit-uploaded-document';
import ReactHookFormProvider from 'components/ReactHookFormProvider';
import { Attachment } from 'model/attachment';
import { Client } from 'model/client';
import { AttachmentType } from 'model/enums/attachment-types';
import { useEditForm } from 'provider/edit-form';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import StringUtils from 'shared/util/string-utils';
import * as yup from 'yup';
import { Container, DocumentContainer, IdentityContainer, Title } from './styles';

interface IResumeScreenProps {
  isIdentificationDocumentOnly?: boolean;
  attachmentResume?: Attachment;
  onlyView?: boolean;
  visible?: boolean;
  mobile?: boolean;
}

export const EditSelfieScreen: React.FC<IResumeScreenProps> = props => {
  const { t } = useTranslation();
  const { onClickNext, files } = useEditForm();
  const schema = yup.object({
    attachmentSelfie: yup.mixed(),
  });
  const [requiredError, setRequiredError] = useState<string[]>([]);

  useEffect(() => {
    if (files.find(file => file.type === AttachmentType.SELFIE)) {
      setRequiredError([]);
    }
  }, [files]);

  return (
    <ReactHookFormProvider schema={schema} onSubmit={() => onClickNext({ attachments: files } as Client)}>
      <Container visible={props.visible}>
        <DocumentContainer>
          <Title>{t('documents.proofs.selfie')}</Title>
          <IdentityContainer>
            {!props.onlyView && (
              <ContainerEditDocument
                requiredError={requiredError.includes(AttachmentType.SELFIE)}
                nameInput="doc4"
                name="attachmentSelfie"
                type={AttachmentType.SELFIE}
                title={files && files.find(att => att.type === AttachmentType.SELFIE)?.fileName}
                docUrl={StringUtils.getFileOrUrl(files.find(att => att.type === AttachmentType.SELFIE))}
              />
            )}
            <ContainerEditUploadedDocument editDocument={!props.onlyView} attachmentType={AttachmentType.SELFIE} />
          </IdentityContainer>
        </DocumentContainer>
      </Container>
    </ReactHookFormProvider>
  );
};
