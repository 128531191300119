import ReactHookFormProvider from 'components/ReactHookFormProvider';
import ContainerDocument from 'components/container-document';
import ContainerUploadedDocument from 'components/container-uploaded-document';
import { Button, ButtonsContainer } from 'components/register-forms/styles';
import { Attachment } from 'model/attachment';
import { AttachmentType } from 'model/enums/attachment-types';
import { useRegisterForm } from 'provider/register-form';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import StringUtils from 'shared/util/string-utils';
import * as yup from 'yup';
import { Container, IdentityContainer, Subtitle, Title } from './styles';

interface IIncomeScreenProps {
  attachmentIncome?: Attachment;
  actualStep: number;
  setActualStep: (step: number) => void;
  handleVerifyEmptyFields: (field: string) => boolean;
}

export const IncomeScreen: React.FC<IIncomeScreenProps> = props => {
  const { t } = useTranslation();
  const { files } = useRegisterForm();
  const schema = yup.object({
    attachmentIncome: yup.mixed(),
  });
  const [requiredError, setRequiredError] = useState<boolean>(false);

  useEffect(() => {
    if (files.find(file => file.type === AttachmentType.PROOF_INCOME)) {
      setRequiredError(false);
    }
  }, [files]);

  const handleSubmit = () => {
    if (!props.handleVerifyEmptyFields(AttachmentType.PROOF_INCOME)) {
      props.setActualStep(props.actualStep + 1);
    } else {
      setRequiredError(true);
    }
  };

  return (
    <ReactHookFormProvider schema={schema} onSubmit={() => handleSubmit()}>
      <Container>
        <Title>{t('documents.proofs.incomeProof.title')}</Title>
        <Subtitle>{t('documents.proofs.incomeProof.subtitle')}</Subtitle>
        <IdentityContainer>
          <ContainerDocument
            requiredError={requiredError}
            titleDocument="documents.identification"
            editDocument
            nameInput="doc2"
            name="attachmentIncome"
            type={AttachmentType.PROOF_INCOME}
            title={files && files.find(att => att.type === AttachmentType.PROOF_INCOME)?.fileName}
            docUrl={StringUtils.getFileOrUrl(files.find(att => att.type === AttachmentType.PROOF_INCOME))}
          />
          <ContainerUploadedDocument editDocument attachmentType={AttachmentType.PROOF_INCOME} />
        </IdentityContainer>
        <ButtonsContainer>
          {props.actualStep !== 0 ? (
            <Button onClick={() => props.setActualStep(props.actualStep - 1)}>
              <span>{t('global.button.backStep')}</span>
            </Button>
          ) : (
            <div />
          )}
          <Button primary type="submit">
            <span>{t('global.button.nextStep')}</span>
          </Button>
        </ButtonsContainer>
      </Container>
    </ReactHookFormProvider>
  );
};
