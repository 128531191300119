import LoanGracePeriodComponent from 'components/loan-grace-period-component';
import Slider from 'components/slider';
import { useConfirmSimulationContext } from 'context/confirm-simulation';
import { useSimulatorContext } from 'context/simulator';
import useSimulatorStorage from 'hooks/use-simulator-storage';
import { useSystemParameters } from 'hooks/use-system-parameters';
import { GeneralImages } from 'images/general-images';
import { FlowMap } from 'model/enums/organization';
import { RoutePath } from 'model/enums/route-path';
import { FinancingSimpleSimulation } from 'model/financing';
import { SimulatorParameters } from 'model/landing-page';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import landingPageService from 'services/landing-page-service';
import { formatDateToLocalFormat } from 'shared/util/date-utils';
import { convertCurrencyStringToNumber } from 'shared/util/number-utils';
import { unMaskedCurrency } from 'shared/util/register-utils';
import StringUtils from 'shared/util/string-utils';
import { useSendSimulation } from '../hooks/use-send-simulation';
import * as S from './styled';

interface SimulatorProps {}

const Simulator: React.FC<SimulatorProps> = ({}) => {
  const sliderInputRef = useRef<HTMLInputElement>(null);
  const { simulatorScreenData, simulatorLimits } = useSimulatorContext();
  const { systemParameters } = useSystemParameters();
  const [desiredValue, setDesiredValue] = useState<string>('');
  const [installments, setInstallments] = useState<number>(0);
  const [maxInstallment, setMaxInstallment] = useState<number>();
  const [exeptionMinInstallment, setExceptionMinInstallment] = useState<number>();
  const [currentTax, setCurrentTax] = useState<number>();
  const [simulatorParameters, setSimulatorParameters] = useState<SimulatorParameters>();
  const [chosenFirstDueDateInDaysId, setChosenFirstDueDateInDaysId] = useState<number>();
  const { clientId } = useParams<{ clientId: string }>();

  const { setHasSimulationValueChanged, hasSimulationValueChanged, setNewSimulation } = useConfirmSimulationContext();
  const { sendSimulationWithInsuranceOrWithout: handleSendToSimulations } = useSendSimulation();

  const { getClientSimulatorInfoFromStorage } = useSimulatorStorage();

  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    if (!simulatorLimits?.newMaxValue) {
      return history.push(RoutePath.HOME);
    }

    let simulatorInfo = getClientSimulatorInfoFromStorage(clientId) as FinancingSimpleSimulation;
    if (simulatorInfo) {
      simulatorInfo.installmentsTotal && setInstallments(simulatorInfo.installmentsTotal);
      simulatorInfo.requestedValue && setDesiredValue(simulatorInfo.requestedValue);

      void handleNewSimulation(simulatorInfo);
    }

    landingPageService.getSimulatorParameters().then(data => {
      setSimulatorParameters(data);
      setChosenFirstDueDateInDaysId(data.firstDueDateInDays.find(option => option.isDefault)?.id ?? data.firstDueDateInDays[0]?.id);
      const getMaxValue = data.simulatorTaxes.sort((a, b) => a.maxInstallment - b.maxInstallment)[data.simulatorTaxes.length - 1]
        .maxInstallment;
      setMaxInstallment(getMaxValue);
      const getMinValue = data.simulatorTaxes.sort((a, b) => a.minInstallment - b.minInstallment)[0].minInstallment;
      setExceptionMinInstallment(getMinValue);
    });
  }, []);

  useEffect(() => {
    if (sliderInputRef.current) {
      sliderInputRef.current.focus();
    }
  }, [sliderInputRef]);

  useEffect(() => {
    if (simulatorScreenData?.simulate) {
      setDesiredValue(simulatorScreenData?.simulate.requestedValue?.toFixed(2).toString());
    }
  }, [simulatorScreenData]);

  const calculateDaysRemaining = (firstDueDate: string) => {
    let currentDate = new Date();
    let dueDate = new Date(firstDueDate);

    let timeDifference: number = dueDate.getTime() - currentDate.getTime();

    let daysRemaining = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

    return daysRemaining + 1;
  };

  useEffect(() => {
    if (simulatorScreenData?.simulate) {
      const equalValue = unMaskedCurrency(desiredValue) === unMaskedCurrency(simulatorScreenData?.simulate?.requestedValue?.toFixed(2));
      const equalInstallment = installments === simulatorScreenData?.simulate?.installmentsNumber;
      const equalChosenFirstDueDateInDaysId =
        chosenFirstDueDateInDaysId ===
        (simulatorParameters?.firstDueDateInDays.find(
          option => option.days === calculateDaysRemaining(simulatorScreenData?.simulate?.firstDueDate ?? '')
        )?.id ?? simulatorParameters?.firstDueDateInDays[0]?.id);

      const hasAnyValueChanged = !equalInstallment || !equalValue || !equalChosenFirstDueDateInDaysId;

      if (!hasAnyValueChanged) {
        setHasSimulationValueChanged(false);
      } else {
        setHasSimulationValueChanged(true);

        const simulatorInfo = getClientSimulatorInfoFromStorage(clientId) as FinancingSimpleSimulation;

        if (simulatorInfo) {
          simulatorInfo.choosedValue = unMaskedCurrency(desiredValue) / 100;
          simulatorInfo.id = simulatorScreenData?.financingId;
          simulatorInfo.installmentsTotal = installments;
          simulatorInfo.firstDueDateInDaysId = chosenFirstDueDateInDaysId;

          setNewSimulation(simulatorInfo);
        }
      }
    }
  }, [installments, desiredValue, simulatorScreenData, chosenFirstDueDateInDaysId]);

  useEffect(() => {
    if (simulatorParameters?.simulatorTaxes) {
      const findTax = simulatorParameters?.simulatorTaxes.find(item => {
        if (installments >= item.minInstallment && installments <= item.maxInstallment) {
          return item.tax;
        }
      });
      setCurrentTax(findTax?.tax);
    }
  }, [installments, simulatorParameters]);

  const getFirstDueDatePreview = () => {
    const daysFromFirstDueDateSelected = simulatorParameters?.firstDueDateInDays.find(
      option => option.id === chosenFirstDueDateInDaysId
    )?.days;

    if (daysFromFirstDueDateSelected) {
      let dateNow = new Date(Date.now());
      dateNow.setDate(dateNow.getDate() + daysFromFirstDueDateSelected);
      return formatDateToLocalFormat(dateNow);
    }
  };

  const paymentInformation = [
    {
      icon: GeneralImages.whiteCalendar,
      title: t('simulatorFLow.simulator.firstDueDate'),
      value: getFirstDueDatePreview() ?? t('simulatorFLow.simulator.none'),
    },
    {
      icon: GeneralImages.whiteCreditCard,
      title: t('simulatorFLow.simulator.installmentsOf'),
      installment: true,
      value:
        simulatorScreenData?.simulate && !hasSimulationValueChanged && desiredValue != null
          ? StringUtils.currencyMask(simulatorScreenData?.simulate?.firstInstallmentValue?.toFixed(2))
          : t('simulatorFLow.simulator.none'),
    },
  ];

  const handleChange = (event: React.SyntheticEvent | Event, newValue: number | number[]) => {
    if (typeof newValue === 'number') {
      setInstallments(newValue);
    }
  };

  const handleChangeDesiredValue = (event: React.SyntheticEvent | Event, newValue: number | number[]) => {
    if (typeof newValue === 'number') {
      setDesiredValue(StringUtils.currencyMask(newValue.toFixed(2)));
    }
  };

  const renderTax = () => {
    switch (systemParameters) {
      case FlowMap.DEFAULT:
        return (
          <>
            <S.StyledIcon customStyle={{ backgroundImage: GeneralImages.alertCircle }} />
            <S.StyledRate>{t('simulatorData.confirmSimulationTax', { currentTax })}</S.StyledRate>
          </>
        );
      default:
        return <></>;
    }
  };

  const handleNewSimulation = async (simulatorInfo: FinancingSimpleSimulation) => {
    try {
      await handleSendToSimulations(simulatorInfo);
    } catch (error) {
      console.error(error);
    }
  };

  const valueChange = e => {
    if (simulatorParameters) {
      setDesiredValue(StringUtils.currencyMask(e.target.value));
    }
  };

  const handleBlur = e => {
    if (simulatorParameters) {
      let desiredValueInNumber = convertCurrencyStringToNumber(e.target.value);
      if (desiredValueInNumber) {
        desiredValueInNumber = Math.min(desiredValueInNumber, simulatorLimits.newMaxValue ?? desiredValueInNumber);
        desiredValueInNumber = Math.max(desiredValueInNumber, simulatorParameters.minValue);
        setDesiredValue(StringUtils.currencyMask(desiredValueInNumber.toFixed(2).toString()));
      }
    }
  };

  return (
    <S.SimulatorContainer>
      <S.StyledRateBox>{renderTax()}</S.StyledRateBox>
      <>
        <S.SliderTitleContainer>
          <S.SliderTitle>{t('simulatorFLow.simulator.title')}</S.SliderTitle>
          <S.SliderValueInput
            ref={sliderInputRef}
            value={StringUtils.currencyMask(desiredValue)}
            onChange={valueChange}
            onBlur={handleBlur}
          />
        </S.SliderTitleContainer>
        <Slider
          min={simulatorParameters?.minValue}
          max={simulatorLimits.newMaxValue}
          value={convertCurrencyStringToNumber(desiredValue) ?? 0}
          handleChange={handleChangeDesiredValue}
        />
      </>

      <S.SliderTitleContainer>
        <S.SliderTitle>{t('simulatorFLow.simulator.installmentsNumber')}</S.SliderTitle>
        <S.SliderValue>{installments}</S.SliderValue>
      </S.SliderTitleContainer>
      <Slider
        min={simulatorLimits.newMinInstallment}
        max={simulatorLimits.newMaxInstallment ? simulatorLimits.newMaxInstallment : maxInstallment}
        value={installments}
        handleChange={handleChange}
      />

      {simulatorParameters && simulatorParameters.firstDueDateInDays.length > 1 && (
        <LoanGracePeriodComponent
          setChosenFirstDueDateInDaysId={setChosenFirstDueDateInDaysId}
          firstDueDateInDays={simulatorParameters.firstDueDateInDays.sort((a, b) => {
            return a?.days - b?.days;
          })}
        />
      )}

      <S.PaymentInformationContainer>
        {paymentInformation.map((paymentInformation, idx) => (
          <S.PaymentInformationBox key={idx}>
            <S.PaymentInformationHeader>
              <S.StyledIcon customStyle={{ backgroundImage: paymentInformation.icon }} />
              <S.PaymentInformationTitle>{paymentInformation.title}</S.PaymentInformationTitle>
            </S.PaymentInformationHeader>
            {!paymentInformation.installment ? (
              <S.PaymentInformationText>{paymentInformation.value}</S.PaymentInformationText>
            ) : (
              <S.PaymentInformationText>{paymentInformation.value}</S.PaymentInformationText>
            )}
          </S.PaymentInformationBox>
        ))}
      </S.PaymentInformationContainer>
      <S.SimulatorInfo>{t('simulatorFLow.simulator.simulatorInfo')}</S.SimulatorInfo>
    </S.SimulatorContainer>
  );
};

export default Simulator;
