import { FormInputsContainer, Row } from 'components/edit-forms/styles';
import { Column } from 'components/register-forms-mobile/styles';
import { SearchSelectInput } from 'components/search-selected-input';
import InputText from 'components/styled-input';
import { TextInput } from 'components/text-input';
import { Mask } from 'model/enums/mask-types';
import { useEditForm } from 'provider/edit-form';
import { useSelectLists } from 'provider/select-list';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import StringUtils from 'shared/util/string-utils';

export const ContainerInputsGeneralData = props => {
  const { t } = useTranslation();
  const { initialClientData } = useEditForm();
  const { getMainActivities, mainActivities, mainActivitiesTotalPages } = useSelectLists();
  const [mainActivitiesPage, setMainActivitiesPage] = useState(0);
  const [mainActivityName, setMainActivityName] = useState('');

  useEffect(() => {
    getMainActivities({ name: mainActivityName, page: mainActivitiesPage, size: 7 }, true);
  }, [mainActivitiesPage]);

  const renderMobile = () => {
    return (
      <Column>
        <InputText
          isRequired
          disabled={props.onlyView}
          name="responsibleName"
          label={t('enums.campNames.responsibleName')}
          placeholder={t('register.placeholder.name')}
          defaultValue={initialClientData?.legalPerson?.responsibleName}
        />
        <InputText
          isRequired
          disabled={props.onlyView}
          name="formationDate"
          label={t('enums.campNames.criationDate')}
          placeholder={t('enums.campNames.criationDate')}
          maxLength={10}
          mask={Mask.BIRTHDAY}
          defaultValue={
            initialClientData?.legalPerson?.formationDate
              ? StringUtils.dateFormatMask(initialClientData?.legalPerson?.formationDate.toString())
              : ''
          }
        />
        <InputText
          isRequired
          name="cnpj"
          label={t('register.inputs.cnpj')}
          placeholder={t('register.placeholder.cnpj')}
          mask={Mask.CNPJ}
          maxLength={19}
          defaultValue={initialClientData?.legalPerson?.cnpj}
          disabled
        />
        <InputText
          isRequired
          disabled={props.onlyView}
          name="corporateName"
          label={t('register.inputs.corporateName')}
          placeholder={t('register.placeholder.corporateName')}
          mask={Mask.DEFAULT}
          maxLength={150}
          defaultValue={initialClientData?.legalPerson?.corporateName}
        />
        <TextInput name="mainActivity" label={t('register.inputs.mainActivity')} isRequired hasCustomInput>
          <SearchSelectInput
            disabled={props.onlyView}
            name="mainActivity"
            placeholder={t('register.inputs.mainActivity')}
            defaultValue={initialClientData?.legalPerson?.mainActivity?.name}
            options={mainActivities.map(activity => ({ label: activity?.name ?? '', value: activity.id.toString() }))}
            defaultOptions={mainActivities.map(activity => ({ label: activity?.name ?? '', value: activity.id.toString() }))}
            handleChange={values => {
              setMainActivityName(values.name);
              setMainActivitiesPage(0);
              getMainActivities({ name: values.name, size: 7 });
            }}
            handleOnScroll={() => {
              if (mainActivitiesPage < mainActivitiesTotalPages - 1) {
                setMainActivitiesPage(mainActivitiesPage + 1);
              }
            }}
          />
        </TextInput>
        <InputText
          isRequired
          disabled={props.onlyView}
          name="email"
          label={t('register.inputs.email')}
          placeholder={t('register.placeholder.email')}
          defaultValue={initialClientData?.email}
        />
      </Column>
    );
  };
  return (
    <FormInputsContainer visible={props.visible}>
      {props.mobile ? (
        renderMobile()
      ) : (
        <>
          <Row>
            <InputText
              isRequired
              disabled={props.onlyView}
              name="responsibleName"
              label={t('enums.campNames.responsibleName')}
              placeholder={t('register.placeholder.name')}
              defaultValue={initialClientData?.legalPerson?.responsibleName}
            />
            <InputText
              isRequired
              disabled={props.onlyView}
              name="formationDate"
              label={t('enums.campNames.criationDate')}
              placeholder={t('enums.campNames.criationDate')}
              maxLength={10}
              mask={Mask.BIRTHDAY}
              defaultValue={
                initialClientData?.legalPerson?.formationDate
                  ? StringUtils.dateFormatMask(initialClientData?.legalPerson?.formationDate.toString())
                  : ''
              }
            />
            <InputText
              isRequired
              name="cnpj"
              label={t('register.inputs.cnpj')}
              placeholder={t('register.placeholder.cnpj')}
              mask={Mask.CNPJ}
              maxLength={19}
              defaultValue={initialClientData?.legalPerson?.cnpj}
              disabled
            />
            <InputText
              isRequired
              disabled={props.onlyView}
              name="corporateName"
              label={t('register.inputs.corporateName')}
              placeholder={t('register.placeholder.corporateName')}
              mask={Mask.DEFAULT}
              maxLength={150}
              defaultValue={initialClientData?.legalPerson?.corporateName}
            />
            <TextInput name="mainActivity" label={t('register.inputs.mainActivity')} isRequired hasCustomInput>
              <SearchSelectInput
                name="mainActivity"
                disabled={props.onlyView}
                placeholder={t('register.inputs.mainActivity')}
                defaultValue={initialClientData?.legalPerson?.mainActivity?.name}
                options={mainActivities.map(activity => ({ label: activity?.name ?? '', value: activity.id.toString() }))}
                defaultOptions={mainActivities.map(activity => ({ label: activity?.name ?? '', value: activity.id.toString() }))}
                handleChange={values => {
                  setMainActivityName(values.name);
                  setMainActivitiesPage(0);
                  getMainActivities({ name: values.name, size: 7 });
                }}
                handleOnScroll={() => {
                  if (mainActivitiesPage < mainActivitiesTotalPages - 1) {
                    setMainActivitiesPage(mainActivitiesPage + 1);
                  }
                }}
              />
            </TextInput>
            <InputText
              isRequired
              disabled={props.onlyView}
              name="email"
              label={t('register.inputs.email')}
              placeholder={t('register.placeholder.email')}
              defaultValue={initialClientData?.email}
            />
          </Row>
        </>
      )}
    </FormInputsContainer>
  );
};
