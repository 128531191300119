import { combineReducers } from 'redux';
import { all, fork } from 'redux-saga/effects';
import { authenticationReducer } from './authentication/reducer';
import authenticationSaga from './authentication/sagas';
import { AuthenticationState } from './authentication/types';
import { createEvolveGuaranteeReducer } from './evolve-guarantee/create-evolve-guarantee/reducer';
import createEvolveGuaranteeSaga from './evolve-guarantee/create-evolve-guarantee/sagas';
import { CreateEvolveGuaranteeState } from './evolve-guarantee/create-evolve-guarantee/types';
import { detailEvolveGuaranteeReducer } from './evolve-guarantee/detail-evolve-guarantee/reducer';
import detailEvolveGuaranteeSaga from './evolve-guarantee/detail-evolve-guarantee/sagas';
import { DetailEvolveGuaranteeState } from './evolve-guarantee/detail-evolve-guarantee/types';
import { updateEvolveGuaranteeReducer } from './evolve-guarantee/update-evolve-guarantee/reducer';
import updateEvolveGuaranteeSaga from './evolve-guarantee/update-evolve-guarantee/sagas';
import { UpdateEvolveGuaranteeState } from './evolve-guarantee/update-evolve-guarantee/types';
import { globalModalReducer } from './globalModal/reducer';
import { GlobalModalState } from './globalModal/types';
import { landingPageTemplateReducer } from './landing-page-template/reducer';
import landingPageTemplateSaga from './landing-page-template/sagas';
import { LandingPageTemplateState } from './landing-page-template/types';
import { organizationSubsidiaryReducer } from './organization-subsidiaries/reducer';
import organizationSubsidiarySaga from './organization-subsidiaries/sagas';
import { SubsidiaryState } from './organization-subsidiaries/types';
import { registerReducer } from './register/reducer';
import registerSaga from './register/sagas';
import { RegisterState } from './register/types';
import { searchReducer } from './search/reducer';
import { SearchState } from './search/types';
import { systemParametersReducer } from './system-parameters/reducer';
import systemParametersSaga from './system-parameters/sagas';
import { SystemParametersState } from './system-parameters/types';

export interface IRootState {
  readonly authentication: AuthenticationState;
  readonly register: RegisterState;
  readonly search: SearchState;
  readonly globalModal: GlobalModalState;
  readonly systemParameters: SystemParametersState;
  readonly landingPageTemplate: LandingPageTemplateState;
  readonly organizationSubsidiary: SubsidiaryState;
  readonly updateEvolveGuarantee: UpdateEvolveGuaranteeState;
  readonly createEvolveGuarantee: CreateEvolveGuaranteeState;
  readonly detailEvolveGuarantee: DetailEvolveGuaranteeState;
}

const rootReducer = combineReducers<IRootState>({
  authentication: authenticationReducer,
  register: registerReducer,
  search: searchReducer,
  globalModal: globalModalReducer,
  systemParameters: systemParametersReducer,
  landingPageTemplate: landingPageTemplateReducer,
  organizationSubsidiary: organizationSubsidiaryReducer,
  updateEvolveGuarantee: updateEvolveGuaranteeReducer,
  createEvolveGuarantee: createEvolveGuaranteeReducer,
  detailEvolveGuarantee: detailEvolveGuaranteeReducer,
});

export function* rootSaga() {
  yield all([
    fork(authenticationSaga),
    fork(registerSaga),
    fork(systemParametersSaga),
    fork(landingPageTemplateSaga),
    fork(organizationSubsidiarySaga),
    fork(updateEvolveGuaranteeSaga),
    fork(createEvolveGuaranteeSaga),
    fork(detailEvolveGuaranteeSaga),
  ]);
}

export default rootReducer;
